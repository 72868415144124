<template>
  <div>
    <Row :gutter="8" class="m-b-10">
      <i-col span="24">
        <Alert v-show="nearDemandEntity.orderQauntity !== 0" type="error" show-icon>
          待上刊设置提醒
          <Icon type="ios-alert-outline" size="32" slot="icon" />
          <template slot="desc">
            您有新的待上刊设置，包含(<span class="text-red">{{ nearDemandEntity.orderQauntity }}</span>个订单 , <span
              class="text-green">{{ nearDemandEntity.resourceQauntity }}</span>个资源) 请及时设置上刊需求，提示规则为：档期开始前14天进行提醒。
            <Icon type="md-arrow-forward" class="m-l-10" /><a @click="handleSearch(true)">点击显示待上刊设置订单</a>
            <Icon type="md-arrow-back" />
          </template>
        </Alert>
      </i-col>
    </Row>
    <Row :gutter="8" class="m-b-10">
      <i-col span="4">
        <Select v-model="query.type" size="small" placeholder="发布类型" :clearable="true">
          <Option v-for="item in orderTypeArray" :key="'type_' + item.type" :value="item.type">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <DatePicker :show-week-numbers="true" size="small" type="daterange" v-model="orderSchdule"
          @on-change="scheduleChange" style="width: 100%" placement="top-start" placeholder="订单档期"></DatePicker>
      </i-col>
      <i-col span="6">
        <i-input size="small" placeholder="客户名称、投放品牌、备注" v-model='query.keyword'></i-input>
      </i-col>
      <i-col span="6">
        <Button size="small" icon="ios-search" type="primary" class="m-r-5" @click="handleSearch()">搜索</Button>
        <!-- <Button
          type="success"
          @click="showTask"
        >轨行区作业令显示</Button> -->
      </i-col>
    </Row>
    <Table stripe max-height="620" size="small" :data="tableList" :columns="tableColumns" :loading="dataLoading">
    </Table>
    <div class="paging_style">
      <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator :current="query.pageNumber"
        @on-change="changePage"></Page>
    </div>

    <purchase-resource-list ref="purchaseResourceList" />
  </div>
</template>

<script>
import purchaseResourceList from '@/components/order/common/PurchaseResourceList'

import { sysMixins } from '@/assets/mixins/sys'
import { GetCurrentSchedule, GetDateStr } from '@/utils/dateFormat'
import { getOrderTypeList } from '@/api/order/order'
import { getNearDemandQuantity, getMspOrderPage } from '@/api/order/demand'

export default {
  mixins: [sysMixins],
  props: {
    serviceType: { // 服务对象类型，1：销售，2：代理商
      type: Number,
      default: 1
    }
  },
  components: { purchaseResourceList },
  data () {
    return {
      nearDemandEntity: {
        orderQauntity: 0,
        resourceQauntity: 0
      },
      orderTypeArray: [],
      query: {
        pageNumber: 1,
        pageSize: 15,
        companyId: null,
        userId: null,
        // status: JSON.stringify([4]),
        type: null,
        startDate: '',
        endDate: '',
        keyword: '',
        // locked: true,
        // showProductQuantity: true,
        nearDemand: false
      },
      orderSchdule: '',
      tableList: [],
      total: 0,
      tableColumns: [
        { title: '客户名称', key: 'advertiserName' },
        { title: '发布品牌', key: 'brandName' },
        { title: '发布类型', key: 'typeName' },
        // { title: '备注', key: 'remark' },
        {
          title: '投放档期',
          key: 'schedule',
          render: (h, params) => {
            const html = []
            params.row.scheduleVOList.forEach(item => {
              html.push(h('p', GetCurrentSchedule(item.startDate, item.endDate)))
            })
            return h('div', html)
          }
        },
        {
          title: '采购资源统计',
          key: 'Statistic',
          width: 150,
          render: (h, params) => {
            const spans = []
            params.row.orderProductQuantityBeanList.forEach(resource => {
              spans.push(h(
                'span',
                resource.productName
              ))
              spans.push(h(
                'span',
                {
                  attrs: {
                    class: 'text-orange text-12'
                  }
                },
                resource.quantity
              ))
              spans.push(h(
                'span',
                resource.unitName
              ))
              spans.push(h('br'))
            })
            return h('div', {
              attrs: {
                class: 'text-href'
              },
              on: {
                click: () => {
                  this.$refs.purchaseResourceList.showProductSkuList(params.row.id)
                }
              }
            }, spans)
          }
        },
        // {
        //   title: '素材上传次数 / 审过次数',
        //   align: 'center',
        //   render: (h, params) => {
        //     return h('span', `${params.row.fileApprovalTotalCount} / ${params.row.fileApprovalPassedCount}`)
        //   }
        // },
        {
          title: '总数 / 已派单 / 执行中 / 已完成 / 已下刊',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('span', {
                style: {
                  color: (params.row.draftCount) > 0 ? '#ef4f4f' : ''
                }
              }, params.row.mspTotalCount),
              h('span', ` / ${params.row.dispatchCount} / ${params.row.executeCount} / ${params.row.finishCount} / ${params.row.uninstallCount}`)
            ])
            // return h('span', `${params.row.mspUnSubmittedCount} / ${params.row.mspTotalCount}`)
          }
        },
        { title: '备注', align: 'center', key: 'remark', ellipsis: true, tooltip: true },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.handleViewDetial(params.row)
                  }
                }
              }, '详情'
              )
            ])
          }
        }
      ],
      dataLoading: false
    }
  },
  created () {
    // 如果存在搜索条件状态，则取之
    if (this.$store.state.installSetting.indexSearchParams) {
      this.query = this.$store.state.installSetting.indexSearchParams
      this.orderSchdule = [this.query.startDate, this.query.endDate]
    }
    this.query.userId = this.serviceType === 1 ? this.$store.getters.token.userInfo.userId : null
    this.getNearDemand()
    this.initData()

    // 设置头部路由标签
    this.setLinkTagArray()
    this.setLinkTagArray({ key: 'installIndex', value: '上刊列表' })
    this.setLinkRouterMaps(['installIndex', () => { this.handleIndexPage() }])
    this.setActivedTagName('installIndex')
  },
  methods: {
    initData () {
      // 保存搜索条件状态
      this.dataLoading = true
      this.$store.commit('set_index_search_params', this.query)
      getMspOrderPage(this.query).then(res => {
        if (res && !res.errcode) {
          this.tableList = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.tableList = []
          this.total = 0
          this.query.pageNumber = 1
        }
      }).finally(() => { this.dataLoading = false })
    },
    changePage (curpage) {
      this.query.pageNumber = curpage
      this.initData()
    },
    /**
     * 获取临近档期需要上刊数量
     */
    getNearDemand () {
      const query = { userId: this.query.userId }
      getNearDemandQuantity(query).then(res => {
        if (res && !res.errcode) {
          this.nearDemandEntity = res
        }
      })

      getOrderTypeList().then(res => {
        this.orderTypeArray = res
      })
    },
    scheduleChange (date) {
      const shcedule = this.orderSchdule
      if (shcedule[0] === '' || shcedule[1] === '') {
        this.query.startDate = ''
        this.query.endDate = ''
      } else {
        this.query.startDate = GetDateStr(shcedule[0], 1)
        this.query.endDate = GetDateStr(shcedule[1], 1)
      }
    },
    handleSearch (nearDemand = false) {
      this.query.nearDemand = nearDemand
      this.query.pageNumber = 1
      this.initData()
    },
    /**
     * 查看轨行区作业令
     */
    showTask () {

    },
    /**
     * 查看详情
     */
    handleViewDetial (params) {
      this.$store.commit('set_demand_orderId', params.id)
      this.$store.commit('set_demand_type', null)
      this.handleDetailPage()
      // 设置头部路由标签
      // this.setLinkTagArray({ key: 'installList', value: '上刊设置列表', actived: true })
      this.setLinkRouterMaps(['installList', () => { this.handleDetailPage() }])
    },
    handleIndexPage () {
      this.setShowLeftBlock(false)
      this.setShowRightBlock(false)
      this.setShowMap(false)
      this.setBottomHeight('90%')
      this.setLeftComponent('')
      this.setRightComponent('')
      this.setBottomComponent('IndexTable')
    },
    /**
     * 处理页面显示
     */
    handleDetailPage () {
      this.setShowLeftBlock(true)
      this.setLeftComponent('MaterialList')
      this.setShowRightBlock(true)
      this.setRightComponent('InstallSummary')
      this.setBottomComponent('InstallDetail')
    }
  }
}
</script>
