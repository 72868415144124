import { dictionary } from '@/api/sys/dictbiz'
import { getCompanyChild } from '@/api/os/company'
export const projectMixins = {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      companyId: this.$store.getters.token.userInfo.companyId,
      platProductArray: [],
      childCompanyArray: []
    }
  },
  methods: {
    /**
     * 获取平台产品
     */
    async getPlatProductData () {
      const query = {
        code: 'platform_product',
        publisherId: this.publisherId
      }
      const result = await dictionary(query)
      this.platProductArray = (result && result.success) ? result.data : []
    },
    /**
     * 获取下属公司
     */
    async getChildCompanyData () {
      const query = {
        publisherId: this.publisherId,
        enabled: true,
        companyId: this.companyId
      }
      const result = await getCompanyChild(query)
      this.childCompanyArray = (result && !result.errcode) ? result : []
    }
  }
}
