import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 临近上刊订单数量、资源数量
 * @param {Object} data
 * @returns
 */
export function getNearDemandQuantity (data) {
  return request.post('/ooh-order/v1/demand/getneardemandquantity', qs.stringify(data))
}

/**
 * 分页获取上刊设置列表
 * @param {Object} data
 * @returns
 */
export function getMspOrderPage (data) {
  return request.post('/ooh-order/v1/demand/getmsporderpage', qs.stringify(data))
}

/**
 * 分页获取任务列表
 * @param {Object} data
 * @returns
 */
export function getTaskSortPage (data) {
  return request.post('/ooh-order/v1/demand/gettasksortpage', qs.stringify(data))
}

/**
 * 打开任务创建下刊任务开关
 * @param {Object} data
 * @returns
 */
export function openCreateUninstallFlag (data) {
  return request.post('/ooh-order/v1/demand/openCreateUninstallFlag', qs.stringify(data))
}

/**
 * 基于同一订单内上刊申请 发起监播申请
 * @param {Object} data
 * @returns
 */
export function createInspectTask (data) {
  return request.post('/ooh-order/v1/demand/createinspecttask', qs.stringify(data))
}

/**
 * 任务进度统计
 * @param {Object} data
 * @returns
 */
export function taskProgress (data) {
  return request.post('/ooh-order/v1/demand/taskprogress', qs.stringify(data))
}

/**
 * 获取需求产品列表(只有来自于订单才有值)
 * @param {Object} data
 * @returns
 */
export function getProductList (data) {
  return request.post('/ooh-order/v1/demand/getproductlist', qs.stringify(data))
}

/**
 * 分页获取任务项
 * @param {Object} data
 * @returns
 */
export function getTaskItemPage (data) {
  return request.post('/ooh-order/v1/demand/gettaskitempage', qs.stringify(data))
}

/**
 * 获取资源序列号，及对应的画面信息
 * @param {Object} data
 * @returns
 */
export function getSerialsInfo (data) {
  return request.post('/ooh-order/v1/demand/getserialsinfo', qs.stringify(data))
}

/**
 * 作业项目带素材更新（noPictureFill参数和fileKeys、picturesourc只能传一个）
 * @param {Object} data
 * @returns
 */
export function updateTaskitemFile (data) {
  return request.post('/ooh-order/v1/demand/updatetaskitemfile', qs.stringify(data))
}

/**
 * 更新作业为连刊
 * @param {Object} data
 * @returns
 */
export function updateLx (data) {
  return request.post('/ooh-order/v1/demand/updatelx', qs.stringify(data))
}

/**
 * 提交申请 支持提交后再重复提交
 * @param {Object} data
 * @returns
 */
export function submit (data) {
  return request.post('/ooh-order/v1/demand/submit', qs.stringify(data))
}

/**
 * 分页获取正在发布中的任务项。
 * @param {Object} data
 * @returns
 */
export function getPublishingTaskitemPage (data) {
  return request.post('/ooh-order/v1/demand/getpublishingtaskitempage', qs.stringify(data))
}

/**
 * 分页获取点位下一档期任务信息
 * @param {Object} data
 * @returns
 */
export function getNextTaskitemPage (data) {
  return request.post('/ooh-order/v1/demand/getnexttaskitempage', qs.stringify(data))
}

/**
 * 查询任务中包含的站点集合
 * @param {Object} data
 * @returns
 */
export function getTaskStationList (data) {
  return request.post('/ooh-order/v1/demand/gettaskstationlist', qs.stringify(data))
}

/**
 * 查询站点指定楼层点位的在刊画面信息
 * @param {Object} data
 * @returns
 */
export function getStationPublishingDeviceList (data) {
  return request.post('/ooh-order/v1/demand/getstationpublishingdevicelist', qs.stringify(data))
}

/**
 * 发起 上刊需求任务撤回申请
 * @param {Object} data
 * @returns
 */
export function applyRevokeTaskitem (data) {
  return request.post('/ooh-order/v1/demand/applyrevoketaskitem', qs.stringify(data))
}

/**
 * 获取上刊需求产品的资产站点统计(只有来自于订单才有值)
 * @param {Object} data
 * @returns
 */
export function getResourceParameter (data) {
  return request.post('/ooh-order/v1/demand/getresourceparameter', qs.stringify(data))
}

/**
 * 创建换刊需求
 * @param {Object} data
 * @returns
 */
export function createOrderTask (data) {
  return request.post('/ooh-order/v1/demand/createordertask', qs.stringify(data))
}

/**
 * 更新监播任务
 * @param {Object} data
 * @returns
 */
export function updateTaskitem (data) {
  return request.post('/ooh-order/v1/demand/updatetaskitem', qs.stringify(data))
}

/**
 * 获取订单服务项使用情况
 * @param {Object} data
 * @returns
 */
export function getOrderServiceItemList (data) {
  return request.post('/ooh-order/v1/demand/getOrderServiceItemList', qs.stringify(data))
}

/**
 * 删除履约设置
 * @param {Object} data
 * @returns
 */
export function deleteTaskitem (data) {
  return request.post('/ooh-order/v1/demand/deletetaskitem', qs.stringify(data))
}

/**
 * 删除履约任务
 * @param {Object} data
 * @returns
 */
export function deleteTask (data) {
  return request.post('/ooh-order/v1/demand/deletetask', qs.stringify(data))
}

/**
 * 设置刊中换画
 * @param {Object} data
 * @returns
 */
export function setPictureWithInstallPic (data) {
  return request.post('/ooh-order/v1/demand/setPictureWithInstallPic', qs.stringify(data))
}
