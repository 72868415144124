<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
</style>

<template>
  <div>
    <Row :gutter="8">
      <i-col span="4">
        <p class="title p-t-5">账单分类</p>
        <Select size="small" v-model="type" placeholder="账单分类">
          <Option
            v-for="item in reconciliationTypes"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</Option
          >
        </Select>
      </i-col>

      <i-col span="4">
        <p class="title p-t-5">结算状态</p>
        <Select size="small" v-model="billStatus" placeholder="结算状态">
          <Option
            v-for="item in billStatusArray"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</Option
          >
        </Select>
      </i-col>

      <i-col span="4">
        <p class="title p-t-5">结算公司</p>
        <Select v-model="currentsettlementCompanyId" size="small">
          <Option value="-1">全部</Option>
          <Option
            v-for="(company, index) in settlementcompanyArray"
            :key="index"
            :value="company.id"
            >{{ company.name }}</Option
          >
        </Select>
      </i-col>
      <i-col span="4">
        <p class="title p-t-5">供应商</p>
        <Select v-model="currentsupplierCompanyId" size="small">
          <Option value="-1">全部</Option>
          <Option
            v-for="(company, index) in suppliercompanyArray"
            :key="index"
            :value="company.id"
            >{{ company.name }}</Option
          >
        </Select>
      </i-col>

      <i-col span="4" class="p-b-5">
        <p class="title p-t-5">计费区间</p>
        <i-input
          type="number"
          size="small"
          v-model="minChargeAmount"
          clearable
          placeholder="最低计费金额"
        ></i-input>
      </i-col>
      <i-col span="4" class="p-b-5">
        <p class="title p-t-5">&nbsp;</p>
        <i-input
          type="number"
          size="small"
          v-model="maxChargeAmount"
          clearable
          placeholder="最高计费金额"
        ></i-input>
      </i-col>
      <i-col span="4">
        <p class="title p-t-5">时间类型</p>
        <Select size="small" v-model="timeCategory" placeholder="时间类型">
          <Option
            v-for="item in timecategorys"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</Option
          >
        </Select>
      </i-col>
      <i-col span="4" class="p-b-5">
        <p class="title p-t-5">查询周期</p>
        <DatePicker
          size="small"
          placeholder="查询周期"
          v-model="cycleDate"
          type="daterange"
          style="width: 100%"
        ></DatePicker>
      </i-col>
      <i-col span="16">
        <Row :gutter="8">
          <i-col span="20">
            <p class="title p-t-5">关键字</p>
            <i-input
              type="text"
              size="small"
              v-model="keyword"
              clearable
              placeholder="关键字，如：客户、品牌、资源编号等"
            ></i-input>
          </i-col>
          <i-col span="4">
            <p class="title p-t-5">&nbsp;</p>
            <Button
              type="primary"
              icon="ios-search"
              long
              size="small"
              :loading="loadSearch"
              @click="searchmaintenanceReconciliationList"
            >
              <span v-if="!loadSearch">搜索</span>
              <span v-else>加载中...</span>
            </Button>
          </i-col>
        </Row>
      </i-col>
    </Row>
    <template v-if="showAllCondition">
      <p class="title p-t-5">作业类型</p>
      <Row :gutter="8">
        <i-col span="24">
          <div
            v-for="(item, index) in taskitemTypeArray"
            :key="index"
            class="p-l-10 p-1-10 m-b-2"
            @click="changeChecked('taskitemType', item.id)"
            :class="
              chkTaskitemTypes.includes(item.id)
                ? 'workplatform-radio-button-active'
                : 'workplatform-radio-button'
            "
          >
            {{ item.name }}
          </div>
        </i-col>
      </Row>
      <p class="title p-t-5">资源类型</p>
      <Row :gutter="8">
        <i-col span="24">
          <div
            v-for="(item, index) in resourceTypeArray"
            :key="index"
            class="p-l-10 p-1-10 m-b-2"
            @click="changeChecked('resourcetype', item.id)"
            :class="
              chkResourceTypes.includes(item.id)
                ? 'workplatform-radio-button-active'
                : 'workplatform-radio-button'
            "
          >
            {{ item.name }}
          </div>
        </i-col>
      </Row>
      <template v-if="type === 1">
        <p class="title p-t-5">用途</p>
        <Row>
          <i-col span="24">
            <div
              v-for="(item, index) in orderadTypes"
              :key="index"
              class="p-l-10 p-1-10 m-b-2"
              @click="changeChecked('adType', item.value)"
              :class="
                chkadTypes.includes(item.value)
                  ? 'workplatform-radio-button-active'
                  : 'workplatform-radio-button'
              "
            >
              {{ item.name }}
            </div>
          </i-col>
        </Row>
      </template>
    </template>
    <a
      style="line-height: 35px"
      @click="showAllCondition = !showAllCondition"
      >{{ showAllCondition ? "收起" : "展开全部搜索条件" }}</a
    >
    <hr style="margin: 10px 0px" />
  </div>
</template>

<script>
// import {
//   getsettlements,
//   getsuppliers
// } from '@/api/msp/settlement'

import {
  listMspFeeCategory,
  listBillSettlementStatus,
  getMspTimeTypeList,
  listMspTaskType,
  listMspResourceType,
  listMspSettlementCompany,
  listMspSupplierCompany
} from '@/api/statement/mspBillDetail'
import { getOrderAdType } from '@/api/msp/status'

import { ParseDate } from '@/utils/dateFormat'

export default {
  data () {
    return {
      showAllCondition: false,
      reconciliationTypes: [],
      type: 1,

      billStatusArray: [],
      billStatus: -1,

      loadSearch: false,
      taskitemTypeArray: [], // 作业类型集合

      chkTaskitemTypes: [-1],

      resourceTypeArray: [], // 资源类型集合
      resourceTypeId: null,
      chkResourceTypes: [-1],

      timecategorys: [],
      timeCategory: null,

      orderadTypes: [],
      adType: '-1',
      chkadTypes: [-1],

      cycleDate: [],
      startDate: '',
      endDate: '',
      keyword: '',
      minChargeAmount: null,
      maxChargeAmount: null,

      settlementcompanyArray: [], // 结算方公司清单
      suppliercompanyArray: [], // 供应商清单
      currentsupplierCompanyId: '-1',
      currentsettlementCompanyId: '-1'
    }
  },
  created () {
    this.initReconciliationType()
    this.initStatusArray()

    this.inittaskitemTypeArray()
    this.initTimecategory()
    this.initOrderAdType()
    this.initsettlementCompanyArray()
  },
  methods: {
    // 获取账单类型
    initReconciliationType () {
      const that = this
      listMspFeeCategory({}).then((res) => {
        if (res.length) {
          that.type = res[0].id
        }
        that.reconciliationTypes = res
      })
    },
    initStatusArray () {
      const that = this
      listBillSettlementStatus({}).then((res) => {
        that.billStatusArray = res
      })
    },

    initTimecategory () {
      const that = this
      getMspTimeTypeList({}).then((res) => {
        if (res.length) {
          that.timeCategory = res[0].id
        }
        that.timecategorys = res
      })
    },
    initOrderAdType () {
      const that = this
      getOrderAdType({
        type: 1
      }).then((res) => {
        that.orderadTypes = [
          {
            value: -1,
            name: '全部',
            type: -1
          }
        ]
        that.orderadTypes = that.orderadTypes.concat(res)
      })
    },

    // 加载作业类型
    inittaskitemTypeArray () {
      const that = this
      that.taskitemTypeArray = []

      listMspTaskType({
        mspBillCategory: that.type
      }).then((res) => {
        that.taskitemTypeArray = res
        that.initresourceTypeArray()
      })
    },
    // 变更多选框选择
    changeChecked (key, value) {
      const that = this
      switch (key) {
        case 'taskitemType':
          if (value === -1) {
            that.chkTaskitemTypes = [-1]
          } else {
            const index = that.chkTaskitemTypes.indexOf(-1)
            if (index > -1) {
              that.chkTaskitemTypes.splice(index, 1)
            }

            if (that.chkTaskitemTypes.includes(value)) {
              const index1 = that.chkTaskitemTypes.indexOf(value)
              if (index1 > -1) {
                that.chkTaskitemTypes.splice(index1, 1)
              }
            } else {
              that.chkTaskitemTypes.push(value)
            }
          }
          that.initresourceTypeArray()
          break
        case 'resourcetype':
          if (value === -1) {
            that.chkResourceTypes = [-1]
          } else {
            const index = that.chkResourceTypes.indexOf(-1)
            if (index > -1) {
              that.chkResourceTypes.splice(index, 1)
            }

            if (that.chkResourceTypes.includes(value)) {
              const index1 = that.chkResourceTypes.indexOf(value)
              if (index1 > -1) {
                that.chkResourceTypes.splice(index1, 1)
              }
            } else {
              that.chkResourceTypes.push(value)
            }
          }
          break
        case 'adType':
          if (value === -1) {
            that.chkadTypes = [-1]
          } else {
            const index = that.chkadTypes.indexOf(-1)
            if (index > -1) {
              that.chkadTypes.splice(index, 1)
            }

            if (that.chkadTypes.includes(value)) {
              const index1 = that.chkadTypes.indexOf(value)
              if (index1 > -1) {
                that.chkadTypes.splice(index1, 1)
              }
            } else {
              that.chkadTypes.push(value)
            }
          }
          break
      }
      that.searchmaintenanceReconciliationList()
    },
    // 加载资源类型
    initresourceTypeArray () {
      const that = this
      that.resourceTypeArray = []
      let taskitemTypeStr = null
      if (that.chkTaskitemTypes.length && !that.chkTaskitemTypes.includes(-1)) {
        taskitemTypeStr = JSON.stringify(that.chkTaskitemTypes)
      }
      listMspResourceType({
        mspBillCategory: that.type,
        taskTypeList: taskitemTypeStr
      }).then((res) => {
        that.resourceTypeArray = res
        if (res.length) {
          that.resourceTypeId = that.resourceTypeArray[0].resourcetypeId
        }
      })
    },
    // 获取结算方公司列表
    initsettlementCompanyArray () {
      listMspSettlementCompany({}).then((res) => {
        this.settlementcompanyArray = res
        // if (this.settlementcompanyArray.length) {
        //   this.currentsettlementCompanyId = this.settlementcompanyArray[0].companyId
        // }
        this.inisupplierCompanyArray()
      })
    },
    // 获取供应商公司列表
    inisupplierCompanyArray () {
      listMspSupplierCompany({}).then((res) => {
        this.suppliercompanyArray = res
        // if (this.suppliercompanyArray.length) {
        //   this.currentsupplierCompanyId = this.suppliercompanyArray[0].companyId
        // }
        this.searchmaintenanceReconciliationList()
      })
    },
    formatDate (date) {
      if (!date) {
        return null
      } else {
        return ParseDate(date)
      }
    },
    // 搜索列表
    searchmaintenanceReconciliationList () {
      var query = {
        billStatus: this.billStatus,
        mspBillCategory: this.type,
        startDate: this.formatDate(this.cycleDate[0]), // this.startDate ? ParseDate(this.startDate) : '',
        endDate: this.formatDate(this.cycleDate[1]), // this.endDate ? ParseDate(this.endDate) : '',
        mspTimeType: this.timeCategory,

        purchaserCompanyId: this.currentsettlementCompanyId,
        resourceTypeId: this.resourceTypeId,

        supplierCompanyId: this.currentsupplierCompanyId
      }

      if (this.keyword) {
        query.keyword = this.keyword
      }
      if (this.minChargeAmount || this.minChargeAmount === 0) {
        query.minChargeAmount = this.minChargeAmount
      }
      if (this.maxChargeAmount || this.maxChargeAmount === 0) {
        query.maxChargeAmount = this.maxChargeAmount
      }
      if (this.chkTaskitemTypes.length && !this.chkTaskitemTypes.includes(-1)) {
        query.taskTypeList = JSON.stringify(this.chkTaskitemTypes)
      }
      if (this.chkResourceTypes.length && !this.chkResourceTypes.includes(-1)) {
        query.resourceTypeIdList = JSON.stringify(this.chkResourceTypes)
      }
      if (this.chkadTypes.length && !this.chkadTypes.includes(-1)) {
        query.taskAdTypeList = JSON.stringify(this.chkadTypes)
      }

      // if (this.executeStatus !== -1) { query.executeStatus = this.executeStatus }
      if (this.$store.hasModule('supplierStore')) {
        this.$store.commit(
          'set_supplierStore_maintenanceReconciliationQuery',
          query
        )
        this.$store.commit('set_supplierStore_update', new Date())
      }
    }
  },
  computed: {
    companyId () {
      return this.$store.getters.token.userInfo.companyId
    },
    publisherId () {
      return this.$store.getters.token.userInfo.publisherId
    }
  },
  watch: {
    type () {
      this.inittaskitemTypeArray()
    }
  }
}
</script>
