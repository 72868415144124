import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 分页获取满足条件的发布中资源
 * @param {Object} data
 */
export function getUninstallResourcePage (data) {
  return request({
    url: '/ooh-msp/v2/uninstall/getuninstallresourcepage',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 资源类型统计
 * @param {Object} data
 */
export function getResourcetypeStatisticList (data) {
  return request({
    url: '/ooh-msp/v2/uninstall/getresourcetypestatisticlist',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 设置站点资源上刊需求
 * @param {Object} data
 */
export function createUninstallTask (data) {
  return request({
    url: '/ooh-msp/v2/uninstall/createuninstalltask',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 提交下刊
 * @param {Object} data
 */
export function submitUninstall (data) {
  return request({
    url: '/ooh-msp/v2/uninstall/submituninstall',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 申请下刊
 * @param {Object} data
 */
export function applyUninstall (data) {
  return request({
    url: '/ooh-msp/v2/uninstall/applyuninstall',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 设置下刊画面
 * @param {Object} data
 */
export function setUnintallPic (data) {
  return request({
    url: '/ooh-msp/v2/uninstall/setunintallpic',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 根据条件查询当前在刊任务
 * @param {Object} data
 */
export function getPublishingPage (data) {
  return request({
    url: '/ooh-msp/v2/uninstall/getpublishingpage',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 创建下刊任务
 * @param {Object} data
 */
export function createTaskitem (data) {
  return request({
    url: '/ooh-msp/v2/uninstall/createtaskitem',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 查询设备履约历史节点
 * @param {Object} data
 */
export function getDeviceNotes (data) {
  return request({
    url: '/ooh-msp/v2/uninstall/getdevicenotes',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 查询履约任务历史节点
 * @param {Object} data
 */
export function getTaskitemNotes (data) {
  return request({
    url: '/ooh-msp/v2/uninstall/gettaskitemnotes',
    method: 'post',
    data: qs.stringify(data)
  })
}

// /**
//  * 打开任务创建下刊任务开关
//  * @param {Object} data
//  */
// export function openCreateUninstallFlag (data) {
//   return request({
//     url: '/ooh-msp/v2/uninstall/openCreateUninstallFlag',
//     method: 'post',
//     data: qs.stringify(data)
//   })
// }

/**
 * 待派单下刊与当前在刊信息不符点位集合
 * @param {Object} data
 */
export function getCodeList (data) {
  return request({
    url: '/ooh-msp/v2/uninstall/getCodeList',
    method: 'post',
    data: qs.stringify(data)
  })
}
