<template>
  <div class="p-b-10">

      <!-- <component :is="bottomListComponent"></component> -->
      <maintenanceList></maintenanceList>
  </div>
</template>

<script>
export default {
  components: {
    maintenanceList: () => import('../common/maintenanceList')
  },
  data () {
    return {
      bottomListComponent: 'maintenanceListList'
    }
  },
  created () {
    this.bottomListComponent = 'maintenanceListList'
  },
  methods: {

  },
  computed: {
  },
  watch: {

  }
}
</script>
