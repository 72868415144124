<template>
  <div>
    <div class="platform-buttom-list">
      <div class="bottom-button m-r-5" @click="goToIndex">
        项目管理
      </div>
      <div class="bottom-button-active">
        资产包管理
      </div>
    </div>
    <div class="m-b-10 text-right">
      <div><Button type="primary" size="small" @click="onAdd">新增资产包</Button></div>
    </div>
    <div>
      <Table stripe max-height="620" size="small" :data="list" :columns="tableColumns" :loading="tableLoading"></Table>
    </div>
    <add-package ref="addPackage" :projectId="id" :isAdd="isAdd" :onSuccess="getTableData" />
  </div>
</template>

<script>
import { projectCommonMxins } from '../../mixins/common'
import { getPackageList } from '@/api/product/resource'

import AddPackage from './Add.vue'
export default {
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  mixins: [projectCommonMxins],
  components: {
    AddPackage
  },
  data () {
    return {
      isAdd: true,
      tableLoading: false,
      list: [],
      tableColumns: [
        { title: '资产包名称', align: 'center', key: 'name' },
        { title: '备注', align: 'center', key: 'desc' },
        {
          title: '操作',
          align: 'center',
          render: (h, { row }) => {
            const html = []
            html.push(
              h('a', {
                class: ['m-r-5'],
                on: {
                  click: () => {
                    this.onEdit(row)
                  }
                }
              }, '编辑')
            )
            return h('div', html)
          }
        }
      ]
    }
  },
  mounted () {
    this.getTableData()
  },
  methods: {
    async getTableData () {
      this.tableLoading = true
      const result = await getPackageList({ projectId: this.id }).finally(() => { this.tableLoading = false })
      this.list = (result && !result.errcode) ? result : []
    },
    onAdd () {
      this.$nextTick(() => {
        this.$refs.addPackage.showModal()
      })
    },
    onEdit (params) {
      this.isAdd = false
      this.$nextTick(() => {
        this.$refs.addPackage.showModal(params)
      })
    }
  }
}
</script>
