<template>
  <div>
    <Divider dashed style="color:#fff">设置填充内容</Divider>
    <div>
      <h4 class="workplatform-title m-t-10 m-b-20">作业日期</h4>
      <DatePicker
        size="small"
        placeholder="选择作业日期"
        v-model="actionDate"
        type="date"
        style="width:100%"
        :editable="false"
        :clearable="false"
        @on-change="changeActionDate"
      ></DatePicker>
    </div>
    <set-screen
      :agreeFileArray="fileArray"
      :selectedResourceIds="selectedResourceIdArray"
      :curTaskId="curTaskId"
      :selectedTaskitemIds="selectedTaskitemIdArray"
      :setOperatingType="opreateType"
      :fileCreateDate="fileDate"
      :isSetFiles="false"
      :btnSetLoading="btnSetLoading"
      @parentMethod="handelSubmit"
    >
      <template #pictureDate>
        <div class="m-b-10">
          <label>素材类型：</label>
          <RadioGroup
            v-model="materialType"
            type="button"
            size="small"
            @on-change="getFileDates"
          >
            <Radio
              v-for="item in materialTypeArray"
              :key="'m_'+item.value"
              :label="item.value"
            >{{item.name}}</Radio>
          </RadioGroup>
        </div>
        <div>
          <Select
            v-model="fileDate"
            size="small"
            filterable
            placeholder="选择画面上传日期"
            @on-change="changeFileDate"
          >
            <Option
              v-for="item in fileDateArray"
              :key="'f_'+item"
              :value="item"
            >{{item}}</Option>
          </Select>
        </div>

      </template>
    </set-screen>
  </div>
</template>

<script>
import SetScreen from '@/components/task/SetScreen'
import { agreefile, getFileUploadDateList } from '@/api/msp/file'
import { setFillTaskitemFile } from '@/api/msp/demandV3'
import { updateTaskitemFile } from '@/api/order/demand'
import { getFillType } from '@/api/msp/status'
export default {
  props: {
    opreateType: {
      type: Number,
      default: 1
    },
    taskId: {
      type: Number,
      required: true
    },
    dataSource: {
      type: Number,
      default: 1
    },
    deviceId: {
      require: true,
      type: Number
    },
    selectedResourceIdArray: [Array],
    selectedTaskitemIdArray: [Array]
  },
  components: {
    SetScreen
  },
  data () {
    return {
      query: {
        pageNumber: 1,
        pageSize: 500,
        approvalDate: '',
        approvalId: null,
        fileTypes: 1,
        keyword: null,
        status: 5,
        createDate: '',
        type: null
      },
      fileArray: [],
      fileDateArray: [],
      fileDate: '',
      actionDate: '',
      materialType: 0,
      materialTypeArray: [
        { value: 0, name: '全部' }
      ],
      dateQuery: {
        type: null,
        startDate: '',
        endDate: ''
      },
      btnSetLoading: false
    }
  },
  computed: {
  },
  created () {
    this.getScreenTypeData()
    this.getFileDates()
    // this.getFileData()
  },
  methods: {
    getScreenTypeData () {
      getFillType().then(res => {
        if (res && !res.errcode) {
          this.materialTypeArray = this.materialTypeArray.concat(res)
        }
      })
    },
    /**
     * 获取素材上传时间集合
     */
    getFileDates () {
      this.dateQuery.type = this.materialType === 0 ? null : this.materialType
      getFileUploadDateList(this.dateQuery).then(res => {
        if (res && !res.errcode) {
          this.fileDateArray = res
          this.fileDate = this.fileDateArray.sort()[0]
          this.getFileData()
        } else {
          this.fileDateArray = []
        }
      })
    },
    /**
     * 获取已通过审核的画面文件
     */
    getFileData () {
      this.query.type = this.materialType === 0 ? null : this.materialType
      this.query.createDate = this.fileDate || ''
      agreefile(this.query).then(res => {
        if (res && !res.errcode) {
          this.fileArray = res.list
        } else {
          this.fileArray = []
        }
      })
    },
    changeActionDate (date) {
      this.actionDate = date
    },
    handelSubmit (data) {
      if (!this.actionDate) {
        this.$Notice.error({ desc: '请选择作业日期' })
        return false
      }

      if (data && data instanceof Object) {
        this.btnSetLoading = true
        // data.taskId = this.curTaskId
        data.demandMainId = this.curTaskId
        data.actionDate = this.actionDate

        if (this.opreateType === 1) {
          setFillTaskitemFile(data).then(res => {
            this.btnSetLoading = false
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.selectedResourceIdArray = []
              this.$store.commit('set_temp_selected_item', null)
              this.$store.dispatch('getPublishingTaskitemData')
            }
          })
        } else {
          updateTaskitemFile(data).then(res => {
            this.btnSetLoading = false
            if (res && res.errcode === 0) {
              this.selectedResourceIdArray = []
              this.selectedTaskitemIdArray = []
              this.$store.commit('set_temp_select     ed_item', null)
              this.$store.dispatch('getTaskitemData')
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      } else {
        this.$Notice.error({ desc: '非法请求' })
      }
    },
    changeFileDate () {
      this.getFileData()
    }
  }
}
</script>
