<template>
  <div>
    <i-table stripe :columns="columns" :data="products"></i-table>
    <Modal v-model="showModal" :width="400" :mask-closable="false">
      <p slot="header" class="workplatform-title">
        <span>签约单价</span>
      </p>
      <InputNumber
        v-model="params.signUnitPrice"
        :min="0"
        style="width: 100%"
      ></InputNumber>

      <div slot="footer">
        <i-button class="btn-black" @click="showModal = false">关闭</i-button>
        <i-button type="primary" @click="confirmChange">确认</i-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'

import { saveSignServiceFee } from '@/api/scp/contractorder'
export default {
  props: {
    versionId: {
      type: Number,
      required: true
    },
    products: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      columns: [{ title: '服务费名称', key: 'serviceItemName' },
        { title: '服务费类型', key: 'typeName' },
        {
          title: '资源数量',
          key: 'serviceQuantity',
          render: (h, data) => {
            return h('div', [
              h('span', {}, data.row.serviceQuantity + data.row.serviceQuantityName)
            ])
          }
        },
        {
          title: '刊例单价',
          key: 'price',
          render: (h, data) => {
            return h('div', [
              h('span', {}, this.formatMoney(data.row.unitPrice) + '/' + data.row.serviceQuantityName)
            ])
          }
        },
        {
          title: '签约数量',
          key: 'signServiceQuantity',
          render: (h, data) => {
            return h('div', [
              h('span', {}, data.row.signServiceQuantity + data.row.serviceQuantityName)
            ])
          }
        },
        {
          title: '签约单价',
          key: 'signUnitPrice',
          render: (h, data) => {
            return h('div', [
              h(
                'span',
                { class: 'money' },
                this.formatMoney(data.row.signUnitPrice) + '/' + data.row.serviceQuantityName
              )
            ])
          }
        },
        {
          title: '签约总价',
          key: 'signServiceAmount',
          render: (h, data) => {
            return h('div', [
              h(
                'span',
                { class: 'money' },
                this.formatMoney(data.row.signServiceAmount)
              ),
              h(
                'span',
                { attrs: { class: 'discount' } },
                this.computedSale(data.row.totalServiceFee, data.row.signServiceAmount)
              )
            ])
          }
        },

        {
          title: '操作',
          render: (h, data) => {
            const detailButton = h(
              'a',
              {
                style: { 'margin-left': '5px' },
                on: {
                  click: () => {
                    this.showDetail(data.row)
                  }
                }
              },
              '调整签约单价'
            )
            const returnBtn = [
              detailButton
            ]
            return h('div', returnBtn)
          }
        }],
      showModal: false,
      params: {
        orderId: 0,
        id: 0,
        signUnitPrice: 0
      },
      services: []
    }
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },
    computedSale (usePrice, sellPrice) {
      if (usePrice > 0) {
        const discount = (sellPrice / usePrice) * 10
        const showDisCount = discount.toFixed(2)
        return '(' + showDisCount + '折)'
      } else {
        return ''
      }
      // return Number((sellPrice * 10 / usePrice).toFixed(2))
    },
    showDetail (record) {
      this.params.id = record.id
      this.params.orderId = record.orderId
      this.params.signUnitPrice = record.signUnitPrice
      // this.params.totalInstallSettlePrice = record.totalInstallSettlePrice
      // this.params.totalProduceSettlePrice = record.totalProduceSettlePrice
      this.showModal = true
    },
    confirmChange () {
      const that = this
      saveSignServiceFee({
        orderId: this.params.orderId,
        versionId: that.versionId,

        serviceFees: JSON.stringify(
          [{ id: that.params.id, signUnitPrice: that.params.signUnitPrice }]
        )
        // statementDetailId: that.params.statementDetailId,
        // signUnitPrice: that.params.signUnitPrice
      }).then((res) => {
        that.showModal = false
        that.$emit('reloadProducts')
      })
    }

  }
}
</script>
