<template>
  <div>
    <!-- <Divider dashed style="color:#fff">设置概览</Divider> -->
    <service-fee-item
      ref="serviceFeeItem"
      :orderId="orderInfo.id"
      class="m-b-10"
    />
    <div class="m-b-10">
      <h4 class="workplatform-title m-t-10 m-b-20">上刊进度</h4>
      <Table
        stripe
        size="small"
        :data="progressList"
        :columns="progressColumns"
      ></Table>
    </div>
    <div class="m-b-10">
      <h4 class="workplatform-title m-t-10 m-b-20">报告生成</h4>
      <div class="p-b-10">
        <p class="text-orange">提示：</p>
        <p>1.不选择“<span class="text-orange">生成全部资源报告</span>”仅生成已返回照片资源的报告。</p>
        <p>2.选择“<span class="text-orange">生成时使用原图</span>”会花费较长时间。</p>
      </div>
      <Row
        :gutter="8"
        class="m-b-10"
      >
        <i-col span="10">
          <Checkbox v-model="isAddShadow">生成时带水印</Checkbox>
        </i-col>
        <i-col span="14">
          <Checkbox v-model="allTaskitems">生成全部资源报告</Checkbox>
        </i-col>
      </Row>
      <Row
        :gutter="8"
        class="m-b-10"
      >
        <i-col span="24">
          <Checkbox v-model="useOriginalPic">生成时使用原图</Checkbox>
        </i-col>
      </Row>
      <Row
        :gutter="8"
        class="m-b-10"
      >
        <Select
          v-model="templateId"
          size="small"
          @on-change="handleChangeTemplate"
          placeholder="请选择模板"
        >
          <Option
            v-for="item in templateArray"
            :value="item.id"
            :key="item.id"
          >{{ item.name }}</Option>
        </Select>
      </Row>
      <Row :gutter="8">
        <i-col span="8">
          <Button
            v-if="templateId"
            type="primary"
            size="small"
            long
            @click="handleDownloadTemplate"
          >下载模板预览</Button>
        </i-col>
        <i-col span="8">
          <Button
            type="primary"
            size="small"
            long
            @click="getReportData"
          >刷新报告列表</Button>
        </i-col>
        <i-col span="8">
          <Button
            type="success"
            size="small"
            long
            @click="handleCreateReport"
          >生成报告</Button>
        </i-col>
      </Row>
      <Table
        stripe
        size="small"
        :loading="reportLoading"
        :data="reportList"
        :columns="reportColumns"
      >
      </Table>
    </div>
    <div>
      <!-- <h4 class="workplatform-title m-t-10 m-b-20">反馈素材下载</h4> -->
      <batch-download-file ref="batchDownloadFile" :mspTaskId="curMspTaskId" />
    </div>
  </div>
</template>

<script>
// import { taskProgress } from '@/api/msp/taskitemstatistic'
import { formatEnable } from '@/utils/tagStatus'
import { createInspectPpt, getPptReportList, getReportTemplateList } from '@/api/msp/msppreport'
import ServiceFeeItem from '../common/ServiceFeeItem'
import BatchDownloadFile from '../common/BatchDownloadFile'
export default {
  components: { ServiceFeeItem, BatchDownloadFile },
  data () {
    return {
      progressColumns: [
        { title: '资源类型', align: 'center', key: 'resourcetypeName' },
        { title: '状态', align: 'center', key: 'statusName' },
        // {
        //   title: '已制作/制作中/制作总数',
        //   align: 'center',
        //   render: (h, params) => {
        //     return h('span', `${params.row.produceFinishedCount} / ${params.row.producingCount} / ${params.row.produceTotalCount}`)
        //   }
        // },
        // {
        //   title: '已上刊数/上刊总数',
        //   align: 'center',
        //   render: (h, params) => {
        //     return h('span', `${params.row.installFinishedCount} / ${params.row.installTotalCount}`)
        //   }
        // },
        { title: '总量', align: 'center', key: 'totalQuantity' }
      ],
      reportLoading: false,
      reportList: [],
      reportColumns: [
        { title: '生成时间', key: 'createTime' },
        {
          title: '水印',
          key: 'isShadow',
          render: (h, params) => {
            return formatEnable(h, params.row.isShadow)
          }
        },
        {
          title: '进度',
          render: (h, params) => {
            if ([-1, 1].includes(params.row.status)) {
              return h('span', params.row.statusText)
            } else {
              return h('i-progress', {
                props: {
                  percent: params.row.percent,
                  status: 'active'
                }
              })
            }
          }
        },
        {
          title: '操作',
          render: (h, params) => {
            return h('div', [
              params.row.status === 3 ? h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    location.href = params.row.fileKey
                  }
                }
              }, '下载') : null
            ])
          }
        }
      ],
      isAddShadow: false, // 是否添加水印
      allTaskitems: false, // 全部任务用于报告创建
      templateId: null, // 报告模板id
      useOriginalPic: false, // 是否使用原图。false:图片质量压缩50%；true:使用原图
      templatePath: '',
      templateArray: [] // 报告模板集合
    }
  },
  created () {
    this.getDemandProgressData()
    this.getReportTemplateData()
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.serviceFeeItem.getServiceFeeData()
    })
  },
  destroyed () {
    this.progressList = []
  },
  computed: {
    orderInfo () {
      return this.$store.state.installSetting.orderInfo
    },
    curTaskId () {
      return this.$store.state.installSetting.currentTaskId
    },
    curMspTaskId () {
      return this.$store.state.installSetting.curMspTaskId
    },
    progressList: {
      get () {
        return this.$store.state.installSetting.progressList
      },
      set (val) {
        this.$store.commit('set_progress_list', val)
      }
    }
  },
  methods: {
    getDemandProgressData () {
      this.$store.dispatch('getDemandProgressData', { demandMainId: this.curTaskId })
    },
    getReportTemplateData () {
      getReportTemplateList().then(res => {
        if (res && !res.errcode) {
          this.templateArray = res
          if (this.templateArray.length) {
            this.templateId = this.templateArray[0].id
            this.templatePath = this.templateArray[0].templatePath
          }
        } else {
          this.templateArray = []
        }
      })
    },
    handleChangeTemplate () {
      this.templatePath = this.templateArray.find(x => x.id === this.templateId).templatePath
    },
    getReportData () {
      if (this.curMspTaskId < 0) {
        return false
      }
      this.reportLoading = true
      getPptReportList({ taskId: this.curMspTaskId }).then(res => {
        this.reportLoading = false
        if (res && !res.errcode) {
          this.reportList = res

          this.reportList.forEach(item => {
            if (item.status >= 2) {
              const nowDate = new Date().getTime()
              const downloadTime = item.estimatedTime ? new Date(item.estimatedTime).getTime() : 0
              const cTime = new Date(item.createTime).getTime()
              const percent = parseInt((nowDate - cTime) / (downloadTime - cTime) * 100) >= 100 ? 100 : parseInt((nowDate - cTime) / (downloadTime - cTime) * 100)
              if (item.status === 3) {
                item.percent = 100
              } else {
                if (percent >= 100) {
                  item.percent = 99
                } else {
                  item.percent = percent >= 0 ? percent : 0
                }
              }
            }
          })
        } else {
          this.reportList = []
        }
      })
    },
    /**
     * 创建报告
     */
    handleCreateReport () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要生成报告？',
        onOk: () => {
          const postData = {
            taskId: this.curMspTaskId,
            isAddShadow: this.isAddShadow,
            allTaskitems: this.allTaskitems,
            templateId: this.templateId,
            useOriginalPic: this.useOriginalPic
          }
          createInspectPpt(postData).then(res => {
            if (res && res.errcode === 0) {
              this.getReportData()
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    handleDownloadTemplate () {
      window.location.href = this.templatePath
    }
  },
  watch: {
    curMspTaskId (val) {
      if (val > 0) {
        this.getReportData()
      }
    }
  }
}
</script>
