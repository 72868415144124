<template>
  <div>
    <h4 slot="title">
      <Divider dashed v-if="pageType === 'default'">
        <span class="divider-text">新建</span>
      </Divider>
      <div v-else>
        <Divider dashed>
          <span class="divider-text">配置</span>
        </Divider>
        你正在进行
        <span class="text-orange">{{ typeName }}-{{ subName }}</span> 的人员配置
        <!-- <span class="text-orange">无法删除和新增节点.</span> -->
      </div>

      <span v-if="pageType === 'default'">您正在创建一个新的审批流程</span>
      <span v-else></span>
    </h4>

    <div>
      <div v-if="pageType === 'default'" class="p-b-10">
        <p class="p-t-10">请选择一个审批类型</p>
        <RadioGroup v-model="type" @on-change="typeChange">
          <Radio v-for="(item, i) in types" :key="i" :label="item.value">{{
            item.name
          }}</Radio>
        </RadioGroup>
        <p class="p-t-10">请选择一个审批通道</p>
        <RadioGroup v-model="chkSub">
          <Radio
            v-for="(item, i) in subs"
            :key="i"
            :label="item.subProcessType"
            >{{ item.subProcessTypeName }}</Radio
          >
        </RadioGroup>
      </div>
      <p class="p-t-10">
        <a @click="addNode">点击添加节点</a>
        <!-- <i-button type="success" @click="addNode" size="small">点击添加节点</i-button> -->
      </p>
      <!-- 配置审批节点 -->
      <Row :gutter="16" class="p-t-10 p-b-5">
        <i-col span="24" v-for="(node, i) in nodes" :key="i">
          <new-node
            @removeUser="handleGroupUserRemove"
            :id="node.id"
            :editable="true"
            :can-delete="true"
            @addUser="handleAddUser"
            @deleteNode="deleteNode"
            @editTitle="editTitle"
            @changeSort="changeSort"
            :sort="node.sort"
            :nodeLength="nodes.length"
            :users="node.users"
            :publisherId="publisherId"
            :companyId="companyId"
          ></new-node>
        </i-col>
      </Row>
      <div v-if="nodes.length" class="text-right">
        <i-button type="success" size="small" @click="handleSubmit"
          >确认发布</i-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import NewNode from './NewNode'

import { enableProcess, addProcessWithNodes } from '@/api/approval/process'
import {
  getProcessTypeList,
  getApprovalNodeList,
  // updateApprovalNodeList,
  getSubProcessList
} from '@/api/approval/processconfig'
import { getUserPage } from '@/api/os/user'

export default {
  components: {
    NewNode
  },
  data () {
    return {
      types: [],
      subs: [],
      chkSub: '',
      nodes: [],
      users: [],
      type: '',
      typeName: ''
    }
  },
  created () {
    this.getTypes()
    getUserPage({
      companyId: this.companyId,
      pageNumber: 1,
      pageSize: 1e3
    }).then((data) => {
      this.users = data.list
    })
  },
  methods: {
    getTypes () {
      getProcessTypeList({ companyId: this.companyId }).then((data) => {
        if (data && data.length > 0) {
          this.types = data
          if (!this.chkType) {
            this.type = this.types.length ? this.types[0].value : ''
            this.initSubProcessList()
          }
        } else {
          this.types = []
        }
        // this.initSubProcessList()
      })
    },
    initSubProcessList () {
      getSubProcessList({ processType: this.type }).then((data) => {
        if (data && data.length > 0) {
          this.subs = data
          this.chkSub =
            this.sub || (this.subs.length ? this.subs[0].subProcessType : '')
        } else {
          this.subs = []
        }
      })
    },
    initApprovalDetail () {
      if (this.pageType === 'default') {
        this.nodes = []
        return
      }

      this.typeName = this.name
      getApprovalNodeList({ processId: this.detailId }).then((data) => {
        this.nodes = data.map((item, index) => {
          return {
            id: item.approvalnode.id,
            sort: index + 1,
            name: item.approvalnode.name,
            users: item.users || []
          }
        })
      })
    },

    // 增加节点

    // name: '第' + (this.nodes.length + 1) + '步',
    addNode () {
      this.nodes.push({
        sort: this.nodes.length + 1,
        users: [],
        id: new Date().getTime()
      })
    },
    // 删除节点
    deleteNode (nodeId) {
      const index = this.nodes.findIndex((item) => item.id === nodeId)
      this.nodes.splice(index, 1)
    },
    // 调整排序位置
    changeSort (para) {
      const node = this.nodes.find((item) => item.id === para.id)
      const newNodes = []
      this.nodes
        .filter((item) => item.id !== para.id)
        .forEach((item) => {
          if (parseInt(para.newSort) < parseInt(para.old)) {
            if (parseInt(item.sort) < parseInt(para.newSort)) { // 如果在设置的新节点前，直接加入
              newNodes.push(item)
            } else {
              if (parseInt(item.sort) === parseInt(para.newSort)) {
                node.sort = para.newSort
                newNodes.push(node)
              }
              if (parseInt(item.sort) < parseInt(para.old)) {
                item.sort += 1
              }
              newNodes.push(item)
            }
          } else if (parseInt(para.newSort) > parseInt(para.old)) {
            if (parseInt(item.sort) < parseInt(para.old)) {
              newNodes.push(item)
            } else if (parseInt(item.sort) < parseInt(para.newSort)) {
              item.sort -= 1
              newNodes.push(item)
            } else {
              if (parseInt(item.sort) === parseInt(para.newSort)) {
                item.sort -= 1
                newNodes.push(item)
                node.sort = para.newSort
                newNodes.push(node)
              } else {
                newNodes.push(item)
              }
            }
          }
        })
      this.nodes = parseInt(para.newSort) === parseInt(para.old) ? this.nodes : newNodes
    },

    // 编辑节点名称
    editTitle (para) {
      const originName = para.name
      const node = this.nodes.find((item) => item.id === para.id)
      this.$Modal.confirm({
        title: '更改节点名字',
        render: (h) => {
          return h('Input', {
            props: {
              value: para.name
            },
            on: {
              'on-change': (event) => {
                node.name = event.target.value
              }
            }
          })
        },
        onCancel: () => {
          node.name = originName
        }
      })
    },
    // 增加节点用户
    handleAddUser (nodeId, user) {
      this.nodes.find((item) => item.id === nodeId).users.push(user)
    },
    // 移除节点用户
    handleGroupUserRemove (nodeId, userIndex) {
      const node = this.nodes.find((item) => item.id === nodeId)
      node.users.splice(userIndex, 1)
    },

    // 监听保存按钮
    handleSubmit () {
      this.createApproval()
      // if (this.pageType === 'default') {
      //   this.createApproval()
      // } else {
      //   this.updateApproval()
      // }
    },
    // 新建审批流程
    createApproval () {
      const nodes = this.nodes.map((node, index) => {
        return {
          nodeName: '第' + node.sort + '步',
          nodeMemberIds: JSON.stringify(node.users.map((item) => item.id))
        }
      })
      const param = {
        companyId: this.companyId,
        publisherId: this.publisherId,
        processtype: this.type,
        subprocessType: this.chkSub,
        approvalNodes: JSON.stringify(nodes)
      }

      const typeName = this.types.find((item) => item.value === this.type).name
      const subName = this.subs.find(
        (item) => item.subProcessType === this.chkSub
      ).subProcessTypeName

      if (this.nodes.every((x) => x.users.length > 0)) {
        this.$Modal.confirm({
          title: '友情提示',
          content:
            '您将要创建一个' +
            typeName +
            '类型下' +
            subName +
            '的审批通道，当前生效的审批通道将会置为无效，是否继续？',
          onOk: () => {
            this.$Spin.show()
            addProcessWithNodes(param).then((data) => {
              enableProcess({
                processId: data,
                operateUserId: this.$store.getters.token.userInfo.companyId
              }).then((res) => {
                this.$Spin.hide()
                this.$store.commit('set_setting_detailId', data)
                this.$store.commit('set_setting_update', new Date())
                // this.$store.commit('set_setting_type', '')
                // this.$store.commit('set_setting_sub', '')
                // this.$store.commit('set_setting_detailId', null)
              })
            })
          }
        })
      } else {
        this.$Notice.error({ desc: '每个审批节点至少需要一个人' })
      }
    },
    // 修改审批流程
    // updateApproval () {
    //   const updateNodes = this.nodes.map((node) => {
    //     return {
    //       id: node.id,
    //       name: node.name,
    //       userIds: node.users.map((item) => item.id)
    //     }
    //   })

    //   if (this.nodes.every((x) => x.users.length > 0)) {
    //     this.$Spin.show()
    //     updateApprovalNodeList({ approvalNodes: JSON.stringify(updateNodes) })
    //       .then((data) => {
    //         this.$Spin.hide()
    //         this.$Notice.success({
    //           desc: '更新审批流程 ' + this.typeName + ' 成功'
    //         })
    //         this.$store.commit('set_setting_update', new Date())
    //       })
    //       .catch(() => {
    //         this.$Spin.hide()
    //       })
    //   } else {
    //     this.$Notice.error({ desc: '每个审批节点至少需要一个人' })
    //   }
    // },

    typeChange () {
      this.$store.commit('set_setting_pageType', 'default')
      this.$store.commit('set_setting_detailId', null)
      this.$store.commit('set_setting_name', null)

      this.$store.commit('set_setting_type', this.type)
      this.$store.commit('set_setting_sub', '')
      this.initSubProcessList()
    }
  },
  computed: {
    companyId () {
      return this.$store.state.setting.companyId
    },
    publisherId () {
      return this.$store.state.setting.publisherId
    },
    detailId () {
      return this.$store.state.setting.detailId
    },
    name () {
      return this.$store.state.setting.name
    },
    subName () {
      return this.$store.state.setting.subName
    },
    pageType () {
      return this.$store.state.setting.pageType
    },
    chkType () {
      return this.$store.state.setting.chkType || 0
    },
    sub () {
      return this.$store.state.setting.sub
    }
  },
  watch: {
    detailId (val) {
      this.initApprovalDetail()
    },
    chkType (val) {
      this.type = this.chkType
      this.initSubProcessList()
    },
    sub (val) {
      this.chkSub = this.sub
    }
  }
}
</script>
