<template>
  <div>
    <Modal v-model="addPackageModal" width="600">
      <p slot="header" class="text-center">{{ isAdd ? '新增' : '编辑' }}资产包</p>
      <Form ref="formValidate" :model="packageModel" :rules="ruleValidate" :label-width="100">
        <FormItem label="名称" prop="name">
          <Input v-model="packageModel.name" size="small" placeholder="填写名称"></Input>
        </FormItem>
        <FormItem label="备注">
          <Input v-model="packageModel.desc" type="textarea" :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="填写备注"></Input>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="text" @click="addPackageModal = false">取消</Button>
        <Button type="primary" @click="onSave">保存</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { savePackage, updatePackage } from '@/api/product/resource'
export default {
  props: {
    projectId: {
      type: Number,
      required: true
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    onSuccess: [Function]
  },
  data () {
    return {
      addPackageModal: false,
      packageModel: {
        id: null,
        projectId: null,
        name: '',
        desc: ''
      },
      ruleValidate: {
        name: [{ required: true, message: ' ', trigger: 'blur' }]
      }
    }
  },
  methods: {
    showModal (data) {
      if (data && !this.isAdd) {
        // 编辑
        Object.keys(this.packageModel).forEach(key => {
          this.packageModel[key] = data[key]
        })
      } else {
        this.packageModel.id = null
        this.packageModel.name = ''
        this.packageModel.desc = ''
        this.$refs.formValidate.resetFields()
      }
      this.packageModel.projectId = this.projectId
      this.addPackageModal = true
    },
    onSave () {
      this.$refs.formValidate.validate(valid => {
        if (valid) {
          this.$Modal.confirm({
            title: '操作提示',
            content: '确定信息无误，并提交？',
            onOk: async () => {
              let result = null
              if (this.isAdd) {
                result = await savePackage(this.packageModel)
              } else {
                result = await updatePackage(this.packageModel)
              }
              if (result && result.success) {
                this.$Notice.success({ desc: '操作成功' })
                this.addPackageModal = false
                this.onSuccess()
              }
            }
          })
        }
      })
    }
  }
}
</script>
