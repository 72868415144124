<template>
  <div>
    <div class="platform-buttom-list">
      <div class="bottom-button m-r-5" @click="goToIndex">
        项目管理
      </div>
      <div class="bottom-button-active">
        设备资源管理
      </div>
    </div>
    <div>
      <div class="m-b-5">
        <div :class="[tagType === 1 ? 'bottom-button-active' : 'bottom-button', 'text-center']"
          @click="handleChangeTag(1)">设备列表</div>
        <div :class="[tagType === 2 ? 'bottom-button-active' : 'bottom-button', 'text-center']"
          @click="handleChangeTag(2)">资源列表</div>
      </div>

      <component :is="currentComponent" :projectId="id"></component>
    </div>
  </div>
</template>

<script>
import { projectCommonMxins } from '../../mixins/common'
export default {
  mixins: [projectCommonMxins],
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  components: {
    DeviceList: () => import('@/components/device/bottom/DeviceList.vue'),
    MediumList: () => import('@/components/device/bottom/MediumList.vue')
  },
  data () {
    return {
      tagType: 1,
      currentComponent: 'DeviceList'
    }
  },
  methods: {
    handleChangeTag (tag) {
      this.tagType = tag
      switch (this.tagType) {
        case 1:
          this.currentComponent = 'DeviceList'
          break
        case 2:
          this.currentComponent = 'MediumList'
          break
      }
    }
  }
}
</script>

<style></style>
