<template>
  <div>
    <h3 v-if="categoryId!==4" class="workplatform-title m-b-10"> 媒体属性</h3>
    <component :is="attributeComponent" :resourceModel="resourceModel" :resourceId="resourceId"></component>
    <!-- <Divider style="margin: 5px 0;" /> -->

    <Tabs class="m-t-10">
      <TabPane label="全部售卖记录">
        <Row :gutter="8" class="m-t-10 p-b-10">
          <i-col span="6">
            <Select size="small" v-model="query.orderStatus" clearable>
              <Option v-for="(item,index) in orderStatusList" :key="index" :value="item.value">{{item.name}}</Option>
            </Select>
          </i-col>
          <i-col span="6">
            <DatePicker size="small" style="width:100%" type="date" placeholder="档期开始日期" format="yyyy-MM-dd"
              v-model="query.startDate"></DatePicker>
          </i-col>
          <i-col span="6">
            <DatePicker size="small" style="width:100%" type="date" placeholder="档期结束日期" format="yyyy-MM-dd"
              v-model="query.endDate"></DatePicker>
          </i-col>
          <i-col span="6">
            <Button size="small" icon="ios-search" type="primary" @click="handleSearchRecords">搜索</Button>
          </i-col>
        </Row>
        <Table size="small" :columns="columns" :data="recordBeanList" stripe></Table>
        <div class="paging_style">
          <Page :total="total" size="small" :page-size="query.pageSize" :current="query.pageNumber" show-total
            show-elevator @on-change="handleChangePage"></Page>
        </div>
      </TabPane>
      <TabPane label="锁位记录" v-if="categoryId!==4">
        <Table :columns="expiretimeColumns" :data="expiretimeList" stripe></Table>
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
import ThemeInfo from '../resourceComponent/ThemeInfo'

import { getResourceById } from '@/api/product/productresource'
import { getResourceRecords, getResourceExpiretime } from '@/api/order/productdetail'
import { getProductSkuRecords } from '@/api/order/productfix'
import { formatOrderStatus, formatOrderSaleType } from '@/utils/tagStatus'

export default {
  props: {
    resourceId: {
      type: Number,
      required: true
    },
    categoryId: {
      type: Number,
      required: true
    },
    isManage: {
      requried: true,
      type: Boolean
    },
    deviceId: [Number],
    startDate: [String], // 时间轴在JS类中使用
    endDate: [String] // 时间轴在JS类中使用
  },
  components: {
    ThemeInfo,
    defaultComponent: () => import('../resourceComponent/LightboxAttribute'),
    brandComponent: () => import('../resourceComponent/BrandAttribute'),
    trainComponent: () => import('../resourceComponent/TrainAttribute'),
    themeComponent: () => import('../resourceComponent/ThemeAttribute')
  },
  data () {
    return {
      attributeComponent: '',
      resourceModel: {
        assetId: 0,
        assetName: '',
        code: '',
        enabled: null,
        exit: '',
        explain: '',
        finalheight: 0,
        finalwidth: 0,
        id: 0,
        layer: 0,
        lockState: null,
        makeheight: 0,
        makewidth: 0,
        position: null,
        positionName: '',
        resourcetypeId: null,
        resourcetypeName: '',
        shortperiodAllowed: null,
        stationId: 0,
        stationLevel: 0,
        stationLevelName: '',
        stationName: ''
      },
      orderStatusList: [
        { value: 1, name: '预订中' },
        { value: 2, name: '待确认' },
        { value: 4, name: '已完成' }
      ],
      query: {
        resourceId: this.resourceId,
        productskuId: this.resourceId,
        startDate: '',
        endDate: '',
        orderStatus: null,
        pageSize: 10,
        pageNumber: 1
      },
      total: 0,
      recordBeanList: [],
      columns: [
        { title: '订单编号', key: 'orderCode' },
        {
          title: '售卖档期',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.startDate + ' 至 ' + params.row.endDate)
          }
        },
        {
          title: '状态',
          align: 'center',
          width: 100,
          render: (h, params) => {
            return formatOrderStatus(h, params.row.orderStatus, params.row.orderStatusName)
          }
        },
        { title: '发布类型', width: 90, align: 'center', key: 'orderTypeName' },
        {
          title: '购买形式',
          align: 'center',
          width: 90,
          render: (h, params) => {
            return formatOrderSaleType(h, params.row.buyType)
          }
        },
        { title: '更新时间', align: 'center', key: 'updateTime' }
      ],

      // 是否显示售出品牌情况按钮
      resourceShowBrand: this.$store.getters.token.publisherSetting.resourceShowBrand,
      expiretimeColumns: [
        { title: '订单编号', key: 'orderCode' },
        {
          title: '售卖档期',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.startDate + ' 至 ' + params.row.endDate)
          }
        },
        {
          title: '状态',
          align: 'center',
          render: (h, params) => {
            return formatOrderStatus(h, params.row.orderStatus, params.row.orderStatusName)
          }
        },
        { title: '发布类型', align: 'center', key: 'orderTypeName' },
        { title: '释放时间', align: 'center', key: 'expireTime' }
      ],
      expiretimeList: []
    }
  },
  created () {
    this.loadComponent()
    this.loadResourceRecords()

    if (this.categoryId !== 4) {
      this.loadResourceExpiretime()
      this.initData()
    }
  },
  methods: {
    loadComponent () {
      if (this.categoryId === 2) {
        this.attributeComponent = 'trainComponent'
      } else if (this.categoryId === 3) {
        this.attributeComponent = 'brandComponent'
      } else if (this.categoryId === 4) {
        this.attributeComponent = 'themeComponent'
      } else {
        this.attributeComponent = 'defaultComponent'
      }
    },
    initData () {
      const data = { resourceId: this.resourceId }
      getResourceById(data).then(res => {
        this.resourceModel = res
      })
    },
    loadResourceRecords () {
      if (this.isManage) {
        this.columns.push(
          { title: '所属客户', align: 'center', key: 'advertiserName' },
          { title: '投放品牌', align: 'center', key: 'brandName' },
          { title: '用户', align: 'center', width: 90, key: 'userName' }
        )

        this.expiretimeColumns.push(
          { title: '所属客户', align: 'center', key: 'advertiserName' },
          { title: '投放品牌', align: 'center', key: 'brandName' },
          { title: '用户', align: 'center', key: 'userName' }
        )
      }
      this.handleSearchRecords()
    },
    loadResourceExpiretime () {
      const expireQuery = {
        deviceId: this.deviceId,
        startDate: this.startDate,
        endDate: this.endDate
      }

      getResourceExpiretime(expireQuery).then((res) => {
        if (res && !res.errcode) {
          this.expiretimeList = res
          // 如果仅显示品牌，那么移除最后3条数据
          if (this.resourceShowBrand !== 2 && this.expiretimeColumns.length === 7) {
            this.expiretimeColumns.splice(4, 3)
          }
        } else {
          this.$Notice.error({ title: '获取资源锁位记录失败', desc: res.errmsg })
        }
      }).catch((err) => {
        this.$Notice.error({ title: '获取资源锁位记录出错', desc: err })
      })
    },
    handleSearchRecords () {
      if (this.categoryId === 4) {
        getProductSkuRecords(this.query).then(res => {
          if (res && !res.errcode) {
            this.recordBeanList = res.recordBeanList.list
            this.query.pageNumber = res.recordBeanList.pageNumber
            this.total = res.recordBeanList.totalRow
          } else {
            this.$Notice.error({ title: '获取售卖记录失败', desc: res.errmsg })
          }
        }).catch(err => {
          this.$Notice.error({ title: '获取售卖记录出错', desc: err })
        })
      } else {
        getResourceRecords(this.query).then((res) => {
          if (res && !res.errcode) {
            this.recordBeanList = res.salesRecordBeanCommonPage.list
            this.query.pageNumber = res.salesRecordBeanCommonPage.pageNumber
            this.total = res.salesRecordBeanCommonPage.totalRow
          } else {
            this.$Notice.error({ title: '获取售卖记录失败', desc: res.errmsg })
          }
        }).catch((err) => {
          this.$Notice.error({ title: '获取售卖记录出错', desc: err })
        })
      }
    },
    handleChangePage (currentPage) {
      this.query.pageNumber = currentPage
      this.handleSearchRecords()
    }
  }
}
</script>
