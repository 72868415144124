
<style lang="css">
.ivu-drawer-content {
  background-color: #1a1a1a !important;
}
</style>
<template>
  <div>
    <Row :gutter="8" class="m-b-10">
      <i-col span="3">
        <Select
          v-model="assetId"
          size="small"
          :clearable="true"
          placeholder="资产"
          @on-change="handleChangeAsset"
        >
          <Option
            v-for="item in companyAssetArray"
            :key="'asset_' + item.id"
            :value="item.id"
            >{{ item.name }}</Option
          >
        </Select>
      </i-col>
      <i-col span="3">
        <Select
          v-model="query.stationId"
          size="small"
          placeholder="站点"
          :clearable="true"
        >
          <Option
            v-for="item in stationArray"
            :key="'station_' + item.id"
            :value="item.id"
            >{{ item.name }}</Option
          >
        </Select>
      </i-col>
      <i-col span="3" v-if="curDemandType !== 11">
        <Select
          v-model="query.position"
          size="small"
          placeholder="位置"
          :clearable="true"
        >
          <Option
            v-for="item in positionArray"
            :key="'position_' + item.value"
            :value="item.value"
            >{{ item.name }}</Option
          >
        </Select>
      </i-col>
      <i-col span="3" v-if="curDemandType !== 11">
        <Select
          v-model="query.resourcetypeId"
          size="small"
          :clearable="true"
          placeholder="资源类型"
        >
          <OptionGroup
            v-for="item in resourceTypeArray"
            :key="'re_parent_' + item.id"
            :label="item.name"
          >
            <Option
              v-for="child in item.children"
              :value="child.id"
              :key="'re_child' + child.id"
              >{{ child.name }}</Option
            >
          </OptionGroup>
        </Select>
      </i-col>
      <i-col span="3">
        <Select
          v-model="query.status"
          size="small"
          placeholder="任务状态"
          :clearable="true"
        >
          <Option
            v-for="item in taskStatusArray"
            :key="'ps_' + item.value"
            :value="item.value"
            >{{ item.name }}</Option
          >
        </Select>
      </i-col>
      <i-col span="3">
        <Input
          v-model="query.keyword"
          size="small"
          placeholder="资源编号、站点名称"
        />
      </i-col>
      <i-col span="1">
        <Button
          type="primary"
          icon="ios-search"
          size="small"
          @click="handleSearch"
          >搜索</Button
        >
      </i-col>
      <i-col span="5"> </i-col>
    </Row>
    <Row :gutter="8" class="m-b-5">
      <i-col span="12">
        <Button
          v-if="[7, 8, 9, 1].includes(curDemandType)"
          size="small"
          type="primary"
          class="m-r-5"
          :disabled="
            selectedTaskitemIds.length === 0 ||
            ![-2].includes(selectedTaskStatus)
          "
          @click="handleAddNewTask"
          >编辑任务</Button
        >
        <Button
          v-if="![5, 7].includes(curDemandType)"
          size="small"
          type="error"
          class="m-r-5"
          :disabled="
            selectedTaskitemIds.length === 0 ||
            ![-2].includes(selectedTaskStatus)
          "
          @click="handelDeleteTaskitem"
          >删除设置</Button
        >
        <Button
          size="small"
          type="error"
          class="m-r-5"
          v-if="![5].includes(curDemandType)"
          :disabled="
            ![-3, 0, 1, 2, 4].includes(selectedTaskStatus) ||
            selectedTaskitemIds.length === 0
          "
          @click="handleCancelTask"
          >撤销任务</Button
        >
        <Button
          size="small"
          type="success"
          class="m-r-5"
          :disabled="selectedTaskitemIds.length === 0"
          @click="handleSubmitTask"
          >提交任务</Button
        >
        <Button
          type="success"
          size="small"
          v-if="[1, 7].includes(curDemandType)"
          @click="handleVisualSetting"
          >可视化设置</Button
        >
        <!-- <Button
          v-if="curDemandType===7"
          size="small"
          type="primary"
          @click="handleViewOrderInfo"
        >查看订单信息</Button> -->
      </i-col>
      <i-col span="12">
        <div class="text-right">
          <Page
            size="small"
            :total="tableData.totalRow"
            :page-size="query.pageSize"
            show-total
            show-sizer
            :page-size-opts="pagesizeSetting"
            @on-page-size-change="changePageSize"
            :current="query.pageNumber"
            @on-change="changePage"
          ></Page>
        </div>
      </i-col>
    </Row>
    <div>
      <Table
        stripe
        max-height="620"
        size="small"
        :data="tableData.list"
        :columns="tableColumns"
        :loading="dataLoading"
        @on-selection-change="handleSelection"
      ></Table>
      <Row :gutter="8">
        <i-col span="12">
          <div class="m-t-5" style="line-height: 25px">
            当前已选中<span style="color: #ff9900; padding: 0 3px">{{
              selectedTaskitemIds.length
            }}</span
            >条
          </div>
        </i-col>
        <i-col span="12">
          <div class="paging_style">
            <Page
              size="small"
              :total="tableData.totalRow"
              :page-size="query.pageSize"
              show-total
              show-elevator
              show-sizer
              :page-size-opts="pagesizeSetting"
              @on-page-size-change="changePageSize"
              :current="query.pageNumber"
              @on-change="changePage"
            ></Page>
          </div>
        </i-col>
      </Row>
    </div>
    <Modal v-model="visiblePreview" width="800">
      <p slot="header" class="text-center">
        {{ fileName }} {{ variableTitle }}
      </p>
      <img-light-box
        ref="imgLightBox"
        :sortIndex.sync="sortIndex"
      ></img-light-box>
      <div slot="footer" class="text-left">
        <div v-if="serialArray && serialArray.length">
          <div>
            <h4 class="workplatform-title m-t-10 m-b-20">对应序号</h4>
          </div>
          <div>
            <Tag
              color="primary"
              v-for="item in serialArray"
              :key="'serial_' + item"
              >{{ item }}</Tag
            >
          </div>
        </div>
      </div>
    </Modal>
    <device-work-record ref="deviceWorkRecord"></device-work-record>
    <!-- <Modal
      v-if="curDemandType===7"
      v-model="showOrderInfoModal"
      width="1000"
    >
      <p
        slot="header"
        class="text-center"
      >查看原始订单信息</p>
      <uninstall-order-info
        ref="orderInfo"
        :taskId="query.taskId"
      ></uninstall-order-info>
    </Modal> -->
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { companyMixin } from '@/assets/mixins/company'
import { commonMixins } from '@/assets/mixins/common'
import {
  formatEnable,
  formartMspTaskitemStatus,
  formartMspTaskitemRephotoStatus
} from '@/utils/tagStatus'
// import { getStationByAssetIds } from '@/api/product/station'
import { getRimStationByAssetIds } from '@/api/rim/station'
import { getPositions } from '@/api/product/resource'
import { getResourceTypeList } from '@/api/product/asset'
import { submit, deleteTaskitem } from '@/api/msp/demandV3'
import { getTaskitemStatus } from '@/api/msp/taskitem'
// import { applyRevokeTaskitem } from '@/api/order/demand'
// import { submit } from "@/api/order/demand";
import { applyRevokeTaskitem } from '@/api/msp/terminate'

import ImgLightBox from '@/components/common/ImgVideoLightBox'
import DeviceWorkRecord from './DeviceWorkRecord'
// import UninstallOrderInfo from './UninstallOrderInfo'
export default {
  mixins: [sysMixins, companyMixin, commonMixins],
  components: {
    ImgLightBox,
    DeviceWorkRecord
    // UninstallOrderInfo
  },
  data () {
    return {
      companyId: this.$store.getters.token.userInfo.companyId,
      assetId: null,
      query: {
        taskId: this.$store.state.task.curTaskId,
        assetIds: '',
        stationId: null,
        position: null,
        resourcetypeId: null,
        keyword: '',
        status: null,
        pageSize: 15,
        pageNumber: 1
      },
      pagesizeSetting: [15, 50, 100, 200, 500],
      selectedTaskStatus: null,
      // selectedTaskitemIds: [], // 撤销任务使用
      stationArray: [],
      positionArray: [],
      resourceTypeArray: [],
      taskStatusArray: [],
      taskTypeShowColumns: new Map([
        [
          5,
          [
            'deviceCode',
            'domainName',
            'typeName',
            'actionDate',
            'repairName',
            'supplierName',
            'status',
            'remark',
            'revoke',
            'operate'
          ]
        ], // 维修
        [
          8,
          [
            'selection',
            'domainName',
            'typeName',
            'actionDate',
            'supplierName',
            'status',
            'remark',
            'revoke'
          ]
        ], // 巡检
        [
          9,
          [
            'selection',
            'deviceCode',
            'domainName',
            'typeName',
            'actionDate',
            'actionFile',
            'supplierName',
            'status',
            'remark',
            'revoke',
            'operate'
          ]
        ], // 维护
        [
          7,
          [
            'selection',
            'resourceCode',
            'domainName',
            'typeName',
            'side',
            'actionDate',
            'actionFile',
            'actionName',
            'picturesource',
            'supplierName',
            'status',
            'remark',
            'revoke',
            'operate'
          ]
        ], // 下刊申请
        [
          1,
          [
            'selection',
            'resourceCode',
            'domainName',
            'typeName',
            'side',
            'actionDate',
            'actionFile',
            'actionName',
            'picturesource',
            'supplierName',
            'status',
            'remark',
            'revoke',
            'operate'
          ]
        ], // 自定义上刊(画面填充)
        [
          11,
          [
            'selection',
            'resourceCode',
            'assetName',
            'domainName',
            'typeName',
            'actionDate',
            'supplierName',
            'status',
            'remark',
            'revoke',
            'operate'
          ]
        ] // 临时任务
      ]),
      tableColumns: [],
      dataLoading: false,
      visiblePreview: false,
      fileName: '',
      variableTitle: '',
      deleteProduce: false,
      selectedPictureSource: [], // 选中任务的素材来源
      sortIndex: 0,
      serialArray: [],
      showSerial: false // 查看素材是否显示序列号
      // showOrderInfoModal: false
    }
  },
  computed: {
    curDemandType () {
      return this.$store.state.task.curDemandType
    },
    taskStatus () {
      return this.$store.state.task.taskStatus
    },
    tableData: {
      get () {
        var results = this.$store.state.task.tableData
        if (results.list) {
          results.list.forEach((item) => {
            item._disabled =
              item.status === 3 || item.revocation || item.hangUpStatus === 2 // 禁用已完成或者挂起任务
            if (
              this.$store.state.task.selectedTaskitemIdArray.some(
                (x) => x === item.id
              )
            ) {
              item._checked = true
            }
          })
        }

        return results
      },
      set (val) {
        this.$store.commit('set_table_data', val)
      }
    },
    selectedResourceIdArray: {
      get () {
        return this.$store.state.task.selectedResourceIdArray
      },
      set (val) {
        this.$store.commit('set_selected_resourceId_array', val)
      }
    },
    selectedTaskitemIds: {
      get () {
        return this.$store.state.task.selectedTaskitemIdArray
      },
      set (val) {
        this.$store.commit('set_selected_taskitemid_array', val)
      }
    },
    tempSelectedTaskItem: {
      // 临时存储分页保留之前选中的taskItem
      get () {
        return this.$store.state.task.tempSelectedItem
      },
      set (val) {
        this.$store.commit('set_temp_selected_item', val)
      }
    }
  },
  created () {
    this.setLinkTagArray({
      key: 'DemandDetial',
      value: '任务查看',
      actived: true
    })
    this.setActivedTagName('DemandDetial')

    this.setLinkRouterMaps([
      'DemandDetial',
      () => {
        this.handlePage()
      }
    ])

    this.$store.commit('set_opreate_type', 2) // 编辑操作标识设置

    this.getCompanyAssetArray()
    this.getTaskitemData()
    this.getTaskStatusData()
  },
  mounted () {
    this.tableColumns = this.getTableColumns()
  },
  beforeDestroy () {
    this.selectedResourceIdArray = []
    this.selectedTaskitemIds = []
    this.selectedPictureSource = []
    this.tableData = {}
    this.$store.commit('set_page_query', null)
    this.$store.commit('set_temp_selected_item', null)
  },
  methods: {
    /**
     * 动态生成Table列表
     */
    getTableColumns () {
      const tableColumnArray = {
        selection: {
          type: 'selection',
          width: 60,
          align: 'center',
          fixed: 'left'
        },
        resourceCode: {
          title: this.curDemandType === 11 ? '任务名称' : '资源编号',
          key: 'resourceCode',
          minWidth: 100,
          align: 'center'
        },
        deviceCode: {
          title: '设备编号',
          key: 'resourceCode',
          minWidth: 100,
          align: 'center'
        },
        assetName: {
          title: '所属资产',
          key: 'assetName',
          minWidth: 100,
          align: 'center'
        },
        domainName: {
          title: '所属站台',
          key: 'domainName',
          minWidth: 100,
          align: 'center'
        },
        typeName: {
          title: '任务类型',
          key: 'typeName',
          minWidth: 100,
          align: 'center'
        },
        side: { title: '面数', key: 'side', minWidth: 60, align: 'center' },
        // finishDate: { title: '当前在刊结束日', key: 'finishDate', minWidth: 100, align: 'center' },
        actionDate: {
          title: '作业日期',
          key: 'actionDate',
          minWidth: 120,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('p', params.row.actionDate),
              h(
                'Tag',
                {
                  props: {
                    color:
                      params.row.actionDateModifyStatus === 1
                        ? 'default'
                        : 'primary'
                  }
                },
                params.row.actionDateModifyStatusName
              )
            ])
          }
        },
        actionFile: {
          title: '作业画面',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            if (
              params.row.taskitemFiles &&
              params.row.taskitemFiles.length > 0
            ) {
              if (
                params.row.taskitemFiles.some(
                  (x) =>
                    x.mimeType.includes('video') || x.mimeType.includes('audio')
                )
              ) {
                return h(
                  'a',
                  {
                    on: {
                      click: () => {
                        const postData = {
                          startIndex: 0,
                          imgList: params.row.taskitemFiles
                        }
                        this.sortIndex = 0
                        this.fileName = params.row.stationName
                        this.curShowFileArray = params.row.taskitemFiles
                        this.$refs.imgLightBox.init(postData)
                        this.showSerial = true
                        this.handleCurFileSerial()
                        this.visiblePreview = true
                      }
                    }
                  },
                  '查看素材'
                )
              } else {
                return h('div', [
                  h('img', {
                    domProps: {
                      src:
                        params.row.taskitemFiles[0].fileKey +
                        '?x-oss-process=image/resize,w_64'
                    },
                    on: {
                      click: () => {
                        const postData = {
                          startIndex: 0,
                          imgList: params.row.taskitemFiles
                        }
                        this.sortIndex = 0
                        this.fileName = `${params.row.domainName} ${params.row.code}`
                        this.variableTitle = ''
                        this.curShowFileArray = params.row.taskitemFiles
                        this.$refs.imgLightBox.init(postData)
                        this.showSerial = true
                        this.handleCurFileSerial()
                        this.visiblePreview = true
                      }
                    },
                    style: {
                      width: '64px',
                      cursor: 'pointer'
                    }
                  })
                ])
              }
            } else {
              return h('span', '暂无画面')
            }
          }
        },
        repairName: {
          title: '维修项',
          minWidth: 120,
          align: 'center',
          render: (h, params) => {
            return h(
              'Tooltip',
              {
                props: {
                  content: params.row.taskitemRepair.repairName || '',
                  transfer: true,
                  'max-width': '200px'
                }
              },
              params.row.taskitemRepair.repairName &&
                params.row.taskitemRepair.repairName.length > 12
                ? params.row.taskitemRepair.repairName.substr(0, 11) + '...'
                : params.row.taskitemRepair.repairName
            )
          }
        },
        actionName: {
          title: '作业名称',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            const name = Array.from(
              new Set(params.row.taskitemFiles.map((x) => x.fileName))
            ).toString()
            return h(
              'Tooltip',
              {
                props: {
                  content: name,
                  transfer: true,
                  'max-width': '200px'
                }
              },
              name.length > 12 ? name.substr(0, 11) + '...' : name
            )
          }
        },
        picturesource: {
          title: '画面来源',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            return h('div', [
              h('p', params.row.picturesourceName),
              params.row.isReuseOldFile
                ? h(
                  'p',
                  {
                    style: { color: '#ff9900' }
                  },
                  '使用已有画面'
                )
                : null
            ])
          }
        },
        supplierName: {
          title: '供应商',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            return h('span', params.row.taskitemLabor.companyName)
          }
        },
        userName: {
          title: '巡检人员',
          align: 'center',
          minWidth: 60,
          render: (h, params) => {
            return h('span', params.row.taskitemLabor.userName)
          }
        },
        status: {
          title: '状态',
          minWidth: 120,
          align: 'center',
          render: (h, params) => {
            const html = [
              formartMspTaskitemStatus(
                h,
                params.row.status,
                params.row.statusName
              ),
              formartMspTaskitemRephotoStatus(h, params.row.rephoto)
            ]
            if (params.row.hangUpStatus === 2) {
              // 挂起
              html.push(
                h('div', [
                  h(
                    'Tag',
                    {
                      props: {
                        color: 'warning'
                      }
                    },
                    '已挂起'
                  )
                ])
              )
            }
            return h('div', html)
          }
        },
        remark: {
          title: '备注',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            const remark = `${params.row.remark} ${params.row.extra}`
            return h(
              'Tooltip',
              {
                props: {
                  content: remark,
                  transfer: true,
                  'max-width': '200px'
                }
              },
              remark.length > 12 ? remark.substr(0, 11) + '...' : remark
            )
          }
        },
        revoke: {
          title: '撤销申请',
          minWidth: 80,
          align: 'center',
          render: (h, params) => {
            return formatEnable(h, params.row.revocation)
          }
        },
        operate: {
          title: '操作',
          align: 'center',
          minWidth: 120,
          render: (h, params) => {
            var html = []
            if (
              params.row.taskitemAttachs &&
              params.row.taskitemAttachs.length
            ) {
              html.push(
                h(
                  'a',
                  {
                    style: { marginRight: '5px' },
                    on: {
                      click: () => {
                        const postData = {
                          startIndex: 0,
                          imgList: params.row.taskitemAttachs
                        }
                        this.fileName = `${params.row.domainName} ${params.row.code}`
                        this.variableTitle = params.row.finishTime
                          ? `（完成时间：${params.row.finishTime}）`
                          : ''
                        this.curShowFileArray = params.row.taskitemAttachs
                        this.showSerial = false
                        this.handleCurFileSerial()
                        this.$refs.imgLightBox.init(postData)
                        this.visiblePreview = true
                      }
                    }
                  },
                  '照片反馈'
                )
              )
            }
            if (this.curDemandType === 7) {
              html.push(
                h(
                  'a',
                  {
                    style: { marginRight: '5px' },
                    on: {
                      click: () => {
                        this.onViewWorkRecord(params.row)
                      }
                    }
                  },
                  '历史作业记录'
                )
              )
            }
            if (params.row.status === -2) {
              if ([5, 11].includes(this.curDemandType)) {
                html.push(
                  h(
                    'a',
                    {
                      style: { marginRight: '5px' },
                      on: {
                        click: () => {
                          this.handleEditTask(params.row)
                          this.handleCurFileSerial()
                        }
                      }
                    },
                    '编辑'
                  )
                )
              }
              if (this.curDemandType === 5) {
                html.push(
                  h(
                    'a',
                    {
                      style: { marginRight: '5px', color: '#ef4f4f' },
                      on: {
                        click: () => {
                          this.selectedTaskitemIds = [params.row.id]
                          this.handelDeleteTaskitem()
                        }
                      }
                    },
                    '删除'
                  )
                )
              }
            }
            // if (params.row.resourceNotFinishTask.id) {
            //   html.push(h('a', {
            //     style: { display: 'block' },
            //     on: {
            //       click: () => {
            //         this.relatedTaskObj = params.row.resourceNotFinishTask
            //         this.curTaskItemStatus = params.row.status
            //         this.produceTaskitemStatus = params.row.produceTaskitemStatus
            //         this.mergeModel.executeTaskitemId = this.taskType === 1 ? params.row.id : params.row.resourceNotFinishTask.id
            //         this.mergeModel.terminateTaskitemId = this.taskType === 1 ? params.row.resourceNotFinishTask.id : params.row.id
            //         this.taskViewModal = true
            //       }
            //     }
            //   }, '关联任务'))
            // }
            return h('div', html)
          }
        }
      }

      const data = []
      var showColumns = this.taskTypeShowColumns.get(this.curDemandType)
      if (!showColumns || showColumns.length === 0) {
        showColumns = this.taskTypeShowColumns.get(1)
      }
      showColumns.forEach((col) => data.push(tableColumnArray[col]))

      return data
    },
    getTaskitemData () {
      this.dataLoading = true
      this.query.assetIds = this.assetId ? JSON.stringify([this.assetId]) : ''
      // this.query.status = null
      this.$store.dispatch('getTaskitemData', this.query).finally(() => {
        this.dataLoading = false
      })
    },
    handleChangeAsset () {
      this.query.pageNumber = 1
      this.query.stationId = null
      this.query.position = null
      this.query.resourcetypeId = null
      this.tempSelectedTaskItem = []
      this.selectedTaskitemIds = []
      this.selectedResourceIdArray = []
      this.selectedPictureSource = []
      this.getStationData()
      this.getPositionData()
      this.getResourceTypeData()
      this.getTaskitemData()
    },
    getStationData () {
      getRimStationByAssetIds({
        assetIds: this.assetId ? JSON.stringify([this.assetId]) : ''
      }).then((res) => {
        if (res && !res.errcode) {
          this.stationArray = res
        } else {
          this.stationArray = []
        }
      })
    },
    getPositionData () {
      const assetIds = []
      if (this.assetId) {
        assetIds.push(this.assetId)

        getPositions({ assetIds: JSON.stringify(assetIds) }).then((res) => {
          if (res && !res.errcode) {
            this.positionArray = res
          } else {
            this.positionArray = []
          }
        })
      } else {
        this.positionArray = []
      }
    },
    getResourceTypeData () {
      if (this.assetId) {
        const postData = {
          assetId: this.assetId
        }
        getResourceTypeList(postData).then((res) => {
          if (res && !res.errcode) {
            this.resourceTypeArray = res
          } else {
            this.resourceTypeArray = []
          }
        })
      } else {
        this.resourceTypeArray = []
      }
    },
    /**
     * 获取任务状态类型
     */
    getTaskStatusData () {
      getTaskitemStatus({ taskType: this.curDemandType }).then((res) => {
        if (res && !res.errcode) {
          this.taskStatusArray = res
        } else {
          this.taskStatusArray = []
        }
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.tempSelectedTaskItem = []
      this.selectedTaskitemIds = []
      this.selectedResourceIdArray = []
      this.selectedPictureSource = []
      this.getTaskitemData()
    },
    changePageSize (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.tempSelectedTaskItem = []
      this.selectedTaskitemIds = []
      this.selectedResourceIdArray = []
      this.selectedPictureSource = []
      this.getTaskitemData()
    },
    changePage (curPage) {
      this.query.pageNumber = curPage
      this.getTaskitemData()
    },
    handleSelection (selection) {
      this.selectedTaskitemIds = []
      this.selectedResourceIdArray = []
      this.selectedPictureSource = []
      var tempSelectedTaskStatus = []

      // 最终选中的所有taskItem
      const curSelection = selection.map((x) => {
        return {
          id: x.id,
          deviceId: x.deviceId,
          status: x.status,
          pictureSource: x.picturesource
        }
      })
      this.handelPageSelections(
        this.tempSelectedTaskItem,
        curSelection,
        this.query.pageNumber
      )
      this.tempSelectedTaskItem.forEach((item) => {
        this.selectedTaskitemIds = this.selectedTaskitemIds.concat(
          item.existSelections.map((x) => x.id)
        )
        this.selectedResourceIdArray = this.selectedResourceIdArray.concat(
          item.existSelections.map((x) => x.deviceId)
        )
        this.selectedPictureSource = this.selectedPictureSource.concat(
          item.existSelections.map((x) => x.pictureSource)
        )
        tempSelectedTaskStatus = tempSelectedTaskStatus.concat(
          item.existSelections.map((x) => x.status)
        )
      })

      const selectedTaskStatusArray = Array.from(
        new Set(tempSelectedTaskStatus)
      )
      this.selectedTaskStatus = selectedTaskStatusArray.length
        ? selectedTaskStatusArray[0]
        : []

      if (selectedTaskStatusArray.length > 1) {
        this.selectedTaskitemIds = []
        this.selectedResourceIdArray = []
        this.selectedTaskStatus = null
        this.$Notice.warning({ desc: '请选择相同状态的任务进行批量操作' })
      }
    },
    /**
     * 单条数据编辑
     */
    handleEditTask (data) {
      this.$store.commit('set_show_left_block', true)

      this.selectedTaskitemIds = [data.id]
      switch (this.curDemandType) {
        case 5: // 维修任务
          this.selectedResourceIdArray = [data.deviceId]
          this.$store.commit('set_selected_taskitem_files', data.taskitemFiles)
          this.$store.commit('set_cur_device_rapair_info', data.taskitemRepair)
          this.$store.commit('set_left_component', 'SetRepairTask')
          break
        case 11: // 临时任务
          this.$store.commit('set_left_component', 'SetTempTask')
          break
        default:
          this.$store.commit('set_left_component', 'SetRepairTask')
          break
      }
    },
    handleAddNewTask () {
      if ([7, 1].includes(this.curDemandType)) {
        this.$store.commit('set_left_component', 'SetFillFile')
      } else {
        this.$store.commit('set_left_component', 'SetMaintainTask')
      }
      this.$store.commit('set_show_left_block', true)
    },
    /**
     * 取消任务
     */
    handleCancelTask () {
      this.$Modal.confirm({
        title: '操作提示',
        render: (h) => {
          const html = []
          // 选中任务的素材来源存在”新制作“，则提示是否取消制作任务
          if (this.selectedPictureSource.includes(1)) {
            html.push(
              h(
                'p',
                {
                  class: ['m-b-10']
                },
                '当前存在制作任务，是否要取消制作任务？'
              )
            )
            html.push(
              h(
                'Checkbox',
                {
                  props: {
                    autofocus: true,
                    value: this.deleteProduce
                  },
                  class: ['m-b-10'],
                  on: {
                    input: (val) => {
                      this.deleteProduce = val
                    }
                  }
                },
                '取消制作任务'
              )
            )
          }
          html.push(
            h('Input', {
              props: {
                type: 'textarea',
                rows: 4,
                value: this.cancelRemark,
                autofocus: true,
                placeholder: '填写取消任务原因，非必填'
              },
              on: {
                input: (val) => {
                  this.cancelRemark = val
                }
              }
            })
          )
          return h('div', html)
          // return h('Input', {
          //   props: {
          //     type: 'textarea',
          //     rows: 4,
          //     value: this.cancelRemark,
          //     autofocus: true,
          //     placeholder: '填写取消任务原因，非必填'
          //   },
          //   on: {
          //     input: (val) => {
          //       this.cancelRemark = val
          //     }
          //   }
          // })
        },
        onOk: () => {
          applyRevokeTaskitem({
            taskitemIds: JSON.stringify(this.selectedTaskitemIds),
            deleteProduce: this.deleteProduce,
            remark: this.cancelRemark
          }).then((res) => {
            if (res && res.errcode === 0) {
              this.tempSelectedTaskItem = []
              this.selectedTaskitemIds = []
              this.selectedResourceIdArray = []
              this.selectedPictureSource = []
              this.getTaskitemData()
              this.$Notice.success({ desc: res.errmsg })
            }
          })
        }
      })
    },
    /**
     * 删除任务设置
     */
    handelDeleteTaskitem () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要删除所选数据？',
        onOk: () => {
          deleteTaskitem({
            taskitemIds: JSON.stringify(this.selectedTaskitemIds)
          }).then((res) => {
            if (res && res.errcode === 0) {
              this.tempSelectedTaskItem = []
              this.selectedTaskitemIds = []
              this.selectedResourceIdArray = []
              this.selectedPictureSource = []

              this.$Notice.success({ desc: '操作成功' })
              this.getTaskitemData()
            }
          })
        }
      })
    },
    handleSubmitTask () {
      if (this.selectedTaskitemIds.length === 0) {
        this.$Notice.warning({ desc: '请至少选择一项任务进行提交' })
        return false
      }
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要提交已设置任务？',
        onOk: () => {
          submit({
            taskId: this.query.taskId,
            taskitemIds: JSON.stringify(this.selectedTaskitemIds)
          }).then((res) => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              // 处理切换到首页
              this.setActivedTagName('demandIndex')
              this.setShowLeftBlock(false)
              this.setShowRightBlock(false)
              this.setShowMap(false)
              this.setBottomHeight('90%')
              this.setLeftComponent('')
              this.setRightComponent('')
              this.setBottomComponent('IndexTable')
            }
          })
        }
      })
    },
    /**
     * 获取当前画面所在的梯牌号
     */
    handleCurFileSerial () {
      this.serialArray = []
      if (this.showSerial) {
        this.serialArray = this.curShowFileArray[this.sortIndex].serials
          ? JSON.parse(this.curShowFileArray[this.sortIndex].serials)
          : []
      }
    },
    /**
     * 查看资源历史作业记录
     */
    onViewWorkRecord (param) {
      this.$refs.deviceWorkRecord.show(param.deviceId)
    },
    // /**
    //  * 查看原始订单信息
    //  */
    // handleViewOrderInfo () {
    //   this.showOrderInfoModal = true
    //   this.$refs.orderInfo.initData()
    // },
    handlePage () {
      if (this.curDemandType === 7) {
        // 下刊申请
        this.setShowLeftBlock(true)
        this.setLeftComponent('UninstallScreen')
      } else {
        this.setShowLeftBlock(false)
        this.setLeftComponent('')
      }

      this.setShowRightBlock(false)
      this.setShowMap(false)
      this.setBottomHeight('90%')
      this.setRightComponent('')
      this.setBottomComponent('DemandDetial')
    },
    /**
     * 可视化设置
     */
    handleVisualSetting () {
      this.$store.commit('set_show_left_block', false)
      this.$store.commit('set_bottom_component', 'VisualSetting')
    }
  },
  watch: {
    sortIndex: {
      deep: true,
      immediate: true,
      handler (val) {
        this.handleCurFileSerial()
      }
    }
  }
}
</script>
