<template>
  <Modal v-model="showsettingModal" :width="600" :mask-closable="false">
    <p slot="header" class="workplatform-title">
      <span>服务采购</span>
    </p>
    <Row class="text-bold">
      <i-col span="6">服务项名称</i-col>
      <i-col span="4">类型</i-col>
      <i-col span="4">刊例价</i-col>
      <i-col span="4">订单内数量</i-col>
      <i-col span="6">采购量</i-col>
    </Row>
    <div style="margin-top: 15px" v-for="(sc, i) in services" :key="i">
      <Row>
        <i-col span="6">
          {{ sc.serviceItemName }}
        </i-col>
        <i-col span="4">
          {{ sc.typeName }}
        </i-col>
        <i-col span="4"> {{ formatMoney(sc.price) }} /{{ sc.quantityUnitName }} </i-col>
        <i-col span="4"> {{ sc.defaultQuantity }}  </i-col>
        <i-col span="6">
          <!--  总数量 -->
          <InputNumber
            v-model="sc.quantity"
            :min="0"
            size="small"
          ></InputNumber>
        </i-col>
      </Row>
    </div>
    <div slot="footer">
      <i-button class="btn-black" @click="handleCancelSetting">关闭</i-button>
      <i-button type="primary" @click="handleConfirmSetting">确认</i-button>
    </div>

    <Spin fix size="large" v-if="spinShow">
      <Icon type="ios-loading" size="18" class="spin-icon-load"></Icon>
      <div>加载中...</div>
    </Spin>
  </Modal>
</template>

<script>

import { getItemlist, addServiceItems } from '@/api/order/orderserviceV3'
import { toMoney } from '@/utils/wnumb_own'

export default {
  props: {
    orderId: {
      type: Number
    },
    isShow: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      services: [],

      showsettingModal: false,
      category: [],
      productPriceMap: new Map(),
      products: [],
      spinShow: false
    }
  },
  components: {},
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },
    getDetail () {
      // 获取当前订单的基本信息
      getItemlist({
        orderId: this.orderId
      }).then((res) => {
        this.services = res.data

        this.spinShow = false
      })
    },
    handleConfirmSetting () {
      // const products = [...this.productPriceMap.values()]

      addServiceItems({
        orderId: this.orderId,

        serviceitemList: this.services.map(item => {
          return {
            serviceitemId: item.serviceItemId, quantity: item.quantity
          }
        })
      }).then((res) => {
        this.showsettingModal = false
        this.$emit('on-finished')
      })
    },
    handleCancelSetting () {
      this.showsettingModal = false
    }
  },
  watch: {
    showsettingModal (val) {
      this.$emit('update:isShow', val)
    },
    isShow (val) {
      this.showsettingModal = val
      if (val) {
        this.spinShow = true
        this.getDetail()
      }
    }
  }
}
</script>
