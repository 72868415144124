<template>
  <div>
      <Divider dashed ><span class="divider-text">订单基础信息</span></Divider>
      <div class="p-l-10 p-r-10">
          <Row class="p-b-5">
              <i-col span="6" class="title">投放客户</i-col>
              <i-col span="18">{{orderInfo.advertiserName}}</i-col>
          </Row>
          <Row class="p-b-5">
              <i-col span="6" class="title">发布品牌</i-col>
              <i-col span="18">{{orderInfo.brandName}}</i-col>
          </Row>
          <Row class="p-b-5">
              <i-col span="6" class="title">创建者</i-col>
              <i-col span="18">{{orderInfo.userName}}</i-col>
          </Row>
          <Row class="p-b-5">
              <i-col span="6" class="title">订单状态</i-col>
              <i-col span="18">
                <span v-if="orderInfo.status === 0">{{orderInfo.statusName}}</span>
                <Tag v-else :color="getOrderStatusColor(orderInfo.status).bgColor" :style="getOrderStatusColor(orderInfo.status).border ? 'border: 1px solid #fff' : 'none'">{{orderInfo.statusName}}</Tag>
              </i-col>
          </Row>
          <Row class="p-b-5">
              <i-col span="6" class="title">创建时间</i-col>
              <i-col span="18">{{orderInfo.createTime}}</i-col>
          </Row>
          <Row class="p-b-5">
              <i-col span="6" class="title">有效期至</i-col>
              <i-col span="18">{{orderInfo.expireTime}}</i-col>
          </Row>
          <Row class="p-b-5">
              <i-col span="6" class="title p-t-5">发布日期</i-col>
              <i-col span="18"><Tag type="border" color="black">{{orderInfo.startDate}} 至 {{orderInfo.endDate}} </Tag></i-col>
          </Row>
          <Row class="p-b-5">
              <i-col span="6" class="title">订单备注</i-col>
              <i-col span="18">{{orderInfo.remark}}</i-col>
          </Row>
          <Row class="p-b-5">
              <i-col span="6" class="title">不确定方案</i-col>
              <i-col span="18">
                <span v-if="orderInfo.fixed">-</span>
                <Icon v-else type="md-checkmark" color="#19be6b" size="18" />
                <Button v-show="isEdit && !orderBean.fixed && orderInfo.status === 4"  size="small" type="info" class="m-l-5 right" icon="md-lock" @click="handleLockPonit">锁定点位</Button>
              </i-col>
          </Row>
          <Row class="p-b-5" v-if="showServicePurchase">
              <i-col span="24">
                <Button  size="small" type="warning" class="m-l-5 right" style="width:100%" @click="handleServiceSetting">服务采购</Button>
              </i-col>
          </Row>
          <Row v-if="isEdit">
              <i-col span="6" class="title">操作</i-col>
              <i-col span="18">
                <template v-if="isAuth('order_modify_submit')">
                  <Button size="small" v-show="(orderInfo.status === 0 || orderInfo.status === 3)&&!mediaLibrary" type="success" @click="handleSubmitOrder" :loading="loading">提交订单</Button>
                  <Button size="small" class="m-l-5" v-show="(fixQueue||commonQueue)" type="primary" @click="showOrderQuene">查看排队资源</Button>
                </template>

                <div class="m-t-5" v-if="mediaLibrary">
                  <Button size="small" type="primary" @click="handleConfirmChange">确认订单</Button>
                  <Button size="small" class="m-l-5" type="error" @click="handleDiscardOrder">废弃订单</Button>
                </div>

              </i-col>
              <!-- <i-col span="18" v-if="isAuth('order_modify_locked')">
                <Button size="small" v-show="(orderInfo.status === 0 || orderInfo.status === 3)" type="success" @click="handleLockedOrder" :loading="loading">订单锁位</Button>
              </i-col> -->
          </Row>
      </div>

      <resourceQuene ref="resourceQuene"/>
      <Modal v-model="modalShowConfirm" width="800" title="订单冲突提醒" :footer-hide="true">
        <p class="remark" v-html="confirmWarn"></p>
        <h2 class="p-t-10 m-b-5">冲突点位</h2>
        <Table class="conflictTable" :columns="orderProductConflictColumns" :data="orderProductConflictList" stripe></Table>

        <Row class="text-right m-t-10">
          <i-col span="24">
            <Button type="success" size="small" class=" m-r-10" @click="SubmitOrder">提交订单</Button>
          </i-col>
        </Row>
      </Modal>
      <ServiceSetting  :isShow.sync="showServiceSettingModal" :orderId="orderBean.id" @on-finished="handleSettingFinished"></ServiceSetting>
  </div>
</template>

<script>
import resourceQuene from './ResourceQuene'
import ServiceSetting from './ServiceSetting'
import { beforeSubmit, submitOrder, lockOrder, finishOrder } from '@/api/order/order'
import { getOrderStatus } from '@/utils/tagStatus'
import { GetCurrentSchedule } from '@/utils/dateFormat'

import { discardOrder, confirmChange } from '@/api/order/cloudorder'

export default {
  props: {
    orderBean: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    resourceQuene,
    ServiceSetting
  },
  data () {
    return {
      orderInfo: {},
      isManage: this.$store.state.order.isManage,
      fixQueue: this.$store.getters.token.publisherSetting.fixQueue, // 主题媒介是否可以排队
      commonQueue: this.$store.getters.token.publisherSetting.commonQueue, // 常规资源是否可以排队
      loading: false,
      showServiceSettingModal: false,
      modalShowConfirm: false,
      confirmWarn: '',
      orderProductConflictList: [],
      orderProductConflictColumns: [
        { title: '客户', width: 160, key: 'advertiserName' },
        { title: '品牌', key: 'brandName' },
        { title: '冲突资源编号', key: 'resourceCode' },
        {
          title: '冲突档期',
          width: 180,
          render: (h, data) => {
            return h('span', GetCurrentSchedule(data.row.startDate, data.row.endDate))
          }
        },
        { title: '使用人', key: 'userName' }
      ]
    }
  },
  created () {
    this.orderInfo = this.orderBean
  },
  methods: {
    getOrderStatusColor (status) {
      return getOrderStatus(status)
    },
    handleSubmitOrder () {
      this.loading = true
      beforeSubmit({ orderId: this.orderBean.id }).then(res => {
        const status = res.orderStatusValue
        let lblcontent = ''
        if (status === 3) { // 先签先得
          lblcontent = '请注意：当前订单开始档期为 <b style="color:orange"> 临近档期 </b>或<b style="color:orange"> 非常规资源</b>，将不锁定点位，订单状态为： <b class="text-red">' + res.orderStatusName + '</b>'
        } else {
          lblcontent = '您提交的订单点位将被锁定，订单状态为： <b class="text-red">' + res.orderStatusName + '</b>，锁定截止时间：' + res.expireTime
        }

        // 主题媒介和已采购的资源冲突
        if (res.orderProductConflictList.length > 0) {
          this.confirmWarn = lblcontent
          this.orderProductConflictList = res.orderProductConflictList
          this.modalShowConfirm = true
        } else {
          this.$Modal.confirm({
            title: '确认要提交订单吗？',
            content: lblcontent,
            loading: true,
            onOk: () => {
              this.$Modal.remove()
              this.SubmitOrder()
            }
          })
        }

        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    SubmitOrder () { // 提交订单
      this.modalShowConfirm = false
      const postData = { orderId: this.orderBean.id }

      submitOrder(postData).then(res => {
        if (res && !res.errcode) {
          this.$store.dispatch('getOrderBaseInfo', postData)
          this.$store.commit('set_order_pageType', 'detail')
          this.$Notice.success({ desc: '订单提交成功' })
        }
      })
    },
    handleLockPonit () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认要锁定当前订单点位吗？点位锁定后，将不再允许变更！',
        onOk: () => {
          const postData = { orderId: this.orderBean.id }

          lockOrder(postData).then(res => {
            if (res && !res.errcode) {
              this.$store.dispatch('getOrderBaseInfo', postData)
              this.$store.commit('set_order_pageType', 'detail')
              this.$Notice.success({ desc: '订单锁位成功！' })
            }
          })
        }
      })
    },
    handleLockedOrder () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认要锁定当前订单点位吗？点位锁定后，将不再允许变更！',
        onOk: () => {
          const postData = { orderId: this.orderBean.id }
          finishOrder(postData).then(res => {
            if (res && res.errcode === 0) {
              this.$store.dispatch('getOrderBaseInfo', postData)
              this.$store.commit('set_order_pageType', 'detail')
              this.$Notice.success({ desc: '订单锁位成功' })
            }
          })
        }
      })
    },
    showOrderQuene () {
      this.$refs.resourceQuene.showModal(this.orderInfo.id)
    },
    handleConfirmChange () {
      this.$Modal.confirm({
        title: '操作提示',
        // content: '确认要<b style="color:#19be6b"> 生成交易结算单 </b> 吗？，生成后当前订单将默认被提交。',
        content: '是否确认订单信息？',
        onOk: () => {
          const postData = { orderId: this.orderBean.id }

          confirmChange(postData).then(res => {
            this.$store.dispatch('getOrderBaseInfo', postData)
            this.$store.commit('set_order_pageType', 'detail')
            this.$Notice.success({ desc: '订单确认成功' })
          })
        }
      })
    },
    handleDiscardOrder () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认要<b style="color:red"> 废弃 </b>当前订单吗？',
        onOk: () => {
          const postData = { orderId: this.orderBean.id }

          discardOrder(postData).then(res => {
            this.$store.dispatch('getOrderBaseInfo', postData)
            this.$store.commit('set_order_pageType', 'detail')
            this.$Notice.success({ desc: '订单废弃成功' })
          })
        }
      })
    },
    handleServiceSetting () {
      this.showServiceSettingModal = true
    },
    handleSettingFinished () {
      this.$store.commit('set_order_update', new Date())
      this.$store.commit('set_order_detail_reload', new Date())
    }
  },
  watch: {
    orderBean (val) {
      this.orderInfo = this.orderBean
    }
  },
  computed: {

    showServicePurchase () {
      if (this.isManage) {
        return this.isAuth('order_manage_tool_service')
      } else {
        // “预定中” 和 “先签先得”
        const orderStatusAllow = [1, 3].includes(this.orderBean.status)

        return orderStatusAllow
      }
    },
    beginUpdate () {
      return this.$store.state.order.beginUpdate
    },
    mediaLibrary () {
      // 媒体商城生成的订单，并且仅 0备选中、 1预订中的状态才能显示该按钮
      if (this.orderBean.type === 12 && [0, 1].includes(this.orderBean.status)) {
        return true
      }
      return false
    }
  }
}
</script>

<style>
.conflictTable .ivu-table-cell{
  padding-left: 5px;
  padding-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  box-sizing: border-box;
}
</style>
