<template>
  <div>
    <Modal v-model="modifyModal" width="800" footer-hide>
      <p slot="header" class="text-center">{{ newAdd ? '新增规则配置' : '编辑规则配置' }}</p>
      <Form ref="formValidate" :model="settingModel" :rules="ruleValidate" :label-width="130">
        <FormItem label="名称" prop="name">
          <Input v-model="settingModel.name" size="small" placeholder="填写规则配置名称" />
        </FormItem>
        <FormItem label="媒体类型" prop="categoryId">
          <Select v-model="settingModel.categoryId" size="small" placeholder="选择媒体类型" @on-change="onChangeCategory">
            <Option v-for="item in mediaCategoryArray" :key="'category_' + item.id" :value="item.id">{{ item.name }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="包含产品" prop="products">
          <Select v-model="settingModel.products" size="small" multiple placeholder="选择产品（可多选）">
            <Option v-for="item in productArray" :key="'product_' + item.id" :value="item.id">{{ item.name }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="最小售卖规格（秒）" prop="durations">
          <Input v-model="settingModel.durations" size="small" placeholder="最小售卖规格，多个规格请用英文逗号“,”隔开，例：10,20,30" />
        </FormItem>
        <FormItem label="每天周期数（次）" prop="periods">
          <Input v-model="settingModel.periods" size="small" placeholder="每天周期数，多个周期数请用英文逗号“,”隔开，例：10,20,30" />
        </FormItem>
        <FormItem label="起止时间">
          <TimePicker v-model="times" :editable="false" :transfer="true" size="small" type="timerange"
            placeholder="选择起止时间" style="width: 100%"></TimePicker>
        </FormItem>
        <FormItem class="text-right m-t-10">
          <Button type="text" class="m-r-5" @click="modifyModal = false">取消</Button>
          <Button type="primary" @click="handleSave">确定</Button>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import { productMixins } from '../mixins/product'
import { add, edit } from '@/api/product/electricProducts'
export default {
  props: {
    projectId: [Number],
    publisherId: [Number],
    newAdd: {
      type: Boolean,
      default: false
    },
    onSuccess: {
      type: Function,
      default: () => { }
    }
  },
  mixins: [productMixins],
  data () {
    return {
      modifyModal: false,
      settingModel: {
        durations: '',
        endTime: '',
        id: null,
        name: '',
        periods: '',
        products: [],
        productIds: '',
        publisherId: null,
        projectId: null,
        startTime: '',
        categoryId: null

      },
      mediaCategoryArray: [
        { id: 8, name: '电子媒体' },
        { id: 4, name: '主题媒体' },
        { id: 7, name: '套装媒体' }
      ],
      productArray: [],
      times: ['00:00:00', '23:59:59'], // 起止时间
      ruleValidate: {
        name: [
          { required: true, type: 'string', message: ' ', trigger: 'blur' }
        ],
        categoryId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        products: [
          { required: true, type: 'array', min: 1, message: ' ', trigger: 'change' }
        ],
        durations: [
          { required: true, pattern: /^\d+(,\d+)*$/, message: ' ', trigger: 'blur' }
        ],
        periods: [
          { required: true, pattern: /^\d+(,\d+)*$/, message: ' ', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    init (data) {
      if (this.newAdd) { // 新增
        Object.assign(this.$data.settingModel, this.$options.data().settingModel)
        this.times = ['00:00:00', '23:59:59']
      } else { // 编辑
        if (data) {
          Object.keys(this.settingModel).forEach(key => {
            this.settingModel[key] = data[key]
          })
          this.settingModel.products = JSON.parse(this.settingModel.productIds)
          this.times = [this.settingModel.startTime, this.settingModel.endTime]
          this.$nextTick(() => {
            this.getProductArray(this.settingModel.categoryId)
          })
        }
      }
      this.settingModel.publisherId = this.publisherId
      this.settingModel.projectId = this.projectId
      this.modifyModal = true
    },
    getProductArray (cid) {
      this.productArray = []
      this.getProductData({ categoryId: cid }).then(res => {
        if (res && !res.errcode) {
          this.productArray = res
        }
      })
    },
    onChangeCategory () {
      this.getProductArray(this.settingModel.categoryId)
    },
    handleSave () {
      // 电子媒体处理方式
      if (this.times.length) {
        this.settingModel.startTime = this.times[0]
        this.settingModel.endTime = this.times[1]
      }
      this.settingModel.productIds = JSON.stringify(this.settingModel.products)
      console.log(this.settingModel)
      this.$refs.formValidate.validate((valid) => {
        if (valid) {
          this.$Modal.confirm({
            title: '操作提示？',
            content: '确定信息无误，并提交？',
            onOk: () => {
              if (this.newAdd) {
                this.funAdd(this.settingModel)
              } else {
                this.funEdit(this.settingModel)
              }
            }
          })
        }
      })
    },
    funAdd (data) {
      add(data).then(res => {
        if (res && res.errcode === 0) {
          this.$Notice.success({ desc: '操作成功' })
          this.onSuccess()
        }
        this.modifyModal = false
      })
    },
    funEdit (data) {
      edit(data).then(res => {
        if (res && res.errcode === 0) {
          this.$Notice.success({ desc: '操作成功' })
          this.onSuccess()
        }
        this.modifyModal = false
      })
    }
  }
}
</script>
