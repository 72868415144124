const filedMap = new Map()
filedMap.set('contractName', 'contract')
filedMap.set('mark', 'contract')
filedMap.set('customerInfoBean', 'payer')
filedMap.set('contractAttachmentFiles', 'annex')
filedMap.set('amount', 'price', 'signUseFeeList', 'signServiceFeeList')
// filedMap.set('checkContractOrderBeanList', 'price')

filedMap.set('paymentBeanList', 'payments')
filedMap.set('signUseFeeList', 'signUseFee')
filedMap.set('signServiceFeeList', 'signServiceFee')

export function getChangeFields (diff) {
  var contract = []
  var payer = []
  var price = []
  var payments = []
  var annex = []
  var signUseFee = []
  var signServiceFee = []
  for (const item of diff) {
    var arrayName = filedMap.get(item.field)

    switch (arrayName) {
      case 'contract':
        contract.push(item)
        break
      case 'payer':
        payer.push(item)
        break
      case 'price':

        price.push(item)
        break
      case 'payments':
        payments.push(item)
        break
      case 'annex':
        annex.push(item)
        break
      case 'signUseFee':
        signUseFee.push(item)
        break
      case 'signServiceFee':
        signServiceFee.push(item)
        break
      default:
        break
    }
  }
  return [{
    key: 'contract',
    value: contract
  },
  {
    key: 'payer',
    value: payer
  },
  {
    key: 'price',
    value: price
  },
  {
    key: 'payments',
    value: payments
  },
  {
    key: 'annex',
    value: annex
  },
  {
    key: 'signUseFee',
    value: signUseFee
  },
  {
    key: 'signServiceFee',
    value: signServiceFee
  }
  ]
}
