<!-- 结算单创建 -->
<template>
  <Modal v-model="showFeeitemsModal" width="1000" :closable="false">

      <Table
         stripe border
        :data="list"
         :height="500"
        :columns="fineColumns"
         @on-selection-change="selectionChange"
      ></Table>
    <Row>
      <i-col span='12'>
        <span style="height: 28px;line-height: 28px;">已选中{{chkfeeitemIds.length}}条</span>
        </i-col>
        <i-col span="12">
          <div class="paging_style">
        <Page
          size="small"
          :total="total"
          :page-size="query.pageSize"
          show-total
          :current="query.pageNumber"
          @on-change="handlePageChanged"
          show-sizer
          :page-size-opts="[15, 50, 100, 200, 500]"
          @on-page-size-change="handlePageSizeChanged"
        ></Page>
      </div>
        </i-col>
    </Row>

    <div slot="footer">
        <Button type="text"  @click="showFeeitemsModal=false">取消</Button>
        <Button type="primary" @click="confirm">确认</Button>
      </div>

  </Modal>
</template>
<script>
import { toMoney } from '@/utils/wnumb_own'
import { batchaddorremovefeeitemlist } from '@/api/statement/bill'
import { getPenaltyPage } from '@/api/statement/penalty'

export default {
  data () {
    return {
      showFeeitemsModal: false,
      companyArray: [],
      total: 0,
      lastPage: true,
      billId: 0,
      query: {
        feeType: 53,
        billStatus: -1,
        status: 5,
        pageSize: 15,
        pageNumber: 1
      },
      list: [],
      fineColumns: [
        {
          type: 'selection',
          key: 'id',
          width: 60,
          align: 'center'
        },
        {
          title: '罚款金额',
          align: 'center',
          key: 'amount',
          render: (h, params) => {
            // return toMoney(params.row.amount)
            return h('span', { class: 'money' }, toMoney(params.row.amount))
          }
        },
        { title: '罚款原因', align: 'center', key: 'content' },
        { title: '罚款日期', align: 'center', key: 'penaltyDate' },
        { title: '创建时间', align: 'center', key: 'createTime' },
        { title: '创建人员', align: 'center', key: 'createUserName' }
      ],

      currentPageAllItemIds: [],
      chkfeeitemIds: [],
      mark: ''
    }
  },
  methods: {
    showModal (feeitemType, companyId, billId, publisherId) {
      this.list = []
      this.chkfeeitemIds = []
      this.billId = billId
      this.query.publisherId = publisherId

      if (feeitemType === 1) {
        this.query.feeType = 53
        this.query.agentCompanyId = companyId
      } else if (feeitemType === 2) {
        this.query.feeType = 52
        this.query.supplierCompanyId = companyId
      }
      this.showFeeitemsModal = true
      if (companyId) {
        this.initItemArray()
      }
    },
    // 加载所有可加入结算的交罚款项
    initItemArray () {
      const that = this
      getPenaltyPage(this.query).then((res) => {
        res.list.map((item) => {
          item._checked = that.chkfeeitemIds.includes(item.id)
          return item
        })
        that.currentPageAllItemIds = res.list.map((item) => {
          return item.id
        })
        that.list = res.list
        that.total = res.totalRow
        that.lastPage = res.lastPage
      })
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.initItemArray()
    },

    handlePageSizeChanged (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.initItemArray()
    },
    // 选中的结算项变更
    selectionChange (selection) {
      const that = this
      const selectionIds = selection.map((item) => {
        return item.id
      })
      // 循环当前页面所有列
      that.currentPageAllItemIds.forEach((item) => {
        // 列是否是选中状态
        if (selectionIds.includes(item)) {
          // 判断是否已在列表，不在则加入
          if (!that.chkfeeitemIds.includes(item)) {
            that.chkfeeitemIds.push(item)
          }
        } else {
          // 列未被选中
          // 判断是否已在列表，在则移除
          if (that.chkfeeitemIds.includes(item)) {
            that.chkfeeitemIds.splice(that.chkfeeitemIds.indexOf(item), 1)
          }
        }
      })
    },
    // 确认加入罚款项
    confirm () {
      const that = this
      if (that.chkfeeitemIds.length) {
        const data = {
          billId: that.billId,
          feeitemType: 3,
          feeitemListJsonStr: JSON.stringify(that.chkfeeitemIds),
          action: 1
        }
        batchaddorremovefeeitemlist(data).then((res) => {
          if (res && !res.errcode) {
            that.showFeeitemsModal = false
            if (that.$store.hasModule('settlementSupplier')) {
              that.$store.commit('set_settlementSupplier_update', new Date())
            }
            if (that.$store.hasModule('settlementChannel')) {
              that.$store.commit('set_settlementChannel_update', new Date())
            }
          }
        })
      } else {
        that.$Notice.info({ desc: '请至少选择一个罚款项！' })
      }
    }
  }
}
</script>
