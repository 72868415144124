<template>
  <div>
  <Tabs @on-click="handleTabClick" v-if="loadType">
    <TabPane label="结算单详情">
      <div class="text-center workplatform-title">媒体广告发布结算单</div>
      <p>
        <span  class="title">结算单编码 </span> {{ statement.code }}
         <Tag
          class="m-l-5"
          color="blue"
          >{{statement.contractCategoryName}}</Tag
        >
         <Tag
          class="m-l-5"
          color="green"
          v-if="statement.salePolicy===2"
          >{{statement.salePolicyName}}</Tag
        >
      </p>
        <!-- <p><span  class="title">发布类型 </span> {{statement.contractCategoryName}}</p>
        <p v-if="statement.salePolicy===2"><span  class="title">发布标记 </span> {{statement.salePolicyName}}</p> -->
      <p><span class="title">甲方 </span> {{ firstParty }}</p>
      <p><span  class="title">乙方 </span> {{ secondParty }}</p>
      <p>
        按照双方签订的年度广告发布《合作协议》（以下简称“年度合同”），以下列表为本次乙方代理
        <span class="text-orange">{{ order.brandName }}</span>
        广告发布信息，发布日期为：
        <span class="text-orange">{{ order.schedule }}</span>
      </p>
      <p class="p-b-5 p-t-20 workplatform-title">媒体费用概览</p>
        <i-table
          stripe
          :data="products"
          :columns="productColumns"
          :summary-method="handleSummary"
          show-summary
        ></i-table>
        <Row class="p-t-10 m-b-10 workplatform-title">
          <span>服务费用概览</span>
        </Row>

        <i-table
          stripe
          :data="serviceItems"
          :columns="serviceColumns"
          :summary-method="handleSummary"
          show-summary
        ></i-table>
        <Row class="m-b-10 m-t-10">
          <i-col span="12"> </i-col>
          <i-col span="12" class="text-right text-16 p-r-20">
            结算单总金额：<b class="text-orange">{{
              formatMoney(statement.originalTotalAmount)
            }}</b>
          </i-col>
        </Row>
      <Card :bordered="false" dis-hover v-if="paymentMethod===1">
        <p slot="title">付款信息</p>
        <p>
          经过核算，乙方的广告投放共产生了费用：
          <span class="money"
            >{{ formatMoney(noDiscountTotal) }}, （人民币
            {{ formateUpperMoney(noDiscountTotal) }} ） </span
          >
          ,乙方选择刊前付款
          <span class="discount">{{ (proportion * 100).toFixed(2) }}%</span>
          ,所以乙方享有媒介使用费折扣
          <span class="discount">{{ (discount * 10).toFixed(2) }}折</span
          >，故共需付费
          <span class="money">
            {{ formatMoney(totalMoney) }}, （人民币
            {{ formateUpperMoney(totalMoney) }} ）
          </span>
        </p>
        <p v-show="payments.length > 0">
          乙方应按下列期限及方式将合同款如期支付给甲方：
        </p>
        <ol>
          <li v-for="(item, i) in payments" :key="12545 + i">
            {{ item.date }} 前支付
            <span class="money"
              >{{ formatMoney(item.amount) }} 元（大写：人民币
              {{ formateUpperMoney(item.amount) }}）</span
            >
          </li>
        </ol>
      </Card>
      <Card :bordered="false" dis-hover v-if="paymentMethod===2">
        <p slot="title">付款信息</p>
        <p>
          经过核算，乙方的广告投放共产生了费用：
          <span class="money"
            >{{ formatMoney(noDiscountTotal) }}, （人民币
            {{ formateUpperMoney(noDiscountTotal) }} ） </span
          >
         ,乙方选择储值抵扣,所以乙方无需支付媒介使用费，故共需付服务费
          <span class="money">
            {{ formatMoney(totalMoney) }}, （人民币
            {{ formateUpperMoney(totalMoney) }} ）
          </span>
        </p>
        <p v-show="payments.length > 0">
          乙方应按下列期限及方式将合同款如期支付给甲方：
        </p>
        <ol>
          <li v-for="(item, i) in payments" :key="12545 + i">
            {{ item.date }} 前支付
            <span class="money"
              >{{ formatMoney(item.amount) }} 元（大写：人民币
              {{ formateUpperMoney(item.amount) }}）</span
            >
          </li>
        </ol>
      </Card>
    </TabPane>
    <!-- <TabPane label="服务费明细"  v-if="isApproval">
      <service-fees
        :serviceFees="statement.realServicefeeList || []"
        :statementBean="statement"
      ></service-fees>
    </TabPane> -->
<TabPane label="调整项" name="adjustment" v-if="!isApproval">
      <AdjustmentItem  :id="id" :orderId="orderId" :versionId="versionId" v-if="versionId"></AdjustmentItem>
    </TabPane>
    <TabPane label="付款详情" name="payment"  v-if="!isApproval">
      <payments :id="id" v-if="showPayments" :serviceType="2"></payments>
    </TabPane>
    <TabPane label="开票详情" name="invoice"  v-if="!isApproval">
      <invoice :id="id" v-if="showInvoice"></invoice>
    </TabPane>
    <TabPane label="变更记录" name="record" v-if="!isApproval">
      <change-record
        :id="id"
        :status="'结算单'"
        v-if="showRecord"
      ></change-record>
    </TabPane>
    <TabPane label="作废详情" v-if="!isApproval">
      <invalid-detail  :relateId="id" :processType="7"></invalid-detail>
    </TabPane>
    <TabPane v-if="versionId !== 0" label="附件">
      <!-- <files type="STATEMENT_ATTACHMENT_FILE" :relateId="versionId"></files> -->
       <!-- <files
            :fileList="files"
            :downloadUrl="'/ooh-scp/v1/contractfile/downloadfile'"
          ></files> -->
          <div v-if="versionId">
          <div class="  workplatform-title">销售个人上传</div>
          <files
            :fileList="files"
            :downloadUrl="'/ooh-scp/v1/contractfile/downloadfile'"
          ></files>
          <div class="m-t-10 workplatform-title">管理员上传</div>
          <files
            :fileList="signfiles"
            :downloadUrl="'/ooh-scp/v1/contractfile/downloadfile'"
          ></files>
        </div>

    </TabPane>
  </Tabs>

  <statement-detail
    v-else-if="statement.code && !loadType"
    :statementBean="statement"
  />
</div>
</template>

<script>
import InvalidDetail from '@/components/approval/InvalidDetail'
import files from '@/components/common/FilesV2'
import Payments from '../contract/contractDetail/detail/Payments'
import Invoice from '../contract/contractDetail/detail/Invoice'

// import serviceFees from './ServiceFees'
import statementDetail from './StatementDetail'
import AdjustmentItem from './AdjustmentItem'
import ChangeRecord from '../contract/contractDetail/detail/ChangeRecord'
// import NewPro from '@/components/statement/NewPro'

import { toMoney } from '@/utils/wnumb_own'
import { parseUpperMoney } from '@/utils'
import { GetCurrentSchedule } from '@/utils/dateFormat'

import { getOrderScheduleCategoryList } from '@/api/scp/statementorder'
import { listSignServiceItem } from '@/api/scp/contractorder'
import {
  getStatementDetailByVersionId,
  getStatementInvalidmark
} from '@/api/scp/statement'
import { getOrder } from '@/api/order/order'
import { getFileList } from '@/api/scp/contractfile'

export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    versionId: {
      type: Number,
      required: true
    },
    loadType: {
      type: [Boolean],
      default: true
    },
    isApproval: {
      type: [Boolean],
      default: false
    }
  },
  components: {
    statementDetail,
    InvalidDetail,
    Invoice,
    Payments,
    // serviceFees,
    // NewPro,
    files,
    AdjustmentItem,
    ChangeRecord
  },
  data () {
    return {
      firstParty: '',
      secondParty: '',
      order: {},
      payments: [],
      totalMoney: 0,
      statementId: this.$route.params.id,
      orderId: 0,
      status: 0, // 结算单状态

      paymentMethod: 1,

      invalidmark: {},
      proportion: 0,
      discount: 0,
      statement: {},
      showHistory: false,
      showInvoice: false,
      showRecord: false,
      showPayments: false,
      EstimateService: [],
      mainData: [],
      normal: [],
      normal1: [],
      special1: [],
      special: [],

      files: [],
      signfiles: [],

      products: [],
      productColumns: [
        { title: '资源名称', key: 'productName' },
        {
          title: '投放档期',
          render: (h, data) => {
            return h(
              'span',
              GetCurrentSchedule(data.row.startDate, data.row.endDate)
            )
          }
        },
        {
          title: '采购数量',
          align: 'center',
          render: (h, data) => {
            return h('span', data.row.signNormalQuantity)
          }
        },

        {
          title: '订单价',
          key: 'normalUsePrice',
          render: (h, data) => {
            return h('div', [
              h('span', { class: 'money' }, toMoney(data.row.normalUsePrice)),
              h('span', '(' + data.row.usePriceDiscount + ')')
            ])
          }
        },

        {
          title: '结算价',
          key: 'originalSignNormalPrice',
          render: (h, data) => {
            return h('div', [
              h(
                'span',
                { attrs: { class: 'money' } },
                toMoney(data.row.originalSignNormalPrice)
              ),
              h(
                'span',
                { attrs: { class: 'discount' } },
                this.computedSale(data.row.normalUsePrice, data.row.originalSignNormalPrice)
              )
            ])
          }
        }
      ],

      serviceItems: [],
      serviceColumns: [
        { title: '服务费名称', key: 'serviceItemName' },
        { title: '服务费类型', key: 'typeName' },
        {
          title: '资源数量',
          key: 'serviceQuantity',
          render: (h, data) => {
            return h('div', [
              h(
                'span',
                {},
                data.row.serviceQuantity + data.row.serviceQuantityName
              )
            ])
          }
        },
        {
          title: '刊例单价',
          key: 'price',
          render: (h, data) => {
            return h('div', [
              h(
                'span',
                {},
                this.formatMoney(data.row.unitPrice) +
                  '/' +
                  data.row.serviceQuantityName
              )
            ])
          }
        },
        {
          title: '签约数量',
          key: 'signServiceQuantity',
          render: (h, data) => {
            return h('div', [
              h(
                'span',
                {},
                data.row.signServiceQuantity + data.row.serviceQuantityName
              )
            ])
          }
        },
        {
          title: '签约单价',
          key: 'signUnitPrice',
          render: (h, data) => {
            return h('div', [
              h(
                'span',
                { class: 'money' },
                this.formatMoney(data.row.signUnitPrice) +
                  '/' +
                  data.row.serviceQuantityName
              )
            ])
          }
        },
        {
          title: '签约总价',
          key: 'signServiceAmount',
          render: (h, data) => {
            return h('div', [
              h(
                'span',
                { class: 'money' },
                this.formatMoney(data.row.signServiceAmount)
              ),
              h(
                'span',
                { class: 'discount' },
                this.computedSale(
                  data.row.totalServiceFee,
                  data.row.signServiceAmount
                )
              )
            ])
          }
        }
      ]
    }
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },
    formateUpperMoney (money) {
      return parseUpperMoney(money)
    },
    computedSale (usePrice, sellPrice) {
      if (usePrice > 0) {
        const discount = (sellPrice / usePrice) * 10
        const showDisCount = discount.toFixed(2)
        return '(' + showDisCount + '折)'
      } else {
        return ''
      }
      // return Number((sellPrice * 10 / usePrice).toFixed(2))
    },

    handleSummary ({ columns, data }) {
      const needSummarykey = [
        'normalUsePrice',
        'originalSignNormalPrice',
        'signServiceAmount'
      ]
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          }
          return
        }
        if (needSummarykey.includes(key)) {
          const values = data.map((item) => Number(item[key]))
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[key] = {
              key,
              value: toMoney(v)
            }
          } else {
            sums[key] = {
              key,
              value: '-'
            }
          }
        } else {
          sums[key] = {
            key,
            value: '-'
          }
        }
      })

      return sums
    },
    getDetail () {
      getStatementDetailByVersionId({ versionId: this.versionId || this.id }).then((res) => {
        this.orderId = res.orderIdList[0]
        this.payments = res.paymentBeanList
        this.firstParty = res.firstParty
        this.secondParty = res.secondParty
        this.payments.forEach((x) => {
          this.totalMoney += x.amount
        })
        this.paymentMethod = res.paymentMethod
        this.proportion = res.payRatioBeforePublish
        this.discount = res.payDiscountForUseAmount
        this.statement = res
        this.status = res.status
        this.$emit('on-inited', res)
        if (res.status === -1) {
          getStatementInvalidmark({ statementId: this.versionId }).then((invalid) => {
            this.invalidmark = invalid
          })
        }
        getOrder({ orderId: this.orderId }).then((response) => {
          this.order = response
          this.order.schedule = GetCurrentSchedule(
            response.startDate,
            response.endDate
          )
        })
        getOrderScheduleCategoryList({
          statementId: this.id,
          version: this.versionId
        }).then((response) => {
          this.mainData = response[0].signPriceDetail
          this.normal1 = []
          this.special1 = []
          this.products = response[0].signPriceDetail
          // response[0].signPriceDetail.forEach((items) => {
          //   this.formatData(items)
          // })
        })
        listSignServiceItem({
          versionId: this.versionId
        }).then((res) => {
          this.serviceItems = res
        })

        this.getFiles()
      })
    },

    getFiles () {
      if (this.versionId) {
        getFileList({ relateId: this.versionId, type: 'STATEMENT_ATTACHMENT_FILE' }).then(res => {
          this.files = res.map(x => {
            return Object.assign({}, x, { url: process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/contractfile/downloadfile?fileId=' + x.id })
          })
        })
        getFileList({ relateId: this.versionId, type: 'STATEMENT_MANAGE_ATTACHMENT_FILE' }).then(res => {
          this.signfiles = res.map(x => {
            return Object.assign({}, x, { url: process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/contractfile/downloadfile?fileId=' + x.id })
          })
        })
      }
    },
    computeSchedule (products) {
      if (products.length > 0) {
        const product = products[0]
        return GetCurrentSchedule(product.startDate, product.endDate)
      }
    },
    handleTabClick (name) {
      if (name === 'invoice') {
        this.showInvoice = true
      }
      if (name === 'payment') {
        this.showPayments = true
      }
      if (name === 'record') {
        this.showRecord = true
      }
    }
  },
  created () {
    this.getDetail()
  },
  watch: {
    id () {
      this.getDetail()
    }
  },
  computed: {
    noDiscountTotal: function () {
      let total = 0
      this.mainData.forEach((product) => {
        total += product.originalSignNormalPrice
      })
      this.serviceItems.forEach((item) => {
        total += item.signServiceAmount
      })

      return total
    }
  }
}
</script>
