import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取项目下的规则列表
 * @param {object} data
 * @returns
 */
export function getProjectRules (data) {
  return request.post('/ooh-os/v1/projectRule/getProjectRules', qs.stringify(data))
}
