<template>
  <div>
    <Modal v-model="setProductModal" width="70%">
      <p slot="header" class="text-center">分配产品</p>
      <div>
        <Row :gutter="8" class="m-b-5">
          <i-col span="3">
            <Select v-model="type" :clearable="true" size="small" placeholder="选择媒体类型" @on-change="handleChangeType">
              <Option v-for="item in typeArray" :key="'type_' + item.value" :value="item.value">{{ item.name }}</Option>
            </Select>
          </i-col>
          <i-col span="3">
            <Select v-model="assetIds" multiple size="small" placeholder="选择资产">
              <Option v-for="item in assetArray" :key="'assetId_' + item.id" :value="item.id">{{ item.name }}</Option>
            </Select>
          </i-col>
          <i-col span="3" v-show="type === 1">
            <Select v-model="query.categoryId" :clearable="true" size="small" placeholder="选择类目"
              @on-change="handleChangeCategory">
              <Option v-for="item in categoryArray" :key="'category_' + item.id" :value="item.id">{{ item.name }}
              </Option>
            </Select>
          </i-col>
          <i-col span="3">
            <Select v-model="query.productId" :clearable="true" size="small" placeholder="选择产品">
              <Option v-for="item in productArray" :key="'productId_' + item.id" :value="item.id">{{ item.name }}
              </Option>
            </Select>
          </i-col>
          <i-col span="2">
            <Select v-model="query.enabled" :clearable="true" size="small" placeholder="是否可用">
              <Option v-for="item in enabledArray" :key="'enabled_' + item.value" :value="item.value">{{ item.name }}
              </Option>
            </Select>
          </i-col>
          <i-col span="4">
            <i-input v-model="query.keyword" size="small" placeholder="输入关键字"></i-input>
          </i-col>
          <i-col span="5">
            <i-button size="small" class="m-r-5" type="primary" icon="ios-search" @click="handleSearch">搜索</i-button>
          </i-col>
        </Row>
        <div class="m-b-5">
          <Button type="success" size="small" :disabled="addDisabled" @click="onAddSku">批量添加</Button>
        </div>
        <Table size="small" stripe :data="list" :columns="tableColumns" :loading="tableLoading"
          @on-selection-change="onSelection"></Table>
        <div class="paging_style">
          <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator
            :current="query.pageNumber" @on-change="handlePageChange"></Page>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { projectProductMxins } from '../../mixins/product'
import { getPublisherProductAssetList } from '@/api/product/asset'
import { getCommonPage, getFixPage, getCustomPage, setCompanySku } from '@/api/product/productskuedit'
export default {
  props: {
    projectId: {
      type: Number,
      required: true
    },
    companyId: {
      type: Number,
      required: true
    },
    onSuccess: [Function]

  },
  mixins: [projectProductMxins],
  data () {
    return {
      setProductModal: false,
      type: 1,
      assetIds: [],
      query: {
        assetIds: '',
        categoryId: null,
        productId: null,
        enabled: null,
        keyword: '',
        pageSize: 15,
        pageNumber: 1,
        projectId: null
      },
      assetArray: [],
      selectedIds: []
    }
  },
  computed: {
    addDisabled () {
      return this.selectedIds.length === 0
    }
  },
  methods: {
    showModal () {
      this.query.projectId = this.projectId
      this.initAssetArray()
      // this.initData()
      this.setProductModal = true
    },
    initData () {
      switch (this.type) {
        case 1:
          this.getTableColumns(this.type)
          // 常规媒体
          this.getRoutineMediumData()
          break
        case 4:
          this.getTableColumns(this.type)
          // 主题媒体
          this.getFixMediumData()
          break
        case 7:
          this.getTableColumns(this.type)
          // 套装媒体
          this.getCustomMediumData()
          break
      }
    },
    // 初始化资产选项，并默认选中第一条
    initAssetArray () {
      getPublisherProductAssetList().then((res) => {
        if (res && !res.errcode) {
          this.assetArray = res
        }
      }).then(() => {
        this.initData()
      })
    },
    handleChangeType () {
      this.getTableColumns(this.query.type)
      this.query.categoryId = null
      this.query.pageNumber = 1
      if (this.type === 1) {
        this.productArray = []
      } else {
        this.getProductData({ categoryId: this.query.type })
      }
      this.initData()
    },
    /**
    * 分页获取常规媒体数据
    */
    getRoutineMediumData () {
      const assetIds = this.assetIds.length === 0 ? this.assetArray.map(x => x.id) : this.assetIds
      this.query.assetIds = JSON.stringify(assetIds)
      this.tableLoading = true

      getCommonPage(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
      }).finally(() => { this.tableLoading = false })
    },
    /**
     * 分页获取主题媒体数据
     */
    getFixMediumData () {
      const assetIds = this.assetIds.length === 0 ? this.assetArray.map(x => x.id) : this.assetIds
      this.query.assetIds = JSON.stringify(assetIds)
      this.tableLoading = true
      getFixPage(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
      }).finally(() => { this.tableLoading = false })
    },
    /**
     * 分页获取套装媒体数据
     */
    getCustomMediumData () {
      const assetIds = this.assetIds.length === 0 ? this.assetArray.map(x => x.id) : this.assetIds
      this.query.assetIds = JSON.stringify(assetIds)
      this.tableLoading = true
      getCustomPage(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
      }).finally(() => { this.tableLoading = false })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.initData()
    },
    handlePageChange (curPage) {
      this.query.pageNumber = curPage
      this.initData()
    },
    onSelection (selections) {
      this.selectedIds = selections.map(x => x.id)
    },
    onAddSku () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要添加所选项？',
        onOk: async () => {
          const postData = {
            companyId: this.companyId,
            skuIds: JSON.stringify(this.selectedIds)
          }
          const result = await setCompanySku(postData)
          if (result && result.success) {
            this.selectedIds = []
            this.$Notice.success({ desc: '操作成功' })
            this.onSuccess()
            this.setProductModal = false
          }
        }
      })
    }
  }
}
</script>
