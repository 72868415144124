<template>
    <div>
        <Row>
            <i-col span="23">
                <label>站点周边关键字：</label>
                <i-input type="text" size="small" v-model="searchModel.aroundkeyword" clearable placeholder="多关键字用逗号隔开,如：超市,商场,电影院" ></i-input>
            </i-col>
        </Row>
        <Row class="p-b-10">
            <i-col span="15">
                <Slider v-model="searchModel.arounddistance" :max="1000" :step="50" @on-change="AdaptationRange"></Slider>
            </i-col>
            <i-col span="9" class="p-t-10 p-l-5">适配范围( <span class="text-orange">{{searchModel.arounddistance}}</span> 米)</i-col>
        </Row>
        <Row class="p-b-10">
            <label>检索线路 (不选默认为全部线路):</label>
            <i-col span="24">
                <div v-for="(line,index) in lines"  :key="index" @click="changeCheckLines(line,index)" class="m-b-2"
                    :class="line.checked?'workplatform-radio-button-active':'workplatform-radio-button'">{{line.label}}</div>
            </i-col>
        </Row>
        <Row class="p-b-10">
            <label>可用产品 (不选默认为全部产品):</label>
            <i-col span="24">
                <div v-for="product in productList" :key="product.id" class="p-l-10 p-1-10 m-b-2" @click="changeCheckedProduct(product.id)"
                    :class="checkProducts.includes(product.id)?'workplatform-radio-button-active':'workplatform-radio-button'">{{product.name}}</div>
            </i-col>
            <p v-if="bufferDay>0" class="text-orange">PS: 当前产品存在施工缓冲期 {{bufferDay}} 天</p>
        </Row>
        <!-- <template >
            <Row class="p-b-10">
              <label class="p-b-5">细分资源类型</label>
              <i-col span="24">
                  <div v-for="resourcetype in resourcetypeList" :key="resourcetype.id" class="p-l-10 p-1-10 m-b-2" @click="changeCheckedResourceType(resourcetype.id)"
                      :class="currentResourceType.includes(resourcetype.id)?'workplatform-radio-button-active':'workplatform-radio-button'">{{resourcetype.name}}</div>
              </i-col>
            </Row>
        </template> -->
        <Row class="p-b-10">
            <label>检索档期: <span v-if="days">（查询时长：<span class="text-orange">{{days}}</span>）</span></label>
            <i-col span="24">
                <DatePicker size="small" transfer class="text-black" v-model="searchModel.startDate" format="yyyy-MM-dd" type="date" placeholder="开始日期" :options="startDateOptions" @on-change='startDateChange' :clearable="false" style="width: 150px"></DatePicker>
                至
                <DatePicker size="small" transfer class="text-black" v-model="searchModel.endDate" format="yyyy-MM-dd" type="date" placeholder="结束日期" :options="endDateOptions" @on-change='endDateChange' :clearable="false" style="width: 150px"></DatePicker>
            </i-col>
        </Row>
        <Row class="p-b-5">
          <i-col span="8">显示无余量站点：</i-col>
          <i-col span="6">
            <i-switch v-model="checkShowAllPoint" size="large"  true-color="#13ce66" @on-change="handelSearch">
                <span slot="open">开启</span>
                <span slot="close">关闭</span>
            </i-switch>
          </i-col>
          <i-col span="10" class="text-right">
            <Button size="small" type="primary" icon="ios-search" :loading="loadSearch" @click="handelSearch">
                <span v-if="!loadSearch">搜索</span>
                <span v-else>加载中...</span>
            </Button>
          </i-col>
        </Row>

        <flow ref="flowComponent"/>
    </div>
</template>

<script>
import flow from './Flow'

import { getAllProduct } from '@/api/product/product'
import { assetList } from '@/api/product/category'
import { getStationResourceQuantity } from '@/api/product/productresource'
import { getProductBufferDay } from '@/api/product/rule'

import { GetPublishDay, addMonth, ParseDate } from '@/utils/dateFormat'

export default {
  components: { flow },
  data () {
    return ({
      loadSearch: false,
      lines: [], // 所有可用线路
      productList: [],
      checkProducts: [], // 选中的产品集合

      // resourcetypeList: [], // 当前可用的资源类型清单
      // currentResourceType: [],

      searchModel: {
        assetIds: [],
        productIds: null,
        // resourcetypeIds: '',
        startDate: new Date().getFullYear() + '-' + ((new Date().getMonth() + 1).toString().padStart(2, '0')) + '-' + new Date().getDate().toString().padStart(2, '0'),
        endDate: ParseDate(addMonth(new Date(), 1)),
        aroundkeyword: '',
        arounddistance: 200
      },
      days: undefined, // 查询时长
      bufferDay: 0,
      startDateOptions: {},
      endDateOptions: {},
      checkShowAllPoint: false
    })
  },
  created () {
    // 开始加载当前城市下的所有资产线路
    this.getMediumAssetList()
  },
  methods: {
    getMediumAssetList () {
      const assetquery = { categoryId: this.categoryId, publisherId: this.$store.getters.token.userInfo.publisherId }
      assetList(assetquery).then(res => {
        let flag = true
        this.lines = res.map(x => {
          if (flag) {
            flag = false
            return { value: x.id, label: x.name, rimAssetIds: x.rimAssetIds, checked: true }
          } else {
            return { value: x.id, label: x.name, rimAssetIds: x.rimAssetIds, checked: false }
          }
        })
      }).then(() => {
        // 开始加载所有的产品类型
        this.getProducts()
      })
    },
    getProducts () { // 获取所有可用的产品列表： 12封灯箱、18封灯箱等
      const data = {
        assetIds: JSON.stringify(this.chooseAssetIds),
        categoryId: this.categoryId
      }

      getAllProduct(data).then(res => {
        if (res !== null && res.length > 0) {
          this.productList = res
          // 获取上一次的默认选择
          if (this.checkProducts.length > 0) {
            const historyChecked = []
            this.productList.forEach(element => {
              if (this.checkProducts.includes(element.id)) {
                historyChecked.push(element.id)
              }
            })
            this.checkProducts = historyChecked
          } else {
            // 默认选择第一条
            this.checkProducts = [this.productList[0].id]
          }
          // this.loadProductBufferDay()
          this.handelSearch()
          // this.getResourceTypeList()
        } else {
          this.productList = []
          this.searchModel.productIds = null
        }
      })
    },
    changeCheckLines (line, i) {
      line.checked = !line.checked
      this.lines.splice(i, 1, line)
      this.getProducts()
    },
    changeCheckedProduct (productId) {
      if (this.checkProducts.includes(productId)) {
        // if (this.checkProducts.length === 1) {
        //   this.$Notice.warning({ desc: '至少需要选择一个产品' })
        //   return
        // }
        const index = this.checkProducts.indexOf(productId)
        this.checkProducts.splice(index, 1)
      } else {
        this.checkProducts.push(productId)
      }

      // this.loadProductBufferDay()
      // this.getResourceTypeList()
      this.handelSearch()
    },
    // getResourceTypeList () {
    //   // 格式化细分资源类型
    //   this.resourcetypeList = []
    //   this.productList.forEach(element => {
    //     if (this.checkProducts.includes(element.id)) {
    //       this.resourcetypeList = this.resourcetypeList.concat(element.resourcetypeList)
    //     }
    //   })
    //   this.currentResourceType = this.resourcetypeList.map(x => x.id)
    //   this.handelSearch()
    // },
    // changeCheckedResourceType (resourcetypeId) {
    //   // 格式化细分资源类型
    //   if (this.currentResourceType.includes(resourcetypeId)) {
    //     const index = this.currentResourceType.indexOf(resourcetypeId)
    //     this.currentResourceType.splice(index, 1)
    //   } else {
    //     this.currentResourceType.push(resourcetypeId)
    //   }

    //   this.handelSearch()
    // },
    loadProductBufferDay () { // 获取施工缓冲期
      const postData = {
        productId: this.searchModel.productId
      }
      getProductBufferDay(postData).then(res => {
        this.bufferDay = res
      })
    },
    handelSearch () {
      this.loadSearch = true

      // 格式化查询日期
      this.searchModel.schedules = JSON.stringify([{ startDate: ParseDate(this.searchModel.startDate), endDate: ParseDate(this.searchModel.endDate) }])
      this.searchModel.assetIds = JSON.stringify(this.chooseAssetIds)
      this.searchModel.productIds = JSON.stringify(this.chooseProducts)
      // this.searchModel.resourcetypeIds = JSON.stringify(this.currentResourceType)

      // 获取地图显示站点数量
      getStationResourceQuantity(this.searchModel).then(res => {
        this.loadSearch = false
        // 可能存在虚拟现实，比如北京复八线，其实是地铁1号线。  也可能存在小微，包含多条线路包含在一个虚拟包
        const showAssetIds = []
        let flagAssetIds = []
        this.lines.forEach(element => {
          if (this.chooseAssetIds.includes(element.value)) {
            flagAssetIds = element.rimAssetIds.split(',')
            flagAssetIds.forEach(rimAssetId => {
              // 可能存在重复数据，比如北京地铁一号线、北京地铁复八线。都是指向地铁一号线
              if (!showAssetIds.includes(parseInt(rimAssetId))) {
                showAssetIds.push(parseInt(rimAssetId))
              }
            })
          }
        })

        this.$refs.flowComponent.loadData(ParseDate(this.searchModel.startDate), ParseDate(this.searchModel.endDate), res, showAssetIds)

        this.$store.commit('set_stock_show_assets', showAssetIds)
        this.$store.commit('set_stock_map_type', 'resource')
        this.$store.commit('set_stock_search_condition', this.searchModel)
        if (this.checkShowAllPoint) { // 显示无余量点位， 需要merge其他站点
          this.$store.commit('set_stock_stations', this.mergeStation(res))
        } else {
          this.$store.commit('set_stock_stations', res)
        }
        this.$store.commit('set_stock_update', new Date())
      }).catch(() => {
        this.loadSearch = false
      })
    },
    startDateChange (e) {
      this.days = GetPublishDay(this.searchModel.startDate, this.searchModel.endDate)
      this.endDateOptions = {
        disabledDate: date => {
          const startTime = this.searchModel.startDate ? new Date(this.searchModel.startDate).valueOf() : 0
          return date && (date.valueOf() < startTime)
        }
      }
    },
    endDateChange (e) {
      this.days = GetPublishDay(this.searchModel.startDate, this.searchModel.endDate)
      const endTime = this.searchModel.endDate ? new Date(this.searchModel.endDate).valueOf() - 1 * 24 * 60 * 60 * 1000 : 0
      this.startDateOptions = {
        disabledDate (date) {
          return date && (date.valueOf() > endTime)
        }
      }
    },
    AdaptationRange (val) { // 查询距离改变
      if (this.searchModel.aroundkeyword !== '') {
        this.handelSearch()
      } else {
        this.$store.commit('set_stock_search_range', val)
      }
    },
    mergeStation (searchStations) { // 将无库存余量的站点，合并到现有站点中，并用绿色标注
      const meargeStationList = []
      const allowStations = this.allStationList.filter(x => this.chooseAssetIds.includes(x.assetId))

      allowStations.forEach(element => {
        if (!searchStations.find(x => x.stationId === element.id)) {
          meargeStationList.push({
            inOrder: 0,
            latitude: '',
            longitude: '',
            quantity: 0,
            nothingQuantity: true,
            regionName: element.regionName,
            stationId: element.id,
            stationName: element.name
          })
        } else {
          meargeStationList.push(Object.assign({}, searchStations.find(x => x.stationId === element.id)))
        }
      })
      return meargeStationList
    }
  },
  computed: {
    chooseAssetIds () {
      const checkLines = this.lines.filter(x => x.checked).map(x => x.value)
      if (checkLines.length > 0) {
        return checkLines
      } else {
        return this.lines.map(x => x.value)
      }
    },
    chooseProducts () {
      if (this.checkProducts.length > 0) {
        return this.checkProducts
      } else {
        return this.productList.map(x => x.id)
      }
    },
    allStationList () {
      return this.$store.state.stock.allStationList
    },
    categoryId () {
      return this.$store.state.stock.categoryId
    }
  },
  watch: {
    'searchModel.aroundkeyword' (val) {
      if (val !== '') {
        this.handelSearch()
      }
    },
    categoryId (val) {
      this.searchModel.aroundkeyword = ''
      this.searchModel.arounddistance = 200
      this.getMediumAssetList()
    }
  }
}
</script>
