const settlementSupplier = {
  state: {
    pageType: 'List',
    moduleType: 'transaction',
    beginUpdate: new Date(),

    transactionSettlementQuery: {
      agentCompanyId: 0, // 代理商公司id
      billStatus: -1, // 结算单状态
      startDate: '',
      endDate: ''
    }, // 交易结算查询条件
    maintenanceSettlementQuery: {
      billStatus: -1, // 结算单状态
      startDate: '',
      endDate: '',
      supplierCompanyId: 0,
      purchaserCompanyId: 0
    }, // 运维结算查询条件
    feeitemIds: [], // 计费项主键
    billId: 0, // 结算单主键
    performanceQuery: {
      agentCompanyId: 0,
      keyword: '',
      minDate: '',
      maxDate: ''
    }
  },
  mutations: {
    set_settlementSupplier_pageType (state, data) {
      state.pageType = data
    },
    set_settlementSupplier_moduleType (state, data) {
      state.moduleType = data
    },
    set_settlementSupplier_update (state, data) {
      state.beginUpdate = data
    },
    set_settlementSupplier_transactionSettlementQuery (state, data) {
      state.transactionSettlementQuery = data
    },
    set_settlementSupplier_maintenanceSettlementQuery (state, data) {
      state.maintenanceSettlementQuery = data
    },
    set_settlementSupplier_feeitemIds (state, data) {
      state.feeitemIds = data
    },
    set_settlementSupplier_billId (state, data) {
      state.billId = data
    },

    set_performanceQuery (state, data) {
      state.performanceQuery = data
    }
  },
  getters: {

  },
  actions: {

  }
}

export default settlementSupplier
