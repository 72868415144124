<template>
  <div>
    <Modal v-model="showModal" :width="1200" :mask-closable="false" @on-visible-change="modalShowChange" >
      <p slot="header" class="workplatform-title" >
          <span>签约价填写</span>
      </p>
      <div class="workplatform-title">订单基础信息</div>
      <Row>
        <i-col span="12"><span  class="title">发布品牌 </span>{{orderBasic.brandName}}</i-col>
        <i-col span="12"><span  class="title">订单备注 </span>{{orderBasic.remark}}</i-col>
      </Row>
      <Row class="p-b-5">
        <i-col span="12"><span  class="title">发布日期 </span><Tag type="border" color="black">{{orderBasic.startDate}} 至 {{orderBasic.endDate}} </Tag></i-col>
        <i-col span="12"><span class="title">创建时间 </span>{{orderBasic.createTime}}</i-col>
      </Row>
      <div class="workplatform-title">增值服务</div>
      <Row class="p-b-5">
        <i-col span="8">
            <span class="title">定位服务 </span>
            <Icon v-if="orderBasic.fixProtect" color="lightgreen" size="20" type="md-checkbox-outline" />
            <span v-else>无</span>
        </i-col>
        <i-col span="8">
            <span class="title">不确认方案 </span>
            <Icon v-if="!orderBasic.fixed" color="lightgreen" size="20" type="md-checkbox-outline" />
            <span v-else>无</span>
        </i-col>
        <i-col span="8">
            <span  class="title">行业保护 </span>
            <template v-if="orderBasic.industryProtect">
                <Icon color="green" size="20" type="md-checkbox-outline" />
                {{orderBasic.industryProtectName}}
            </template>
            <span v-else>无</span>
        </i-col>
      </Row>

      <div class=" workplatform-title"  style="margin:30px 0px 10px 0px;border-left:8px solid #1677FF;padding-left:5px;">发布签约价</div>
      <Row class="text-bold">
        <i-col span="4" >档期</i-col>
          <i-col span="4" >产品名称</i-col>
          <i-col span="4">采购数量</i-col>
          <i-col span="4">刊例总价(优惠比例)</i-col>
          <!-- <i-col span="3">发布费折扣</i-col> -->
          <i-col span="4">签约价</i-col>
          <i-col span="4">签约折扣</i-col>
          <!-- <i-col span="2">上下刊费</i-col> -->
          <!-- <i-col span="3">定位保护费</i-col> -->
      </Row>
      <publicPriceSetting v-for="item in category" :key="item.categoryId" :schedules="item.schedules" :hasLimit="hasLimit" :fixProtect="orderBasic.fixProtect"
        @on-settled="handlePriceSettled" ></publicPriceSetting>

        <div class=" workplatform-title"  style="margin:30px 0px 10px 0px;border-left:8px solid #FF7200;padding-left:5px;">服务签约价</div>
      <Row class="text-bold">
        <i-col span="4" >服务项名称</i-col>
          <i-col span="4" >类型</i-col>
          <i-col span="4">订单内数量</i-col>
          <i-col span="4">采购数量</i-col>
          <!-- <i-col span="3">发布费折扣</i-col> -->
          <i-col span="4">服务刊例价</i-col>
          <i-col span="4">签约单价</i-col>
          <!-- <i-col span="2">上下刊费</i-col> -->
          <!-- <i-col span="3">定位保护费</i-col> -->
      </Row>
      <servicePriceSetting :services="services"
        @on-serviceItemChange="handleServiceChange"></servicePriceSetting>

      <div slot="footer">
        <span class="text-red" v-show="!showConfirm">请配置完所有媒体金额</span>
        <i-button class="btn-black" @click="showModal=false">关闭</i-button>
        <i-button type="primary" :disabled="!showConfirm" @click="handleConfirm">确认</i-button>
      </div>

      <Spin fix size="large" v-if="spinShow">
          <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
          <div>加载中...</div>
      </Spin>
    </Modal>
  </div>

</template>

<script>
import publicPriceSetting from './publicPriceSetting'
import servicePriceSetting from './servicePriceSetting'

import { getOrder } from '@/api/order/order'
import { getOrderPrice, listSignServiceItem, saveOrderGoodUseFee, saveSignServiceFee } from '@/api/scp/contractorder'

// import { getConfirmation } from '@/api/order/confirmation'
import { buildCategories } from '@/utils/orderUtils'

export default {
  props: {
    orderId: {
      type: Number
    },
    isShow: {
      type: Boolean,
      required: true
    },
    contractId: {
      type: [Number, String],
      required: true
    },
    contractType: {
      type: [Number, String],
      required: true
    },
    versionId: {
      type: [Number, String],
      required: true
    },
    hasLimit: {
      type: Boolean
    }

  },
  data () {
    return {
      orderContractSchedulePriceBeanList: [],
      orderBasic: { },
      showModal: false,
      category: [],
      productPriceMap: new Map(),
      products: [],

      servicePriceMap: [],

      showConfirm: false,
      spinShow: false,
      disablePrice: false,

      product: {},
      services: []
    }
  },
  components: {
    publicPriceSetting, servicePriceSetting
  },
  methods: {
    getDetail () {
      // 获取当前订单的基本信息
      getOrder({ orderId: this.orderId }).then(res => {
        this.orderBasic = res
      })

      // const query = { orderId: this.orderId }
      // getConfirmation(query).then(res => {
      //   if (res.id === -1 || res.status === 0) { // 未所属确认单，价格从SCP获取
      // this.disablePrice = false
      this.initOrderPrice()
      this.initServiceList()
    },
    initOrderPrice () {
      // 获取当前订单的执行价，历史保存值SCP
      getOrderPrice({ orderId: this.orderId, contractId: this.contractId, version: this.versionId }).then(res => {
        // 获取当前订单内产品的刊例价和最低结算价
        this.products = res
        this.category = buildCategories(res)
        this.spinShow = false
      })
    },
    // 加载服务费
    initServiceList () {
      listSignServiceItem({ orderId: this.orderId, contractId: this.contractId, versionId: this.versionId }).then(res => {
        res.forEach(item => {
          item.signUnitPrice = item.signUnitPrice || item.unitPrice
        })
        this.services = res
      })
    },

    modalShowChange (val) {
      if (!val) {
        this.$emit('on-updated')
      }
    },

    handlePriceSettled (data) {
      if (data.settled) {
        data.products.forEach(product => {
          this.productPriceMap.set(product.goodId + '~' + product.startDate + '~' + product.endDate, product)
        })
      }
      this.showConfirm = data.settled && this.productPriceMap.size === this.products.length
    },
    handleServiceChange (data) {
      this.servicePriceMap = data.services
    },
    handleConfirm () {
      const productFees = []
      this.productPriceMap.forEach(item => {
        productFees.push({ goodUseFeeId: item.goodUseFeeId, signUseFee: item.signNormalPrice })
      })

      const serviceFees = this.servicePriceMap.map(item => {
        return { id: item.id, signUnitPrice: item.signUnitPrice }
      })

      saveOrderGoodUseFee({
        orderId: this.orderId,
        versionId: this.versionId,
        productFees: JSON.stringify(
          productFees
        )
      }).then((res) => {
        saveSignServiceFee({
          orderId: this.orderId,
          versionId: this.versionId,

          serviceFees: JSON.stringify(serviceFees)
        }).then((res) => {
          this.showModal = false
        })
      })
    }
  },
  watch: {
    showModal (val) {
      this.$emit('update:isShow', val)
    },
    isShow (val) {
      this.showModal = val
      if (val) {
        this.productPriceMap.clear()
        this.servicePriceMap = []
        this.spinShow = true
        this.getDetail()
      }
    }
  }
}
</script>
