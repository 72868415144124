import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 添加项目下的资源
 * @param {Object} data
 * @returns
 */
export function addProjectResource (data) {
  return request.post('/ooh-product/v1/projectResource/addProjectResource', qs.stringify(data))
}

/**
 * 删除项目下的资源
 * @param {Object} data
 * @returns
 */
export function deleteProjectResource (data) {
  return request.post('/ooh-product/v1/projectResource/deleteProjectResource', qs.stringify(data))
}

/**
 * 一键加入项目下的资源到sku
 * @param {Object} data
 * @returns
 */
export function autoAddProjectResource (data) {
  return request.post('/ooh-product/v1/projectResource/autoAddProjectResource', qs.stringify(data))
}

/**
 * 更新项目下的资源规格
 * @param {Object} data
 * @returns
 */
export function updateProjectResource (data) {
  return request.post('/ooh-product/v1/projectResource/updateProjectResource', qs.stringify(data))
}
