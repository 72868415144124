<template>
    <div>
        <Row :gutter="8" class="m-b-5">
            <i-col span="6">
                <Select size="small" v-model="advertiserStatus" placeholder="客户状态" clearable >
                    <Option v-for="(status,index) in statuslist" :key="index"  :value="status.value">{{status.name}}</Option>
                </Select>
            </i-col>
            <i-col span="6">
                <Select size="small" v-model="query.type" placeholder="客户类型" clearable>
                    <Option  value="1">广告主</Option>
                    <Option  value="2">广告公司</Option>
                    <Option  value="3">4A公司</Option>
                </Select>
            </i-col>
            <i-col span="6">
                <i-input size="small" v-model='query.keyword' placeholder="关键字：客户名称/投放品牌"></i-input>
            </i-col>
            <i-col span="6">
                <Button size="small" type="primary" icon="ios-search" @click="SearchAdvertisers">搜索</Button>
                <Button size="small" type="success" class="m-l-5" @click="customerConflictModal=true">冲突预测</Button>
            </i-col>
        </Row>

        <Table  stripe size="small" :columns="columndata" :data="tableData"></Table>
        <div class="paging_style">
          <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator :current="query.pageNumber" @on-change="changePage"></Page>
        </div>

        <Modal v-model="customerConflictModal" title="客户冲突预查询" width="800" :footer-hide="true">
            <Row class="p-b-5">
                <i-col span="16">
                <i-input placeholder="客户名称/品牌名称" size="small" v-model='customerQuery.keyword'></i-input>
                </i-col>
                <i-col span="4">
                <Button size="small" type="primary" icon="ios-search" @click="selectconflictChangeData" >搜索</Button>
                </i-col>
            </Row>

            <h2 v-if="conflictdata.length===0" class="text-green text-center m-t-50 p-b-50">
                当前未检测到可能冲突的客户信息
            </h2>
            <Card v-else>
                <span slot="title">当前可能冲突的客户</span>
                <Table   stripe :columns="conflictcolumndata" :data="conflictdata"></Table>
                <div class="paging_style">
                    <Page size="small" :total="conflictTotal" :page-size="customerQuery.pageSize" show-total show-elevator :current="customerQuery.pageNumber" @on-change="conflictChangePage"></Page>
                </div>
            </Card>
        </Modal>
    </div>
</template>

<script>
import { getAdvertiserPage } from '@/api/crm/advertiser'
import { getDictList } from '@/api/sys/dict'

import { getTimeDifference } from '@/utils/dateFormat'
import { formatCustomerStatus, formatCustomerDifference } from '@/utils/tagStatus'

export default {
  data () {
    return {
      statuslist: [],
      advertiserStatus: '',
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 15,
        publisherId: '',
        userId: this.$store.getters.token.userInfo.userId,
        companyStatus: '',
        type: null,
        keyword: ''
      },
      tableData: [],
      columndata: [
        { title: '客户名称', key: 'name' },
        { title: '报备品牌', key: 'brands' },
        { title: '客户类型', width: 90, key: 'typeName' },
        {
          title: '状态',
          width: 100,
          align: 'center',
          render: (h, params) => {
            return formatCustomerStatus(h, params.row.companyAdvertiserStatus, params.row.companyAdvertiserStatusName)
          }
        },
        {
          title: '保护倒计时',
          key: 'difference',
          width: 100,
          align: 'center',
          render: (h, params) => {
            return formatCustomerDifference(h, getTimeDifference(Date.now(), params.row.overdueTime))
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 80,
          render: (h, params) => {
            return h('a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.handleShowDetail(params.row.id)
                  }
                }
              }, '详情')
          }
        }
      ],
      customerConflictModal: false, // 客户冲突预查询
      conflictTotal: 0,
      customerQuery: {
        publisherId: this.$store.getters.token.userInfo.publisherId,
        child: true,
        pageNumber: 1,
        pageSize: 6,
        keyword: ''
      },
      conflictcolumndata: [
        { title: '客户名称', key: 'name' },
        { title: '报备品牌', key: 'brands' },
        {
          title: '状态',
          key: 'status',
          align: 'center',
          render: (h, params) => {
            return formatCustomerStatus(h, params.row.companyAdvertiserStatus, params.row.companyAdvertiserStatusName)
          }
        },
        {
          title: '保护倒计时',
          key: 'difference',
          align: 'center',
          render: (h, params) => {
            return formatCustomerDifference(h, getTimeDifference(Date.now(), params.row.overdueTime))
          }
        }
      ],
      conflictdata: []
    }
  },
  created () {
    getDictList({ key: 'company_advertiser_status' }).then(res => {
      this.statuslist = res
    })

    this.initPageData()
  },
  methods: {
    initPageData () {
      getAdvertiserPage(this.query).then(response => {
        this.total = response.totalRow
        this.tableData = response.list
        this.query.pageNumber = response.pageNumber
      })
    },
    reloadData () {
      this.query.pageNumber = 1
      this.initPageData()
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPageData()
    },
    SearchAdvertisers () {
      this.query.pageNumber = 1
      if (this.advertiserStatus !== '') {
        const status = []
        status.push(this.advertiserStatus)
        this.query.companyStatus = JSON.stringify(status)
      } else {
        this.query.companyStatus = ''
      }

      this.initPageData()
    },
    handleShowDetail (customerId) {
      this.$emit('on-detail', customerId)
    },
    conflictChangePage (currentPage) {
      this.customerQuery.pageNumber = currentPage
      this.getConflictPageData()
    },
    selectconflictChangeData () {
      this.customerQuery.pageNumber = 1
      this.getConflictPageData()
    },
    getConflictPageData () {
      if (this.customerQuery.keyword) {
        getAdvertiserPage(this.customerQuery).then(response => {
          this.conflictTotal = response.totalRow
          this.conflictdata = response.list
          this.customerQuery.pageNumber = response.pageNumber
        })
      } else {
        this.conflictTotal = 0
        this.conflictdata = []
        this.customerQuery.pageNumber = 1
      }
    }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.advertiser.beginUpdate
    }
  },
  watch: {
    beginUpdate (val) {
      this.initPageData()
    }
  }
}
</script>
