import request from '@/utils/requestV2'

const qs = require('qs')

/**
 * 成本账单分类接口
 * @param {Object} data
 */
export function getModuleList (data) {
  return request({
    url: '/ooh-statement/v1/mspBillDetail/getModuleList',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取msp成本账单金额和数量汇总统计
 * @param {Object} data
 */
export function getMspBillSummaryStatistics (data) {
  return request({
    url: '/ooh-statement/v1/mspBillDetail/getMspBillSummaryStatistics',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取工单时间类型
 * @param {Object} data
 */
export function getMspTimeTypeList (data) {
  return request({
    url: '/ooh-statement/v1/mspBillDetail/getMspTimeTypeList',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取账单结算状态
 * @param {Object} data
 */
export function listBillSettlementStatus (data) {
  return request({
    url: '/ooh-statement/v1/mspBillDetail/listBillSettlementStatus',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取成本账单费用类型
 * @param {Object} data
 */
export function listMspFeeCategory (data) {
  return request({
    url: '/ooh-statement/v1/mspBillDetail/listMspFeeCategory',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取资源类型列表
 * @param {Object} data
 */
export function listMspResourceType (data) {
  return request({
    url: '/ooh-statement/v1/mspBillDetail/listMspResourceType',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取工单的作业类型
 * @param {Object} data
 */
export function listMspTaskType (data) {
  return request({
    url: '/ooh-statement/v1/mspBillDetail/listMspTaskType',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 获取工单任务用途接口
 * @param {Object} data
 */
export function listTaskAdType (data) {
  return request({
    url: '/ooh-statement/v1/mspBillDetail/listTaskAdType',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 查询msp成本账单列表
 * @param {Object} data
 */
export function listMspBillDetail (data) {
  return request({
    url: '/ooh-statement/v1/mspBillDetail/listMspBillDetail',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 导出msp成本账单列表
 * @param {Object} data
 */
export function exportMspBillDetail (data) {
  return request({
    url: '/ooh-statement/v1/mspBillDetail/exportMspBillDetail',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 检测文件是否生成成功,返回结果(200:成功,400:未成功)
 * @param {Object} data
 */
export function checkFileExist (data) {
  return request({
    url: '/ooh-statement/bill/file/checkFileExist',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 获取成本账单结算公司列表
 * @param {Object} data
 */
export function listMspSettlementCompany (data) {
  return request({
    url: '/ooh-statement/v1/mspBillDetail/listMspSettlementCompany',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取成本账单供应商公司
 * @param {Object} data
 */
export function listMspSupplierCompany (data) {
  return request({
    url: '/ooh-statement/v1/mspBillDetail/listMspSupplierCompany',
    method: 'post',
    data: qs.stringify(data)
  })
}
