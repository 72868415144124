<template>
  <div>
    <Row :gutter="8" class="m-b-5">
      <i-col :xs="24" :sm="12" :md="4" :lg="4">
        <Select
          size="small"
          v-model="advertiserStatus"
          placeholder="客户状态"
          clearable
        >
          <Option
            v-for="(status, index) in statuslist"
            :key="index"
            :value="status.value"
            >{{ status.name }}</Option
          >
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4">
        <Select
          size="small"
          v-model="query.sign"
          placeholder="客户空间"
          clearable
        >
          <Option
            v-for="(sign, index) in signlist"
            :key="index"
            :value="sign.value"
            >{{ sign.name }}</Option
          >
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4">
        <Select
          size="small"
          v-model="query.type"
          placeholder="客户类型"
          clearable
        >
          <Option value="1">广告主</Option>
          <Option value="2">广告公司</Option>
          <Option value="3">4A公司</Option>
          <Option value="9">个人</Option>
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5" v-if="isManage">
        <Select
          v-model="query.companyId"
          size="small"
          placeholder="所属公司"
          clearable
          @on-change="initDepartmentList"
        >
          <Option value="-1">客户池</Option>
          <Option
            v-for="(channel, index) in channelCompanyList"
            :key="index"
            :value="channel.id"
            >{{ channel.name }}</Option
          >
        </Select>
      </i-col>
        <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5" v-if="isManage">
        <Select
          v-model="query.departmentId"
          size="small"
          placeholder="所属部门"
          clearable
          @on-change="getChannelUserList"
        >
          <Option
            v-for="(department, index) in deparmentList"
            :key="index"
            :value="department.id"
            >{{ department.name }}</Option
          >
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5" v-if="isManage">
        <Select
          v-model="query.userId"
          placeholder="归属销售"
          clearable
          size="small"
        >
          <Option
            v-for="user in channelUsers"
            :key="user.id"
            :value="user.id"
            >{{ user.name }}</Option
          >
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4">
        <i-input
          size="small"
          v-model="query.keyword"
          placeholder="关键字：客户名称/投放品牌"
        ></i-input>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <Button
          size="small"
          type="primary"
          icon="ios-search"
          @click="SearchAdvertisers"
          >搜索</Button
        ><Button
          size="small"
          type="success"
          class="m-l-5"
           v-if="isAuth('Advertiser_Download_List')"
          @click="handleDownloadCustomer"
          >导出客户清单</Button
        >
      </i-col>
    </Row>

    <Table
      size="small"
      :columns="isManage ? columnManagedata : columndata"
      :data="tableData"
      stripe
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="changePage"
      ></Page>
    </div>
    <Modal
      title="调整客户所属"
      v-model="ChangeCustomerUsermodel"
      :mask-closable="false"
      @on-ok="confirmChangeCustomerUser"
    >
      <Row class="p-b-5">
        当前所属代理商：
        <strong> {{ currentCompanyName }}</strong>
      </Row>
      <Row>
        变更代理商：
        <Select v-model="choiceComponyId" placeholder="选择代理商">
          <Option
            v-for="channel in channellist"
            :key="channel.id"
            :value="channel.id"
            >{{ channel.name }}</Option
          >
        </Select>
      </Row>
      <Row class="p-t-10 p-b-5">
        当前所属销售：
        <strong>{{ currentUserName }}</strong>
      </Row>
      <Row>
        变更销售：
        <Select
          class="p-b-5"
          v-model="choiceSalerId"
          placeholder="选择销售人员"
        >
          <Option
            v-for="user in choicechannelUsers"
            :key="user.id"
            :value="user.id"
            >{{ user.name }}</Option
          >
        </Select>
      </Row>
    </Modal>
  </div>
</template>

<script>
import { getAdvertiserPage, invalidAdvertiser } from '@/api/crm/advertiser'
import { getDictList } from '@/api/sys/dict'

import { getCompanyPage, getCompanyChild } from '@/api/os/company'
import { getSellerPage } from '@/api/os/companyuser'
import { addAdvertiserForUser } from '@/api/crm/companyadvertiser'

import { getTimeDifference, ParseDate } from '@/utils/dateFormat'
import { downloadFileRequest } from '@/utils/download'

import {
  formatCustomerStatus,
  formatCustomerDifference
} from '@/utils/tagStatus'

export default {
  // props: {
  //   isManage: {
  //     type: Boolean,
  //     default: false
  //   },
  //   onlyread: {
  //     type: Boolean,
  //     default: false
  //   }
  // },
  data () {
    return {
      channellist: [], // 代理商列表
      channelCompanyList: [],
      deparmentList: [],
      channelUsers: [], // 代理商下所有销售
      choicechannelUsers: [], // 用于变更客户所属销售的列表
      currentCompanyName: '', // 当前代理商名称
      choiceComponyId: 0, // 当前代理商Id
      currentUserName: '', // 当前销售名称
      choiceSalerId: 0, // 当前销售ID

      signlist: [
        { value: 1, name: '私有市场' },
        { value: 2, name: '生活圈' }
        // { value: 3, name: '广告商城' }
      ],
      statuslist: [],
      advertiserStatus: '',
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 15,
        publisherId: this.$store.getters.token.userInfo.publisherId,
        userId: this.$store.getters.token.userInfo.userId,
        companyStatus: '',
        type: null,
        keyword: ''
      },
      tableData: [],
      columndata: [
        { title: '客户名称', key: 'name' },
        { title: '报备品牌', key: 'brands' },
        { title: '客户类型', key: 'typeName' },
        { title: '客户空间', key: 'signs' },
        {
          title: '状态',
          align: 'center',
          render: (h, params) => {
            return formatCustomerStatus(
              h,
              params.row.companyAdvertiserStatus,
              params.row.companyAdvertiserStatusName
            )
          }
        },
        {
          title: '保护倒计时',
          key: 'difference',
          align: 'center',
          render: (h, params) => {
            return formatCustomerDifference(
              h,
              getTimeDifference(Date.now(), params.row.overdueTime)
            )
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            const detailButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.handleShowDetail(params.row.id)
                  }
                }
              },
              '详情'
            )
            return h('div', [detailButton])
          }
        }
      ],
      columnManagedata: [
        { title: '客户名称', key: 'name' },
        { title: '报备品牌', key: 'brands' },
        {
          title: '所属公司/部门/销售',
          render: (h, params) => {
            return h('div', [
              h(
                'p',
                params.row.currentUserName === ''
                  ? params.row.currentCompanyName === null
                    ? '-'
                    : params.row.currentCompanyName
                  : params.row.currentCompanyName +
                          '(' +
                          params.row.currentUserName +
                          ')'
              )
            ])
          }
        },
        { title: '客户类型', key: 'typeName' },
        { title: '客户空间', key: 'signs' },
        { title: '报备来源', key: 'sourceName' },
        {
          title: '状态',
          align: 'center',
          render: (h, params) => {
            return formatCustomerStatus(
              h,
              params.row.companyAdvertiserStatus,
              params.row.companyAdvertiserStatusName
            )
          }
        },
        {
          title: '保护倒计时',
          key: 'difference',
          align: 'center',
          render: (h, params) => {
            return formatCustomerDifference(
              h,
              getTimeDifference(Date.now(), params.row.overdueTime)
            )
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            const detailButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.handleShowDetail(params.row.id)
                  }
                }
              },
              '详情'
            )
            const updateButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.showModel(params.row)
                  }
                }
              },
              '调整'
            )
            const invalidButton = h('a', {
              props: {
                type: 'warning',
                size: 'small'
              },
              style: { marginRight: '5px', color: '#f87676' },
              on: {
                click: () => {
                  this.$Modal.confirm({
                    title: '操作确认？',
                    content: '<p>确定要标记客户【 ' + params.row.name + '】为无效状态？</p>',
                    loading: true,
                    onOk: () => {
                      invalidAdvertiser({ advertiserId: params.row.id, companyId: params.row.currentCompanylId }).then(res => {
                        if (res && !res.errcode) {
                          this.$Notice.success({ desc: '操作成功' })
                          this.initPageData()
                        }
                        this.$Modal.remove()
                      }).catch(error => {
                        this.$Modal.remove()
                        this.$Notice.error({ title: '服务器请求错误', desc: error })
                      })
                    }
                  })
                }
              }
            }, '标记失效')

            return h('div', [
              detailButton,
              this.adjustAscription && params.row.source === 0 ? updateButton : null,
              this.invalidAdvertiser && this.allinvalidStatus.includes(params.row.companyAdvertiserStatus) ? invalidButton : null
            ])
          }
        }
      ],

      allinvalidStatus: [2, 5, 6],
      ChangeCustomerUsermodel: false,
      changeSaler: {
        advertiserId: 0,
        companyId: 0,
        userId: 0
      }
    }
  },
  created () {
    getDictList({ key: 'company_advertiser_status' }).then((res) => {
      this.statuslist = res
    })
    if (this.isManage) {
      this.initChannelList()
      this.query.userId = null
    }

    this.initPageData()
  },
  methods: {
    initChannelList () {
      getCompanyChild({ companyId: this.$store.getters.token.userInfo.companyId, type: this.$store.getters.token.userInfo.publisherId === this.$store.getters.token.userInfo.companyId ? 1 : 2, childType: 2, companyType: 1 }).then(res => {
        let array = [{
          id: res.companyId, name: res.companyName
        }]
        if (res.children.length) {
          array = array.concat(this.formatCompanyArray(res.children).filter(x => x.id !== res.companyId))
        }
        this.channelCompanyList = array
        if (!this.query.companyId) {
          if (array.length > 1) {
            this.query.companyId = null
          } else {
            this.query.companyId = this.companyId
          }
        }
        this.initDepartmentList()
      })
    },
    initDepartmentList () {
      if (this.query.companyId > 0) {
        getCompanyChild({ companyId: this.query.companyId, type: 2, childType: 2, companyType: 2 }).then(res => {
          let array = [{
            id: res.companyId, name: res.companyName
          }]
          if (res.children.length) {
            array = array.concat(this.formatCompanyArray(res.children).filter(x => x.id !== res.companyId))
          }
          this.deparmentList = array
          // this.query.departmentId = null
          // if (res.length > 1) {
          //   this.query.departmentId = null
          // } else {
          //   this.query.departmentId = this.companyId
          // }
          this.getChannelUserList()
        })
      } else {
        this.query.departmentId = null
        this.deparmentList = null
        this.getChannelUserList()
      }
    },
    // 重组公司列表结构
    formatCompanyArray (companyTree) {
      let resultArray = []
      companyTree.forEach(item => {
        resultArray.push({ id: item.companyId, name: item.companyName })
        if (item.children.length) {
          resultArray = resultArray.concat(this.formatCompanyArray(item.children))
        }
      })
      return resultArray
    },
    initPageData () {
      getAdvertiserPage(this.query).then((response) => {
        this.total = response.totalRow
        this.tableData = response.list
        this.query.pageNumber = response.pageNumber
      })
    },
    reloadData () {
      this.query.pageNumber = 1
      this.initPageData()
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPageData()
    },
    SearchAdvertisers () {
      this.query.pageNumber = 1
      if (this.advertiserStatus !== '') {
        const status = []
        status.push(this.advertiserStatus)
        this.query.companyStatus = JSON.stringify(status)
      } else {
        this.query.companyStatus = ''
      }

      this.initPageData()
    },
    getChannelUserList () {
      if (this.query.companyId && this.query.companyId !== '-1') {
        const data = {
          companyId: this.query.departmentId || this.query.companyId,
          pageNumber: 1,
          pageSize: 1000
        }
        getSellerPage(data).then((response) => {
          this.channelUsers = response.list
        })
      } else {
        this.channelUsers = []
        this.query.userId = ''
      }
    },
    // CreateCustomer () {
    //   this.$emit('on-create')
    // },
    handleShowDetail (customerId) {
      this.$emit('on-detail', customerId)
    },
    showModel (params) {
      const channelquery = {
        // publisherId: this.query.publisherId,
        pageNumber: 1,
        pageSize: 100,
        type: JSON.stringify([2])
      }
      getCompanyPage(channelquery).then((res) => {
        this.channellist = res.list
      })
      this.currentCompanyName = params.currentCompanyName
      this.choiceComponyId = params.currentCompanyId
      this.currentUserName = params.currentUserName
      this.choiceSalerId = params.currentUserId
      this.changeSaler.advertiserId = params.id
      this.ChangeCustomerUsermodel = true
    },
    // 确认变更客户所属
    confirmChangeCustomerUser () {
      this.changeSaler.companyId = this.choiceComponyId
      this.changeSaler.userId = this.choiceSalerId
      addAdvertiserForUser(this.changeSaler).then(res => {
        if (res) {
          this.$Notice.success({ desc: '成功变更客户所属信息！' })
          this.ChangeCustomerUsermodel = false
          this.initPageData()
        } else {
          this.ChangeCustomerUsermodel = true
        }
      })
    },
    handleDownloadCustomer () {
      const filename = '客户清单(' + ParseDate(new Date()) + ').xlsx'

      let customerStatus = ''
      if (this.advertiserStatus !== '') {
        const status = []
        status.push(this.advertiserStatus)
        customerStatus = JSON.stringify(status)
      }

      const params = {
        companyStatus: customerStatus,
        type: this.query.type,
        companyId: this.query.companyId,
        userId: this.query.userId,
        keyword: this.query.keyword
      }

      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-crm/v1/advertiser/exportexcel', params, filename)
    }
  },
  watch: {
    choiceComponyId (val) {
      if (val) {
        const data = {
          companyId: val,
          pageNumber: 1,
          pageSize: 1000
        }
        getSellerPage(data).then((response) => {
          this.choicechannelUsers = response.list
        })
      }
    }
  },
  computed: {
    isManage: function () {
      // let startDate = new Date(this.contract.startDate)
      return this.isAuth('Advertiser_Manage_View')
    },
    allowEdit: function () {
      return this.isAuth('Advertiser_Edit')
    },
    adjustAscription: function () { // 调整销售
      return this.isAuth('Advertiser_Adjust_Ascription')
    },
    invalidAdvertiser: function () { // 调整销售
      return this.isAuth('Advertiser_Invalid')
    }
  }
}
</script>
