<template>
  <div>
    <!-- <div class="m-b-10 text-right">
      <Button size="small" type="success" @click="onCreateProject">创建项目</Button>
    </div> -->
    <Row :gutter="8">
      <i-col span="8" v-for="item in list" :key="'project_' + item.id" class="m-b-20">
        <Card>
          <p slot="title" class="text-center">{{ item.name }}</p>
          <div>
            <!-- <div class="project-info">
              <div class="project-info-label">平台产品:</div>
              <div>{{ item.productName || '/' }}</div>
            </div>
            <div class="project-info">
              <div class="project-info-label">项目描述:</div>
              <div>{{ item.desc || '/' }}</div>
            </div> -->
            <div class="m-t-20">
              <Button size="small" type="primary" icon="ios-browsers-outline" class="m-r-10 m-b-5"
                @click="onViewProduct(item)">SKU管理</Button>
              <Button size="small" type="primary" icon="ios-briefcase-outline" class="m-r-10 m-b-5"
                @click="onViewAssetPackge(item)">资产包管理</Button>
              <Button size="small" type="primary" icon="ios-people" class="m-r-10 m-b-5"
                @click="onViewAgent(item)">代理商管理</Button>
              <Button size="small" type="primary" icon="ios-cube" class="m-r-10 m-b-5"
                @click="onViewSupplier(item)">供应商管理</Button>
              <Button size="small" type="primary" icon="ios-construct" class="m-b-5"
                @click="onViewRules(item)">规则配置</Button>
            </div>
          </div>
        </Card>
      </i-col>
    </Row>
    <modify ref="modify" :opreate="opreate" :onSuccess="getTableData" />
  </div>
</template>

<script>
import Modify from './Modify.vue'
import { getList } from '@/api/os/project'
export default {
  components: { Modify },
  data () {
    return {
      opreate: 1,
      publisherId: this.$store.getters.token.userInfo.publisherId,
      // tableLoading: false,
      list: []
      // tableColumns: [
      //   { title: '项目', align: 'center', key: 'name' },
      //   { title: '平台产品', align: 'center', key: 'productName' },
      //   { title: '项目描述', align: 'center', key: 'desc' },
      //   { title: '更新时间', align: 'center', key: 'updateTime' },
      //   { title: '创建时间', align: 'center', key: 'createTime' },
      //   {
      //     title: '操作',
      //     align: 'center',
      //     render: (h, { row }) => {
      //       const html = []
      //       html.push(
      //         h('a', {
      //           class: ['m-r-5'],
      //           on: {
      //             click: () => {
      //               this.onEdit(row)
      //             }
      //           }
      //         }, '编辑'),
      //         h('a', {
      //           on: {
      //             click: () => {
      //               this.onViewDetail(row)
      //             }
      //           }
      //         }, '详情')
      //       )
      //       return h('div', html)
      //     }
      //   }
      // ]
    }
  },
  created () {
    this.getTableData()
  },
  methods: {
    async getTableData () {
      this.tableLoading = true
      const result = await getList({ publisherId: this.publisherId }).finally(() => { this.tableLoading = false })
      this.list = (result && !result.errcode) ? result : []
    },
    onCreateProject () {
      this.opreate = 1
      this.$nextTick(() => {
        this.$refs.modify.showModal()
      })
    },
    onEdit (params) {
      this.opreate = 2
      this.$nextTick(() => {
        this.$refs.modify.showModal(params)
      })
    },
    // onViewDevice (params) {
    //   this.$router.push({ name: 'project-device', params: { id: params.id } })
    // },
    onViewProduct (params) {
      this.$router.push({ name: 'project-product', params: { id: params.id } })
    },
    onViewAgent (params) {
      this.$router.push({ name: 'project-agent', params: { id: params.id } })
    },
    onViewSupplier (params) {
      this.$router.push({ name: 'project-supplier', params: { id: params.id } })
    },
    onViewRules (params) {
      this.$router.push({ name: 'project-rules', params: { id: params.id } })
    },
    onViewAssetPackge (params) {
      this.$router.push({ name: 'project-assetpackage', params: { id: params.id } })
    }
  }
}
</script>
<style scoped lang="less">
.project-info {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  &-label {
    width: 100px;
    padding-right: 12px;
    word-break: break-all;
  }
}
</style>
