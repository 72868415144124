<template>
  <div>
    <Modal v-model="setAgentComModal" width="600" footer-hide>
      <p slot="header" class="text-center">分配代理商</p>
      <div>
        <div class="m-b-10">
          <Button type="primary" size="small" :disabled="selectedComArray.length===0" @click="onConfirmSet">确认分配</Button>
        </div>
        <Table size="small" stripe :max-height="700" :data="list" :columns="tableColumns" :loading="tableLoading"
          @on-selection-change="onSelection"></Table>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getProjectCompany } from '@/api/os/project'
import { setCompanySku } from '@/api/product/productskuedit'
export default {
  props: {
    projectId: {
      type: Number,
      required: true
    },
    selectedSkuIds: [Array],
    onSuccess: [Function]
  },
  data () {
    return {
      setAgentComModal: false,
      tableLoading: false,
      list: [],
      tableColumns: [
        { type: 'selection', width: 60, align: 'center' },
        { title: '公司名称', align: 'center', key: 'name' }
      ],
      selectedComArray: []
    }
  },
  methods: {
    showModal () {
      console.log(this.selectedSkuIds)
      this.getComData()
      this.setAgentComModal = true
    },
    async getComData () {
      this.tableLoading = true
      const query = {
        projectId: this.projectId,
        type: 3 // 3：代理商；5：供应商
      }
      const result = await getProjectCompany(query).finally(() => { this.tableLoading = false })
      this.list = (result && !result.errcode) ? result : []
    },
    onSelection (selections) {
      this.selectedComArray = selections.map(x => x.id)
    },
    onConfirmSet () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认分配代理商？',
        onOk: async () => {
          const postData = {
            companyIds: JSON.stringify(this.selectedComArray),
            skuIds: JSON.stringify(this.selectedSkuIds)
          }
          const result = await setCompanySku(postData)
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.selectedComArray = []
            this.setAgentComModal = false
            this.onSuccess()
          }
        }
      })
    }
  }
}
</script>
