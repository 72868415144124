<template>
  <div >
    <h3 class="p-t-10">服务采购详情</h3>
    <Table  stripe size="small" :columns="serviceColumns" :data="serviceData" border disabled-hover  />
    <Row class="p-t-5">
      <i-col span="24" class="text-right text-16 p-r-20">
        服务费刊例价合计：<b class="text-green">{{serviceSummary}}</b>
      </i-col>
    </Row>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { getItemlist } from '@/api/order/orderserviceV3'

export default {
  data () {
    return {
      orderId: 0,
      serviceColumns: [
        {
          title: '服务项名称',
          render: (h, data) => {
            return h('span', data.row.serviceItemName)
          }
        },
        { title: '类型', key: 'typeName' },
        {
          title: '刊例价',
          key: 'price',
          render: (h, data) => {
            return h('span', data.row.formatPrice + '/' + data.row.quantityUnitName)
          }
        },
        { title: '订单内数量', key: 'defaultQuantity' },
        { title: '采购数量', key: 'quantity' },
        { title: '刊例价小计', align: 'right', key: 'totalPrice' }
      ],
      serviceData: [],
      scheduleColumSpan: [],
      mediumTypeColumSpan: [],

      serviceSummary: 0
    }
  },
  methods: {
    initPrice (orderId) {
      this.orderId = orderId
      getItemlist({ orderId: orderId }).then(res => {
        this.serviceData = []
        let summary = 0

        res.data.forEach(element => {
          element.formatPrice = toMoney(element.price)
          this.serviceData.push(element)
          summary += element.totalPrice
        })

        this.serviceSummary = toMoney(summary)
      })
    }
  },
  watch: {
    // beginUpdate () {
    //   this.initPrice(this.orderId)
    // }
  },
  computed: {
    // beginUpdate () {
    //   return this.$store.state.order.beginUpdate
    // }
  }
}
</script>
