<template>
  <div>
    <Modal v-model="addAgentModal" width="800" footer-hide>
      <p slot="header" class="text-center">添加代理商</p>
      <div class="m-b-10">
        <Button type="success" size="small" :disabled="selectedItems.length === 0" @click="onConfirmAdd">确认添加</Button>
      </div>
      <div>
        <Table stripe max-height="620" size="small" :data="list" :columns="tableColumns" :loading="tableLoading"
          @on-selection-change="onSelection">
        </Table>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getCompanyList } from '@/api/os/company'
import { addAgency } from '@/api/os/project'
export default {
  props: {
    projectId: {
      type: Number,
      required: true
    },
    onSuccess: [Function]
  },
  data () {
    return {
      addAgentModal: false,
      query: {
        enabled: true,
        publisherId: this.$store.getters.token.userInfo.publisherId,
        type: 2
      },
      list: [],
      tableLoading: false,
      tableColumns: [
        { type: 'selection', width: 60, align: 'center' },
        { title: '公司名称', align: 'center', key: 'name' },
        { title: '联系电话', align: 'center', key: 'tel' }
      ],
      selectedItems: []
    }
  },
  methods: {
    showModal () {
      this.getTableData()
      this.addAgentModal = true
    },
    async getTableData () {
      this.tableLoading = true
      const result = await getCompanyList(this.query).finally(() => { this.tableLoading = false })
      this.list = (result && !result.errcode) ? result : []
    },
    onSelection (selections) {
      this.selectedItems = selections.map(x => x.id)
    },
    onConfirmAdd () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认要添加当前勾选项？',
        onOk: async () => {
          const postData = {
            agencyIds: JSON.stringify(this.selectedItems),
            projectId: this.projectId
          }
          const result = await addAgency(postData)
          if (result && result.success) {
            this.selectedItems = []
            this.$Notice.success({ desc: '操作成功' })
            this.onSuccess()
            this.addAgentModal = false
          }
        }
      })
    }
  }
}
</script>
