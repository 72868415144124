<template>
  <div>
    <div style="margin:10px">
      <row class="p-b-5">
        <span>当前包含的订单：</span>
        <a  @click="showOrdersPicker=true">添加订单</a>
        <!-- <span v-if="stepType===2" class="remark">（PS：订单变更仅支持 变更价格 或 基本信息，无法新增或者移除订单）</span> -->
      </row>
      <i-table stripe :data="orders" :columns="titles"></i-table>

      <!-- 费用汇总 -->
      <price-summary class="p-t-10" v-if="priceOrder.length" :orders="priceOrder" :serviceItems="serviceItems"></price-summary>
      <!-- <price-summary class="p-t-10" v-if="priceOrder.length" :orders="priceOrder"></price-summary> -->
      <!-- 合同付款项 -->
      <payment :totalMoney="totalMoney" :payItems="params.payments" @paymentUpdate="handlePaymentUpdate" :defaultItems="defaultPayments"></payment>

      <row class="p-t-10">
        <i-col span="24">
          <Button type="info" @click="handlePreviousStep">上一步</Button>
          <div class="right">
            <span v-show="!canSubmit" class="text-red m-r-5">请配置完所有订单的金额</span>
            <Button type="info" :disabled="!canSubmit" :loading="loadingNext" @click="handleSubmit">下一步</Button>
          </div>
        </i-col>
      </row>

    </div>

    <Modal v-model="showOrdersPicker" :width="800"  >
      <div slot="header">
        <p>当前可以选择的订单</p>
      </div>
      <orders-picker :params="params" :existedOrders="orders" @on-chosen="handleOrdersChosen"></orders-picker>
      <Spin fix size="large" v-if="spinShow">
          <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
          <div>提交中...</div>
      </Spin>
      <div slot="footer"></div>
    </Modal>

    <price-setting :hasLimit="hasLimit" :contractId="params.id" :contractType="params.type" :orderId="currentOrder.id" :versionId="currentVersionId"
      @on-updated="getContractPriceSetting" :isShow.sync="showPriceSettingModal" ></price-setting>

  </div>
</template>

<script>
import payment from '../common/Payment'
import priceSummary from '../common/PriceSummaryV2'
import ordersPicker from '../common/OrdersPicker'
import priceSetting from '../steps/PriceSettingV2'

import { GetCurrentSchedule, ParseDate } from '@/utils/dateFormat'
import { changeContract } from '@/api/scp/contractchange'
import { checkContractDetail } from '@/api/scp/contract'
import { deleteOrder, getExpectPayList, getOrderScheduleCategoryList, addOrder, listSignServiceItem } from '@/api/scp/contractorder'
import { getOrderList } from '@/api/order/order'
import { getConfirmation } from '@/api/order/confirmation'

export default {
  props: {
    params: {
      type: Object,
      required: true
    },
    stepType: {
      type: Number,
      default: 1
    }
  },
  components: {
    payment, priceSummary, priceSetting, ordersPicker
  },
  data () {
    return {
      loadingNext: false,
      titles: [
        { title: '投放品牌', key: 'brandName' },
        { title: '备注', key: 'remark' },
        // {
        //   title: '投放档期',
        //   render: (h, data) => {
        //     return h(
        //       'span',
        //       GetCurrentSchedule(data.row.startDate, data.row.endDate)
        //     )
        //   }
        // },
        // {
        //   title: '档期天数',
        //   key: 'pubishDay',
        //   render: (h, data) => {
        //     return h('span',
        //       data.row.tradePublishday + '天'
        //     )
        //   }
        // },
        {
          title: '投放档期',
          render: (h, data) => {
            const spans = []
            data.row.purchasedScheduleList.forEach(item => {
              spans.push(h('span', GetCurrentSchedule(item.startDate, item.endDate)))
              spans.push(h('br'))
            })
            return h('div', spans)
          }
        },
        {
          title: '采购资源统计',
          width: 150,
          render: (h, data) => {
            const spans = []
            data.row.orderProductQuantityBeanList.forEach(resource => {
              spans.push(h('span', resource.productName))
              spans.push(
                h(
                  'span',
                  {
                    attrs: {
                      class: 'text-orange text-12'
                    }
                  },
                  resource.quantity
                )
              )
              spans.push(h('span', resource.unitName))
              spans.push(h('br'))
            })
            return h('div', spans)
          }
        },
        {
          title: '操作',
          render: (h, data) => {
            const operate = [
              h('a', {
                on: {
                  click: () => {
                    this.handlePriceSetting(data.row)
                  }
                }
              }, '配置金额')
            ]

            // 判断合同是否已经存在了订单， 当前新添加的订单允许被删除
            // if (this.historyContractOrderIds.indexOf(data.row.id) < 0) {
            operate.push(h('a', {
              style: {
                'margin-left': '5px',
                color: '#ef4f4f'
              },
              on: {
                click: () => {
                  if (!this.deleteing) {
                    this.handleDelete(data.row)
                  }
                }
              }
            }, '删除'))
            // }

            return h('div', operate)
          }
        }
      ],
      orders: [],
      historyContractOrderIds: [], // 原版合同包含的订单ID
      priceOrder: [],
      serviceItems: [],
      payments: [],
      showNext: false,
      contract: {},
      showPriceSettingModal: false, // 显示价格设置窗口
      currentOrder: {},
      showConfirm: false,
      showOrdersPicker: false,
      showAgreementPicker: false,
      schedulePrice: [],
      currentVersionId: -1, // 当前可用的版本号
      priceMap: new Map(), // 装载全局的价格设置
      changeCheck: {}, // 验证订单价格是否有变更
      canCheckPrice: false,
      spinShow: false
    }
  },
  methods: {
    handlePriceSetting (order) {
      this.currentOrder = this.orders.find(x => x.id === order.id)
      this.showPriceSettingModal = true
    },
    handleDelete (order) {
      // this.checkOrderInAgreement(order)
      this.deleteing = true
      const data = {
        contractId: this.params.id,
        version: this.currentVersionId,
        orderId: order.id
      }
      deleteOrder(data).then(res => {
        if (!res.errcode) {
          getExpectPayList({ contractId: this.params.id, version: this.currentVersionId }).then(scheduleprice => {
            this.schedulePrice = scheduleprice
          })
          const index = this.orders.findIndex(o => o.id === order.id)
          this.orders.splice(index, 1)
          this.priceMap.delete(order.id)
          this.priceOrder = [...this.priceMap.values()]
        }

        this.deleteing = false
      })
    },
    checkOrderInAgreement (order) {
      const query = { orderId: order.id }
      getConfirmation(query).then(res => {
        if (res.orderIdList.length > 1) { // 当前订单和其他订单同属一个确认单， 弹窗请求是否批量删除
          alert('当前订单和其他订单同属一个确认单， 暂时不能删除')
        } else {
          const data = {
            contractId: this.params.id,
            version: this.currentVersionId,
            orderId: order.id
          }
          deleteOrder(data).then(res => {
            getExpectPayList({ contractId: this.params.id, version: this.currentVersionId }).then(scheduleprice => {
              this.schedulePrice = scheduleprice
            })
            const index = this.orders.findIndex(o => o.id === order.id)
            this.orders.splice(index, 1)
            this.priceMap.delete(order.id)
            this.priceOrder = [...this.priceMap.values()]
          })
        }
      })
    },
    handlePreviousStep () {
      const data = {
        orders: this.orders,
        priceOrders: this.priceOrders,
        payments: this.payments
      }
      this.$emit('prev-step', data)
    },
    handlePaymentUpdate (payments) {
      this.payments = payments
    },
    handleSubmit () {
      this.loadingNext = true
      if (this.stepType === 1) {
        const contract = {
          contractId: this.contract.id,
          orderIds: JSON.stringify(this.orders.map(x => x.id))
        }
        this.$emit('next-step', Object.assign({}, contract, { priceOrder: this.priceOrder, orders: this.orders, payments: this.payments, schedulePrice: this.schedulePrice }))
      } else {
        let contractMoney = 0
        this.payments.forEach(payment => {
          contractMoney += parseFloat(payment.money)
        })

        if (Math.abs(this.totalMoney.toFixed(2)) !== Math.abs(contractMoney.toFixed(2))) {
          this.$Notice.error({
            desc: '您所配置的付款总额与合计应付总额不一致，请检查您的付款项配置！'
          })
        } else {
          this.$Modal.confirm({
            title: '确认执行价',
            content: '<p>请确认执行价</p>',
            onOk: () => {
              this.submitContractChange()
            },
            onCancel: () => {
              this.loadingNext = false
            }
          })
        }
      }

      this.loadingNext = false
    },
    submitContractChange () {
      const contract = {
        versionId: this.currentVersionId,
        name: this.contract.contractName,
        mark: this.contract.mark,
        customerId: this.contract.firstParty.id,
        orderIds: JSON.stringify(this.orders.map(x => x.id)),
        isSubmitDraft: false,
        payments: JSON.stringify(this.payments.filter(x => x.money > 0).map(payment => {
          return {
            date: ParseDate(payment.date),
            amount: payment.money,
            mark: payment.mark
          }
        })),
        commissiontypeId: this.contract.commissiontypeId || -11,
        isStandard: this.contract.standard || true
      }

      changeContract(contract).then(res => {
        this.$emit('next-step', {
          priceOrder: this.priceOrder,
          orders: this.orders,
          submitContract: contract,
          payments: this.payments,
          totalMoney: this.totalMoney,
          defaultPayItems: this.defaultPayments,
          schedulePrice: this.schedulePrice
        })
      })
    },
    handleOrdersChosen (orderId) {
      const that = this
      that.spinShow = true
      addOrder({ versionId: that.currentVersionId, orderId: orderId }).then(res => {
        that.getContractPriceSetting()

        that.spinShow = false
      })
    },
    getContractPriceSetting () {
      listSignServiceItem({ contractId: this.params.id, versionId: this.currentVersionId }).then(res => {
        this.serviceItems = res
        getOrderScheduleCategoryList({ contractId: this.params.id, version: this.currentVersionId }).then(res => {
        // if (!this.contract.notSetPrice) {
        // this.priceOrder = res.map(x => {
        //   return {
        //     orderId: x.orderId,
        //     products: x.signPriceDetail
        //   }
        // })
        // }
          this.priceOrder = res.map(x => {
            return {
              orderId: x.orderId,
              products: x.signPriceDetail
            }
          })
          const orderIds = res.map(x => x.orderId)
          this.historyContractOrderIds = orderIds
          if (orderIds.length) {
            getOrderList({ orderIdStr: JSON.stringify(orderIds) }).then(orders => {
              this.orders = orders
            })

            res.forEach(order => {
              this.priceMap.set(order.orderId, { orderId: order.orderId, products: order.signPriceDetail })
            })

            getExpectPayList({ contractId: this.params.id, version: this.currentVersionId }).then(paylist => {
              this.schedulePrice = paylist
            })
          }
        })
      })
    },
    priceChangeCheck () {
      let ans = 0
      for (var i in this.changeCheck) {
        if (!this.changeCheck[i]) {
          ans++
          break
        }
      }
      if (ans === 0) {
        this.canCheckPrice = true
      }
    },
    cannelLoading () {
      this.loadingNext = false
    },
    getDetail (contractId) {
      checkContractDetail({ contractId: contractId }).then(res => {
        // this.contract.notSetPrice = res.notSetPrice
        this.currentVersionId = (this.stepType === 1) ? this.contract.versionId : this.contract.newVersionId
        this.getContractPriceSetting()
      })
    }
  },
  computed: {
    totalMoney: function () {
      let total = 0
      this.serviceItems.forEach(item => {
        total += item.signServiceAmount || 0
      })
      this.priceOrder.forEach(order => {
        order.products.forEach(product => {
          // total += product.recommendSellPrice
          total += product.signNormalPrice || 0
        })
      })

      return parseFloat(total.toFixed(2))
    },
    canSubmit: function () {
      if (this.orders.length < 1) {
        return false
      }
      return true
      // const orderIds = this.orders.map(x => x.id)
      // const priceOrderIds = this.priceOrder.map(x => x.orderId)

      // if (this.orders.length !== this.priceOrder.length) {
      //   return false
      // }
      // return orderIds.every(x => priceOrderIds.includes(x))
    },
    hasLimit: function () {
      // 允许输入 执行价低于最低折扣
      return !this.params.public && this.$store.getters.token.publisherSetting.contractPriceSetting
    },
    defaultPayments () {
      return this.schedulePrice.map(x => {
        return Object.assign({}, x, {
          date: x.endDate,
          money: x.signNormalPrice + x.signServicePrice,
          serviceFee: x.signServicePrice
        })
      }).filter(x => x.money > 0)
    }

  },
  created () {
    this.priceMap = new Map()
    this.contract = this.params
    this.getDetail(this.params.id)
  }
}
</script>
