<template>
  <div>
    <div
      class="m-b-5"
      v-if="[5,9].includes(taskType)"
    >
      <RadioGroup
        v-model="dataSource"
        type="button"
        size="small"
        @on-change="handleChangeData"
      >
        <Radio
          v-for="item in dataSourceArray"
          :key="item.value"
          :label="item.value"
        >{{item.name}}</Radio>
      </RadioGroup>
    </div>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="3">
        <Select
          v-model="query.assetId"
          size="small"
          placeholder="资产"
          @on-change="handleChangeAsset"
        >
          <Option
            v-for="item in companyAssetArray"
            :key="'asset_'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select
          v-model="query.stationId"
          size="small"
          placeholder="站点"
          :clearable="true"
        >
          <Option
            v-for="item in stationArray"
            :key="'station_'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select
          v-model="query.position"
          size="small"
          placeholder="位置"
          :clearable="true"
        >
          <Option
            v-for="item in positionArray"
            :key="'position_'+item.value"
            :value="item.value"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select
          v-model="query.resourcetypeId"
          size="small"
          :clearable="true"
          placeholder="设备类型"
        >
          <Option
            v-for="item in resourceTypeArray"
            :key="'resType_'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="6">
        <Input
          search
          v-model="query.keyword"
          size="small"
          enter-button="搜索"
          placeholder="设备编号、站点名称"
          @on-search="handleSearch"
        />
      </i-col>
      <i-col span="6">
        <Button
          v-if="[8,9,10].includes(taskType)"
          size="small"
          type="primary"
          class="m-r-5"
          :disabled="selectedResourceIdArray.length===0"
          @click="handleAddNewTask"
        >设置任务</Button>
        <!-- <Button
          type="success"
          size="small"
          @click="handleSubmitTask"
        >提交全部任务</Button> -->
      </i-col>
    </Row>
    <div>
      <Table
        stripe
        size="small"
        max-height="520"
        :loading="tableLoading"
        :data="tableData.list"
        :columns="tableColumns"
        @on-selection-change="handleSelection"
      ></Table>
      <Row :gutter="8">
        <i-col span="6">
          <div
            class="m-t-5"
            style="line-height:25px;"
          >当前已选中<span style="color:#ff9900;padding:0 3px">{{selectedResourceIdArray.length}}</span>条</div>
        </i-col>
        <i-col span="18">
          <div class="paging_style">
            <Page
              :total="tableData.totalRow"
              :page-size="query.pageSize"
              size="small"
              show-total
              show-elevator
              show-sizer
              :page-size-opts="pagesizeSetting"
              @on-page-size-change='changePageSize'
              :current="query.pageNumber"
              @on-change="changePage"
            ></Page>
          </div>
        </i-col>
      </Row>

    </div>
    <Modal
      v-model="setTaskModal"
      width="800"
      footer-hide
    >
      <p
        slot="header"
        class="text-center"
      >任务设置</p>
      <component
        :is="curSetTaskComponent"
        :opreateType="1"
        :taskId="curTaskId"
        :taskType="taskType"
        :dataSource="dataSource"
        :deviceId="deviceId"
        :selectedResourceIdArray.sync="selectedResourceIdArray"
        :selectedFaultIdArray="selectedFaultIdArray"
        @handleSetSuccess="handleSetSuccess"
      ></component>
    </Modal>
    <Modal
      v-model="visiblePreview"
      width="800"
      :footer-hide="true"
    >
      <p
        slot="header"
        class="text-center"
      >{{fileName}}</p>
      <img-light-box ref="imgLightBox"></img-light-box>
    </Modal>
  </div>
</template>

<script>
import { companyMixin } from '@/assets/mixins/company'
import { commonMixins } from '@/assets/mixins/common'
// import { getStationByAssetIds } from '@/api/product/station'
import { getRimStationByAssetIds } from '@/api/rim/station'
import { getPositions } from '@/api/product/resource'
// import { getResourcetypeByAsset } from '@/api/product/asset'
import { submit, getStationPage } from '@/api/msp/demandV3'
import { closeTrouble, getTroublePage } from '@/api/msp/trouble'
// import { getDevicePage, getDeviceModelList } from '@/api/product/device'
import { getCompanyDevicePage, getDeviceModelQuantity } from '@/api/rim/device'
// import { getPublishingTaskitemPage } from '@/api/msp/taskitem'
// import { submit } from '@/api/order/demand'

import ImgLightBox from '@/components/common/ImgLightBox'
export default {
  mixins: [companyMixin, commonMixins],
  props: {
    taskType: {
      type: [Number, String],
      required: true
    },
    taskId: {
      type: [Number, String],
      required: true
    }
  },
  components: {
    ImgLightBox,
    SetRepairTask: () => import('./SetRepairTask'),
    SetMaintainTask: () => import('./SetMaintainTask')
  },
  data () {
    return {
      dataSourceArray: [
        { value: 1, name: '设备列表' },
        { value: 2, name: '上报列表' }
      ],
      companyId: this.$store.getters.token.userInfo.companyId,
      query: {
        assetId: '',
        stationId: null,
        position: null,
        resourcetypeId: null,
        keyword: '',
        pageSize: 15,
        pageNumber: 1,
        devicemodel: null,
        enabled: null
      },
      stationArray: [],
      positionArray: [],
      resourceTypeArray: [],
      pagesizeSetting: [15, 50, 100, 200, 500],
      tableData: {
        list: [],
        totalRow: 0
      },
      tableColumns: [],
      tableLoading: false,
      curTaskId: null,
      // curTaskType: null,
      demandTypeShowColumns: new Map([
        [5, ['code', 'stationName', 'resourcetypeName', 'operate']], // 维修
        // [8, ['selection', 'stationName']], // 巡检
        [9, ['selection', 'code', 'stationName', 'resourcetypeName']], // 维护
        // [10, ['selection', 'code', 'stationName', 'resourcetypeName', 'side', 'currentFile', 'picturesource', 'status', 'remark']], // 画面填充
        ['5_2', ['code', 'stationName', 'resourcetypeName', 'desc', 'attach', 'remark', 'createTime', 'operate']], // 维修-上报资源显示列表
        ['9_2', ['selection', 'code', 'stationName', 'resourcetypeName', 'desc', 'attach', 'operate2']] // 维护-上报资源显示列表
      ]),
      visiblePreview: false,
      fileName: '',
      dataSource: 1,
      selectedTroubleIds: [],
      selectedResourceIdArray: [],
      tempSelectedResourceIds: [],
      selectedFaultIdArray: [],
      setTaskModal: false,
      curSetTaskComponent: '',
      deviceId: null
    }
  },
  computed: {
  },
  created () {
    // this.setLinkTagArray({ key: 'AddDemandTask', value: '添加任务', actived: true })
    // this.setActivedTagName('AddDemandTask')
    // this.setLinkRouterMaps(['AddDemandTask', () => { this.handlePage() }])

    // this.$store.commit('set_opreate_type', 1) // 新增操作标识设置

    // this.getCompanyAssetArray()
    // this.initData()
  },
  methods: {
    /**
     * 动态生成Table列表
     */
    getTableColumns (key) {
      const tableColumnArray = {
        selection: { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        code: { title: '编号', key: 'code', minWidth: 100, align: 'center' },
        stationName: { title: '所属站台', key: 'stationName', minWidth: 100, align: 'center' },
        resourcetypeName: { title: '类型', key: 'devicemodelName', minWidth: 100, align: 'center' },
        desc: {
          title: '问题描述',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            if (params.row.category1Name) {
              return h('span', params.row.category1Name + '-' + params.row.category2Name)
            } else {
              return h('span', '/')
            }
          }
        },
        attach: {
          title: '上报画面',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            if (params.row.attachList && params.row.attachList.length > 0) {
              return h('div', [
                h(
                  'img',
                  {
                    domProps: {
                      src: params.row.attachList[0].fileKey + '?x-oss-process=image/resize,w_64'
                    },
                    on: {
                      click: () => {
                        this.fileName = params.row.stationName + params.row.code
                        const postData = {
                          startIndex: 0,
                          imgList: params.row.attachList.map(x => x.fileKey)
                        }
                        this.$refs.imgLightBox.init(postData)
                        this.visiblePreview = true
                      }
                    },
                    style: {
                      width: '64px',
                      cursor: 'pointer'
                    }
                  }
                )
              ])
            } else {
              return h('span', '暂无画面')
            }
          }
        },
        status: { title: '状态', key: 'statusName', minWidth: 80, align: 'center' },
        remark: { title: '备注', key: 'remark', align: 'center', minWidth: 100, ellipsis: true, tooltip: true },
        createTime: { title: '提交时间', key: 'createTime', minWidth: 80, align: 'center' },
        operate: {
          title: '操作',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            var html = [
              h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.deviceId = params.row.deviceId
                    this.selectedResourceIdArray = [params.row.deviceId]
                    // 对上报资源进行设置
                    if (this.dataSource === 2) {
                      this.selectedFaultIdArray = [params.row.id]
                      this.$store.commit('set_cur_device_rapair_info', params.row)
                    }
                    this.curSetTaskComponent = ''
                    this.handleSetRepairTask()
                  }
                }
              }, '设置')
            ]
            if (this.dataSource === 2) {
              html.push(
                h('a', {
                  style: { color: '#f87676' },
                  on: {
                    click: () => {
                      this.selectedTroubleIds.push(params.row.id)
                      this.handleColoseTrouble()
                    }
                  }
                }, '关闭')
              )
            }
            return h('div', html)
          }
        },
        operate2: {
          title: '操作',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            var html = []
            if (this.dataSource === 2) {
              html.push(
                h('a', {
                  style: { color: '#f87676' },
                  on: {
                    click: () => {
                      this.selectedTroubleIds.push(params.row.id)
                      this.handleColoseTrouble()
                    }
                  }
                }, '关闭')
              )
            }
            return h('div', html)
          }
        }
      }

      const data = []
      var showColumns = this.demandTypeShowColumns.get(key)
      if (!showColumns || showColumns.length === 0) {
        showColumns = this.demandTypeShowColumns.get(5)
      }
      showColumns.forEach(col => data.push(tableColumnArray[col]))
      return data
    },
    initData () {
      this.tableData = {}
      if (this.dataSource === 1) {
        this.getTableData()
        this.tableColumns = this.getTableColumns(this.taskType)
      } else {
        this.getTroubleData()
        this.tableColumns = this.getTableColumns(this.taskType + '_2')
      }
      // if (this.taskType === 10) {
      //   // 画面填充获取数据
      //   this.query.assetIds = JSON.stringify([this.query.assetId])
      //   this.getPublishingTaskitemData()
      // } else if (this.taskType === 8) {
      //   this.getStationPageData()
      // } else {
      //   if (this.dataSource === 1) {
      //     this.getTableData()
      //   } else {
      //     this.getTroubleData()
      //     this.tableColumns = this.getTableColumns(this.taskType + '_2')
      //   }
      // }
    },
    getTableData () {
      // this.$store.dispatch('getResourceData', this.query)
      this.tableLoading = true
      this.query.taskId = this.curTaskId
      this.query.devicemodel = this.query.resourcetypeId
      this.query.enabled = 1
      getCompanyDevicePage(this.query).then(res => {
        if (res && !res.errcode) {
          this.tableData = res
          if (this.tableData.list) {
            this.tableData.list.forEach(item => {
              if (this.selectedResourceIdArray.some(x => x === item.id)) {
                item._checked = true
              }
            })
          }
        }
      }).finally(() => { this.tableLoading = false })
    },
    getTroubleData () {
      // this.$store.dispatch('getTroubleData', this.query)
      this.tableLoading = true
      this.query.devicemodel = null
      this.query.enabled = null
      getTroublePage(this.query).then(res => {
        this.tableData = res
        if (this.tableData.list) {
          this.tableData.list.forEach(item => {
            if (this.selectedResourceIdArray.some(x => x === item.deviceId)) {
              item._checked = true
            }
          })
        }
      }).finally(() => { this.tableLoading = false })
    },
    getStationPageData () {
      // this.$store.dispatch('getStationData', this.query)
      getStationPage(this.query).then(res => {
        this.tableData = res
        if (this.tableData.list) {
          this.tableData.list.forEach(item => {
            if (this.selectedResourceIdArray.some(x => x === item.deviceId)) {
              item._checked = true
            }
          })
        }
      })
    },
    // getPublishingTaskitemData () {
    //   // this.$store.dispatch('getPublishingTaskitemData', this.query)
    //   getPublishingTaskitemPage(this.query).then(res => {
    //     this.tableData = res
    //     if (this.tableData.list) {
    //       this.tableData.list.forEach(item => {
    //         if (this.selectedResourceIdArray.some(x => x === item.deviceId)) {
    //           item._checked = true
    //         }
    //       })
    //     }
    //   })
    // },
    /**
     * 切换数据来源
     */
    handleChangeData () {
      this.query.pageNumber = 1
      this.query.pagesize = 15
      this.query.stationId = null
      this.query.position = null
      this.query.resourcetypeId = null
      this.query.devicemodel = null
      this.query.keyword = ''
      this.selectedResourceIdArray = []
      this.tempSelectedResourceIds = []
      this.initData()
    },
    handleChangeAsset () {
      this.query.pageNumber = 1
      // this.$store.commit('set_selected_assetId', this.query.assetId)
      this.selectedResourceIdArray = []
      this.tempSelectedResourceIds = []
      this.getStationData()
      this.getPositionData()
      this.getResourceTypeData()
      this.initData()
    },
    getStationData () {
      getRimStationByAssetIds({ assetIds: JSON.stringify([this.query.assetId]) }).then(res => {
        if (res && !res.errcode) {
          this.stationArray = res
        } else {
          this.stationArray = []
        }
      })
    },
    getPositionData () {
      const assetIds = []
      assetIds.push(this.query.assetId)
      const data = {
        assetIds: JSON.stringify(assetIds)
      }
      getPositions(data).then(res => {
        if (res && !res.errcode) {
          this.positionArray = res
        } else {
          this.positionArray = []
        }
      })
    },
    getResourceTypeData () {
      getDeviceModelQuantity({ assetId: this.query.assetId }).then(res => {
        if (res && !res.errcode) {
          this.resourceTypeArray = res
        } else {
          this.resourceTypeArray = []
        }
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.selectedResourceIdArray = []
      this.tempSelectedResourceIds = []
      this.initData()
    },
    changePageSize (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.selectedResourceIdArray = []
      this.tempSelectedResourceIds = []
      this.initData()
    },
    changePage (curPage) {
      this.query.pageNumber = curPage
      this.initData()
    },
    handleSelection (selection) {
      let relateIds = []
      if (this.curDemandType === 8) {
        relateIds = selection.map(x => x.stationId)
      } else {
        relateIds = selection.map(x => x.deviceId)
      }
      this.handelPageSelections(this.tempSelectedResourceIds, relateIds, this.query.pageNumber)
      // 最终选中的所有taskItemIds
      this.selectedResourceIdArray = []
      this.tempSelectedResourceIds.forEach(item => {
        this.selectedResourceIdArray = this.selectedResourceIdArray.concat(item.existSelections)
      })
    },
    handleAddNewTask () {
      if (this.taskType === 10) {
        // this.$store.commit('set_left_component', 'SetFillFile')
        this.curSetTaskComponent = 'SetFillFile'
      } else {
        // this.$store.commit('set_left_component', 'SetMaintainTask')
        this.curSetTaskComponent = 'SetMaintainTask'
      }
      this.setTaskModal = true
      // this.$store.commit('set_show_left_block', true)
    },
    handleSetRepairTask () {
      // this.$store.commit('set_left_component', 'SetRepairTask')
      // this.$store.commit('set_show_left_block', true)
      this.setTaskModal = true
      this.curSetTaskComponent = 'SetRepairTask'
    },
    handleSubmitTask () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要提交已设置任务？',
        onOk: () => {
          submit({ taskId: this.query.taskId }).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    /**
     * 关闭上报资源
     */
    handleColoseTrouble () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要关闭所选上报资源？',
        onOk: () => {
          closeTrouble({ troubleIds: JSON.stringify(this.selectedTroubleIds) }).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.initData()
            }
          })
        }
      })
    },
    handleSetSuccess () {
      this.setTaskModal = false
      this.selectedTroubleIds = []
      this.selectedResourceIdArray = []
      this.selectedFaultIdArray = []
      this.tempSelectedResourceIds = []
      this.initData()
      // 调用任务列表页面获取数据接口
      this.$emit('handleSetSuccess')
    }
  },
  watch: {
    taskId: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val) {
          this.curTaskId = val
          this.getCompanyAssetArray()
        }
      }

    },
    setTaskModal (val) {
      if (!val) {
        this.selectedResourceIdArray = []
        this.deviceId = null
        this.curSetTaskComponent = ''
      }
    },
    // taskType: {
    //   deep: true,
    //   immediate: true,
    //   handler (val) {
    //     if (val) {
    //       this.curTaskType = val
    //     }
    //   }

    // },
    companyAssetArray: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val && val.length) {
          this.query.assetId = val[0].id
          this.query.stationId = null
          this.query.pageNumber = 1
          this.query.position = null
          this.query.devicemodel = null
          this.query.resourcetypeId = null
          this.query.keyword = ''
          // this.$store.commit('set_selected_assetId', this.query.assetId)
          this.getStationData()
          this.getPositionData()
          this.getResourceTypeData()
          this.initData()
        }
      }
    }
  }
}
</script>
