<template>
  <div>
    <div style="margin-top: 15px" v-for="(sc, i) in schedules" :key="i">
      <div v-for="product in sc" :key="product.goodId">
        <Row>
          <i-col span="4">
            {{ getSchedule(sc) }}
          </i-col>
          <i-col span="4">{{ product.goodName }}</i-col>
          <i-col span="4">
            {{ product.signNormalQuantity }} {{ product.productUnit }}
          </i-col>
          <i-col span="4">
            {{ formatMoney(product.normalUsePrice) }} ({{
              product.usePriceDiscount
            }})
          </i-col>
          <i-col span="4">
            <InputNumber
              v-model="product.signNormalPrice"
              @on-blur="invalidSellNumber(product)"
              :min="0"
              size="small"
            ></InputNumber>
          </i-col>
          <i-col span="4">
            <InputNumber
              @on-change="changeSellSales($event, product)"
              :value="
                computedSale(product.normalUsePrice, product.signNormalPrice)
              "
              :min="0"
              size="small"
            ></InputNumber>
            折
          </i-col>
        </Row>

        <Divider
          dashed
          style="margin: 5px 0"
          v-show="
            hasLimit && product.signNormalPrice < product.normalSettlementPrice
          "
        >
          <small class="text-red"
            >产品执行价不能小于
            {{ formatMoney(product.normalSettlementPrice) }}</small
          >
        </Divider>
      </div>
    </div>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { GetCurrentSchedule } from '@/utils/dateFormat'

export default {
  props: {
    schedules: {
      type: Array,
      required: true
    },
    hasLimit: {
      type: Boolean,
      default: false
    },
    fixProtect: Boolean
  },
  data () {
    return {
    }
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },

    invalidSellNumber (produce) {
      if (!produce.signNormalPrice || produce.signNormalPrice === '') {
        produce.signNormalPrice = 0
      }
    },
    getSchedule (products) {
      if (products.length > 0) {
        const product = products[0]
        return GetCurrentSchedule(product.startDate, product.endDate)
      }
    },
    changeSellSales (val, produce) {
      produce.signNormalPrice = Number(
        ((produce.normalUsePrice * val) / 10).toFixed(2)
      )
    },
    computedSale (usePrice, sellPrice) {
      if (usePrice > 0) {
        const discount = (sellPrice / usePrice) * 10
        const showDisCount = parseFloat(discount.toFixed(2))
        return showDisCount
      } else {
        return ''
      }
    }
  },
  watch: {
    schedules: {
      // 监控档期费用录入后， 产生的变化。 直接提交到父窗体
      deep: true,
      handler: function () {
        let products = []
        this.schedules.forEach((x) => {
          products = products.concat(
            x.map((product) => {
              product.normalSignQuantity = product.normalQuantity
              product.fixProtectSignPrice = this.fixProtect
                ? product.signNormalPrice * product.fixProtectRate
                : product.fixProtectSignPrice // 定位保护费
              product.industryProtectSignPrice =
                product.signNormalPrice * product.industryProtectRate
              product.breakScheduleSignPrice =
                product.signNormalPrice * product.breakScheduleRate
              product.monitorPrice = product.totalMonitorPrice // 监播是固定的费率
              return product
            })
          )
        })

        if (this.hasLimit) {
          const settled = this.schedules.every((sc) =>
            sc.every((x) => x.signNormalPrice >= x.normalSettlementPrice)
          )
          this.$emit('on-settled', { products: products, settled: settled })
        } else {
          this.$emit('on-settled', { products: products, settled: true })
        }
      }
    }
  },
  created () {
    const settled = this.schedules.every((sc) =>
      sc.every((x) => x.signNormalPrice >= x.normalSettlementPrice)
    )
    let products = []
    this.schedules.forEach((sc) => {
      products = products.concat(
        sc.map((product) => {
          product.normalSignQuantity = product.normalQuantity
          return product
        })
      )
    })

    if (this.hasLimit) {
      this.$emit('on-settled', { products: products, settled: settled })
    } else {
      this.$emit('on-settled', { products: products, settled: true })
    }
  }
}
</script>

<style>
.serviceExample {
  padding: 5px;
  border: 1px solid #5cadff;
  border-radius: 3px;
  color: #808695;
  font-weight: 400;
  position: absolute;
  background-color: papayawhip;
  z-index: 999;
  width: 130px;
}
</style>
