import { getmspSerialsInfo } from '@/api/msp/demand'
import { getSerialsInfo } from '@/api/order/demand'
export const setScreenMixins = {
  data () {
    return {
      imageFileArray: [],
      stairSetInfo: {},
      selectedFileArray: [], // 已选择设置文件集合
      selectedStairIds: [],
      currSelectedFile: {}, // 当前选中的文件
      stairIdArray: [],
      setFileIdArray: [], // 用于记录已设置画面id集合
      tempFileArray: [] // 临时存储素材数据
    }
  },
  methods: {
    formatImageUrl (fileUrl, width, height) {
      const widthstr = (width === 0) ? '' : ',w_' + width
      const heightstr = (height === 0) ? '' : ',h_' + height

      return fileUrl + '?x-oss-process=image/resize,m_lfit' + widthstr + heightstr
    },
    /**
     * 切换上传日期事件
     */
    changeFileDate () {
      this.imageFileArray = this.agreeFileArray.filter(x => [1, 3, 4].includes(x.type) && x.createTime.includes(this.fileDate)).map(item => {
        item.quantity = 0
        return item
      })
    },
    /**
     * 梯牌设置界面，切换画面事件
     */
    handleChoiceFile (file) {
      // if ([28].includes(this.selectedProductId)) {
      //   // PIS屏
      //   this.$Notice.warning({ desc: '该产品暂不支持画面选择' })
      //   return false
      // }
      // 平面媒体产品
      if ((this.setOperatingType === 1 && this.selectedResourceIds.length === 0)) {
        this.$Notice.warning({ desc: '请先选择一个资源' })
        return false
      }

      this.currSelectedFile = file
      const existFile = this.selectedFileArray.find(x => x.fileId === file.id)
      this.selectedStairIds = existFile ? existFile.serials : []
      // 是否在已设置数据中
      if (!existFile) {
        const obj = {
          fileId: file.id,
          fileKey: file.fileKey,
          filePath: file.filePath,
          fileName: file.realFileName,
          mimeType: file.mimeType,
          serials: this.selectedStairIds
        }
        this.selectedFileArray.push(obj)
      } else {
        existFile.fileKey = file.fileKey
        existFile.filePath = file.filePath
      }

      // 获取当前选中文件以外的所有已勾选编号
      var otherFileStairIds = []
      this.selectedFileArray.forEach(item => {
        if (item.fileId !== file.id) {
          otherFileStairIds = otherFileStairIds.concat(item.serials)
        }
      })
      // 当前选中文件以外勾选的编号禁用
      this.stairIdArray.forEach(item => {
        item.disabled = false
        if (otherFileStairIds.some(x => x === item.serial)) {
          item.disabled = true
        }
      })

      // 临时存储当前选择日期的素材数据
      this.imageFileArray.forEach(item => {
        if (!this.tempFileArray.some(x => x.id === item.id)) {
          this.tempFileArray.push(item)
        }
      })
    },
    handleChangeStair () {
      // 当前选中文件已勾选编号赋值给选中文件集合所对应的项
      this.selectedFileArray.find(x => x.fileId === this.currSelectedFile.id).serials = this.selectedStairIds
    },
    /**
     * 梯牌全选事件
     */
    handelSelectAll () {
      // 筛选出当前可用序列号
      const availableSerialArray = this.stairIdArray.filter(x => !x.disabled)
      if (availableSerialArray.length === this.selectedStairIds.length) {
        this.selectedStairIds = []
      } else {
        availableSerialArray.forEach(item => {
          if (!this.selectedStairIds.some(x => x === item.serial)) {
            this.selectedStairIds.push(item.serial)
          }
        })
      }
      this.selectedFileArray.find(x => x.fileId === this.currSelectedFile.id).serials = this.selectedStairIds
    },
    handlePreview (file) {
      this.currentViewFile = file
      this.visibleImage = true
    },
    /**
     * 获取梯牌序号信息
     */
    getStairSerialsArray () {
      if (this.settingType === 'demand') {
        const params = {
          // resourceIds: JSON.stringify(this.selectedResourceIds),
          taskitemId: JSON.stringify(this.selectedTaskitemIds)
        }
        // this.selectedFileArray = []
        getmspSerialsInfo(params).then(res => {
          if (res && !res.errcode) {
            this.stairSetInfo = res
            this.stairIdArray = res.serialsList
          } else {
            this.stairSetInfo = {}
            this.selectedFileArray = []
            this.stairIdArray = []
          }
        })
      } else {
        const params = {
          // resourceIds: JSON.stringify(this.selectedResourceIds),
          orderitemDetailIds: JSON.stringify(this.selectedTaskitemIds)
        }
        // this.selectedFileArray = []
        getSerialsInfo(params).then(res => {
          if (res && !res.errcode) {
            this.stairSetInfo = res
            this.stairIdArray = res.serialsList
          } else {
            this.stairSetInfo = {}
            this.selectedFileArray = []
            this.stairIdArray = []
          }
        })
      }
    }
  },
  watch: {
    selectedResourceIds: {
      deep: true,
      immediate: true,
      handler (val) {
        // if (this.selectedProductId === 6) {
        if (val && val.length) {
          this.getStairSerialsArray()
          this.btnDisabled = false
        } else {
          this.btnDisabled = true
          this.currSelectedFile = null
          this.stairIdArray = []
          this.selectedStairIds = []
          this.selectedFileArray = []
        }
        // }
      }
    },
    agreeFileArray: {
      deep: true,
      immediate: true,
      handler (val) {
        // this.fileDateArray = []
        // this.fileDate = []
        // this.imageFileArray = []
        // this.fileDate = ''
        if (val && val.length) {
          // 处理画面上传日期
          if (this.fileCreateDate) {
            this.fileDate = this.fileCreateDate
            // 处理显示画面
            this.imageFileArray = val.filter(x => [1, 3, 4].includes(x.type)).map(item => {
              item.quantity = 0
              return item
            })
          } else {
            val.forEach(item => {
              if (this.fileDateArray.indexOf(item.createTime.substring(0, 10)) === -1) {
                this.fileDateArray.push(item.createTime.substring(0, 10))
              }
            })
            if (!this.isPage) {
              this.fileDate = this.fileDate || this.fileDateArray[0]
            }

            // 处理显示画面
            this.imageFileArray = val.filter(x => [1, 3, 4].includes(x.type) && x.createTime.includes(this.fileDate)).map(item => {
              item.quantity = 0
              return item
            })
          }
          // 临时存储浏览过的素材数据
          this.imageFileArray.forEach(item => {
            if (!this.tempFileArray.some(x => x.id === item.id)) {
              this.tempFileArray.push(item)
            }
          })
        } else {
          this.fileDateArray = []
          this.imageFileArray = []
          this.fileDate = []
          if (!this.isPage) {
            this.currSelectedFile = null
            this.selectedFileArray = []
          }
        }
      }
    },
    selectedFileArray: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val && val.length) {
          const serials = val.filter(x => x.serials.length)
          serials.forEach(item => {
            if (!this.setFileIdArray.some(x => x === item.fileId)) {
              this.setFileIdArray.push(item.fileId)
            }
          })
        } else {
          this.setFileIdArray = []
        }
      }
    }
  }
}
