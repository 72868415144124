<template>
  <div>

    <div class="platform-buttom-list">
      <div
        :class="tagType===1? 'bottom-button-active':'bottom-button'"
        @click="handleChangeTag(1)"
      >设备列表</div>
      <div
        :class="tagType===2? 'bottom-button-active':'bottom-button'"
        @click="handleChangeTag(2)"
      >资源列表</div>
    </div>

    <component :is="currentComponent" :projectArray="projectArray"></component>

  </div>
</template>

<script>
export default {
  props: {
    projectArray: [Array]
  },
  components: {
    DeviceList: () => import('./DeviceList.vue'),
    MediumList: () => import('./MediumList.vue')
  },
  data () {
    return {
      tagType: 1,
      currentComponent: 'DeviceList'
    }
  },
  methods: {
    handleChangeTag (tag) {
      this.tagType = tag
      switch (this.tagType) {
        case 1:
          this.currentComponent = 'DeviceList'
          break
        case 2:
          this.currentComponent = 'MediumList'
          break
      }
    }
  }
}
</script>
