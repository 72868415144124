import { toMoney } from '@/utils/wnumb_own'
import { allCateory } from '@/api/product/category'
import { getByCategory } from '@/api/product/product'
export const projectProductMxins = {
  data () {
    return {
      list: [],
      total: 0,
      tableLoading: false,
      typeArray: [
        { value: 1, name: '常规媒体' },
        { value: 2, name: '主题媒体' },
        { value: 3, name: '套装媒体' }
      ],
      enabledArray: [
        { value: 1, name: '可用' },
        { value: 0, name: '不可用' }
      ],
      categoryArray: [],
      productArray: [],
      tableColumns: [],
      showTableColumns: new Map([
        [1, ['selection', 'id', 'name', 'fee', 'fee28', 'statementPrice', 'trainProduceCost', 'asset', 'level', 'enabled', 'minday', 'bufferDay']], // 常规媒体
        [2, ['selection', 'id', 'name', 'fee', 'fee28', 'statementPrice', 'trainProduceCost', 'asset', 'enabled', 'minday', 'bufferDay']], // 主题媒体
        [3, ['selection', 'id', 'name', 'fee', 'fee28', 'statementPrice', 'asset', 'enabled', 'minday']] // 套装媒体
      ])
    }
  },
  methods: {
    getTableColumns (mediaType = 1) {
      const talbeColumns = {
        selection: { type: 'selection', width: 60, align: 'center' },
        id: { title: '主键', minWidth: 80, align: 'center', width: '80', key: 'id' },
        name: { title: '名称', minWidth: 100, align: 'center', key: 'name' },
        fee: {
          renderHeader: (h) => {
            return h('span', this.type === 1 ? '媒介使用费（块/天）' : '媒介使用费（套/天）')
          },
          minWidth: 100,
          align: 'center',
          key: 'usePrice',
          render: (h, params) => {
            return h('span', {
              attrs: {
                class: 'money'
              }
            }, toMoney(params.row.usePrice))
          }
        },
        fee28: {
          renderHeader: (h) => {
            return h('span', this.type === 1 ? '媒介使用费（4周）' : '媒介使用费（4周）')
          },
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            return h('span', {
              attrs: {
                class: 'money'
              }
            }, toMoney(params.row.usePrice * 28))
          }
        },
        statementPrice: {
          renderHeader: (h) => {
            return h('span', this.type === 1 ? '最低限价（块/天）' : '最低限价（套/天）')
          },
          align: 'center',
          minWidth: 100,
          key: 'usePrice',
          render: (h, params) => {
            return h('span', {
              attrs: {
                class: 'money'
              }
            }, toMoney(params.row.statementPrice))
          }
        },
        trainProduceCost: {
          title: '服务费',
          align: 'center',
          minWidth: 160,
          key: 'produceCost',
          render: (h, params) => {
            if (params.row.producePrice && params.row.producePrice.producePrices.length) {
              const html = []
              params.row.producePrice.producePrices.forEach(item => {
                html.push(h('p', {
                  attrs: {
                    class: 'money'
                  }
                }, `${item.name}-刊例价：${toMoney(item.produceCost)}/${item.unitName}-结算价：${toMoney(item.produceStatement)}/${item.unitName}`))
              })
              return html
            } else {
              return h('span', {
                attrs: {
                  class: 'money'
                }
              }, '/')
            }
          }
        },
        produceCost: {
          title: '媒介服务费（套/次）',
          align: 'center',
          minWidth: 100,
          key: 'produceCost',
          render: (h, params) => {
            return h('span', {
              attrs: {
                class: 'money'
              }
            }, toMoney(params.row.produceCost))
          }
        },
        asset: {
          title: '所属资产',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            const html = []
            params.row.assets.forEach(item => {
              html.push(h('p', item.name))
            })
            return h('div', html)
          }
        },
        level: { title: '等级', minWidth: 100, align: 'center', key: 'levelName' },
        enabled: {
          title: '是否可用',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            return h('span', params.row.enabled ? '可用' : '不可用')
          }
        },
        minday: { title: '最小连续售卖天数', minWidth: 100, align: 'center', key: 'minday' },
        bufferDay: { title: '施工缓冲期（天）', minWidth: 100, align: 'center', key: 'bufferDay' }
      }
      const data = []
      var showColumns = this.showTableColumns.get(mediaType)
      if (!showColumns || showColumns.length === 0) {
        showColumns = this.showTableColumns.get(1)
      }
      showColumns.forEach(col => data.push(talbeColumns[col]))
      this.tableColumns = data
    },
    /**
   * 获取类别数据
   */
    getCategoryData () {
      this.categoryArray = []
      allCateory({ type: this.query.type }).then(res => {
        if (res && !res.errcode) {
          this.categoryArray = res
          if (this.query.type !== 1) {
            this.query.categoryId = this.categoryArray.length ? this.categoryArray[0].id : null
            this.getProductData(this.query.categoryId)
          }
        }
      })
    },
    /**
    * 获取产品数据
    * @param {Object} params
    * @returns
    */
    getProductData (params) {
      if (!params) {
        return false
      }

      getByCategory({ categoryId: params }).then(res => {
        if (res && !res.errcode) {
          this.productArray = res
          // if (params.categoryId === 7) {
          //   // 套装媒体不执行
          //   this.$store.commit('set_product_array', [])
          // } else {
          //   // 非套装媒体不执行
          //   this.$store.commit('set_product_array', this.productArray)
          // }
        }
      })
    },
    handleChangeCategory () {
      if (!this.query.categoryId) {
        this.productArray = []
      } else {
        this.getProductData({ categoryId: this.query.categoryId })
      }
    }
  }

}
