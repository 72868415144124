<template>
    <div class="p-t-5 p-l-5">
        <Divider dashed style="margin: 10px 0 5px 0;"><span class="divider-text">数据汇总</span></Divider>
        <div >
          <div class="m-t-10">
              <p class="title">结算公司</p>
             {{statisticsData.purchaserCompanyName}}
          </div>
          <div class="m-t-10">
              <p class="title">供应商</p>
              {{statisticsData.supplierCompanyName}}
          </div>

        </div>
        <Row class=" m-t-10 p-t-5 p-b-5 ">
            <i-col span="24" class="text-16 p-b-10">
               成本总金额<p class="text-orange">{{formatMoney(statisticsData.settledAmount+statisticsData.unsettledAmount)}}</p>
            </i-col>
            <i-col span="12">
                <p class="title">已结算金额</p>
                {{formatMoney(statisticsData.settledAmount)}}
            </i-col>
            <i-col span="12">
                <p class="title">未结算金额</p>
                {{formatMoney(statisticsData.unsettledAmount)}}
            </i-col>
            <i-col span="12">
                <p class="title">已结算数量</p>
                {{statisticsData.settledQuantity}}
            </i-col>
            <i-col span="12">
                <p class="title">未结算数量</p>
                {{statisticsData.unsettledQuantity}}
            </i-col>
        </Row>

    </div>
</template>

<script>
import { getMspBillSummaryStatistics } from '@/api/statement/mspBillDetail'
import { toMoney } from '@/utils/wnumb_own'

export default {
  data () {
    return {
      query: {
        startDate: '',
        endDate: '',
        status: null,
        keyword: ''
      },
      statisticsData: {}
    }
  },
  created () {
    // this.initPageData()
  },
  methods: {
    initPageData () {
      getMspBillSummaryStatistics(this.query).then(res => {
        this.statisticsData = res
      })
    },
    formatMoney (number) {
      return toMoney(number)
    }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.supplierStore.beginUpdate
    },
    maintenanceReconciliationQuery () {
      return this.$store.state.supplierStore.maintenanceReconciliationQuery
    }
  },
  watch: {
    beginUpdate () {
      if (this.maintenanceReconciliationQuery.supplierCompanyId) {
        this.query.supplierCompanyId =
          this.maintenanceReconciliationQuery.supplierCompanyId // 供应商

        this.query.purchaserCompanyId =
          this.maintenanceReconciliationQuery.purchaserCompanyId // 结算公司
        this.query.startDate = this.maintenanceReconciliationQuery.startDate // 开始时间
        this.query.endDate = this.maintenanceReconciliationQuery.endDate // 结束时间

        this.query.mspTimeType =
          this.maintenanceReconciliationQuery.mspTimeType // 时间类型

        this.query.keyword = this.maintenanceReconciliationQuery.keyword // 关键字
        this.query.minChargeAmount = this.maintenanceReconciliationQuery.minChargeAmount // 最小计费金额
        this.query.maxChargeAmount = this.maintenanceReconciliationQuery.maxChargeAmount // 最大计费金额

        this.query.mspBillCategory =
          this.maintenanceReconciliationQuery.mspBillCategory // 成本账单费用类型

        this.query.billStatus =
          this.maintenanceReconciliationQuery.billStatus === -1
            ? null
            : this.maintenanceReconciliationQuery.billStatus // 计费项类型(-1:全部,1:未结算,2:已结算)

        this.query.taskTypeList =
          this.maintenanceReconciliationQuery.taskTypeList // 作业类型

        this.query.resourceTypeIdList =
          this.maintenanceReconciliationQuery.resourceTypeIdList // 资源类型
        this.query.taskAdTypeList = this.maintenanceReconciliationQuery.taskAdTypeList // 用途
      }

      this.initPageData()
    }
  }
}
</script>
