<template>
  <div>
    <Modal v-model="packageModal" width="800">
      <p slot="header" class="text-center">查看资产包</p>
      <div class="m-b-10">
        <h4 class="workplatform-title m-t-10 m-b-10">已有资产包</h4>
        <template v-if="comPackagelist.length">
          <Tag color="primary" class="m-r-5 m-b-5" v-for="item in comPackagelist" :key="'pacakge_' + item.id">{{
            item.name
            }}</Tag>
        </template>
        <template v-else>
          <Tag color="default">暂无资产包</Tag>
        </template>
      </div>
      <div>
        <h4 class="workplatform-title m-t-10 m-b-10">配置资产包</h4>
        <!-- <div class="m-b-10">
          <Button size="small" type="primary" @click="onUpdate">更新资产包</Button>
        </div> -->
        <Table stripe max-height="620" size="small" :data="list" :columns="tableColumns" :loading="tableLoading"
          @on-selection-change="onSelection">
        </Table>
      </div>
      <div slot="footer">
        <Button type="text" @click="packageModal = false">关闭窗口</Button>
        <Button type="primary" @click="onUpdate">确认更新资产包</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getCompanyPackageList, getPackageList, updateCompanyPackage } from '@/api/product/resource'
export default {
  props: {
    companyId: {
      type: Number,
      required: true
    },
    projectId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      packageModal: false,
      tableLoading: false,
      comPackagelist: [],
      list: [],
      tableColumns: [
        { type: 'selection', width: 60, align: 'center' },
        { title: '资产包名称', align: 'center', key: 'name' },
        { title: '备注', align: 'center', key: 'desc' }
      ],
      selectedItems: []
    }
  },
  methods: {
    showModal () {
      this.getComAssetPackageData()
      this.packageModal = true
    },
    async getComAssetPackageData () {
      const result = await getCompanyPackageList({ companyId: this.companyId })
      this.comPackagelist = (result && !result.errcode) ? result : []
      if (this.comPackagelist.length) {
        this.selectedItems = this.comPackagelist.map(x => x.id)
      }
      this.getAllAssetPackageData()
    },
    async getAllAssetPackageData () {
      this.tableLoading = true
      const result = await getPackageList({ projectId: this.projectId }).finally(() => { this.tableLoading = false })
      this.list = (result && !result.errcode) ? result : []
      this.list.forEach(item => {
        if (this.comPackagelist.some(x => x.id === item.id)) {
          item._checked = true
        }
      })
    },
    onSelection (selection) {
      this.selectedItems = selection.map(x => x.id)
    },
    onUpdate () {
      this.$Modal.confirm({
        title: '操作提示',
        content: `当前选中${this.selectedItems.length}项，确定要更新资产包？`,
        onOk: async () => {
          const postData = {
            companyId: this.companyId,
            packageIds: JSON.stringify(this.selectedItems)
          }
          const result = await updateCompanyPackage(postData)
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.getComAssetPackageData()
            // this.packageModal = false
          }
        }
      })
    }
  }
}
</script>
