<style lang="less">
.selectedFile {
  border: 2px solid #ff9900;
}
.file_text .ivu-input {
  color: #aaa;
}
</style>
<template>
  <div>
    <Divider
      dashed
      style="color:#fff"
    >设置上刊内容</Divider>
    <div>
      <h4 class="workplatform-title m-t-10 m-b-20">作业日期</h4>
      <DatePicker
        placeholder="选择作业日期"
        size="small"
        v-model="actionDate"
        type="date"
        style="width:100%"
        :editable="false"
        :clearable="false"
        @on-change="changeActionDate"
      ></DatePicker>
    </div>
    <!-- <div class="p-b-10">
      <h4 class="workplatform-title m-t-10 m-b-20">素材筛选</h4>
      <RadioGroup
        v-model="fileSource"
        size="small"
        type="button"
        @on-change="getAgreeFileData"
      >
        <Radio
          v-for="item in fileSourceArray"
          :key="'fs_'+item.value"
          :label="item.value"
        >{{item.name}}</Radio>
      </RadioGroup>
    </div> -->
    <set-screen
      :agreeFileArray="agreeFileArray"
      :selectedResourceIds="selectedResourceIds"
      :curTaskId="curTaskId"
      :selectedProductId="selectedProductId"
      :selectedTaskitemIds="selectedTaskIds"
      :setOperatingType="setOperatingType"
      :btnSetLoading="btnSetLoading"
      :selectedStMediaAttr="selectedStMediaAttr"
      :settingType="'install'"
      @parentMethod="handelSubmit"
    ></set-screen>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { sysMixins } from '@/assets/mixins/sys'
import { agreeFile } from '@/api/order/orderfile'
import { setOrderTaskitemFile } from '@/api/msp/demandV3'
import { updateTaskitemFile } from '@/api/order/demand'

import SetScreen from '@/components/task/SetScreen'
export default {
  mixins: [sysMixins],
  components: {
    SetScreen
  },
  data () {
    return {
      // allFileArray: [],
      query: {
        approvalDate: '',
        orderId: null,
        pageNumber: 1,
        pageSize: 200,
        types: JSON.stringify([1, 3, 4]), // 画面、视频、音频
        advertiserId: null,
        brandId: null
      },
      agreeFileArray: [],
      fileDateArray: [],
      imageFileArray: [],
      actionDate: '',
      fileSource: 1,
      // fileSourceArray: [
      //   { value: 1, name: '当前订单素材' },
      //   { value: 2, name: '素材库' }
      // ],
      btnSetLoading: false
    }
  },
  computed: {
    ...mapState({
      demandOrderId: state => state.installSetting.demandOrderId,
      orderInfo: state => state.installSetting.orderInfo,
      // demandStartDate: state => state.installSetting.demandStartDate,
      // demandEndDate: state => state.installSetting.demandEndDate,
      selectedProductId: state => state.installSetting.selectedProductId,
      setOperatingType: state => state.installSetting.setOperatingType,
      // selectedTaskStatus: state => state.installSetting.selectedTaskStatus,
      // demandStatus: state => state.installSetting.demandStatus,
      curTaskId: state => state.installSetting.currentTaskId
    }),
    selectedResourceIds: {
      get () {
        return this.$store.state.installSetting.selectedResourceIds
      },
      set (val) {
        this.$store.commit('set_selected_resourceIds', val)
      }
    },
    selectedTaskIds: {
      get () {
        return this.$store.state.installSetting.selectedTaskIds
      },
      set (val) {
        this.$store.commit('set_selected_taskIds', val)
      }
    },
    selectedStMediaAttr: {
      get () {
        return this.$store.state.installSetting.selectedStMediaAttr
      },
      set (val) {
        this.$store.commit('set_selected_stmedia_attr', val)
      }
    }
  },
  created () {
    this.getAgreeFileData()
    // this.getRemarkArray()
  },
  methods: {
    /**
     * 获取审批通过的文件
     */
    getAgreeFileData () {
      // switch (this.fileSource) {
      //   case 1:
      //     this.query.orderId = this.orderInfo.id
      //     this.query.advertiserId = null
      //     this.query.brandId = null
      //     break
      //   case 2:
      //     this.query.orderId = null
      //     this.query.advertiserId = this.orderInfo.advertiserId
      //     this.query.brandId = this.orderInfo.brandId
      //     break

      //   default:
      //     this.query.orderId = this.orderInfo.id
      //     this.query.advertiserId = null
      //     this.query.brandId = null
      //     break
      // }
      this.query.orderId = this.orderInfo.id

      agreeFile(this.query).then(res => {
        if (res && !res.errcode) {
          // this.allFileArray = JSON.parse(JSON.stringify(res))
          this.agreeFileArray = res.list

          // 处理画面上传日期
          this.agreeFileArray.forEach(item => {
            if (this.fileDateArray.indexOf(item.createTime.substring(0, 10)) === -1) {
              this.fileDateArray.push(item.createTime.substring(0, 10))
            }
          })
          this.fileDate = this.fileDateArray.sort()[0]
          // 处理显示画面
          this.imageFileArray = this.agreeFileArray.filter(x => x.createTime.includes(this.fileDate)).map(item => {
            item.quantity = 0
            return item
          })
        } else {
          this.agreeFileArray = []
          this.imageFileArray = []
        }
      })
    },
    changeActionDate (date) {
      this.actionDate = date
    },
    handelSubmit (data) {
      if (!this.actionDate) {
        this.$Notice.error({ desc: '请选择作业日期' })
        return false
      }

      if (data && data instanceof Object) {
        this.btnSetLoading = true
        // data.taskId = this.curTaskId
        data.demandMainId = this.curTaskId
        data.actionDate = this.actionDate

        if (this.setOperatingType === 1) {
          setOrderTaskitemFile(data).then(res => {
            this.btnSetLoading = false
            if (res && res.errcode === 0) {
              this.handleSubmitSuccess()
            }
          })
        } else {
          updateTaskitemFile(data).then(res => {
            this.btnSetLoading = false
            if (res && res.errcode === 0) {
              this.handleSubmitSuccess()
            }
          })
        }
      } else {
        this.$Notice.error({ desc: '非法请求' })
      }
    },
    /**
     * 处理成功提交后事件
     */
    handleSubmitSuccess () {
      // 更新store中数据
      this.selectedResourceIds = []
      this.selectedTaskIds = []
      this.$store.commit('set_temp_selected_item', null)

      // Object.assign(this.$data.demandData, this.$options.data().demandData)

      // 调用列表数据获取函数
      if (this.setOperatingType === 1) {
        this.$store.dispatch('getTaskDetailData')
        // 调用右侧数据统计函数
        this.$store.dispatch('getOrderProductArray')
      } else {
        this.$store.dispatch('getTaskitemData')
      }

      this.$Notice.success({ desc: '操作成功' })
    }

  }

}
</script>
