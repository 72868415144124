<style lang="less" scoped>
.selectedFile {
  border: 2px solid #ff9900;
}

.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.demo-spin-col {
  height: 100px;
  position: relative;
  border: 1px solid #eee;
}
</style>
<template>
  <div>
    <!-- 画面设置界面 -->
    <div class="p-b-10">
      <div class="p-b-10">
        <h4 class="workplatform-title m-t-10 m-b-20">素材上传日期</h4>
        <slot name="pictureDate">
          <Select v-model="fileDate" size="small" filterable placeholder="选择素材上传日期" @on-change="changeFileDate">
            <Option v-for="item in fileDateArray" :key="'f_' + item" :value="item">{{ item }}</Option>
          </Select>
        </slot>

      </div>
      <div class="p-b-10">
        <h4 class="workplatform-title m-t-10 m-b-20">上刊素材（只可单选{{ !isSetFiles ? '，非必填' : '' }}）</h4>
        <div class="p-b-10">
          <p class="text-orange p-b-10 text-14">提示：</p>
          <p v-if="isSetFiles" class="text-14">请先选择一项素材，您所选素材需要设置<span class="text-orange">素材上刊名称</span>，才可进⾏下⼀步，设置<span
              class="text-orange">素材上刊名称</span>以保证运维<span class="text-orange">准确上刊</span></p>
          <p v-else class="text-14">当前选择任务，设置素材为<span class="text-orange">非必填</span>选项</p>
        </div>
        <div class="p-b-10" v-if="setFileIdArray.length">
          <label>已设置素材编号：{{ setFileIdArray.toString() }}</label>
        </div>
        <Row :gutter="8">
          <template v-if="imageFileArray.length > 0">
            <i-col span="8" class="p-b-10" v-for="file in imageFileArray" :key="'file_' + file.id">
              <a href="javascript:;" @click="handleChoiceFile(file)">
                <img v-if="file.mimeType.includes('image')" :src="formatImageUrl(file.fileKey, 200, 0)"
                  :class="currSelectedFile && currSelectedFile.id === file.id && ![28].includes(selectedProductId) ? 'selectedFile' : ''"
                  style="width:100%;height:80px">
                <Card v-if="file.mimeType.includes('video') || file.mimeType.includes('audio')"
                  :class="currSelectedFile && currSelectedFile.id === file.id && ![28].includes(selectedProductId) ? 'selectedFile' : ''"
                  style="word-break: break-all;word-wrap: break-word;height:80px;width:100%;">
                  {{ file.fileName }} (<span class="text-orange">{{ file.duration }}</span>秒)
                </Card>
              </a>
              <div class="text-center">
                <a @click="handleViewImage(file)">预览素材</a>
              </div>
              <div>
                <Label>编号：{{ file.id }}</Label>
                <Input size="small" style="width:100%" v-model="file.realFileName" placeholder="文件名称" />
              </div>
              <div class="m-t-5" v-if="[28].includes(selectedProductId)">
                <InputNumber size="small" v-model="file.quantity" :max="9999" :min="0"></InputNumber><span
                  class="p-l-5">次</span>
              </div>
            </i-col>
          </template>
        </Row>
      </div>
      <div class="p-b-10" v-if="![28].includes(this.selectedProductId)">
        <h4 class="workplatform-title m-t-10 m-b-20">资源序号选择</h4>
        <div class="p-b-10">
          <p class="text-orange text-14">提示：</p>
          <p class="text-14">如您是批量选择设置，此处只会加载资源中最⼤编号数， 设置的画⾯将对应到所有资源的对应编号上</p>
        </div>
        <div v-if="currSelectedFile && currSelectedFile.id">
          <div class="m-b-5">
            <Button type="primary" size="small" @click="handelSelectAll">全选</Button>
          </div>

          <CheckboxGroup v-model="selectedStairIds" @on-change="handleChangeStair">
            <Checkbox style="width:70px;margin-right:5px;margin-bottom:5px;" border
              v-for="(child, index) in stairIdArray" :label="child.serial" :key="'serial_' + index"
              :disabled="child.disabled">{{ child.serial }}</Checkbox>
          </CheckboxGroup>
        </div>
      </div>
      <div class="p-b-10">
        <h4 class="workplatform-title m-t-10 m-b-20">拍照要求（<span class="text-orange">最多选两项</span>）</h4>
        <CheckboxGroup class="m-b-10" size="small" v-model="selectedRemarks">
          <Checkbox v-for="mark in markArray" :key="mark.value" :label="mark.value"
            :disabled="selectedRemarks.length > 1 && !selectedRemarks.includes(mark.value)">{{ mark.name }}</Checkbox>
        </CheckboxGroup>
        <Input v-model.trim="demandData.extras" size="small" placeholder="自定义要求" style="width: 100%" />
      </div>
      <div class="p-b-10">
        <h4 class="workplatform-title m-t-10 m-b-20">素材来源</h4>
        <div class="p-b-10">
          <p class="text-orange text-14">提示：</p>
          <p class="text-14">选择素材后，才能选择素材来源，不选择素材，默认为“不涉及制作”，不用进行勾选操作</p>
        </div>
        <RadioGroup size="small" v-model="demandData.picturesource">
          <Radio v-for="item in picturesourceArray" :key="'ps_' + item.value" :label="item.value"
            :disabled="radioDisabled" border>{{ item.name }}</Radio>
        </RadioGroup>
      </div>
      <div class="text-right">
        <Button type="success" size="small" @click="handelConfirmSet" :disabled="btnDisabled"
          :loading="btnLodaing">确认设置</Button>
      </div>
    </div>
    <Modal v-model="visibleImage" width="800" :footer-hide="true">
      <p slot="header" class="text-center">{{ visibleImage ? currentViewFile.fileName : '' }}</p>
      <div v-if="visibleImage && currentViewFile.type === 1">
        <img :src="currentViewFile.fileKey" style="width: 100%">
      </div>
      <div v-if="visibleImage && [3, 4].includes(currentViewFile.type)">
        <video :src="currentViewFile.fileKey" controls="controls" style="width:100%">
          您的浏览器不支持预览视频。
        </video>
      </div>

    </Modal>
  </div>
</template>

<script>
import { setScreenMixins } from '@/assets/mixins/setScreen'
import { getPhotoDemandDictList } from '@/api/msp/dictionary'
// import { getSerialsInfo } from '@/api/msp/demand'
export default {
  mixins: [setScreenMixins],
  props: {
    agreeFileArray: {
      type: Array,
      default: new Array(0)
    },
    selectedResourceIds: {
      type: Array,
      required: false
    },
    selectedTaskitemIds: {
      type: Array,
      required: false
    },
    curTaskId: {
      type: Number,
      required: true
    },
    fileCreateDate: [String],
    selectedProductId: [Number],
    selectedStMediaAttr: [Object], // 已选中流媒体投放属性
    setOperatingType: [Number],
    isSetFiles: { // 是否设置画面
      type: Boolean,
      default: true
    },
    btnSetLoading: {
      type: Boolean,
      default: false
    },
    isPage: { // 是否分页
      type: Boolean,
      default: false
    },
    // demand 采用msp的接口，install采用order的接口
    settingType: {
      type: String,
      default: 'demand'
    }
  },
  data () {
    return {
      fileDateArray: [],
      fileDate: '',
      markArray: [],
      selectedRemarks: [],
      // stairSetInfo: {},
      // selectedFileArray: [], // 梯牌设置，已选择设置文件集合
      // selectedStairIds: [],
      // currSelectedFile: {}, // 梯牌设置，当前选中的文件
      // stairIdArray: [],
      demandData: {
        taskId: null,
        startDate: '',
        endDate: '',
        resourceIds: '',
        deviceIds: '',
        screenName: '',
        remarks: '',
        extras: '',
        picturesource: 4
      },
      picturesourceArray: [
        { value: 1, name: '新制作' },
        { value: 2, name: '库存' },
        { value: 4, name: '不涉及制作' }
      ],
      visibleImage: false,
      currentViewFile: null,
      // setFileIdArray: [], // 用于记录梯牌已设置画面id集合
      btnDisabled: true,
      radioDisabled: true,
      btnLodaing: false
    }
  },
  created () {
    this.getRemarkArray()
  },
  methods: {
    getRemarkArray () {
      getPhotoDemandDictList().then(res => {
        this.markArray = res
      })
    },
    /**
     * 确认设置
     */
    handelConfirmSet () {
      if ([28].includes(this.selectedProductId)) {
        // 流媒体
        this.handelStMediaSubmit()
      } else {
        // 平面媒体
        this.handleSubmit()
      }
    },
    /**
     * 平面媒体设置保存
     */
    handleSubmit () {
      let fileKeys = ''
      if (this.isSetFiles || (!this.isSetFiles && this.selectedFileArray.length)) {
        // 获取已设置序号画面
        var t = []
        this.selectedFileArray.forEach(item => {
          if (item.serials.length && !t.some(s => s.fileId === item.fileId)) {
            item.fileName = this.tempFileArray.find(x => x.id === item.fileId).realFileName
            t.push(item)
          }
        })
        var setTotal = t.length === 0 ? 0 : t.reduce((prev, cur) => {
          return (typeof (prev) === 'number' ? prev : prev.serials.length) + cur.serials.length
        })

        if (setTotal < this.stairIdArray.length) {
          this.$Notice.warning({ desc: '当前存在未设置序号的项' })
          return false
        }
        // var fileIds = []
        // t.forEach((item, index) => {
        //   const oldFile = this.allFileArray.find(x => x.id === item.fileId)
        //   if (oldFile && oldFile.fileName === item.fileName) {
        //     fileIds.push(item.fileId)
        //   }
        // })
        // if (fileIds.length > 0) {
        //   this.$Notice.warning({ desc: `编号为 ${fileIds.toString()} 项未更改文件名，请更改后提交` })
        //   return false
        // }
        fileKeys = t.map(x => {
          return {
            fileKey: x.filePath,
            mimeType: x.mimeType,
            fileId: x.fileId,
            fileName: x.fileName,
            quantity: x.serials.length > 0 ? this.stairIdArray.filter(f => x.serials.some(s => s === f.serial)).map(m => m.number).reduce((prev, cur) => { return prev + cur }) : 0,
            serials: x.serials
          }
        })
      }

      const extrasRemark = []
      // extrasRemark.push(this.extras)
      this.markArray.forEach(item => {
        if (this.selectedRemarks.some(x => x === item.value)) {
          extrasRemark.push(item.name)
        }
      })
      this.demandData.remarks = extrasRemark.toString()

      const postData = {
        taskId: this.curTaskId,
        // startDate: this.demandStartDate,
        // endDate: this.demandEndDate,
        oldpicture: this.demandData.oldpicture,
        remarks: this.demandData.remarks,
        extras: this.demandData.extras,
        fileKeys: fileKeys && fileKeys.length ? JSON.stringify(fileKeys) : '',
        picturesource: this.demandData.picturesource
        // productId: this.selectedProductId,

      }
      if (this.setOperatingType === 1) {
        postData.deviceIds = JSON.stringify(this.selectedResourceIds)
      } else {
        if (this.settingType === 'demand') {
          postData.taskitemIds = JSON.stringify(this.selectedTaskitemIds)
        } else {
          postData.orderitemDetailIds = JSON.stringify(this.selectedTaskitemIds)
        }
      }

      // if (this.setOperatingType === 1) {
      // 上刊设置
      this.$Modal.confirm({
        title: '操作提示',
        content: '素材名称设置将影响后续上刊的准确性，请确认素材名称无误',
        onOk: () => {
          // this.btnLodaing = true
          // this.$emit('update:btnSetLoading', this.btnLodaing)
          this.$emit('parentMethod', postData)
        }
      })

      // } else {
      //   // 修改上刊设置
      //   this.handleUpdateStationTask(fileKeys)
      // }
    },
    /**
     * 流媒体设置保存
     */
    handelStMediaSubmit () {
      const setFileArray = this.imageFileArray.filter(x => x.quantity)
      if (setFileArray.length === 0) {
        this.$Notice.error({ desc: '当前没有设置投放素材，请设置投放素材' })
        return false
      }
      // 判断所设置素材时长是否大于选中资源设置的规格时长
      if (setFileArray.some(x => x.duration > this.selectedStMediaAttr.unitDuration)) {
        this.$Notice.error({ desc: '当前设置素材中存在播放时长大于所选资源规格时长' })
        return false
      }
      // 判断所设置素材播放次数是否和选中资源设置的投放次数相同

      const setTotalPlayTimes = setFileArray.length === 1 ? setFileArray[0].quantity : setFileArray.reduce((prev, cur) => {
        return prev.quantity + cur.quantity
      })
      if (setTotalPlayTimes !== this.selectedStMediaAttr.playTimes) {
        this.$Notice.error({ desc: '当前设置素材的播放总次数与所选资源投放次数不匹配' })
        return false
      }
      const fileKeys = setFileArray.map(x => {
        return {
          fileKey: x.filePath,
          mimeType: x.mimeType,
          fileId: x.id,
          fileName: x.realFileName,
          quantity: x.quantity,
          serials: [1]
        }
      })

      const extrasRemark = []
      // extrasRemark.push(this.extras)
      this.markArray.forEach(item => {
        if (this.selectedRemarks.some(x => x === item.value)) {
          extrasRemark.push(item.name)
        }
      })
      this.demandData.remarks = extrasRemark.toString()

      const postData = {
        taskId: this.curTaskId,
        oldpicture: this.demandData.oldpicture,
        remarks: this.demandData.remarks,
        extras: this.demandData.extras,
        fileKeys: fileKeys && fileKeys.length ? JSON.stringify(fileKeys) : '',
        picturesource: this.demandData.picturesource,
        unitDuration: this.selectedStMediaAttr.unitDuration

      }
      if (this.setOperatingType === 1) {
        postData.deviceIds = JSON.stringify(this.selectedResourceIds)
      } else {
        if (this.settingType === 'demand') {
          postData.taskitemIds = JSON.stringify(this.selectedTaskitemIds)
        } else {
          postData.orderitemDetailIds = JSON.stringify(this.selectedTaskitemIds)
        }
      }

      // if (this.setOperatingType === 1) {
      // 上刊设置
      this.$Modal.confirm({
        title: '操作提示',
        content: '素材名称设置将影响后续上刊的准确性，请确认素材名称无误',
        onOk: () => {
          // this.btnLodaing = true
          // this.$emit('update:btnSetLoading', this.btnLodaing)
          this.$emit('parentMethod', postData)
        }
      })
    },
    handleViewImage (file) {
      this.currentViewFile = file
      this.visibleImage = true
    }
  },
  watch: {
    selectedFileArray: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val.length) {
          this.radioDisabled = false
          this.demandData.picturesource = [155, 409, 411, 413, 414, 415, 416, 417, 418, 419, 421, 422, 423, 424, 425].includes(this.selectedProductId) ? 4 : 1 // 电子屏，语音产品默认“不涉及制作”
        } else {
          this.radioDisabled = true
          this.demandData.picturesource = 4
          this.setFileIdArray = []
        }
      }
    },
    btnSetLoading (val) {
      this.btnLodaing = val
    }
  }
}
</script>
