<template>
    <div>
      <Divider dashed ><span class="divider-text">辅助工具</span></Divider>
        <Row>
            <i-col span="24">
                <Button type="primary" class="m-r-5" style="margin-bottom: 5px;" size="small" @click="showOrderSize">上刊尺寸</Button>
                <Button type="primary" class="m-r-5 " style="margin-bottom: 5px;" size="small" @click="showchangerecord">订单变更记录</Button>
                <Button type="primary" class="m-r-5 " style="margin-bottom: 5px;" size="small"  @click="CopyOrder">重新加载订单</Button>

                <Button type="primary" class="m-r-5 " style="margin-bottom: 5px;" size="small" @click.native="ExportOrderExcel">导出订单方案</Button>
                <!-- <Button v-if="isAuth('order_tool_export_ppt') || isAuth('order_manage_tool_export_ppt')" type="primary" class="m-r-5 " style="margin-bottom: 5px;" size="small" @click.native="ExportOrderPPT">导出订单到PPT</Button> -->
                <!-- <Button type="primary" class="m-r-5 " style="margin-bottom: 5px;" size="small" @click.native="ExportOrderPPT">导出订单到PPT</Button> -->

                <Button type="primary" class="m-r-5 " style="margin-bottom: 5px;" size="small" @click="showSettlementPrice">价格计算器</Button>

                <Button type="primary" class="m-r-5 " style="margin-bottom: 5px;" size="small" @click="showOrderConfliction">查看订单冲突</Button>
                <Button v-if="(fixQueue||commonQueue)" type="primary" class="m-r-5 " style="margin-bottom: 5px;" size="small" @click="showOrderQuene">查看排队资源</Button>
                <!-- <Button v-if="showServicePurchase" type="success" class="m-r-5 " style="margin-bottom: 5px;" size="small" @click="showPurchaseService">采购服务</Button> -->
                <Button type="primary" class="m-r-5 m-b-5" v-if="isAuth('order_tool_schedule_change')" size="small" @click="handleProductScheduleChange">订单档期平移</Button>
                <Button type="primary" class="m-r-5 " style="margin-bottom: 5px;" size="small" @click="showServicerecord">服务采购历史</Button>
            </i-col>
        </Row>

        <!-- <changeRecord :orderId="orderInfo.id" :isShow.sync="IsShowRecord"></changeRecord> -->
        <orderSize :orderId="orderInfo.id" :isShow.sync="IsShowSize"></orderSize>
        <export-order-excel ref="exportOrderExcel" :hiddenOrderPoint="hiddenOrderPoint" :orderInfo="orderInfo"></export-order-excel>
        <orderConfliction :orderId="orderInfo.id" :isShow.sync="IsShowConfliction"/>
        <purchaseService ref="purchaseService" @update-service="handleUpdateService"/>
        <resourceQuene ref="resourceQuene"/>

        <Modal title="订单价格计算器" v-model="settlementPriceCalcModal" width="800" :footer-hide="true">
            <calculator  v-if="settlementPriceCalcModal" :products="products" :summaryPrice="summaryPrice"
              :defaultDiscount="defaultDiscount"></calculator>
        </Modal>
        <Modal title="重新加载订单" v-model="modalOrderCopy" width="1000" :footer-hide="true">
            <orderCopy v-if="modalOrderCopy" :platform_orderid="orderInfo.id"/>
        </Modal>
        <Modal  v-model="IsShowRecord" width="1000" title="订单资源变更记录" :footer-hide="true">
          <changeRecord :orderId="orderInfo.id" v-if="IsShowRecord"></changeRecord>
        </Modal>

        <Modal  v-model="IsShowServiceRecord" width="1000" title="服务采购历史" :footer-hide="true">
          <ServiceRecord :orderId="orderInfo.id" v-if="IsShowServiceRecord"></ServiceRecord>
        </Modal>

        <productScheduleChange ref="productScheduleChange" @update-detail="handleUpdateDetail"/>
    </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import changeRecord from './ChangeRecord'
import ServiceRecord from './ServiceRecord'
import orderSize from './OrderSize'
import ExportOrderExcel from './ExportOrderExcel'
import calculator from './Calculator'
import orderCopy from './OrderCopy'
import orderConfliction from './OrderConfliction'
import purchaseService from './PurchaseServiceList'
import resourceQuene from './ResourceQuene'
import productScheduleChange from './ProductScheduleChange.vue'

import { downloadFileRequest } from '@/utils/download'
import { GetCurrentSchedule } from '@/utils/dateFormat'

import { getProductPrice, getSummaryPrice } from '@/api/order/orderprice'
import { getUsefeeSettlementDiscount } from '@/api/scp/statementdiscountconfig'

export default {
  mixins: [sysMixins],
  props: {
    orderBean: {
      type: Object,
      required: true
    }
  },
  components: {
    changeRecord,
    ServiceRecord,
    calculator,
    orderSize,
    orderCopy,
    ExportOrderExcel,
    orderConfliction,
    purchaseService,
    resourceQuene,
    productScheduleChange
  },
  data () {
    return {
      orderInfo: this.orderBean,
      modalOrderCopy: false,
      products: [], // 采购的产品清单
      summaryPrice: {}, // 其他费用清单
      defaultDiscount: 10, // 默认结算折扣
      IsShowRecord: false,
      IsShowServiceRecord: false,
      IsShowSize: false,
      showDetail: false,
      settlementPriceCalcModal: false,
      IsShowConfliction: false,
      isManage: this.$store.state.order.isManage,
      fixQueue: this.$store.getters.token.publisherSetting.fixQueue, // 主题媒介是否可以排队
      commonQueue: this.$store.getters.token.publisherSetting.commonQueue, // 常规资源是否可以排队
      hiddenOrderPoint: this.$store.getters.token.publisherSetting.orderHiddenPoint
    }
  },
  methods: {
    ExportOrderExcel () {
      this.$refs.exportOrderExcel.init()
    },
    ExportOrderPPT () {
      const filename = '媒体广告发布方案(' + this.orderBean.advertiserName + GetCurrentSchedule(this.orderBean.startDate, this.orderBean.endDate) + ')' + '.pptx'

      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-order/v1/order/getorderppt', { orderId: this.orderBean.id }, filename)
    },
    showchangerecord () {
      this.IsShowRecord = true
    },
    showServicerecord () {
      this.IsShowServiceRecord = true
    },

    showOrderSize () {
      this.IsShowSize = true
    },
    showSettlementPrice () {
      this.products = []
      this.summaryPrice = []
      this.defaultDiscount = 0
      const applicationPromise = []

      // 获取订单产品价格
      applicationPromise.push(
        getProductPrice({ orderId: this.orderInfo.id }).then(res => {
          this.products = res
        })
      )
      // 获取其他费用汇总
      applicationPromise.push(
        getSummaryPrice({ orderId: this.orderInfo.id }).then(res => {
          this.summaryPrice = res
        })
      )
      // 获取默认折扣
      const postData = {
        companyId: this.$store.getters.token.userInfo.companyId,
        publisherId: this.$store.getters.token.userInfo.publisherId
      }
      applicationPromise.push(
        getUsefeeSettlementDiscount(postData).then(res => {
          this.defaultDiscount = (res * 10).toFixed(1)
        })
      )

      Promise.all(applicationPromise).then(() => {
        this.settlementPriceCalcModal = true
      })
    },
    CopyOrder () {
      this.modalOrderCopy = true
    },
    showOrderConfliction () {
      this.IsShowConfliction = true
    },
    showPurchaseService () {
      this.$refs.purchaseService.showModal(this.orderInfo.id)
    },
    handleUpdateService () {
      this.$store.commit('set_order_detail_reload', new Date())
    },
    showOrderQuene () {
      this.$refs.resourceQuene.showModal(this.orderInfo.id)
    },
    handleProductScheduleChange () {
      this.$refs.productScheduleChange.showModal(this.orderInfo.id)
    },
    handleUpdateDetail () {
      this.$store.commit('set_order_detail_reload', new Date())
    }
  },
  computed: {
    showServicePurchase () {
      if (this.isManage) {
        return this.isAuth('order_manage_tool_service')
      } else {
        // “审批中” 和 “已完成”
        const orderStatusAllow = [2, 4, 6].includes(this.orderInfo.status)

        return this.isAuth('order_tool_service') && !orderStatusAllow
      }
    }
  },
  watch: {
    orderBean (val) {
      this.orderInfo = this.orderBean
    }
  }
}
</script>
