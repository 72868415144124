<template>
  <div v-show="serviceFeeList.length">
     <h4 class="workplatform-title m-t-10 m-b-20">服务项概览</h4>
     <Table
        stripe
        size="small"
        :data="serviceFeeList"
        :columns="serviceFeeColumns"
      ></Table>
  </div>
</template>

<script>
import { getOrderServiceItemList } from '@/api/order/demand'
export default {
  props: {
    orderId: {
      type: Number
    }
  },
  data () {
    return {
      serviceFeeList: [],
      serviceFeeColumns: [
        { title: '费用名称', align: 'center', ellipsis: true, tooltip: true, key: 'serviceItemName' },
        {
          title: '采购数量',
          align: 'center',
          key: 'signServiceQuantity',
          render: (h, { row }) => {
            return h('span', `${row.signServiceQuantity} ${row.unit}`)
          }
        },
        {
          title: '使用数量',
          align: 'center',
          key: 'usedServiceQuantity',
          render: (h, { row }) => {
            return h('span', `${row.usedServiceQuantity} ${row.unit}`)
          }
        }
      ]
    }
  },
  methods: {
    /**
     * 获取服务费概览数据
     */
    getServiceFeeData () {
      this.serviceFeeList = []
      if (!this.orderId || this.orderId < 1) {
        return false
      }
      getOrderServiceItemList({ orderId: this.orderId }).then(res => {
        if (res && !res.errcode) {
          this.serviceFeeList = res
        }
      })
    }
  }
}
</script>
