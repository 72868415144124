<template>
  <div>
    <p class="p-b-10 p-l-10">
      <Button type="success" @click="handleSubmit">保存设置</Button>
    </p>
    <Form label-position="left" :label-width="180">
      <div class="card-backgroud">
        <Card :bordered="false" dis-hover>
          <h4 slot="title">客户</h4>
          <Row>
            <i-col span="8">
              <FormItem label="报备模式">
                <RadioGroup v-model="projectRules.chooseMode" vertical>
                  <Radio :label="1" >按报备者到个人</Radio>
                  <!-- <Radio :label="2">按渠道排名</Radio> -->
                  <Radio :label="3" >按报备者到公司</Radio>
                </RadioGroup>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="报备保护期（天）">
                <InputNumber v-model="projectRules.reportProtection" :max="365" :min="0"></InputNumber>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="签约保护期（天）">
                <InputNumber v-model="projectRules.signProtection" :max="365" :min="0"></InputNumber>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="新建品牌（选择行业）">
                <RadioGroup v-model="projectRules.industryType" vertical>
                  <Radio :label="1" >按用户行业</Radio>
                  <Radio :label="2" >按平台行业</Radio>
                </RadioGroup>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="未找到品牌按钮">
                <i-switch v-model="projectRules.notFoundBrandButton">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
          </Row>
          <!-- <Row>
            <i-col span="8">
              <FormItem label="渠道端客户资源池开关">
                <i-switch v-model="projectRules.advertiserpool">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="渠道行权有效期（小时）">
                <InputNumber v-model="projectRules.chooseValidity" :max="100" :min="0"></InputNumber>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="客户品牌跨渠道报备">
                <i-switch v-model="projectRules.crossChannelReport">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
          </Row> -->
        </Card>
      </div>

      <div class="card-backgroud">
        <Card :bordered="false" dis-hover>
          <h4 slot="title">态势</h4>
          <Row>
            <i-col span="8">
              <FormItem label="线路（默认显示品牌库）">
                <CheckboxGroup size="small" v-model="projectRules.assetBoard">
                  <Checkbox v-if="projectRules.industryType===1" :label="1" border>用户行业</Checkbox>
                  <Checkbox :disabled="projectRules.industryType===2" :label="2" border>平台行业</Checkbox>
                </CheckboxGroup>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="合同">
                <CheckboxGroup size="small" v-model="projectRules.contractBoard">
                  <Checkbox v-if="projectRules.industryType===1" :label="1" border>用户行业</Checkbox>
                  <Checkbox :disabled="projectRules.industryType===2" :label="2" border>平台行业</Checkbox>
                </CheckboxGroup>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="经分（相关行业）">
                <RadioGroup vertical v-model="projectRules.financialBoard">
                  <Radio v-if="projectRules.industryType===1" :label="1">用户行业</Radio>
                  <Radio :disabled="projectRules.industryType===2" :label="2">平台行业</Radio>
                </RadioGroup>
              </FormItem>
            </i-col>
          </Row>
        </Card>
      </div>

      <div class="card-backgroud">
        <Card :bordered="false" dis-hover>
          <h4 slot="title">订单</h4>
          <Row>
            <i-col span="8">
              <FormItem label="推荐系统模块">
                <i-switch v-model="projectRules.recommend">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="订单采购展示增值服务">
                <i-switch v-model="projectRules.orderOptionalService">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="开启主题媒介排队">
                <i-switch v-model="projectRules.fixQueue">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
          </Row>
          <Row>
            <i-col span="8">
              <FormItem label="订单延迟锁定最大次数">
                <InputNumber v-model="projectRules.orderExtendLockMaxQuantity" :max="10" :min="0"></InputNumber>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="创建订单保护时间（分钟）">
                <InputNumber v-model="projectRules.createOrderProtectTime" :max="100" :min="0"></InputNumber>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="采购时间前移(月)">
                <InputNumber v-model="projectRules.advancePurchaseTime" :max="36" :min="0"></InputNumber>
              </FormItem>
            </i-col>
          </Row>
          <Row>
            <i-col span="8">
              <FormItem label="开启常规资源排队">
                <i-switch v-model="projectRules.commonQueue">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="订单默认档期类型">
                <RadioGroup v-model="projectRules.defaultOrderSchedule" vertical>
                  <Radio :label="1">常规档期</Radio>
                  <Radio :label="2">自定义档期</Radio>
                </RadioGroup>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="订单联系人为选填项">
                <i-switch v-model="projectRules.orderContact">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
          </Row>
          <!-- <Row>
            <i-col span="8">
              <FormItem label="二类商业开关">
                <i-switch v-model="projectRules.commerce">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="标准档期天数">
                <InputNumber v-model="projectRules.standardScheduleDaycount" :max="100" :min="0"></InputNumber>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="销售订单隐藏点位">
                <i-switch v-model="projectRules.orderHiddenPoint">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="订单状态是否含先签先得">
                <i-switch v-model="projectRules.orderStatusContainSignfirst">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="开启受众条件">
                <i-switch v-model="projectRules.audienceConditions">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
          </Row> -->
        </Card>
      </div>

      <div class="card-backgroud">
        <Card :bordered="false" dis-hover>
          <h4 slot="title">合同 ＆ 结算单 ＆ 确认单</h4>
          <Row>
            <i-col span="8">
              <FormItem label="开启合同限价">
                <i-switch v-model="projectRules.contractPriceSetting">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="允许合同部分提交">
                <i-switch v-model="projectRules.contractPartiallyCommitAllowed">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="扫描件上传消息通知">
                <i-switch v-model="projectRules.contractSignFileUploadNotificationOpened">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="交易结算单金额可编辑">
                <i-switch v-model="projectRules.statementPriceEditable">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="交易结算单开启审批">
                <i-switch v-model="projectRules.statementApply2">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="合同类型">
                <RadioGroup v-model="projectRules.contractType" vertical>
                  <Radio :label="1">媒介主合同</Radio>
                  <Radio :label="2">代理商合同</Radio>
                </RadioGroup>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="确认单开启审批">
                <i-switch v-model="projectRules.confirmationApply">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
          </Row>
          <!-- <Row>
            <i-col span="8">
              <FormItem label="开启合同绩效">
                <i-switch v-model="projectRules.companyPerformance">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
          </Row> -->
        </Card>
      </div>

      <div class="card-backgroud">
        <Card :bordered="false" dis-hover>
          <h4 slot="title">其他</h4>
          <Row>
            <i-col span="8">
              <FormItem label="允许交易对账即合同执行额自动确认">
                <i-switch v-model="projectRules.allowAutoConfirmContractExecutedAmount">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="开启运维业务审批">
                <i-switch v-model="projectRules.troubleApply">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="开启履约审批流程">
                <i-switch v-model="projectRules.orderMsp">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="开启结算台账单审批流程">
                <i-switch v-model="projectRules.statementApply">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="关闭个人能力">
                <i-switch v-model="projectRules.personInfo">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="态势标题">
                <Input v-model="projectRules.statisticsTitle" placeholder="如果不设置，将默认显示公司名称" style="max-width: 300px;" />
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="资源库存显示方式">
                <RadioGroup v-model="projectRules.resourceShowBrand" vertical>
                  <Radio :label="1">显示售卖品牌</Radio>
                  <Radio :label="2">显示售卖行业</Radio>
                  <Radio :label="0">不显示</Radio>
                </RadioGroup>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="默认媒介类型">
                <RadioGroup v-model="projectRules.mediaType" vertical>
                  <Radio :label="1">地铁</Radio>
                  <Radio :label="2">路面</Radio>
                </RadioGroup>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="站点画像">
                <RadioGroup v-model="projectRules.stationPortray" vertical>
                  <Radio :label="1">系统默认</Radio>
                  <Radio :label="2">VIP定制</Radio>
                </RadioGroup>
              </FormItem>
            </i-col>
          </Row>

          <!-- <Row>
            <i-col span="8">
              <FormItem label="KPI模块">
                <i-switch v-model="projectRules.kpi">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="SVG是否显示图例">
                <i-switch v-model="projectRules.showSvgLegend">
                  <span slot="open">开</span>
                  <span slot="close">关</span>
                </i-switch>
              </FormItem>
            </i-col>
          </Row> -->
        </Card>
      </div>
    </Form>

    <Spin size="large" fix v-if="loading"></Spin>
  </div>
</template>

<script>
import axios from 'axios'
import { getStorage } from '@/utils/storage'
import { getProjectRules } from '@/api/os/projectRule'

export default {
  props: {
    projectId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      projectRules: {}
    }
  },
  mounted () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      getProjectRules({ projectId: this.projectId }).then(res => {
        this.projectRules = res
      })
    },
    handleSubmit () {
      this.loading = true

      axios.post(process.env.VUE_APP_API_URL_V2 + '/ooh-os/v1/projectRule/updateProjectRule',
        this.projectRules,
        {
          headers: {
            'Content-Type': 'application/json',
            'ooh-auth': getStorage('ooh-token')
          },
          withCredentials: true
        }
      ).then(res => {
        this.$Notice.success({
          desc: '项目规则配置更新完毕'
        })
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.card-backgroud{
  background:#25272a;
  padding: 5px 10px;
}
</style>
