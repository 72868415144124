import request from '@/utils/requestV2'
// const qs = require('qs')

// 服务采购
export function addServiceItems (data) {
  return request({
    url: '/ooh-order/v3/orderservice/addServiceItems',
    method: 'post',
    headers: { 'content-type': 'application/json' },
    data: data
  })
}
// 采购历史清单
export function getHistoryList (data) {
  return request({
    url: '/ooh-order/v3/orderservice/getHistoryList',
    method: 'get',
    params: data
  })
}
// 获取当前服务清单和已采购的值
export function getItemlist (data) {
  return request({
    url: '/ooh-order/v3/orderservice/getItemlist',
    method: 'get',
    params: data
  })
}
