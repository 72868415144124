<style>
.deleted {
  text-decoration-line: line-through;
  color: gray;
}
</style>

<template>
  <div style="text-align:center">
     <Table  stripe :data="list" :columns="columns" height="450"></Table>
    </div>
</template>

<script>
import { getHistoryList } from '@/api/order/orderserviceV3'

export default {
  props: {
    orderId: null
  },
  data () {
    return {
      list: [],
      columns: [

        { title: '服务名', key: 'serviceItemName' },
        { title: '类型', key: 'typeName' },

        // {
        //   title: '刊例价',
        //   key: 'price',
        //   render: (h, data) => {
        //     return h('span', (data.row.formatPrice || data.row.price) + data.row.quantityUnitName)
        //   }
        // },
        { title: '订单内数量', key: 'defaultQuantity' },

        { title: '历史采购数量', key: 'oldQuantity' },
        { title: '最新采购数量', key: 'newQuantity' },

        { title: '操作用户', key: 'userName' },
        { title: '操作时间', key: 'createTime' }

      ]
    }
  },
  created () {
    if (this.orderId) {
      this.initData()
    }
  },
  methods: {
    initData () {
      const query = {
        orderId: this.orderId
      }
      getHistoryList(query).then(res => {
        this.list = res.data
      })
    }
  },
  watch: {
    orderId (val) {
      if (val) {
        this.initData()
      }
    }
  }
}
</script>
