<template>
  <div>
    <Modal v-model="submitModal" width="420">
      <p slot="header" class="text-center">确认提交</p>
      <div>
        <p class="m-b-10">确定提交任务前，可进行以下设置，若不需要设置，直接提交。</p>
        <div class="m-b-10">
          <div>
            <Checkbox v-model="formData.isCreateUninstall" @on-change="onChangeCreateUninstall">是否生成下刊任务</Checkbox>
          </div>
          <!-- <div v-show="formData.isCreateUninstall" class="m-t-10">
            <Input v-model="formData.uninstallName" :disabled="uninstallNameInfo.type === 2" size="small"
              placeholder="填写下刊任务名称" />
          </div> -->
          <p class="text-orange m-t-5" v-if="formData.isCreateUninstall && uninstallNameInfo.type === 2">
            当前已存在下刊任务，该项将自动加入存在的下刊任务，故无法编辑下刊任务名称。</p>
        </div>
        <div class="m-b-10">
          <div>
            <Checkbox v-model="formData.isCreateInstallInspect">是否生成上刊监播任务</Checkbox>
          </div>
          <div v-show="formData.isCreateInstallInspect" class="m-t-10">
            <div class="m-b-10">
              <DatePicker placeholder="选择作业日期" size="small" v-model="installInspectActionDate" type="date"
                style="width:100%" :editable="false" :clearable="false"></DatePicker>
            </div>
            <div class="m-b-10">
              <Input size="small" v-model.trim="formData.installInspectName" placeholder="填写上刊监播名称，非必填" />
            </div>
            <div class="m-b-10">
              <CheckboxGroup size="small" class="m-b-10" v-model="installInspectRemark">
                <Checkbox v-for="mark in markArray" :key="mark.value" :label="mark.value"
                  :disabled="installInspectRemark.length > 1 && !installInspectRemark.includes(mark.value)">{{ mark.name
                  }}</Checkbox>
              </CheckboxGroup>
            </div>
            <div>
              <Input v-model.trim="formData.installInspectExtra" size="small" type="textarea" :rows="3"
                placeholder="自定义要求" />
            </div>
          </div>
        </div>
        <div class="m-b-10">
          <div>
            <Checkbox v-model="formData.isCreateUnInstallInspect">是否生成下刊监播任务</Checkbox>
          </div>
          <div v-show="formData.isCreateUnInstallInspect" class="m-t-10">
            <div class="m-b-10">
              <DatePicker placeholder="选择作业日期" size="small" v-model="uninstallInspectActionDate" type="date"
                style="width:100%" :editable="false" :clearable="false"></DatePicker>
            </div>
            <div class="m-b-10">
              <Input size="small" v-model.trim="formData.uninstallInspectName" placeholder="填写下刊监播名称，非必填" />
            </div>
            <div class="m-b-10">
              <CheckboxGroup size="small" class="m-b-10" v-model="uninstallInspectRemark">
                <Checkbox v-for="mark in markArray" :key="mark.value" :label="mark.value"
                  :disabled="uninstallInspectRemark.length > 1 && !uninstallInspectRemark.includes(mark.value)">{{
                    mark.name
                  }}</Checkbox>
              </CheckboxGroup>
            </div>
            <div>
              <Input v-model.trim="formData.uninstallInspectExtra" size="small" type="textarea" :rows="3"
                placeholder="自定义要求" />
            </div>
          </div>

        </div>
      </div>
      <div slot="footer">
        <Button type="text" @click="submitModal = false">取消</Button>
        <Button type="primary" :loading="btnLoading" @click="onSubmit()">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { ParseDate } from '@/utils/dateFormat'
import { getPhotoDemandDictList } from '@/api/msp/dictionary'
import { getUninstallDefaultName } from '@/api/msp/demand'
import { submit } from '@/api/order/demand'
export default {
  props: {
    taskId: {
      type: Number,
      required: true
    },
    demandIds: [Array],
    successFun: {
      type: Function,
      default: () => { }
    }
  },
  data () {
    return {
      submitModal: false,
      formData: {
        demandMainId: null,
        installInspectName: '',
        installInspectActionDate: '',
        installInspectExtra: '',
        installInspectRemark: '',
        isCreateInstallInspect: false,
        isCreateUnInstallInspect: false,
        isCreateUninstall: false,
        demandIds: '',
        uninstallInspectName: '',
        uninstallInspectActionDate: '',
        uninstallInspectExtra: '',
        uninstallInspectRemark: '',
        uninstallName: ''
      },
      installInspectActionDate: '',
      uninstallInspectActionDate: '',
      installInspectRemark: [],
      uninstallInspectRemark: [],
      uninstallNameInfo: {}, // 下刊名称相关信息
      markArray: [], // 监播拍照要求
      btnLoading: false
    }
  },
  methods: {
    init () {
      if (!this.taskId || this.demandIds.length === 0) {
        this.$Notice.warning({ desc: '当前未选择任务项，请重新操作' })
        return
      }
      // 初始化数据
      Object.assign(this.$data.formData, this.$options.data().formData)
      this.installInspectActionDate = ''
      this.uninstallInspectActionDate = ''
      this.installInspectRemark = []
      this.uninstallInspectRemark = []
      this.uninstallNameInfo = {}

      this.getRemarkArray()
      this.formData.demandMainId = this.taskId
      this.formData.demandIds = JSON.stringify(this.demandIds)
      this.submitModal = true
    },
    onChangeCreateUninstall () {
      if (this.formData.isCreateUninstall) {
        this.getUninstallName()
      }
    },
    /**
     * 获取下刊默认名称
     */
    getUninstallName () {
      this.uninstallNameInfo = {}
      getUninstallDefaultName({ taskId: this.taskId }).then(res => {
        if (res && !res.errcode) {
          this.uninstallNameInfo = res
          this.formData.uninstallName = res.name
        }
      })
    },
    getRemarkArray () {
      if (this.markArray.length === 0) {
        getPhotoDemandDictList().then(res => {
          this.markArray = res
        })
      }
    },
    onSubmit () {
      if (!this.isCreateUninstall) {
        this.uninstallName = ''
      }
      // 解析上刊监播数据
      if (this.formData.isCreateInstallInspect) {
        if (!this.installInspectActionDate) {
          this.$Notice.error({ desc: '请选择上刊监播作业日期' })
          return
        }
        const installInspectRemark = this.markArray.filter(x => this.installInspectRemark.includes(x.value))
        this.formData.installInspectRemark = installInspectRemark && installInspectRemark.length ? installInspectRemark.map(x => x.name).toString() : ''
        this.formData.installInspectActionDate = ParseDate(this.installInspectActionDate)
      } else {
        this.installInspectRemark = []
        this.formData.installInspectName = ''
        this.formData.installInspectActionDate = ''
        this.formData.installInspectExtra = ''
        this.formData.installInspectRemark = ''
      }
      // 解析下刊监播数据
      if (this.formData.isCreateUnInstallInspect) {
        if (!this.uninstallInspectActionDate) {
          this.$Notice.error({ desc: '请选择下刊监播作业日期' })
          return
        }
        const uninstallInspectRemark = this.markArray.filter(x => this.uninstallInspectRemark.includes(x.value))
        this.formData.uninstallInspectRemark = uninstallInspectRemark && uninstallInspectRemark.length ? uninstallInspectRemark.map(x => x.name).toString() : ''
        this.formData.uninstallInspectActionDate = ParseDate(this.uninstallInspectActionDate)
      } else {
        this.uninstallInspectRemark = []
        this.formData.uninstallInspectName = ''
        this.formData.uninstallInspectActionDate = ''
        this.formData.uninstallInspectExtra = ''
        this.formData.uninstallInspectRemark = ''
      }
      this.btnLoading = true
      submit(this.formData).then(res => {
        if (res && res.errcode === 0) {
          this.successFun()
        }
      }).finally(() => {
        this.submitModal = false
        this.btnLoading = false
      })
    }
  }
}
</script>
