import request from '@/utils/requestV2'
const qs = require('qs')

// /**
//  * 基于同一订单内上刊申请 发起监播申请
//  * @param {Object} data
//  */
// export function createInspectTask (data) {
//   return request(
//     {
//       url: '/ooh-msp/v3/demand/createinspecttask',
//       method: 'post',
//       data: qs.stringify(data)
//     }
//   )
// }

// /**
//  * 创建订单申请：上刊/下刊 申请 返回任务主键
//  * @param {Object} data
//  */
// export function createOrderTask (data) {
//   return request(
//     {
//       url: '/ooh-msp/v3/demand/createordertask',
//       method: 'post',
//       data: qs.stringify(data)
//     }
//   )
// }

/**
 * 创建其他申请 返回任务主键
 * @param {Object} data
 */
export function createTask (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/createtask',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 获取订单指定档期资源详情 设置上刊申请时使用
 * @param {Object} data
 */
export function getOrderScheduleDetailPage (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/getorderscheduledetailpage',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 设置/更新 资源 申请信息
 * @param {Object} data
 */
export function setOrderTaskitem (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/setordertaskitem',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}
/**
 *订单发起申请 更新 资源 画面信息
 * @param {Object} data
 */
export function updateTaskitem (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/updatetaskitem',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 订单发起申请 设置/更新 资源 画面信息
 * @param {Object} data
 */
export function setOrderTaskitemFile (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/setordertaskitemfile',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 *订单发起申请 更新 资源 画面信息
 * @param {Object} data
 */
export function updateTaskitemFile (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/updatetaskitemfile',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 设置连刊
 * @param {Object} data
 */
export function setResourceLx (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/setresourcelx',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

// /**
//  * 更新连刊
//  * @param {Object} data
//  */
// export function updateLx (data) {
//   return request(
//     {
//       url: '/ooh-msp/v3/demand/updatelx',
//       method: 'post',
//       data: qs.stringify(data)
//     }
//   )
// }

/**
 * 提交申请
 * @param {Object} data
 */
export function submit (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/submit',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 设置/更新 资源 申请信息
 * @param {Object} data
 */
export function setTaskitemFile (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/settaskitemfile',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}
/**
 * 设置/更新 下刊申请
 * @param {Object} data
 */
export function setTaskitemUninstall (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/settaskitemuninstall',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 设置/更新 资源 申请信息
 * @param {Object} data
 */
export function setTaskitem (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/settaskitem',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 根据申请类型获取从属的任务项类型
 * @param {Object} data
 */
export function getTaskitemTypeList (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/gettaskitemtypelist',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 设置/更新维修 资源 申请信息
 * @param {Object} data
 */
export function setRepairTaskitem (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/setrepairtaskitem',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 主动发起申请 设置/更新 资源 画面信息。不是旧画依然可以发起制作
 * @param {Object} data
 */
export function setFillTaskitemFile (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/setfilltaskitemfile',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 删除任务
 * @param {Object} data
 */
export function deleteTaskitem (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/deletetaskitem',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 更新 维修任务
 * @param {Object} data
 */
export function updateRepairTaskitem (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/updaterepairtaskitem',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 获取维护/维修任务站点列表
 * @param {Object} data
 */
export function getStationPage (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/getstationpage',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 设置 站点 申请信息(目前用于巡检)
 * @param {Object} data
 */
export function setStationTaskitem (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/setstationtaskitem',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 设置刊中画面来源
 * @param {Object} data
 */
export function setPublishingPicSource (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/setpublishingpicsource',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 更新刊中画面来源
 * @param {Object} data
 */
export function updatePublishingPicSource (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/updatepublishingpicsource',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 设置 临时任务(resourceCode 作为title , remarks作为详情)
 * @param {Object} data
 */
export function setTempTaskitem (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/settemptaskitem',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 更新 临时任务
 * @param {Object} data
 */
export function updateTempTaskitem (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/updatetemptaskitem',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 提交下刊申请
 * @param {Object} data
 */
export function submitUninstallApply (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/submituninstallapply',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 验证设备指定维修项是否存在已派送未完成任务
 * @param {Object} data
 */
export function check (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/check',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 修改列车信息
 * @param {Object} data
 */
export function updateTrainInfo (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/updatetraininfo',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 添加填充点位
 * @param {Object} data
 */
export function addFillTaskitem (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/addfilltaskitem',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 派单前费用确认
 * @param {Object} data
 */
export function confirmFeeBeforeAssign (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/confirmfeebeforeassign',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 根据任务id获取任务回传照片
 * @param {Object} data
 */
export function getTaskitemAttachList (data) {
  return request(
    {
      url: '/ooh-msp/v3/demand/gettaskitemattachlist',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 根据任务id获取任务回传照片
 * @param {Object} data
 * @returns
 */
export function getDemandAttachList (data) {
  return request.post('/ooh-msp/v3/demand/getdemandattachlist', qs.stringify(data))
}
