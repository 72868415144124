<template>
  <div>
    <Button
      type="primary"
      long
      :loading="loading"
      @click="onBatchDonwload"
    >{{loading?'数据处理中，请耐心等待...':'批量下载反馈素材'}}</Button>

  </div>
</template>

<script>
import { downLoadTaskAttach } from '@/api/msp/msppreport'
// utils
import { downloadFileRequest } from '@/utils/download'
export default {
  props: {
    mspTaskId: [Number]
  },
  data () {
    return {
      loading: false,
      uniqueFlag: '',
      timeOut: null
    }
  },
  computed: {
    orderInfo () {
      return this.$store.state.installSetting.orderInfo
    },
    demandStartDate () {
      return this.$store.state.installSetting.demandStartDate
    },
    demandEndDate () {
      return this.$store.state.installSetting.demandEndDate
    }
  },
  methods: {
    onBatchDonwload () {
      if (!this.mspTaskId) {
        return false
      }
      this.loading = true
      this.onRequestDownload()
      // 5分钟超时处理
      this.timeOut = setTimeout(() => {
        // clearInterval(timer)
        this.loading = false
        this.$Notice.error({ desc: '数据处理时间过长' })
      }, 1000 * 60 * 5)
    },
    onRequestDownload () {
      const postData = {
        taskId: this.mspTaskId,
        uniqueFlag: this.uniqueFlag
      }

      downLoadTaskAttach(postData).then(res => {
        if (res && !res.errcode) {
          this.uniqueFlag = res.uniqueFlag
          // 文件生成完成
          if (res.status === 2) {
            clearTimeout(this.timeOut)
            this.loading = false
            // 执行下载文件流程
            this.onDownloadFile()
          } else {
            setTimeout(() => {
              this.onRequestDownload()
            }, 2500)
          }
        } else {
          this.loading = false
          clearTimeout(this.timeOut)
        }
      })
    },
    onDownloadFile () {
      const query = {
        uniqueFlag: this.uniqueFlag
      }

      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v2/msppreport/getTaskAttachZip', query, `${this.orderInfo.advertiserName}(${this.demandStartDate}至${this.demandEndDate}).zip`)
    }
  }
}
</script>
