<template>
  <div>
    <Modal v-model="show" width="800">
      <!-- <p slot="header" class="text-center">设备详情</p> -->
      <Tabs v-model="tabValue">
        <TabPane label="设备详情" name="detail">
          <div>
            <h4 class="workplatform-title m-t-10 m-b-10">设备信息</h4>
            <Row class="p-b-10">
              <i-col span="8">
                <span class="title">编号</span>{{ deviceInfo.code }}
              </i-col>
              <i-col span="8">
                <span class="title">所属资产</span>{{ deviceInfo.assetName }}
              </i-col>
              <i-col span="8">
                <span class="title">所属站点</span>{{ deviceInfo.stationName }}
              </i-col>
            </Row>
            <Row class="p-b-10">
              <i-col span="8">
                <span class="title">产权方</span>{{ deviceInfo.manageUnitName }}
              </i-col>
              <i-col span="8">
                <span class="title">一级分类</span>{{ deviceInfo.deviceBigmodelName }}
              </i-col>
              <i-col span="8">
                <span class="title">二级分类</span>{{ deviceInfo.devicemodelName }}
              </i-col>
            </Row>
            <Row class="p-b-10">
              <i-col span="8">
                <span class="title">所在楼层</span>{{ deviceInfo.floor }}
              </i-col>
              <i-col span="8">
                <span class="title">所在位置</span>{{ deviceInfo.positionName }}
              </i-col>
              <i-col span="8">
                <span class="title">是否通电</span>{{ deviceInfo.electric ? '是' : '否' }}
              </i-col>
            </Row>
            <Row class="p-b-10">
              <i-col span="8">
                <span class="title">是否滚屏</span>{{ deviceInfo.isroll ? '是' : '否' }}
              </i-col>
              <i-col span="8">
                <span class="title">面数</span>{{ deviceInfo.side }}
              </i-col>
              <i-col span="8">
                <span class="title">设备尺寸，宽x高(mm)</span>{{ deviceInfo.width }}<span class="m-l-5 m-r-5">x</span>{{
                  deviceInfo.height }}
              </i-col>
            </Row>
            <Row class="p-b-10">
              <i-col span="8">
                <span class="title">面积</span>{{ deviceInfo.area }}<span class="m-l-5">㎡</span>
              </i-col>
              <i-col span="8">
                <span class="title">制作尺寸，宽x高(mm)</span>{{ deviceInfo.makewidth }}<span class="m-l-5 m-r-5">x</span>{{
                  deviceInfo.makeheight }}
              </i-col>
              <i-col span="8">
                <span class="title">见光尺寸，宽x高(mm)</span>{{ deviceInfo.finalwidth }}<span class="m-l-5 m-r-5">x</span>{{
                  deviceInfo.finalheight }}
              </i-col>
            </Row>
            <Row class="p-b-10">
              <i-col span="8">
                <span class="title">状态</span>{{ deviceInfo.enabledName }}
              </i-col>
              <i-col span="8">
                <span class="title">移固日期</span>{{ deviceInfo.transferTime }}
              </i-col>
              <i-col span="8">
                <span class="title">创建时间</span>{{ deviceInfo.createTime }}
              </i-col>
            </Row>
            <Row class="p-b-10">
              <i-col span="8">
                <span class="title">所属项目</span>{{ deviceInfo.projectName }}
              </i-col>

            </Row>
          </div>
          <div>
            <h4 class="workplatform-title m-t-10 m-b-10">资源列表</h4>
            <div class="text-right m-b-10">
              <Button type="primary" size="small" @click="handleModifyRes(3)">新增资源</Button>
            </div>
            <Table size="small" stripe :data="list" :columns="tableColumns"></Table>
          </div>
        </TabPane>
        <TabPane label="变更记录" name="history">
          <modify-history ref="modifyHistory"></modify-history>
        </TabPane>
      </Tabs>
    </Modal>
    <Modal v-model="resAddModal" width="600">
      <p slot="header" class="text-center">{{ operate === 1 ? '新增' : '编辑' }}资源</p>
      <medium-add v-if="resAddModal" ref="resAdd" :operate="operate" :assetArray="productAssetArray" :resourceTypeArray="resourceTypeArray"
        :onSuccess="getResourceData"></medium-add>
      <div slot="footer">
        <Button type="text" class="m-r-5" @click="resAddModal = false">取消</Button>
        <Button type="primary" @click="handleAddRes">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getResourcePage } from '@/api/product/resource'
import { getResourceTypeList } from '@/api/product/asset'
import MediumAdd from './MediumAdd.vue'
import ModifyHistory from './ModifyHistory'
export default {
  components: {
    MediumAdd,
    ModifyHistory
  },
  computed: {
    curDeviceInfo () {
      return this.$store.state.deviceManage.curDeviceInfo
    },
    productAssetArray () {
      return this.$store.state.deviceManage.productAssetArray
    }
  },
  data () {
    return {
      show: false,
      operate: 1,
      tabValue: 'detail',
      resAddModal: false,
      deviceInfo: {},
      resourceTypeArray: [],
      query: {
        pageNumber: 1,
        pageSize: 50,
        deviceId: null,
        keyword: ''
      },
      list: [],
      tableColumns: [
        { title: '编号', align: 'center', fixed: 'left', width: 160, key: 'code' },
        { title: '站点', align: 'center', width: 100, key: 'stationName' },
        { title: '类型', align: 'center', width: 100, key: 'resourcetypeName' },
        { title: '等级', align: 'center', width: 60, key: 'levelName' },
        { title: '楼层', align: 'center', width: 60, key: 'floor' },
        { title: '面积(㎡)', align: 'center', width: 100, key: 'area' },
        {
          title: '制作尺寸(mm)',
          align: 'center',
          width: 120,
          render: (h, params) => {
            return h('span', `${params.row.makewidth} * ${params.row.makeheight}`)
          }
        },
        {
          title: '见光尺寸(mm)',
          align: 'center',
          width: 120,
          render: (h, params) => {
            return h('span', `${params.row.finalwidth} * ${params.row.finalheight}`)
          }
        },
        {
          title: '状态',
          align: 'center',
          width: 80,
          key: 'enabled',
          render: (h, params) => {
            let statusName = ''
            switch (params.row.enabled) {
              case -1:
                statusName = '冻结'
                break
              case 0:
                statusName = '不可用'
                break
              case 1:
                statusName = '可用'
                break
              default:
                statusName = '不可用'
                break
            }
            return h('span', statusName)
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 100,
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.handleModifyRes(2, params.row)
                  }
                }
              }, '编辑')
            ])
          }
        }
      ]
    }
  },
  methods: {
    showModal () {
      this.tabValue = 'detail'
      this.deviceInfo = this.curDeviceInfo
      this.query.deviceId = this.deviceInfo.id
      this.getResourceData()
      this.show = true
      // 加载变更历史列表
      this.$refs.modifyHistory.init()
    },
    async getResourceData () {
      const result = await getResourcePage(this.query)
      if (result && !result.errcode) {
        this.list = result.list
      }
    },
    /**
    * 获取资源类型数据
    */
    async getResourceTypeData () {
      const result = await getResourceTypeList({ assetId: this.deviceInfo.assetId })
      this.resourceTypeArray = (result && !result.errcode) ? result : []
    },
    handleModifyRes (operate = 1, data) {
      this.getResourceTypeData()
      this.operate = operate
      if (operate === 2) {
        this.$store.commit('set_cur_res_info', data)
      }

      this.resAddModal = true
    },
    handleAddRes () {
      this.$refs.resAdd.handleSubmit()
      this.resAddModal = false
    }
  }
}
</script>
