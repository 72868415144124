<template>
  <div>
      <transactionStatistics ></transactionStatistics>
  </div>
</template>

<script>
export default {
  components: {
    transactionStatistics: () => import('../common/transactionStatistics')
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {

  },
  computed: {
  },
  watch: {

  }
}
</script>
