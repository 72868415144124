<template>
  <div>
    <Modal v-model="modifyModal" :mask-closable="false" width="680" footer-hide>
      <p slot="header" class="text-center">{{ opType === 1 ? '新增' : '编辑' }}常规媒体产品</p>
      <Form ref="formValidate" :model="productModel" :rules="ruleValidate" :label-width="120">
        <Divider>基础信息</Divider>
        <FormItem label="所属资产" prop="assetIds">
          <Select v-model="productModel.assetIds" multiple size="small" placeholder="选择资产（可多选）"
            @on-change="onChangeAsset">
            <Option v-for="item in assetArray" :key="'assetId_' + item.id" :value="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>

        <Row :gutter="8">
          <i-col span="12">
            <FormItem label="所属类目" prop="categoryId">
              <Select v-model="productModel.categoryId" size="small" placeholder="选择类目"
                @on-change="handleChangeCategory">
                <Option v-for="item in storageCategorys" :key="'category_' + item.id" :value="item.id">{{ item.name
                  }}
                </Option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="所属产品" prop="productId">
              <Select v-model="productModel.productId" size="small" placeholder="选择产品">
                <Option v-for="item in productArray" :key="'product_' + item.id" :value="item.id">{{ item.name }}
                </Option>
              </Select>
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="8">
          <i-col span="12">
            <FormItem label="所属资源类型" prop="resourcetypeId">
              <Select size="small" placeholder="资源类型（可通过关键字搜索）" clearable filterable
                v-model="productModel.resourcetypeId">
                <OptionGroup v-for="item in resourceTypeArray" :key="'re_parent_' + item.id" :label="item.name">
                  <Option v-for="child in item.children" :value="child.id" :key="'re_child' + child.id">{{ child.name }}
                  </Option>
                </OptionGroup>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="单品名称" prop="productskuName">
              <Input v-model="productModel.productskuName" size="small" placeholder="填写单品名称" />
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="8">
          <i-col span="12">
            <FormItem label="资源级别">
              <Select v-model="productModel.level" size="small" placeholder="选择资源级别">
                <Option v-for="item in levelArray" :key="'product_' + item.value" :value="item.value">{{ item.name
                  }}
                </Option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="施工缓冲期">
              <InputNumber size="small" :min="0" v-model="productModel.bufferDay" /><span class="m-l-5">天</span>
            </FormItem>
          </i-col>
        </Row>
        <FormItem label="备注">
          <Input v-model="productModel.desc" type="textarea" :rows="2" />
        </FormItem>
        <FormItem label="是否可用">
          <i-switch size="large" v-model="productModel.enabled">
            <span slot="open">是</span>
            <span slot="close">否</span>
          </i-switch>
        </FormItem>
        <!-- <FormItem label="资源">
          <Button type="primary" size="small" :disabled="productModel.assetIds.length == 0"
            @click="handleChoiceRes">管理资源</Button>
          <div v-show="selectedResArray.length">
            <Tag v-for="item in selectedResArray" :key="item.id">{{ item.code }}</Tag>
          </div>
        </FormItem> -->

        <Divider>售卖规则</Divider>
        <!--电子媒体-->
        <FormItem v-if="[8].includes(productModel.categoryId)" label="最小售卖规格（秒）" prop="duration">
          <InputNumber v-model="productModel.duration" :min="1" size="small" style="width:100%" placeholder="最小售卖规格" />
        </FormItem>
        <FormItem v-else label="最小售卖天数" prop="minday">
          <InputNumber v-model="productModel.minday" :min="1" size="small" style="width:100%" placeholder="最小售卖天数">
          </InputNumber>
        </FormItem>
        <Row :gutter="8">
          <i-col span="12">
            <FormItem v-show="[8].includes(productModel.categoryId)" label="每天周期数" prop="period">
              <InputNumber v-model="productModel.period" :min="1" size="small" style="width:100%" placeholder="每天周期数" />
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="起止时间" v-show="[8].includes(productModel.categoryId)">
              <TimePicker v-model="times" :editable="false" :transfer="true" size="small" type="timerange"
                placeholder="选择起止时间" style="width: 100%"></TimePicker>
            </FormItem>
          </i-col>
        </Row>

        <Divider>媒介费配置</Divider>
        <FormItem label="媒介使用费" prop="usePrice">
          <InputNumber :min="0" v-model="productModel.usePrice"
            :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
            :parser="value => value.replace(/\￥\s?|(,*)/g, '')" size="small" style="width:100%" placeholder="输入媒介使用费">
          </InputNumber>
        </FormItem>
        <Row :gutter="8">
          <i-col span="12">
            <FormItem label="最低限价" prop="statementPrice">
              <InputNumber :min="0" :max="productModel.usePrice" v-model="productModel.statementPrice"
                :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\￥\s?|(,*)/g, '')" size="small" style="width:100%" placeholder="输入最低限价"
                @on-change="onChangeStatePrice">
              </InputNumber>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="最低限价折扣">
              <InputNumber size="small" :max="10" :min="0" :step="0.5" v-model="discount" @on-change="onChangeDiscount">
              </InputNumber>
              <span class="p-l-5">折</span>
            </FormItem>
          </i-col>
        </Row>
        <FormItem class="text-right m-t-10">
          <Button type="text" class="m-r-5" @click="modifyModal = false">取消</Button>
          <Button type="primary" @click="handleSave">确定</Button>
        </FormItem>
      </Form>

    </Modal>
    <!-- <choice-resource v-if="showChoiceRes" ref="choiceResource" :assetIds="choicedAssetIds" :resBoxTag="resBoxShow"
      :skuId="productskuId" :projectId="projectId" :onSaveResInfo="onSaveResInfo" /> -->
  </div>
</template>

<script>
import { productMixins } from '../mixins/product'

import { addCommon, editCommon } from '@/api/product/productskuedit'
import { getResourceTypeList } from '@/api/product/asset'
// import ChoiceResource from './common/ChoiceResource.vue'
export default {
  mixins: [productMixins],
  props: {
    projectId: [Number],
    saveSuccessFun: Function
  },
  // components: { ChoiceResource },
  data () {
    return {
      modifyModal: false,
      productModel: {
        assetIds: [],
        categoryId: null,
        desc: '',
        duration: 1,
        enabled: true,
        minday: 1,
        productId: null,
        productskuName: '',
        usePrice: 1,
        statementPrice: -1,
        endTime: '23:59:59',
        level: 1,
        period: 1,
        startTime: '00:00:00',
        cityCode: '',
        cityName: '',
        bufferDay: 0, // 施工缓冲期，单位：天
        projectId: undefined,
        resourcetypeId: null
        // resourceInfos: ''
      },
      discount: 0, // 最低限价折扣
      times: [], // 起止时间
      productskuId: null, // 单品id
      companyInfo: {},
      ruleValidate: {
        assetIds: [
          { required: true, type: 'array', min: 1, message: ' ', trigger: 'change' }
        ],
        categoryId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        minday: [
          { required: true, type: 'number', message: ' ', trigger: 'blur' }
        ],
        duration: [
          { required: true, type: 'number', message: ' ', trigger: 'blur' }
        ],
        period: [
          { required: true, type: 'number', message: ' ', trigger: 'blur' }
        ],
        // duration: [
        //   { required: true, pattern: /^(\d+,?)+$/, message: ' ', trigger: 'blur' }
        // ],
        // period: [
        //   { required: true, pattern: /^(\d+,?)+$/, message: ' ', trigger: 'blur' }
        // ],
        productId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        productskuName: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        usePrice: [
          { required: true, type: 'number', message: ' ', trigger: 'blur' }
        ],
        statementPrice: [
          { required: true, type: 'number', message: ' ', trigger: 'blur' },
          { pattern: /(^\d+(\.{0,1}\d+){0,1}$)/, message: '该项不能小于0' }
        ],
        resourcetypeId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ]
      },
      curProduct: {},
      productArray: [],
      levelArray: [
        { value: -1, name: '全部级别' },
        { value: 1, name: 'A' },
        { value: 2, name: 'A+' },
        { value: 3, name: 'A++' },
        { value: 4, name: 'S' },
        { value: 5, name: 'S+' },
        { value: 6, name: 'S++' }
      ],
      resBoxShow: 'tab_choice',
      showChoiceRes: false,
      choicedAssetIds: [],
      resourceTypeArray: []
      // selectedResArray: []
    }
  },
  computed: {
    assetArray () {
      return this.$store.state.productManage.assetArray
    },
    storageCategorys () {
      return this.$store.state.productManage.categoryArray
    },
    storageProducts () {
      return this.$store.state.productManage.productArray
    },
    opType () {
      return this.$store.state.productManage.opType
    }
  },
  mounted () {
    this.getCompanyInfo()
    // this.getCategoryData()
  },
  methods: {
    initShow (data) {
      if (this.opType === 1) {
        // 新增
        this.productskuId = null
        this.resBoxShow = 'tab_choice'
        // this.selectedResArray = []
        Object.assign(this.$data.productModel, this.$options.data().productModel)
        this.productModel.projectId = this.projectId
        this.$refs.formValidate.resetFields()
      } else {
        // 编辑
        if (data) {
          this.curProduct = data
          // 先获取产品数据
          this.getProductData({ categoryId: data.categoryId }).then(res => {
            // 防止赋值不成功
            this.productModel.productId = data.productId
          })
          Object.keys(this.productModel).forEach(key => {
            this.productModel[key] = data[key]
          })
          this.productModel.assetIds = data.assetIdList
          this.productModel.productskuName = data.name
          this.productModel.duration = Number(data.duration)
          this.productModel.period = Number(data.period)
          this.productskuId = data.id

          if (data.statementPrice < 0) {
            this.discount = 0
            // this.productModel.statementPrice = 0
          } else {
            this.onChangeStatePrice()
          }

          this.resBoxShow = 'tab_exist'
          // this.selectedResArray = (data.fixResourceList && data.fixResourceList.length) ? data.fixResourceList.map(x => {
          //   return {
          //     id: x.resourceId,
          //     code: x.resourceCode,
          //     duration: x.duration,
          //     period: x.period,
          //     deviceId: x.deviceId
          //   }
          // }) : []
        }
      }
      this.times = [this.productModel.startTime, this.productModel.endTime]
      this.modifyModal = true
    },
    handleChangeCategory () {
      this.productModel.productId = this.opType === 1 ? null : this.curProduct.productId
      if (this.productModel.categoryId) {
        this.getProductData({ categoryId: this.productModel.categoryId }).then(res => {
          if (!res || res.length === 0) {
            this.productModel.productId = null
          }
        })
      } else {
        this.productArray = []
      }
    },
    onChangeDiscount () {
      const price = this.productModel.usePrice * (this.discount / 10)
      this.productModel.statementPrice = Math.round(price * 10000) / 10000
    },
    onChangeStatePrice () {
      const discount = (this.productModel.statementPrice / this.productModel.usePrice) * 10
      this.discount = Math.round(discount * 100) / 100
    },
    handleSave () {
      // if (this.selectedResArray.length === 0) {
      //   this.$Notice.error({ desc: '请选择资源' })
      //   return false
      // }
      this.$refs.formValidate.validate(valid => {
        if (valid) {
          this.$Modal.confirm({
            title: '操作提示',
            content: '确定信息无误，并提交？',
            onOk: () => {
              // const resInfos = this.selectedResArray.map(x => {
              //   return {
              //     resourceId: x.id,
              //     duration: x.duration,
              //     period: x.period
              //   }
              // })
              // this.productModel.resourceInfos = JSON.stringify(resInfos)
              this.productModel.assetIds = JSON.stringify(this.productModel.assetIds)
              // // 列车与其他类目处理方式
              // this.productModel.produceCost = this.productModel.categoryId === 2 ? this.productModel.produceCost : null

              if ([8].includes(this.productModel.categoryId)) {
                // // 去除电子媒体售卖规格和周期数最后的逗号（若存在）
                // const reg = /,$/gi
                // this.productModel.duration = this.productModel.duration.replace(reg, '')
                // this.productModel.period = this.productModel.period.replace(reg, '')

                // 电子媒体处理方式
                this.productModel.startTime = this.times[0]
                this.productModel.endTime = this.times[1]
              }
              if (this.opType === 1) {
                // 新增
                this.productModel.cityCode = this.companyInfo.cityCode
                this.productModel.cityName = this.companyInfo.cityName

                addCommon(this.productModel).then(res => {
                  this.modifyModal = false
                  if (res > 0) {
                    this.productskuId = res
                    this.$Notice.success({ desc: '操作成功' })
                    this.saveSuccessFun()
                  }
                })
              } else {
                const postData = Object.assign(this.productModel, { productskuId: this.productskuId })
                // 编辑
                editCommon(postData).then(res => {
                  this.modifyModal = false
                  if (res && res.errcode === 0) {
                    this.$Notice.success({ desc: '操作成功' })
                    this.saveSuccessFun()
                  }
                })
              }
            }
          })
        }
      })
    },
    onChangeAsset () {
      if (this.productModel.assetIds.length === 0) {
        this.resourceTypeArray = []
        return false
      }
      this.getResourceTypeData()
    },
    /**
    * 获取资源类型数据
    */
    getResourceTypeData () {
      getResourceTypeList({ assetIds: JSON.stringify(this.productModel.assetIds) }).then(res => {
        if (res && !res.errcode) {
          this.resourceTypeArray = res
        } else {
          this.resourceTypeArray = []
        }
      })
    }
    // handleChoiceRes () {
    //   this.choicedAssetIds = this.productModel.assetIds
    //   this.showChoiceRes = true
    //   this.$nextTick(() => {
    //     this.$refs.choiceResource.showModal()
    //   })
    // }
    // onSaveResInfo (selections) {
    //   this.selectedResArray = selections
    // }

  }
}
</script>
