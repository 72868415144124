import { getMaterialLibrary } from '@/api/msp/file'
import { getStationPublishingDeviceList, getTaskStationList } from '@/api/msp/demandsvg'

export const taskMixins = {
  data () {
    return {
      materialLibList: [] // 素材库
    }
  },
  methods: {
    /**
     * 获取素材库数据
     * @param {number} materialType 素材类别
     * @returns Array
     */
    async getMaterialLibData (materialType) {
      const res = await getMaterialLibrary({ type: materialType })
      if (res && !res.errcode) {
        this.materialLibList = res
      } else {
        this.materialLibList = []
      }
    },
    getStationData () {
      getTaskStationList({ taskId: this.curTaskId }).then(res => {
        if (res && !res.errcode) {
          this.stationArray = res
          this.showStationArray = res
          this.handleChoiceItem(this.showStationArray[0])
          if (this.stationArray.length) {
            this.stationArray.forEach(item => {
              if (!this.assetArray.some(s => s.assetId === item.assetId)) {
                this.assetArray.push({ assetId: item.assetId, assetName: item.assetName })
              }
            })
          }
        } else {
          this.stationArray = []
        }
      })
    },
    handleChangeAsset () {
      this.showStationArray = this.assetId ? this.stationArray.filter(x => x.assetId === this.assetId) : this.stationArray
      if (this.showStationArray.length) {
        this.handleChoiceItem(this.showStationArray[0])
      }
    },
    handleChoiceItem (obj) {
      this.curStation = obj
      this.stationSvgInit()
    },
    stationSvgInit () {
      this.curStationId = this.curStation ? this.curStation.stationId : -1
      this.floors = this.curStation.floorList
      this.$nextTick(() => {
        this.$refs.svgStation.initData()
      })
    },
    getPublishingData () {
      // let postSvgData = []
      this.deviceInfoArray = []
      getStationPublishingDeviceList({ stationId: this.curStationId, taskId: this.curTaskId, floors: JSON.stringify(this.floors) }).then(res => {
        this.deviceInfoArray = res
      }).finally(() => {
        this.$refs.svgStation.existScreenDevice(this.deviceInfoArray)
      })
    }
  }
}
