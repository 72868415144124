const supplierStore = {
  state: {
    pageType: '',
    beginUpdate: 1,
    reloadList: 1,
    detailBean: 0, // 对账详情实体
    maintenanceReconciliationQuery: {},
    notAllowReconciliationQuantity: 0 // 不允许对账条目的勾选数量
  },
  mutations: {
    set_supplierStore_pageType (state, data) {
      state.pageType = data
    },
    set_supplierStore_update (state, data) {
      state.beginUpdate = data
    },
    set_supplierStore_reloadList (state, data) {
      state.reloadList = data
    },
    set_supplierStore_detailBean (state, data) {
      state.detailBean = data
    },
    set_supplierStore_maintenanceReconciliationQuery (state, data) {
      state.maintenanceReconciliationQuery = data
    },
    set_supplierStore_notAllowReconciliationQuantity (state, data) {
      state.notAllowReconciliationQuantity = data
    }
  },
  getters: {

  },
  actions: {

  }
}

export default supplierStore
