<template>
  <div class="p-b-10">
      <div class="platform-buttom-list" v-if="pageType === 'Detail'">
          <div :class="tagType===1? 'bottom-button-active':'bottom-button'" @click="handleChangeTag(1)">结算清单</div>
          <div :class="tagType===2? 'bottom-button-active':'bottom-button'" @click="handleChangeTag(2)">结算详情</div>
      </div>

      <component v-show="tagType === 1" :is="bottomListComponent"></component>
      <component v-if="tagType === 2" :is="bottomDetailComponent"></component>
  </div>
</template>

<script>
export default {
  components: {
    transactionList: () => import('../common/transactionList'),
    settlementDetail: () => import('../common/channelDetail')
  },
  data () {
    return {
      tagType: 1,
      bottomListComponent: '',
      bottomDetailComponent: 'settlementDetail'
    }
  },
  created () {
    this.$store.commit('set_settlementChannel_pageType', 'List')
    this.initPageData()
  },
  methods: {
    initPageData () {
      this.tagType = 1
      this.bottomListComponent = 'transactionList'
    },
    handleChangeTag (val) {
      const componentName = val === 1 ? 'List' : 'Detail'
      this.$store.commit('set_settlementChannel_pageType', componentName)
    }
  },
  computed: {
    pageType () {
      return this.$store.state.settlementChannel.pageType
    }
  },
  watch: {
    pageType (val) {
      if (val === 'Detail') {
        this.tagType = 2
      } else {
        this.tagType = 1
        this.bottomListComponent = 'transactionList'
      }
    }
  }
}
</script>
