<!-- 结算单创建 -->
<template>
  <div>
    <div class="p-b-10">
      <div class="workplatform-title">合同基本信息</div>
      <Row>
        <i-col span="8">
          <span class="title">合同编号 </span>{{ detailInfo.code }}
        </i-col>
        <i-col span="8">
          <span class="title">合同甲方 </span>{{ detailInfo.firstParty }}
        </i-col>
        <i-col span="8">
          <span class="title">合同乙方 </span>{{ detailInfo.secondParty }}
        </i-col>

        <i-col span="8">
          <span class="title">合同名称 </span>{{ detailInfo.contractName }}
        </i-col>
        <i-col span="8">
          <span class="title">合同类型 </span>{{ detailInfo.typeName }}
        </i-col>
        <i-col span="8">
          <span class="title">档期时间 </span>{{ detailInfo.startDate }} -
          {{ detailInfo.endDate }}
        </i-col>
        <i-col span="8">
          <span class="title">合同销售 </span>{{ detailInfo.userName }}
        </i-col>
        <i-col span="8">
          <span class="title">审批时间 </span>{{ detailInfo.lastApprovedTime }}
        </i-col>
      </Row>
    </div>
    <div class="p-t-10">
      <Tabs v-model="type">
        <TabPane label="签约发布费" name="2">
          <template>
            <Table
              stripe
              class="m-t-5"
              :max-height="300"
              :data="signUseFee"
              :columns="column"
              :summary-method="handleSummary"
              show-summary
            ></Table>
          </template>
        </TabPane>
        <TabPane label="签约服务费" name="1">
          <template>
            <Table
              stripe
              :max-height="300"
              :data="signServiceFee"
              :columns="serviceColumn"
              :summary-method="handleSummary"
              show-summary
            ></Table>
          </template>
        </TabPane>
      </Tabs>
    </div>
    <div class="p-t-10">
      <div class="workplatform-title m-b-10 m-t-5">执行明细</div>
      <Row :gutter="8">
        <i-col span="4">
          <p class="title p-t-5">费用类型</p>
          <Select v-model="query.feeType" size="small">
            <Option
              v-for="(item, index) in feeTypeArray"
              :key="index"
              :value="item.id"
              >{{ item.name }}</Option
            >
          </Select>
        </i-col>
        <i-col span="6">
          <p class="title p-t-5">计账周期</p>
          <Row :gutter="8">
            <i-col span="12" class="p-b-5">
              <DatePicker
                placeholder="开始月份"
                v-model="chkStartDate"
                type="month"
                format="yyyy-MM"
                size="small"
              ></DatePicker>
            </i-col>
            <i-col span="12" class="p-b-5">
              <DatePicker
                placeholder="结束月份"
                format="yyyy-MM"
                size="small"
                type="month"
                v-model="chkEndDate"
                placement="bottom-end"
              ></DatePicker>
            </i-col>
          </Row>
        </i-col>
        <i-col span="4">
          <p class="title p-t-5">账单状态</p>
          <Select v-model="query.billStatus" size="small">
            <Option
              v-for="(item, index) in statusArray"
              :key="index"
              :value="item.id"
              >{{ item.name }}</Option
            >
          </Select>
        </i-col>
        <i-col span="8">
          <p class="title p-t-5">关键字</p>
          <Row :gutter="8">
            <i-col span="16">
              <i-input
                v-model="query.keyword"
                type="text"
                size="small"
                clearable
                placeholder="如：计费名称"
              ></i-input>
            </i-col>
            <i-col span="8">
              <Button
                type="primary"
                icon="ios-search"
                long
                size="small"
                :loading="loadingData"
                @click="search"
              >
                <span v-if="!loadingData">搜索</span>
                <span v-else>加载中...</span>
              </Button>
            </i-col>
          </Row>
        </i-col>
      </Row>
      <Table
        stripe
        :loading="loadingData"
        class="m-t-5"
        :max-height="300"
        :data="billData"
        :columns="tableColumns"
        :summary-method="handleSummary"
        show-summary
      ></Table>
      <Row>
      <i-col span="12"> </i-col>
      <i-col span="12">
        <div class="paging_style">
          <Page
            size="small"
            :total="total"
            :page-size="query.pageSize"
            show-total
            show-sizer
            :current="query.pageNumber"
            @on-change="handlePageChanged"
            :page-size-opts="[15,50,100,200,500]"
            @on-page-size-change="handlePageSizeChanged"
          ></Page>
        </div>
      </i-col>
    </Row>
    </div>
    <billDetailModal ref="billDetail"></billDetailModal>
  </div>
</template>
<script>
import billDetailModal from '@/views/platform_settlement/bill/common/billDetails'
import { toMoney } from '@/utils/wnumb_own'
import { GetCurrentSchedule, ParseDate, lastMonthDate } from '@/utils/dateFormat'
import { formatReconcilliationStatus } from '@/utils/tagStatus'

import { listContractBillDetail } from '@/api/statement/contractBillDetail'
import { checkContractDetail, getContractSignUseFee } from '@/api/scp/contract'
import { listSignServiceItem } from '@/api/scp/contractorder'

export default {
  components: {
    billDetailModal
  },
  props: {
    contractId: {
      type: Number
    }
  },
  data () {
    return {
      detailInfo: {},
      data: [],
      signUseFee: [],
      column: [
        {
          title: '单品名称',
          key: 'productName'
        },
        {
          title: '档期',
          render: (h, params) => {
            return h('div', [
              h(
                'p',
                GetCurrentSchedule(params.row.startDate, params.row.endDate)
              )
            ])
          }
        },
        {
          title: '资源数量',
          render: (h, params) => {
            return h('div', [h('p', params.row.signNormalQuantity)])
          }
        },
        {
          title: '媒体发布费刊例',
          key: 'normalUsePrice',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.normalUsePrice)
              )
            ])
          }
        },
        {
          title: '媒体发布费签约额',
          key: 'signNormalPrice',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.signNormalPrice)
              )
            ])
          }
        }
      ],
      signServiceFee: [],
      serviceColumn: [
        {
          title: '名称',
          key: 'serviceItemName'
        },
        {
          title: '签约数量',
          render: (h, params) => {
            return h('div', [
              h(
                'p',
                params.row.signServiceQuantity + params.row.serviceQuantityName
              )
            ])
          }
        },
        {
          title: '媒体服务费刊例',
          key: 'price',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.price) + '/' + params.row.serviceQuantityName
              )
            ])
          }
        },
        {
          title: '媒体服务费签约额',
          key: 'signServiceAmount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.signServiceAmount)
              )
            ])
          }
        }
      ],
      type: '2',
      useKeyword: '',
      serviceKeyword: '',
      useStartDate: null,
      useEndDate: null,
      serviceStartDate: null,
      serviceEndDate: null,

      feeTypeArray: [
        { id: -1, name: '全部' },
        { id: 1, name: '发布费' },
        { id: 2, name: '服务费' }
      ],
      statusArray: [
        { id: -1, name: '全部' },
        { id: 1, name: '已结算' },
        { id: 2, name: '未结算' }
      ],
      chkStartDate: '',
      chkEndDate: '',
      query: {
        pageNumber: 1,
        pageSize: 15,
        queryType: 2,
        contractId: this.contractId,
        startDate: '',
        feeType: -1,
        endDate: '',
        billStatus: -1,
        keyword: ''
      },
      total: 0,
      loadingData: false,
      tableColumns: [
        {
          title: '费用类型',
          key: 'feeTypeName'
        },

        {
          title: '执行月',
          key: 'executeMonth'
        },
        {
          title: '计账时间',
          key: 'chargeTime'
        },
        {
          title: '执行时间',
          key: 'executeSchedule'
        },
        {
          title: '计费名称',
          key: 'feeItemName'
        },
        {
          title: '执行额',
          key: 'executeAmount',
          render: (h, params) => {
            return h('div', [h('span', toMoney(params.row.executeAmount))])
          }
        },

        {
          title: '状态',
          key: 'billStatus',
          width: 120,
          render: (h, data) => {
            return formatReconcilliationStatus(
              h,
              data.row.billStatus,
              data.row.billStatusName
            )
          }
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h(
              'a',
              {
                on: {
                  click: () => {
                    this.$refs.billDetail.showModal(params.row.feeItemId)
                  }
                }
              },
              '费用组成'
            )
          }
        }
      ],
      billData: [],
      serviceTableColumns: [],
      useTableColumns: []
    }
  },
  created () {
    this.initPageData()
    this.initContractFee()
    this.initContractBillDetail()
  },
  methods: {
    initPageData () {
      checkContractDetail({ contractId: this.contractId }).then((res) => {
        this.detailInfo = res
      })
    },
    // 获合同费用
    initContractFee () {
      getContractSignUseFee({ contractId: this.contractId }).then((res) => {
        this.signUseFee = res
      })
      listSignServiceItem({ contractId: this.contractId }).then((res) => {
        this.signServiceFee = res
      })
    },

    // 数据求汇总
    handleSummary ({ columns, data }) {
      const needSummarykey = [
        'signNormalPrice',
        'normalUsePrice',
        'signServiceAmount',
        'executeAmount'
      ]
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          }
          return
        }
        if (needSummarykey.includes(key)) {
          const values = data.map((item) => Number(item[key]))
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[key] = {
              key,
              value: toMoney(v)
            }
          } else {
            sums[key] = {
              key,
              value: '-'
            }
          }
        } else {
          sums[key] = {
            key,
            value: '-'
          }
        }
      })

      return sums
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.initContractBillDetail()
    },

    handlePageSizeChanged (pagesize) {
      this.query.pageSize = pagesize
      this.query.pageNumber = 1
      this.initContractBillDetail()
    },
    search () {
      this.query.pageNumber = 1
      this.initContractBillDetail()
    },
    initContractBillDetail () {
      this.loadingData = true
      const that = this
      that.query.startDate = this.chkStartDate
        ? ParseDate(this.chkStartDate)
        : null
      that.query.endDate = this.chkEndDate
        ? lastMonthDate(
          this.chkEndDate.getFullYear(),
          this.chkEndDate.getMonth() + 1
        )
        : null

      listContractBillDetail(that.query).then((res) => {
        that.billData = res.list
        that.total = res.totalRow
        that.loadingData = false
      })
    }
  },
  computed: {},
  watch: {
    contractId () {
      this.initPageData()
      this.initContractFee()
      this.initContractBillDetail()
    }
  }
}
</script>
