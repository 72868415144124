<template>
    <container>
        <div slot="right_box_content">
            <component :is="curRightComponent" ></component>
        </div>
        <div slot="bottom_box_content">
            <component :is="curBottomComponent"></component>
        </div>
    </container>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
import settlementSupplierStoreModule from '@/store/modules/settlementSupplier'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    Right: () => import('./components/Right'),
    Bottom: () => import('./components/Bottom')
  },
  created () {
    // 注册动态store模块
    if (!this.$store.hasModule('settlementSupplier')) {
      this.$store.registerModule('settlementSupplier', settlementSupplierStoreModule)
    }
  },
  mounted () {
    this.setShowLeftBlock(false)
    this.setRightComponent('Right')
    this.setShowRightBlock(true)
    this.setBottomHeight('90%')
    this.setBottomComponent('Bottom')
    this.setShowMap(false)
  },
  destroyed () {
    // 卸载动态store模块
    this.$store.unregisterModule('settlementSupplier')
  }
}
</script>
