import request from '@/utils/requestV2'

const qs = require('qs')

/**
 * 查询渠道执行额账单汇总统计
 * @param {Object} data
 */
export function getContractBillSummaryStatistics (data) {
  return request({
    url: '/ooh-statement/v1/contractBillDetail/getContractBillSummaryStatistics',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 查询渠道执行额账单（按合同汇总账单or账单明细）
 * @param {Object} data
 */
export function listContractBillDetail (data) {
  return request({
    url: '/ooh-statement/v1/contractBillDetail/listContractBillDetail',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 查询计费项费用的组成明细
 * @param {Object} data
 */
export function listFeeItemDetailByFeeItemId (data) {
  return request({
    url: '/ooh-statement/v1/contractBillDetail/listFeeItemDetailByFeeItemId',
    method: 'post',
    data: qs.stringify(data)
  })
}
