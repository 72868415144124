// 订单的状态标识
export function formatOrderStatus (h, status, showName) {
  const statusNum = parseInt(status)
  let bgColor = '#141414'
  let border = false
  if (statusNum === 0) { // 备选中
    return h('span', showName)
  } else if (statusNum === -1) { // 废弃
    bgColor = '#3B3B3B'
  } else if (statusNum === 5) { // 已过期
    bgColor = '#141414'
  } else if (statusNum === 1 || statusNum === 3 || statusNum === 6) { // 预定中和先签先得、待付款
    bgColor = '#a59426'
  } else if (statusNum === 2 || statusNum === 9 || statusNum === 11) { // 待确认、待上刊、发布中
    bgColor = '#44bd32'
  } else if (statusNum === 4 || statusNum === 13) { // 已完成
    bgColor = '#141414'
    border = true
  }
  return h('Tag', { props: { color: bgColor }, style: { border: border ? '1px solid #fff' : 'none' } }, showName)
}
// 订单的状态标识
export function formatOmOrderStatus (h, status, showName) {
  const statusNum = parseInt(status)
  let bgColor = '#141414'
  let border = false
  if (statusNum === 0) { // 备选中
    return h('span', showName)
  } else if (statusNum === -1) { // 已取消
    bgColor = '#3B3B3B'
  } else if (statusNum === 5) { // 已过期
    bgColor = '#141414'
  } else if (statusNum === 6) { // 待付款
    bgColor = '#a59426'
  } else if (statusNum === 11 || statusNum === 8) { // 待上刊和发布中
    bgColor = '#44bd32'
  } else if (statusNum === 13) { // 已完成
    bgColor = '#141414'
    border = true
  }
  return h('Tag', { props: { color: bgColor }, style: { border: border ? '1px solid #fff' : 'none' } }, showName)
}
// 订单的退款状态标识
export function formatRefundStatus (h, status, showName) {
  const statusNum = parseInt(status)
  let bgColor = '#3B3B3B'
  let border = false
  if (statusNum === 0) { // 未申请
    bgColor = '#3B3B3B'
  } else if (statusNum === -1) { // 未申请
    bgColor = '#3B3B3B'
  } else if (statusNum === 1) { // 预定中和先签先得
    bgColor = '#a59426'
  } else if (statusNum === 2) { // 完成退款
    bgColor = '#141414'
    border = true
  }
  return h('Tag', { props: { color: bgColor }, style: { border: border ? '1px solid #fff' : 'none' } }, showName)
}
export function getOrderStatus (status) {
  const statusNum = parseInt(status)
  const tagResult = {
    bgColor: '#141414',
    border: false
  }
  if (statusNum === -1) { // 废弃、备选中
    tagResult.bgColor = '#3B3B3B'
  } else if (statusNum === 5) { // 已过期
    tagResult.bgColor = '#141414'
  } else if (statusNum === 1 || statusNum === 3 || statusNum === 6) { // 预定中和先签先得、待付款
    tagResult.bgColor = '#a59426'
  } else if (statusNum === 2 || statusNum === 9 || statusNum === 11) { // 待确认、待上刊、发布中
    tagResult.bgColor = '#44bd32'
  } else if (statusNum === 4 || statusNum === 13) { // 已完成
    tagResult.bgColor = '#141414'
    tagResult.border = true
  }
  return tagResult
}

// 订单采购类型
export function formatOrderSaleType (h, type) {
  let color = ''
  let showName = '常规'

  if (type === 2) {
    showName = '赠送'
    color = '#ef4f4f'
  } else if (type === 0) {
    showName = '特惠'
    color = '#44bd32'
  }

  return h('span', { style: { color: color } }, showName)
}
export function formatOrderDifference (h, status, expirceTime) {
  let color = '#ed4014'
  let showName = expirceTime
  if (status === -1) {
    color = '#bbb'
    showName = '不保护'
  } else if (status === 0 && showName === '0') {
    color = 'Orange'
    showName = '未提交'
  } else if (status === 1 && showName === '0') {
    showName = '即将过期'
  } else if (status === 3) {
    color = '#bbb'
    showName = '不锁位'
  } else if (status === 2 || status === 4) {
    color = '#19be6b'
    showName = '已经锁位'
  } else if (status === 6 && expirceTime === '0') { // 待付款
    color = '#bbb'
    showName = '不保护'
  }

  return h('span', { style: { color: color } }, showName)
}
// 订单变更标识
export function formatOrderChangeType (h, type, showName) {
  let color = 'Gray'
  if (type === 0) {
    color = '#ed4014'
  } else if (type === 1) {
    color = '#19be6b'
  } else if (type === 2) {
    color = 'Orange'
  }

  return h('tag', { props: { color: color } }, showName)
}

// 上刊设置状态
const statusMap = new Map([
  [0, { statusName: '草稿', bgColor: 'none', fontColor: '#fff' }],
  [1, { statusName: '已提交', bgColor: 'none', fontColor: '#c8c823' }],
  [3, { statusName: '已完成', bgColor: '#000', fontColor: '#fff' }]
  // [2, { statusName: '已派单', bgColor: '#fff', fontColor: '#000' }],
  // [3, { statusName: '执行中', bgColor: '#c8c823', fontColor: '#fff' }],
  // [4, { statusName: '已完成', bgColor: '#000', fontColor: '#fff' }],
  // [-1, { statusName: '拒绝', bgColor: '#f87676', fontColor: '#fff' }]
])
export function formatDemandStatus (h, status, statusName = '') {
  const result = statusMap.get(status)
  if (result.bgColor === 'none') {
    return h('span', {
      style: { color: result.fontColor }
    }, statusName === '' ? result.statusName : statusName)
  } else {
    return h('Tag', { props: { color: result.bgColor } }, statusName === '' ? result.statusName : statusName)
  }
}

// // 画面来源
// const pictureSourceMap = new Map([
//   [0, { statusName: '连刊' }]
// ])

// #region 审批

// 审批意见
export function formatApprovalResult (h, result, showName) {
  let color = 'Gray'
  if (result === 0) { // 审批拒绝
    color = '#ef4f4f'
  } else if (result === 1) { // 审批通过
    color = '#44bd32'
  } else {
    return null
  }
  return h('tag', { props: { color: color } }, showName)
}
// 审批流程状态
export function formatWorkflowStatus (h, status) {
  let color = 'Gray'
  let showName = ''
  if (status === -1) {
    color = 'Red'
    showName = '已删除'
  } else if (status === 0) {
    color = 'Gray'
    showName = '已失效'
  } else if (status === 1) {
    color = 'Green'
    showName = '正在使用'
  } else if (status === 2) {
    color = 'Orange'
    showName = '新版拟定'
  }

  return h('tag', { props: { color: color } }, showName)
}

// 审批任务状态
export function formatApprovalStatus (h, result, showName) {
  let color = '#ef4f4f'
  if (result === 0) { // 待审批
    return h('span', { class: 'm-r-5' }, showName)
  } else if (result === 1) { // 已通过
    color = '#44bd32'
  } else if (result === 2) { // 已拒绝
    color = '#ef4f4f'
  }

  return h('tag', { props: { color: color } }, showName)
}
// 1审批中，2已通过，-1已拒绝，3已中止。
export function formatApprovalProcessStatus (h, status, showName) {
  let color = 'Gray'
  if (status === 1) { // 审批中
    color = '#a59426'
  } else if (status === 2) { // 已通过
    color = '#44bd32'
  } else if (status === 3) { // 已拒绝|已取消
    color = '#ef4f4f'
  } else if (status === -1) { // 已中止
    // color = 'Gray'
    return h('tag', { props: { color: '#3B3B3B' } }, showName)
  }

  return h('tag', { props: { color: color } }, showName)
}

// 通用审批任务状态
export function formatSpecialApprovalStatus (h, result, showName) {
  let color = '#3B3B3B'
  if (result === -1) { // 废弃
    color = '#3B3B3B'
  } else if (result === 0) { // 草稿
    return h('span', { props: { color: '#fff' } }, showName)
  } else if (result === 2) { // 已终止
    color = '#ef4f4f'
  } else if (result === 4) { // 审批中
    color = '#a59426'
  } else if (result === 5) { // 已通过
    color = '#44bd32'
  } else if (result === 6) { // 已拒绝
    color = '#ef4f4f'
  }

  return h('tag', { props: { color: color } }, showName)
}

// 是否可用
export function formatEnable (h, enable) {
  if (enable) {
    return h('Icon', {
      props: { type: 'md-checkmark', size: 16, color: '#19be6b' },
      style: { color: '#19be6b' }
    })
  } else {
    return h('Icon', {
      props: { type: 'md-close', size: 16, color: '#ed3f14' },
      style: { color: '#ed4014' }
    })
  }
}
// 运维任务派单状态颜色
export function formatMspTaskStatusColor (status) {
  let color = 'warning'
  if (status === 0) { // 待派单
    color = 'warning'
  } else if (status === 1) { // 已派单
    color = 'black'
  }

  return color
}

// 运维任务状态
export function formartMspTaskStatus (h, status, showName) {
  let color = '#141414'
  if (status === -1) {
    return h('span', { props: { color: '#fff' } }, showName)
  } else if (status === 0 || status === 2 || status === -3) {
    color = '#a59426'
  } else if (status === -2) {
    color = '#ef4f4f'
  }
  return h('Tag', { props: { color: color } }, showName)
}

// 运维任务详情状态
export function formartMspTaskitemStatus (h, status, showName) {
  let bgColor = '#141414'
  let border = false
  if (status === -1 || status === 6) {
    bgColor = '#3B3B3B'
  } else if (status === -2 || status === 4) { // 草稿，待提交审批
    return h('span', { props: { color: '#fff' } }, showName)
  } else if (status === -3 || status === 0 || status === 5) { // 派单中，审批中
    bgColor = '#a59426'
  } else if (status === 1 || status === 2) { // 已派单，执行中
    bgColor = '#44bd32'
  } else if (status === 3) { // 已完成
    bgColor = '#141414'
    border = true
  }
  return h('Tag', { props: { color: bgColor }, style: { border: border ? '1px solid #fff' : 'none' } }, showName)
}

// 运维任务重拍状态
export function formartMspTaskitemRephotoStatus (h, status) {
  switch (status) {
    case 1:
      return h('Tag', { props: { color: '#a59426' } }, '重拍中')
    case 2:
      return h('Tag', { props: { color: '#141414' }, style: { border: '1px solid #fff' } }, '重拍完成')
  }
}

// 运维质检任务状态颜色
export function formatMspPlanStatusColor (status) {
  let color = 'Gray'
  if (status === 0) { // 1已派单
    color = 'warning'
  } else if (status === 1) { // 执行中
    color = 'success'
  } else if (status === 2) { // 3已完成
    color = 'black'
  }
  return color
}
// 运维质检任务状态
export function formartMspPlanStatus (h, status, showName) {
  let bgColor = '#141414'
  let border = false
  if (status === -1) {
    bgColor = '#3B3B3B'
  } else if (status === -2) { // 草稿
    return h('span', { props: { color: '#fff' } }, showName)
  } else if (status === -3 || status === 0) { // 派单中，审批中
    bgColor = '#a59426'
  } else if (status === 1 || status === 2) { // 已派单，执行中
    bgColor = '#44bd32'
  } else if (status === 3) { // 已完成
    bgColor = '#141414'
    border = true
  }
  return h('Tag', { props: { color: bgColor }, style: { border: border ? '1px solid #fff' : 'none' } }, showName)
}

// 运维质检对账状态
export function formartMspChargeStatus (h, status, showName) {
  let bgColor = '#141414'
  let border = false
  if (status === -1) {
    bgColor = '#3B3B3B'
  } else if (status === 1) { // 未对账
    bgColor = '#a59426'
  } else if (status === 2) { // 已对账
    bgColor = '#141414'
    border = true
  }
  return h('Tag', { props: { color: bgColor }, style: { border: border ? '1px solid #fff' : 'none' } }, showName)
}

// 开票状态
export function formatInvoiceStatus (h, status, showName) {
  let color = '#3B3B3B'
  let border = false
  if (status === 0) {
    return h('span', { props: { color: '#fff' } }, showName)
  } else if (status === 1) {
    color = '#a59426'
  } else if (status === 2) {
    border = true
    color = '#141414'
  }

  return h('tag', { props: { color: color }, style: { border: border ? '1px solid #fff' : 'none' } }, showName)
}

// 发票类型
export function formatInvoiceType (h, type, showName) {
  let color = 'Gray'
  if (type === 0) {
    color = '#a59426'
  } else if (type === 1) {
    color = '#44bd32'
  }

  return h('tag', { props: { color: color, type: 'border' } }, showName)
}

// 结算单状态标识
export function formatStatementStatus (h, status, showName) {
  const statusNum = parseInt(status)
  let border = false
  let color = '#3B3B3B'
  if (statusNum === -1) { // 草稿
    color = '#3B3B3B'
  } else if (statusNum === 0) { // 草稿
    return h('span', { props: { color: '#fff' } }, showName)
  } else if (statusNum === 4) { // 待审批
    color = '#a59426'
  } else if (statusNum === 5) { // 已通过
    color = '#44bd32'
  } else if (statusNum === 6) { // 已拒绝
    color = '#ef4f4f'
  } else if (statusNum === 9) { // 已归档
    border = true
    color = '#141414'
  }

  return h('tag', { props: { color: color }, style: { border: border ? '1px solid #fff' : 'none' } }, showName)
}

/**
 * 代理商对账单状态
 * @param {*} h
 * @param {*} type
 * @param {*} showName
 */
export function formatAgentStatement (h, type, showName) {
  let color = 'grey'
  switch (type) {
    case 1:
      color = 'warning'
      break
    case 2:
      color = 'primary'
      break
    case 3:
      color = 'success'
      break
    case 4:
      color = 'black'
      break
    default:
      break
  }
  return h('tag', { props: { color: color } }, showName)
}
// 客户状态标识
export function formatCustomerStatus (h, status, showName) {
  const statusNum = parseInt(status)
  let color = '#3B3B3B'
  let border = false
  if (statusNum === -2) { // 无效|待认领的客户
    return h('span', { props: { color: '#fff' } }, showName)
  } else if (statusNum === -1) { // 已作废
    color = '#3B3B3B'
  } else if (statusNum === 0) { // 未通过
    color = '#ef4f4f'
  } else if (statusNum === 1) { // 审批中
    color = '#a59426'
  } else if (statusNum === 2) { // 已通过
    color = '#44bd32'
  } else if (statusNum === 3) { // 待行权  前方有正在行权的客户
    color = '#44bd32'
  } else if (statusNum === 4) { // 正在行权
    color = '#44bd32'
  } else if (statusNum === 5) { // 保护期
    color = '#44bd32 '
  } else if (statusNum === 6) { // 已签约
    border = true
    color = '#141414'
  }

  return h('tag', { props: { color: color }, style: { border: border ? '1px solid #fff' : 'none' } }, showName)
}
// 客户保护期
export function formatCustomerDifference (h, showName) {
  let color = '#ffffff'
  if (showName === '0') {
    color = '#aeaeae'
    showName = '不保护'
  } else if (parseInt(showName) <= 30) {
    color = '#ee7c4b'
  }

  return h('span', { style: { color: color } }, showName)
}
// 品牌状态
export function formatBrandStatus (h, status, showName) {
  const statusNum = parseInt(status)
  let color = '#3B3B3B'
  let border = false
  if (statusNum === -2) { // 已过期
    color = '#3B3B3B'
  } else if (statusNum === -1) { // 已过期
    color = '#3B3B3B'
  } else if (statusNum === 0) { // 未通过
    color = '#ef4f4f'
  } else if (statusNum === 1) { // 审批中
    color = '#a59426'
  } else if (statusNum === 2) { // 已通过
    color = '#44bd32'
  } else if (statusNum === 3) { // 先签先得
    color = '#44bd32'
  } else if (statusNum === 5) { // 已签约
    border = true
    color = '#141414'
  }

  return h('tag', { props: { color: color }, style: { border: border ? '1px solid #fff' : 'none' } }, showName)
}
// 合同和合同变更
export function formatContractStatus (h, status, showName) {
  const statusNum = parseInt(status)
  let color = '#3B3B3B'
  let border = false
  if (statusNum === 0) { // 草稿
    return h('span', { props: { color: '#fff' } }, showName)
  } else if (statusNum === 3) { // 创建合同中
    color = '#a59426'
  } else if (statusNum === 4) { // 待审批
    color = '#a59426'
  } else if (statusNum === 5) { // 已通过
    color = '#44bd32'
  } else if (statusNum === 6) { // 已拒绝
    color = '#ef4f4f'
  } else if (statusNum === 9) { // 已归档
    border = true
    color = '#141414'
  }

  return h('tag', { props: { color: color }, style: { border: border ? '1px solid #fff' : 'none' } }, showName)
}

// 交易对账状态
export function formatReconcilliationStatus (h, status, showName) {
  const statusNum = parseInt(status)
  let color = '#3B3B3B'
  let border = false
  if (statusNum === 0) { // 未结算
    return h('span', { props: { color: '#fff' } }, showName)
  } else if (statusNum === 1) { // 已结算
    border = true
    color = '#141414'
  } else if (statusNum === 2) { // 未结算
    color = '#a59426'
  }
  return h('tag', { props: { color: color }, style: { border: border ? '1px solid #fff' : 'none' } }, showName)
}

// 运维对账状态
export function formatMainanceReconcilliationStatus (h, status, showName) {
  const statusNum = status
  let color = '#3B3B3B'
  let border = false
  if (statusNum === 1) { // 未对账
    return h('span', { props: { color: '#fff' } }, showName)
  } else if (statusNum === 2) { // 已对账
    border = true
    color = '#141414'
  }
  return h('tag', { props: { color: color }, style: { border: border ? '1px solid #fff' : 'none' } }, showName)
}

// 结算状态
export function formatsettlementStatus (h, status, showName) {
  const statusNum = status
  let color = '#3B3B3B'
  let border = false
  if (statusNum === 0) { // 草稿
    return h('span', { props: { color: '#fff' } }, showName)
  } else if (statusNum === 1) { // 已对账
    border = true
    color = '#141414'
  } else if (statusNum === 4) { // 待审批
    color = '#a59426 '
  } else if (statusNum === 5) { // 已通过
    color = '#44bd32 '
  } else if (statusNum === 6) { // 已拒绝
    color = '#ef4f4f '
  }
  return h('tag', { props: { color: color }, style: { border: border ? '1px solid #fff' : 'none' } }, showName)
}

// 结算状态
export function formatbillsettlementStatus (h, status, showName) {
  const statusNum = status
  let color = '#3B3B3B'
  let border = false
  if (statusNum === -1) { // 草稿
    return h('span', { props: { color: '#fff' } }, showName)
  } else if (statusNum === 0) { // 待结算
    border = true
    color = '#141414'
  } else if (statusNum === 4) { // 已对账
    color = '#4285F4'
  } else if (statusNum === 5) { // 已结算
    color = '#44BD32'
  }
  return h('tag', { props: { color: color }, style: { border: border ? '1px solid #fff' : 'none' } }, showName)
}

// 合同备注
export function formatContractRemark (h, contract) {
  // let publicTag = ''
  // let microBusinessTag = ''
  // if (contract.invaliding) {
  //   publicTag = h('tag', { props: { color: '#a59426' } }, '作废中')
  // }
  // if (contract.contractCategory === 2) {
  //   microBusinessTag = h('tag', { props: { color: 'blue' } }, '小微')
  // }
  // return h('div', [publicTag, h('span', contract.mark)])
}

// 工作小组类型
export function formatWorkGroupType (h, type) {
  let showName = '未知'

  if (type === 0) {
    showName = '上刊小组'
  } else if (type === 1) {
    showName = '巡检小组'
  } else if (type === 2) {
    showName = '维修小组'
  } else if (type === 3) {
    showName = '监播小组'
  } else if (type === 4) {
    showName = '清洁小组'
  } else if (type === 5) {
    showName = '测量小组'
  }

  return h('span', showName)
}
// 结算状态
export function formatbillPayStatus (h, status, showName) {
  const statusNum = status
  let color = '#3B3B3B'
  let border = false
  if (statusNum === 0) { // 草稿
    return h('span', { props: { color: '#fff' } }, showName)
  } else if (statusNum === 1) { // 待付款
    border = true
    color = '#a59426 '
  } else if (statusNum === 2) { // 已付款
    color = '#141414'
  }
  return h('tag', { props: { color: color }, style: { border: border ? '1px solid #fff' : 'none' } }, showName)
}
// 置换合同状态
export function formatDisplaceStatus (h, status, showName) {
  const statusNum = parseInt(status)
  let color = '#3B3B3B'
  let border = false
  if (statusNum === 1) { // 草稿
    return h('span', { props: { color: '#fff' } }, showName)
  } else if (statusNum === 2) { // 拒绝
    color = '#ef4f4f'
  } else if (statusNum === 3) { // 审批中
    color = '#a59426'
  } else if (statusNum === 5) { // 正常
    border = true
    color = '#141414'
  }

  return h('tag', { props: { color: color }, style: { border: border ? '1px solid #fff' : 'none' } }, showName)
}
