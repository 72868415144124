<template>
  <div>
    <div class="p-b-10">
      <Row v-if="statementApply">
        <i-col span="24" class="text-right">
          <Button type="primary" size="small" class="m-r-5" @click="showHistory=true"
            >审批记录</Button>
          <Button type="success" :loading="submitloading" size="small" @click="confirmSettlement"  v-if="allowEdit"
            >提交审核</Button
          ></i-col
        >
      </Row>
      <Row v-else>
        <i-col span="24" class="text-right">
          <Button type="success" size="small" @click="confirmSettlement" v-if="allowConfirm"
            >确认执行额</Button
          ></i-col
        >
      </Row>
      <div class="workplatform-title">{{  billDetail.name||' - ' }}({{billDetail.code}})<a style="font-size:12px;" v-if="allowEdit" @click="handleChangeName">修改名称</a></div>

      <Row>
          <i-col span="8">
            <span class="title">结算公司 </span>{{ billDetail.purchaserCompanyName }}
          </i-col>
          <i-col span="8">
            <span class="title">供应商 </span>{{ billDetail.supplierCompanyName }}
          </i-col>
          <i-col span="8">
            <span class="title">创建时间 </span>{{ billDetail.createTime }}
          </i-col>
        </Row>

        <Row >
          <i-col span="24">
            <span  class="title">最终结算金额 </span
            ><span class="money">{{
              formatMoney(billDetail.billAmount)
            }}</span>
          </i-col>
        </Row>
        <Row >
          <i-col span="8">
            <span class="title"> 计费项金额  </span>
            <span class="money">{{ formatMoney(billDetail.normalAmount) }}</span>
          </i-col>
          <i-col span="8">
            <span class="title"> 调整项⾦额  </span>
             <span class="money">{{ formatMoney(billDetail.adjustAmount) }}</span>
          </i-col>
          <i-col span="8">
            <span class="title"> 罚款项金额 </span>
             <span class="money">{{ formatMoney(billDetail.penaltyAmount) }}</span>
          </i-col>
        </Row>
        <Row >
          <i-col span="24">
            <span class="title">资产 </span>
            {{ billDetail.zones || "-" }}
          </i-col>
        </Row>
        <Row>
          <i-col span="24">
            <span class="title"> 备注: </span>
            {{ billDetail.mark || "-" }}
            <a v-if="allowEdit" @click="handleChangeCode">修改备注</a>
          </i-col>
        </Row>
    </div>
    <div class="p-t-10">
      <div class="workplatform-title">费用清单列表</div>
      <Tabs>
        <TabPane label="计费项">
          <template>
            <Row class="m-b-5">
              <i-col span="16">
                <Row :gutter="8">
                  <i-col span="18">
                    <i-input
                      type="text"
                      size="small"
                      v-model="feeitemQuery.keyword"
                      clearable
                      placeholder="关键字"
                    ></i-input>
                  </i-col>
                  <i-col span="6">
                    <Button
                      icon="ios-search"
                      type="primary"
                      size="small"
                      @click="searchfeeitems"
                    >
                      搜索
                    </Button>
                    <Button  v-if="allowEdit" type="primary" size="small" class="m-l-5" @click="addFeeitems"
                  >添加明细</Button
                >
                  </i-col>
                </Row>
              </i-col>
            </Row>
            <Table
              stripe
              class="m-t-5"

              :max-height="500"
              :data="feeitempage"
              :columns="column"
              :summary-method="handleSummary"
              show-summary
            ></Table>
            <div class="paging_style">
          <Page size="small"
            :total="feeitemTotal"
            :page-size="feeitemQuery.pageSize"
            show-total
            show-elevator
            show-sizer
            :page-size-opts="[15, 50, 100, 200, 500]"
            @on-page-size-change="handlePageSizeChanged"
            :current="feeitemQuery.pageNumber"
            @on-change="feeitemChangePage"
          ></Page>
        </div>
          </template>
        </TabPane>
        <TabPane label="调整项">
          <template>
            <Row class="m-b-5">
              <i-col span="24" class="text-right">
                <Button type="info" size="small" class="m-r-5" @click="modaluploadFile=true"
                  >{{allowEdit?'附件管理':'附件信息'}}</Button
                >
                <Button type="primary"  v-if="allowEdit" size="small" @click="handleAdd(0)"
                  >添加调整项</Button
                >
              </i-col>
            </Row>
            <Table
              stripe
              :data="adjustfeeitems"
              :max-height="500"
              :columns="adjustmspfeeitemColumn"
              :summary-method="handleSummary"
              show-summary
            ></Table>
             <div class="paging_style">
          <Page size="small"
            :total="adjustfeeitemTotal"
            :page-size="adjustfeeitemQuery.pageSize"
            show-total
            show-elevator
            show-sizer
            :page-size-opts="[15, 50, 100, 200, 500]"
            @on-page-size-change="handleadjustfeeitemPageSizeChanged"
            :current="adjustfeeitemQuery.pageNumber"
            @on-change="adjustfeeitemChangePage"
          ></Page>
        </div>
          </template>
        </TabPane>
        <TabPane label="罚款项">
          <template>
            <Row class="m-b-5" v-if="allowEdit">
              <i-col span="24" class="text-right">
                <Button type="primary" size="small" @click="addFineItems"
                  >添加罚款项</Button
                ></i-col
              >
            </Row>
            <Table
              stripe
              :data="fines"
              :max-height="500"
              :columns="fineColumns"
              :summary-method="handleSummary"
              show-summary
            ></Table>
             <div class="paging_style">
          <Page size="small"
            :total="fineTotal"
            :page-size="fineQuery.pageSize"
            show-total
            show-elevator
            show-sizer
            :page-size-opts="[15, 50, 100, 200, 500]"
            @on-page-size-change="handlefinePageSizeChanged"
            :current="fineQuery.pageNumber"
            @on-change="fineChangePage"
          ></Page>
        </div>
          </template>
        </TabPane>
        <TabPane label="含税汇总" >
          <template>
            <Table
              stripe
              :data="summary"
              :max-height="500"
              :columns="summaryColumns"
              :summary-method="handleSummary"
              show-summary
            ></Table>
          </template>
        </TabPane>
      </Tabs>
    </div>
    <AddFeeitem ref="addFeeitemModal"></AddFeeitem>
    <AddFineItem ref="addFineItemModal"></AddFineItem>

    <!-- <transactionHistory ref="transactionHistoryModal"></transactionHistory> -->

    <Modal v-model="modaluploadFile" width="800" :footer-hide="true" title="调整项附件">
      <div>
         <fileUpload type="STATEMENT_BILL_ADJUST" :relateId="billId" :allowUpload="allowEdit" :format="fileTypes" ></fileUpload>
      </div>
    </Modal>
    <Modal v-model="modalAdd" width="800">
      <Form
        ref="adjustment"
        :model="formData"
        :rules="formDataValidate"
        label-position="top"
      >
        <FormItem label="费用类型" prop="feeType">
          <Select v-model="formData.feeType" placeholder="请选择费用类型">
            <Option
              v-for="item in adjustablefeetypeArray"
              :key="'ft_' + item.feeType"
              :value="item.feeType"
              >{{ item.feeTypeName }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="调整项金额" prop="amount">
          <InputNumber
            v-model="formData.amount"
            style="width: 100%; color: #fff; background: #1a1a1a"
            placeholder="请填写调整项金额"
          ></InputNumber>
        </FormItem>
         <FormItem label="生效时间（该时间作为对应结算周期的生效时间）" prop="chargeTime">
            <DatePicker
          placeholder="生效时间"
          type="date"
          v-model="formData.chargeTime"
          style="width: 100%"
        ></DatePicker>
        </FormItem>
        <FormItem label="调整说明">
          <Input
            v-model="formData.mark"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 5 }"
            placeholder="调整说明"
          />
        </FormItem>
      </Form>

      <div slot="footer">
        <Button type="text"  @click="modalAdd=false">取消</Button>
        <Button type="primary"  @click="handleSave"  :loading="loading" >确认</Button>
      </div>
    </Modal>
     <Modal v-model="showHistory" :width="800" footer-hide>
      <history :relateId="billId" :type="approvalType" v-if="showHistory"></history>
    </Modal>
     <!-- 弹窗 合同详情 -->
     <Modal v-model="modalContract" :width="1200" footer-hide>
        <contract-detail v-if="modalContract" :id="contractId"  :versionId="contractId" ></contract-detail>
      </Modal>

      <Modal  v-model="showHistoryModal" width="1200" title="合同执行额历史"  footer-hide>
          <transactionHistory v-if="chkContractId" :contractId="chkContractId"></transactionHistory>
      </Modal>

  </div>
</template>

<script>
import {
  getbilloutline,
  getmspfeeitempage,
  batchaddorremovefeeitemlist,
  getadjustablefeetypelist,
  addorupdateadjustfeeitemtobill,
  getadjustfeeitempage,
  removeadjustfeeitemfrombill,
  updatebill,
  submitbill,
  getbillamountstructurewithtaxratio,
  setbilltaxratio,
  getValidContractList
} from '@/api/statement/bill'
import { getPenaltyPage } from '@/api/statement/penalty'
import { toMoney } from '@/utils/wnumb_own'
import { ParseDate } from '@/utils/dateFormat'
import AddFeeitem from './AddFeeitem'
import AddFineItem from './AddFineItem'
import transactionHistory from './transactionHistory'
import fileUpload from '@/components/common/FileUpload'
import History from '@/components/approval/History'

import ContractDetail from '@/components/contract/contractDetail/Contract'

export default {
  components: {
    AddFeeitem,
    AddFineItem,
    fileUpload,
    History,
    transactionHistory,
    ContractDetail
  },
  props: {},
  data () {
    return {

      loading: false,

      // 获取是否开启了结算单审批流程
      statementApply: this.$store.getters.token.publisherSetting.statementApply,
      showHistory: false,
      approvalType: 20,
      // 附件
      modaluploadFile: false,
      fileTypes: ['img', 'jpg', 'png', 'doc', 'docx', 'pdf', 'xls', 'xlsx'],

      billDetail: {},
      feeitempage: [],
      column: [],

      modalContract: false,
      contractId: 0,

      feeitemTotal: 0,
      mspfeeitemColumn: [
        {
          title: '供应商',
          key: 'supplierCompanyName',
          render: (h, params) => {
            return h('div', [h('p', params.row.supplierCompanyName)])
          }
        },
        {
          title: '代理商',
          key: 'createCompanyName',
          render: (h, params) => {
            return h('div', [h('p', params.row.createCompanyName)])
          }
        },
        {
          title: '资源编号',
          key: 'resourceCode',
          render: (h, params) => {
            return h('div', [h('p', params.row.resourceCode)])
          }
        },
        {
          title: '资产',
          key: 'code',
          render: (h, params) => {
            return h('div', [h('p', params.row.assetName)])
          }
        },
        {
          title: '位置',
          key: 'position',
          render: (h, params) => {
            return h('div', [h('p', params.row.position)])
          }
        },
        {
          title: '资源类型',
          key: 'startDate',
          render: (h, params) => {
            return h('div', [h('p', params.row.resourceTypeName)])
          }
        },

        {
          title: '作业类型',
          key: 'taskTypeName',
          render: (h, params) => {
            return h('div', [h('p', params.row.taskTypeName)])
          }
        },
        {
          title: '客户',
          key: 'advertiserName'
        },
        {
          title: '品牌',
          key: 'brandName',
          render: (h, params) => {
            return h('div', [h('p', params.row.brandName || '-')])
          }
        },
        {
          title: '用途',
          key: 'adTypeName',
          render: (h, params) => {
            return h('div', [h('p', params.row.adTypeName || '-')])
          }
        },
        {
          title: '记账金额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.amount)
              )
            ])
          }
        },
        {
          title: '单价',
          key: 'unitPrice'
        },
        {
          title: '计费数量',
          key: 'quantity'
        },
        {
          title: '派单时间',
          key: 'supplierDispatchTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.supplierDispatchTime)])
          }
        },
        {
          title: '作业日期',
          key: 'taskRequireFinishTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.taskRequireFinishTime)])
          }
        },
        {
          title: '作业完成时间',
          key: 'taskFinishTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.taskFinishTime)])
          }
        },
        {
          title: '质检时间',
          key: 'qualityCheckTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.qualityCheckTime)])
          }
        },
        {
          title: '记账时间',
          key: 'confirmTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.confirmTime)])
          }
        },
        {
          title: '备注',
          key: 'mark',
          render: (h, params) => {
            return h('div', [h('p', params.row.mark || '-')])
          }
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              this.allowEdit
                ? h(
                  'a',
                  {
                    style: { marginRight: '5px', color: '#ef4f4f' },
                    on: {
                      click: () => {
                        this.removeItem(
                          2,
                          JSON.stringify([params.row.feeitemId])
                        )
                      }
                    }
                  },
                  '移除'
                )
                : null
            ])
          }
        }
      ],
      feeitemQuery: {
        pageSize: 15,
        pageNumber: 1,
        feeitemType: null,
        keyword: ''
      },
      // 调整项
      modalAdd: false,
      adjustablefeetypeArray: [],
      chooseContract: true,
      contractArray: [],
      isAdd: true,
      formData: {
        billId: 0,
        baseFeeitemId: null,
        feeType: null,
        amount: 0,
        mark: ''
      },
      formDataValidate: {
        baseFeeitemId: [
          {
            required: true,
            type: 'number',
            message: ' ',
            trigger: 'change'
          }
        ],
        feeType: [
          {
            required: true,
            type: 'number',
            message: ' ',
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            type: 'number',
            message: ' ',
            trigger: 'blur'
          }
        ]
      },

      adjustfeeitemTotal: 0,
      adjustfeeitemQuery: {
        pageSize: 15,
        pageNumber: 1
      },
      adjustfeeitems: [],
      adjustmspfeeitemColumn: [
        {
          title: '类别',
          key: 'feeTypeName'
        },
        {
          title: '备注',
          key: 'mark'
        },
        {
          title: '金额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.amount)
              )
            ])
          }
        },
        {
          title: '生效时间',
          key: 'chargeTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.chargeTime)])
          }
        },
        {
          title: '创建时间',
          key: 'createTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.createTime)])
          }
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              this.allowEdit
                ? h(
                  'a',
                  {
                    style: { marginRight: '5px' },
                    on: {
                      click: () => {
                        this.formData.feeType = params.row.feeType
                        this.formData.amount = params.row.amount
                        this.formData.mark = params.row.mark
                        this.formData.feeitemId = params.row.feeitemId
                        this.isAdd = false
                        this.modalAdd = true
                      }
                    }
                  },
                  '编辑'
                )
                : null,
              this.allowEdit
                ? h(
                  'a',
                  {
                    style: { marginRight: '5px', color: '#ef4f4f' },
                    on: {
                      click: () => {
                        const that = this
                        that.$Modal.confirm({
                          title: '确定移除？',
                          content:
                              '<p>确定要在当前结算单中移除该调整项？</p>',
                          loading: true,
                          onOk: () => {
                            const query = {
                              billId: that.billId,
                              feeitemId: params.row.feeitemId
                            }
                            removeadjustfeeitemfrombill(query)
                              .then((res) => {
                                if (res && !res.errcode) {
                                  that.$Notice.success({
                                    desc: '移除调整项成功！'
                                  })

                                  that.initBillDetail()
                                  that.initAdjustfeeitems()
                                  that.initbillamountstructurewithtaxratio()
                                  that.$store.commit('set_settlementSupplier_update', new Date())
                                  that.$Modal.remove()
                                }
                              })
                              .catch(() => {
                                that.$Modal.remove()
                              })
                          }
                        })
                      }
                    }
                  },
                  '移除'
                )
                : null
            ])
          }
        }
      ],
      // 罚款
      fineTotal: 0,
      fineQuery: {
        pageSize: 15,
        pageNumber: 1
      },
      fines: [],
      fineColumns: [
        { title: '罚款日期', align: 'center', key: 'penaltyDate' },
        {
          title: '罚款金额',
          align: 'center',
          key: 'amount',
          render: (h, params) => {
            // return toMoney(params.row.amount)
            return h('span', { class: 'money' }, toMoney(params.row.amount))
          }
        },
        { title: '罚款原因', align: 'center', key: 'content' },
        { title: '创建时间', align: 'center', key: 'createTime' },
        { title: '创建人员', align: 'center', key: 'createUserName' },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              this.allowEdit
                ? h(
                  'a',
                  {
                    style: { marginRight: '5px', color: '#ef4f4f' },
                    on: {
                      click: () => {
                        this.removeItem(3, JSON.stringify([params.row.id]))
                      }
                    }
                  },
                  '移除'
                )
                : null
            ])
          }
        }
      ],
      allowEdit: false, // 允许编辑
      allowConfirm: false, // 允许确认,
      submitloading: false,

      summary: [],
      summaryColumns: [
        { title: '任务类型', align: 'center', key: 'feeTypeName' },
        {
          title: '不含税价',
          align: 'center',
          key: 'amountExcludeTax',
          render: (h, params) => {
            // return toMoney(params.row.amount)
            return h('span', toMoney(params.row.amountExcludeTax))
          }
        },
        {
          title: '税点',
          align: 'center',
          key: 'taxRatio',
          render: (h, params) => {
            return h('span', params.row.needTaxRatio ? (params.row.taxRatio * 100).toFixed(2) + '%' : '-')
          }
        },
        {
          title: '含税价',
          align: 'center',
          key: 'amount',
          render: (h, params) => {
            // return toMoney(params.row.amount)
            return h('span', toMoney(params.row.amount))
          }
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              this.allowEdit && params.row.needTaxRatio
                ? h(
                  'a',
                  {
                    style: { marginRight: '5px' },
                    on: {
                      click: () => {
                        this.handleChangeTaxRate(params.row)
                        // this.removeItem(3, JSON.stringify([params.row.id]))
                      }
                    }
                  },
                  '调整税率'
                )
                : null
            ])
          }
        }
      ],

      showHistoryModal: false,
      chkContractId: 0
    }
  },
  created () {
    this.feeitemQuery.billId = this.billId
    this.approvalType = 21
    this.initfeeitemColumn()
    this.initBillDetail()
    this.searchfeeitems()
    this.initAdjustfeeitems()
    this.Initadjustablefeetypelist()
    this.initBillFineArray()
    this.initbillamountstructurewithtaxratio()
  },
  computed: {
    billId () {
      return this.$store.state.settlementSupplier.billId
    },
    beginUpdate () {
      return this.$store.state.settlementSupplier.beginUpdate
    },
    publisherId () {
      return this.$store.getters.token.userInfo.publisherId
    }
  },
  methods: {
    // 计费项分页变更
    feeitemChangePage (currentPage) {
      this.feeitemQuery.pageNumber = currentPage
      this.initbillList()
    },
    // 计费项页大小变更
    handlePageSizeChanged (pagesize) {
      this.feeitemQuery.pageNumber = 1
      this.feeitemQuery.pageSize = pagesize
      this.initbillList()
    },

    // 调整项分页变更
    adjustfeeitemChangePage (currentPage) {
      this.adjustfeeitemQuery.pageNumber = currentPage
      this.initAdjustfeeitems()
    },
    // 调整项页大小变更
    handleadjustfeeitemPageSizeChanged (pagesize) {
      this.adjustfeeitemQuery.pageNumber = 1
      this.adjustfeeitemQuery.pageSize = pagesize
      this.initAdjustfeeitems()
    },

    // 罚款项分页变更
    fineChangePage (currentPage) {
      this.fineQuery.pageNumber = currentPage
      this.initBillFineArray()
    },
    // 罚款项页大小变更
    handlefinePageSizeChanged (pagesize) {
      this.fineQuery.pageNumber = 1
      this.fineQuery.pageSize = pagesize
      this.initBillFineArray()
    },

    initfeeitemColumn () {
      const that = this
      that.column = that.mspfeeitemColumn
    },
    formatMoney (number) {
      return toMoney(number)
    },
    // 数据求汇总
    handleSummary ({ columns, data }) {
      const needSummarykey = ['amount', 'amountExcludeTax', 'contractAmount', 'periodExecutedAmount', 'periodExecutedUseAmount', 'periodExecutedAdjustAmount', 'periodExecutedServiceAmount']
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          }
          return
        }
        if (needSummarykey.includes(key)) {
          const values = data.map((item) => Number(item[key]))
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[key] = {
              key,
              value: toMoney(v)
            }
          } else {
            sums[key] = {
              key,
              value: '-'
            }
          }
        } else {
          sums[key] = {
            key,
            value: '-'
          }
        }
      })

      return sums
    },
    // 加载结算单基本信息
    initBillDetail () {
      const that = this
      getbilloutline({ billId: that.billId }).then((res) => {
        that.billDetail = res
        that.allowEdit =
          that.billDetail.status === 0 && that.isAuth('settlement_maintenance_create')
        that.allowConfirm =
          that.billDetail.status === 0 && that.isAuth('settlement_maintenance_confirm')
      })
    },
    // 搜索计费项
    searchfeeitems () {
      this.feeitemQuery.pageNumber = 1
      this.feeitempage = []
      this.initbillList()
    },
    // 加载所有结算项(分页)
    initbillList () {
      const that = this
      that.feeitemQuery.feeitemType = 2
      getmspfeeitempage(that.feeitemQuery).then((res) => {
        that.feeitempage = res.list
        that.feeitemTotal = res.totalRow
      })
    },

    addFeeitems () {
      this.$refs.addFeeitemModal.showModal(
        2,
        this.billDetail,
        // this.feeitemType === 1
        //   ? this.billDetail.agentCompanyId
        //   : this.billDetail.supplierCompanyId,
        this.billId
      )
    },
    removeItem (feeitemType, feeitemListJsonStr) {
      const that = this
      that.$Modal.confirm({
        title: '确定移除？',
        content: '<p>确定要在当前结算单中移除该计费项？</p>',
        loading: true,
        onOk: () => {
          const query = {
            billId: that.billId,
            feeitemType: feeitemType,
            feeitemListJsonStr: feeitemListJsonStr,
            action: -1
          }
          batchaddorremovefeeitemlist(query)
            .then((res) => {
              if (res && !res.errcode) {
                that.$Notice.success({ desc: '操作成功!' })
                switch (feeitemType) {
                  case 1:
                    that.searchfeeitems()
                    break
                  case 2:
                    that.searchfeeitems()
                    break
                  case 3:
                    that.initBillFineArray()
                    break
                  default:
                    break
                }
                that.initBillDetail()
                that.initbillamountstructurewithtaxratio()
                that.$store.commit('set_settlementSupplier_update', new Date())
                that.$Modal.remove()
              }
            })
            .catch(() => {
              that.$Modal.remove()
            })
        }
      })
    },

    // 获取调整项类型
    Initadjustablefeetypelist () {
      const that = this
      getadjustablefeetypelist({ billType: 2 }).then((res) => {
        that.adjustablefeetypeArray = res
      })
    },

    // 打开添加调整项的弹窗
    handleAdd (baseFeeitemId) {
      if (baseFeeitemId) {
        this.chooseContract = false
        this.formData.baseFeeitemId = baseFeeitemId
      } else {
        this.chooseContract = true
        this.formData.baseFeeitemId = baseFeeitemId

        this.initContracts()
      }

      this.formData.adjustType = null

      this.formData.feeType = null
      this.formData.amount = null
      this.formData.mark = ''
      this.formData.feeitemId = null
      this.isAdd = true
      this.modalAdd = true
    },

    // 打开执行额历史弹窗
    handleShowHistory (contractId) {
      this.chkContractId = contractId
      this.showHistoryModal = true
      // this.$refs.transactionHistoryModal.showModal(
      //   baseFeeitemId
      // )
    },

    //  加载所有可选的合同
    initContracts () {
      getValidContractList({ agentCompanyId: this.billDetail.agentCompanyId }).then(res => {
        this.contractArray = res.map(item => { return { id: item.feeitemId, name: item.contractCode + '_' + item.advertiserName } })
      })
    },

    /**
     * 保存人工调整项
     */
    handleSave () {
      const that = this
      that.$refs.adjustment.validate((valid) => {
        if (valid) {
          that.loading = true
          that.formData.billId = that.billId
          if (that.formData.chargeTime) {
            that.formData.chargeTime = ParseDate(that.formData.chargeTime)
          }
          addorupdateadjustfeeitemtobill(that.formData).then((res) => {
            if (res && res.errcode === 0) {
              that.initBillDetail()
              that.initAdjustfeeitems()
              that.initbillamountstructurewithtaxratio()
              that.$store.commit('set_settlementSupplier_update', new Date())
              that.$Notice.success({ desc: '操作成功' })
            } else {
              that.$Notice.error({ title: '操作失败', desc: res.errmsg })
            }
            that.modalAdd = false
            that.loading = false
          })
        }
      })
    },
    // 加载调整项列表
    initAdjustfeeitems () {
      const that = this
      getadjustfeeitempage({
        billId: that.billId,
        pageSize: that.adjustfeeitemQuery.pageSize,
        pageNumber: that.adjustfeeitemQuery.pageNumber
      }).then((res) => {
        that.adjustfeeitems = res.list
        that.adjustfeeitemTotal = res.totalRow
      })
    },

    // 罚款项
    addFineItems () {
      this.$refs.addFineItemModal.showModal(
        2,
        this.billDetail.supplierCompanyId,
        this.billId,
        this.publisherId
      )
    },
    // 加载结算单内所有罚款项
    initBillFineArray () {
      const that = this
      const query = {
        feeType: 52,
        // agentCompanyId: that.billDetail.agentCompanyId,
        publisherId: that.publisherId,
        status: 5,
        billId: that.billId,
        pageSize: that.fineQuery.pageSize,
        pageNumber: that.fineQuery.pageNumber
      }
      query.supplierCompanyId = that.billDetail.supplierCompanyId
      getPenaltyPage(query).then((res) => {
        that.fineTotal = res.totalRow
        that.fines = res.list
      })
    },
    // 加载结算单内价格汇总
    initbillamountstructurewithtaxratio () {
      const that = this
      const query = {
        billId: that.billId
      }
      getbillamountstructurewithtaxratio(query).then((res) => {
        that.summary = res
      })
    },

    // 结算单更改备注
    handleChangeCode () {
      let newMark = this.billDetail.mark
      this.$Modal.confirm({
        title: '更改结算单的备注',
        render: (h) => {
          return h('Input', {
            props: {
              value: newMark
            },
            on: {
              'on-change': (event) => {
                newMark = event.target.value
              }
            }
          })
        },
        onOk: () => {
          updatebill({ billId: this.billId, mark: newMark, name: this.billDetail.name, zones: this.billDetail.zones }).then((res) => {
            this.$Notice.success({ desc: '更改结算单备注成功' })
            this.$store.commit('set_settlementSupplier_update', new Date())
            this.initBillDetail()
          })
        }
      })
    },
    // 结算单更改名称
    handleChangeName () {
      let newName = this.billDetail.name
      this.$Modal.confirm({
        title: '更改结算单的名称',
        render: (h) => {
          return h('Input', {
            props: {
              value: newName
            },
            on: {
              'on-change': (event) => {
                newName = event.target.value
              }
            }
          })
        },
        onOk: () => {
          updatebill({ billId: this.billId, mark: this.billDetail.mark, name: newName, zones: this.billDetail.zones }).then((res) => {
            this.$Notice.success({ desc: '更改结算单名称成功' })
            this.$store.commit('set_settlementSupplier_update', new Date())
            this.initBillDetail()
          })
        }
      })
    },
    // 税率变更
    handleChangeTaxRate (taxInfo) {
      let taxValue = (taxInfo.taxRatio * 100).toFixed(2)
      this.$Modal.confirm({
        title: '更改' + taxInfo.feeTypeName + '税率',
        render: (h) => {
          return h('Input', {
            props: {
              type: 'number',
              min: 0,
              value: taxValue
            },
            on: {
              'on-change': (event) => {
                taxValue = event.target.value
              }
            }
          })
        },
        onOk: () => {
          setbilltaxratio({ billId: this.billId, feeItemType: taxInfo.feeItemType, feeType: taxInfo.feeType, taxRatio: (taxValue / 100) }).then((res) => {
            this.$Notice.success({ desc: '更改' + taxInfo.feeTypeName + '税率成功！' })
            this.initbillamountstructurewithtaxratio()
          })
        }
      })
    },
    // 确认执行额
    confirmSettlement () {
      this.$Modal.confirm({
        title: this.statementApply ? '提交结算' : '确认执行额',
        content:
          this.statementApply ? '<p>提交此结算进入审批流程，确认继续？</p>' : '<p>确认执行额以后所有计费项将不可再次编辑，此操作不可逆转，确认继续？</p>',
        onOk: () => {
          this.submitloading = true
          submitbill({ billId: this.billId }).then((res) => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '操作成功！' })
              this.$store.commit('set_settlementSupplier_update', new Date())
              this.initBillDetail()
            }
            this.submitloading = false
          })
        }
      })
    }
  },
  watch: {
    billId (val) {
      this.initBillDetail()
    },
    beginUpdate (val) {
      this.initBillDetail()
      this.searchfeeitems()
      this.initBillFineArray()
      this.initbillamountstructurewithtaxratio()
    }
  }
}
</script>
