<template>
  <div>
    <Row :gutter="8" class="m-b-5">
      <i-col :xs="24" :sm="12" :md="4" :lg="4">
        <Select
          size="small"
          v-model="advertiserStatus"
          placeholder="客户状态"
          clearable
        >
          <Option
            v-for="(status, index) in statuslist"
            :key="index"
            :value="status.value"
            >{{ status.name }}</Option
          >
        </Select>
      </i-col>

      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <Select
          v-model="query.companyId"
          size="small"
          placeholder="所属公司"
          clearable
          @on-change="initDepartmentList"
        >
          <Option
            v-for="(channel, index) in channelCompanyList"
            :key="index"
            :value="channel.id"
            >{{ channel.name }}</Option
          >
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <Select
          v-model="query.departmentId"
          size="small"
          placeholder="所属部门"
          clearable
          @on-change="getChannelUserList"
        >
          <Option
            v-for="(department, index) in deparmentList"
            :key="index"
            :value="department.id"
            >{{ department.name }}</Option
          >
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <Select
          v-model="query.userId"
          placeholder="归属销售"
          clearable
          size="small"
        >
          <Option
            v-for="user in channelUsers"
            :key="user.id"
            :value="user.id"
            >{{ user.name }}</Option
          >
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4">
        <i-input
          size="small"
          v-model="query.keyword"
          placeholder="关键字：客户名称/投放品牌"
        ></i-input>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <Button
          size="small"
          type="primary"
          icon="ios-search"
          @click="SearchAdvertisers"
          >搜索</Button
        ><Button
          size="small"
          type="success"
          class="m-l-5"
          v-if="isAuth('Advertiser_Download_List')"
          @click="handleDownloadCustomer"
          >导出客户清单</Button
        >
      </i-col>
    </Row>

    <Table
      size="small"
      :columns="columnManagedata"
      :data="tableData"
      stripe
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="changePage"
      ></Page>
    </div>
    <Modal
      title="维护客户信息"
      v-model="ChangeCustomerUsermodel"
      :mask-closable="false"
      @on-ok="handleSubmit('customerData')"
    >
      <Row class="p-b-5">
        当前关联商家：
        <strong> {{ currentBusinessName }}</strong>
      </Row>
      <Row>
        <i-col span="24">
          <div>
            <Form
              ref="customerData"
              id="customerData"
              :model="customerData"
              :rules="ruleValidate"
              label-position="top"
            >
              <FormItem label="公司名称" prop="name">
                <Row :gutter="8">
                  <i-col span="18">
                    <Input
                      size="small"
                      v-model="customerData.name"
                      placeholder="请填写公司名称"
                    />
                  </i-col>
                  <i-col span="6">
                    <Button
                      size="small"
                      type="success"
                      :disabled="!customerData.name"
                      @click="searchConflictPage"
                      >商家检索</Button
                    >
                  </i-col>
                </Row>
              </FormItem>

              <FormItem
                label="统一信用代码/组织机构代码/纳税人识别号"
                prop="orgCode"
              >
                <Row :gutter="8">
                  <i-col span="18">
                    <Input
                      v-model="customerData.orgCode"
                      size="small"
                      placeholder="格式：91510100MA62L2A037、MA62L2A03、510108600454493"
                    />
                  </i-col>
                  <i-col span="6">
                    <Button
                      size="small"
                      :disabled="!customerData.name"
                      type="success"
                      @click="writeOrgCode"
                      >自动录入</Button
                    >
                  </i-col>
                </Row>
              </FormItem>

              <FormItem label="客户地址" prop="cityCode">
                <Row :gutter="4">
                  <i-col span="8">
                    <FormItem prop="provinceCode">
                      <Select
                        size="small"
                        v-model="customerData.provinceCode"
                        clearable
                        placeholder="省"
                      >
                        <Option
                          v-for="item in provinceList"
                          :value="item.code"
                          :key="item.code"
                          >{{ item.name }}</Option
                        >
                      </Select>
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem prop="cityCode">
                      <Select
                        size="small"
                        v-model="customerData.cityCode"
                        clearable
                        placeholder="市"
                      >
                        <Option
                          v-for="item in cityList"
                          :value="item.code"
                          :key="item.code"
                          >{{ item.name }}</Option
                        >
                      </Select>
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem prop="areaCode">
                      <Select
                        size="small"
                        v-model="customerData.areaCode"
                        clearable
                        placeholder="区"
                      >
                        <Option
                          v-for="item in regionList"
                          :value="item.code"
                          :key="item.code"
                          >{{ item.name }}</Option
                        >
                      </Select>
                    </FormItem>
                  </i-col>
                </Row>
              </FormItem>
              <FormItem prop="address">
                <i-input
                  size="small"
                  v-model.trim="customerData.address"
                  placeholder="输入详细地址"
                ></i-input>
              </FormItem>
              <FormItem label="联系电话">
                <strong> {{ customerData.tel }}</strong>
              </FormItem>
            </Form>

            <Modal
              v-model="customerConflictModal"
              title="商家池检索"
              width="800"
              :footer-hide="true"
            >
              <h2
                v-if="conflictdata.length === 0"
                class="text-green text-center m-t-50 p-b-50"
              >
                当前未检测到商家信息
              </h2>
              <Card v-else>
                <span slot="title">您要查找的商家可能是</span>
                <Table
                  stripe
                  :columns="conflictcolumndata"
                  :data="conflictdata"
                ></Table>
                <div class="paging_style">
                  <Page
                    size="small"
                    :total="conflictTotal"
                    :page-size="customerQuery.pageSize"
                    show-total
                    show-elevator
                    :current="customerQuery.pageNumber"
                    @on-change="conflictChangePage"
                  ></Page>
                </div>
              </Card>
            </Modal>
          </div>
        </i-col>
      </Row>
    </Modal>
    <Modal
      title="调整客户所属销售"
      v-model="ChangeCustomerSalermodel"
      :mask-closable="false"
      @on-ok="confirmChangeCustomerUser"
    >
      <Row class="p-b-5">
        当前所属代理商：
        <strong> {{ currentCompanyName }}</strong>
      </Row>
      <Row>
        变更代理商：
        <Select v-model="choiceComponyId" placeholder="选择代理商">
          <Option
            v-for="channel in channellist"
            :key="channel.id"
            :value="channel.id"
            >{{ channel.name }}</Option
          >
        </Select>
      </Row>
      <Row class="p-t-10 p-b-5">
        当前所属销售：
        <strong>{{ currentUserName }}</strong>
      </Row>
      <Row>
        变更销售：
        <Select
          class="p-b-5"
          v-model="choiceSalerId"
          placeholder="选择销售人员"
        >
          <Option
            v-for="user in choicechannelUsers"
            :key="user.id"
            :value="user.id"
            >{{ user.name }}</Option
          >
        </Select>
      </Row>
    </Modal>
  </div>
</template>

<script>
import { getcommercepage, updateadvertiser } from '@/api/crm/advertiser'
import { addAdvertiserForUser } from '@/api/crm/companyadvertiser'

import { allpage, getBusiness } from '@/api/sf/business'
import { queryorgcodebycompanyfullname } from '@/api/scp/invoice'
import {
  getProvinceList,
  getCityListByProvinceCode,
  getRegionListByCityCode
} from '@/api/sys/provincecityregion'
import { getDictList } from '@/api/sys/dict'

import { getCompanyPage, getCompanyChild } from '@/api/os/company'
import { getSellerPage } from '@/api/os/companyuser'

import { ParseDate } from '@/utils/dateFormat'
import { downloadFileRequest } from '@/utils/download'

import { formatCustomerStatus } from '@/utils/tagStatus'

export default {
  data () {
    return {
      channellist: [], // 代理商列表
      channelCompanyList: [],
      deparmentList: [],
      channelUsers: [], // 代理商下所有销售
      choicechannelUsers: [], // 用于变更客户所属销售的列表
      currentCompanyName: '', // 当前代理商名称
      choiceComponyId: 0, // 当前代理商Id
      currentUserName: '', // 当前销售名称
      choiceSalerId: 0, // 当前销售ID

      currentBusinessName: '', // 当前代理商名称

      statuslist: [],
      advertiserStatus: '',
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 15,
        publisherId: this.$store.getters.token.userInfo.publisherId,
        userId: this.$store.getters.token.userInfo.userId,
        companyStatus: '',
        type: null,
        keyword: ''
      },
      tableData: [],
      columnManagedata: [
        { title: '客户名称', key: 'name' },
        {
          title: '所属销售',
          render: (h, params) => {
            return h('div', [h('p', params.row.currentUserName || '-')])
          }
        },
        {
          title: '状态',
          align: 'center',
          render: (h, params) => {
            return formatCustomerStatus(
              h,
              params.row.companyAdvertiserStatus,
              params.row.companyAdvertiserStatusName
            )
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            const detailButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.handleShowDetail(params.row.id)
                  }
                }
              },
              '详情'
            )
            const updateButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.showModel(params.row)
                  }
                }
              },
              '维护信息'
            )

            const updateSalerButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.showChangeSalerModel(params.row)
                  }
                }
              },
              '调整销售'
            )
            return h('div', [detailButton, updateButton, updateSalerButton])
          }
        }
      ],

      ChangeCustomerUsermodel: false,
      ChangeCustomerSalermodel: false,
      changeSaler: {
        advertiserId: 0,
        companyId: 0,
        userId: 0
      },

      customerData: {
        publisherId: '',
        userId: '',
        type: '5',
        name: '',
        orgCode: '',
        tel: '',
        areaCode: '',
        cityCode: '',
        provinceCode: '',
        address: '',
        companyType: '',
        adFrom: '',
        description: '',
        advertiserBrandJson: '',
        advertiserType: null
      },
      ruleValidate: {
        name: [{ required: true, message: ' ', trigger: 'blur' }],
        cityCode: [{ required: true, message: ' ' }],
        orgCode: [
          {
            required: true,
            message: ' ',
            trigger: 'blur'
          }
        ]
      },

      customerQuery: {
        publisherId: this.$store.getters.token.userInfo.publisherId,
        pageNumber: 1,
        pageSize: 6,
        keyword: ''
      },
      conflictdata: [],
      conflictcolumndata: [
        { title: '商家名称', key: 'name' },
        {
          title: '商家行业',
          render: (h, params) => {
            return h('div', [h('p', params.row.bigTypeName || '-')])
          }
        },
        {
          title: '状态',
          align: 'center',
          render: (h, params) => {
            return formatCustomerStatus(
              h,
              params.row.status,
              params.row.statusName
            )
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            const detailButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.ConfirmAssociation(params.row)
                  }
                }
              },
              '确认关联'
            )
            return h('div', [detailButton])
          }
        }
      ],
      conflictTotal: 0,
      provinceList: [],
      cityList: [],
      regionList: [],
      customerConflictModal: false
    }
  },
  created () {
    getDictList({ key: 'company_advertiser_status' }).then((res) => {
      this.statuslist = res
    })
    this.initChannelList()
    this.query.userId = null

    this.initPageData()
  },
  methods: {
    searchConflictPage () {
      this.customerQuery.pageNumber = 1
      this.getConflictPageData()
    },
    conflictChangePage (currentPage) {
      this.customerQuery.pageNumber = currentPage
      this.getConflictPageData()
    },
    getConflictPageData () {
      if (this.customerData.name) {
        this.customerQuery.keyword = this.customerData.name
        allpage(this.customerQuery).then((response) => {
          this.conflictTotal = response.totalRow
          this.conflictdata = response.list
          this.customerQuery.pageNumber = response.pageNumber
          this.customerConflictModal = true
        })
      } else {
        this.conflictTotal = 0
        this.conflictdata = []
        this.customerQuery.pageNumber = 1
      }
    },
    writeOrgCode () {
      if (this.customerData.name) {
        queryorgcodebycompanyfullname({
          companyName: this.customerData.name
        }).then((res) => {
          if (res.organizationCode) {
            this.customerData.orgCode = res.organizationCode
          } else {
            this.$Notice.error({
              desc: '未检索到社会统一信息代码，请确认公司名称是否正确或手动输入社会统一信息代码'
            })
          }
        })
      } else {
        this.$Notice.info({ desc: '您未输入公司名称！' })
      }
    },
    getProvinceList () {
      getProvinceList().then((res) => {
        this.provinceList = res
      })
    },
    getCityList () {
      const data = {
        provinceCode: this.customerData.provinceCode
      }
      getCityListByProvinceCode(data).then((res) => {
        this.cityList = res
      })
    },
    getRegionList () {
      const data = {
        cityCode: this.customerData.cityCode
      }
      getRegionListByCityCode(data).then((res) => {
        this.regionList = res
      })
    },

    initChannelList () {
      getCompanyChild({
        companyId: this.$store.getters.token.userInfo.companyId,
        type:
          this.$store.getters.token.userInfo.publisherId ===
          this.$store.getters.token.userInfo.companyId
            ? 1
            : 2,
        childType: 2,
        companyType: 1
      }).then((res) => {
        let array = [
          {
            id: res.companyId,
            name: res.companyName
          }
        ]
        if (res.children.length) {
          array = array.concat(
            this.formatCompanyArray(res.children).filter(
              (x) => x.id !== res.companyId
            )
          )
        }
        this.channelCompanyList = array
        if (!this.query.companyId) {
          if (array.length > 1) {
            this.query.companyId = null
          } else {
            this.query.companyId = this.companyId
          }
        }
        this.initDepartmentList()
      })
    },
    initDepartmentList () {
      if (this.query.companyId > 0) {
        getCompanyChild({
          companyId: this.query.companyId,
          type: 2,
          childType: 2,
          companyType: 2
        }).then((res) => {
          let array = [
            {
              id: res.companyId,
              name: res.companyName
            }
          ]
          if (res.children.length) {
            array = array.concat(
              this.formatCompanyArray(res.children).filter(
                (x) => x.id !== res.companyId
              )
            )
          }
          this.deparmentList = array
          // this.query.departmentId = null
          // if (res.length > 1) {
          //   this.query.departmentId = null
          // } else {
          //   this.query.departmentId = this.companyId
          // }
          this.getChannelUserList()
        })
      } else {
        this.query.departmentId = null
        this.deparmentList = null
        this.getChannelUserList()
      }
    },
    getChannelUserList () {
      if (this.query.companyId && this.query.companyId !== '-1') {
        const data = {
          companyId: this.query.departmentId || this.query.companyId,
          pageNumber: 1,
          pageSize: 1000
        }
        getSellerPage(data).then((response) => {
          this.channelUsers = response.list
        })
      } else {
        this.channelUsers = []
        this.query.userId = ''
      }
    },
    // 重组公司列表结构
    formatCompanyArray (companyTree) {
      let resultArray = []
      companyTree.forEach((item) => {
        resultArray.push({ id: item.companyId, name: item.companyName })
        if (item.children.length) {
          resultArray = resultArray.concat(
            this.formatCompanyArray(item.children)
          )
        }
      })
      return resultArray
    },
    initPageData () {
      getcommercepage(this.query).then((response) => {
        this.total = response.totalRow
        this.tableData = response.list
        this.query.pageNumber = response.pageNumber
      })
    },
    reloadData () {
      this.query.pageNumber = 1
      this.initPageData()
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPageData()
    },
    SearchAdvertisers () {
      this.query.pageNumber = 1
      if (this.advertiserStatus !== '') {
        const status = []
        status.push(this.advertiserStatus)
        this.query.companyStatus = JSON.stringify(status)
      } else {
        this.query.companyStatus = ''
      }

      this.initPageData()
    },
    // CreateCustomer () {
    //   this.$emit('on-create')
    // },
    handleShowDetail (customerId) {
      this.$emit('on-detail', customerId)
    },
    showModel (params) {
      this.getProvinceList()
      this.customerData.name = params.name
      this.customerData.orgCode = params.orgCode
      this.customerData.address = params.address
      this.customerData.provinceCode = params.provinceCode
      this.customerData.cityCode = params.cityCode
      this.customerData.areaCode = params.regionCode || params.areaCode
      this.customerData.tel = params.tel
      this.customerData.advertiserId = params.id

      if (params.businessId > 0) {
        getBusiness({ id: params.businessId }).then((res) => {
          this.currentBusinessName = res.name
        })
      } else {
        this.currentBusinessName = '未关联商家'
      }

      this.ChangeCustomerUsermodel = true
    },
    // 确认关联商家
    ConfirmAssociation (params) {
      this.customerData.name = params.name
      this.customerData.address = params.address
      this.customerData.provinceCode = params.provinceCode
      this.customerData.cityCode = params.cityCode
      this.customerData.areaCode = params.regionCode
      this.customerData.businessId = params.id

      this.customerConflictModal = false
    },

    // 确认变更客户所属
    handleSubmit (dataname) {
      const that = this
      that.$refs[dataname].validate((valid) => {
        if (valid) {
          updateadvertiser(this.customerData)
            .then((res) => {
              if (res && !res.errcode) {
                this.$Notice.success({ desc: '成功创建客户！' })
                this.$refs.fileUpload.handleUpload(res)
                this.$refs.addContact.handleAddContact(res)
                // 更新列表
                // this.handleReset('customerData')
              }
              this.allowSubmit = false
            })
            .catch(() => {
              this.allowSubmit = false
            })
        } else {
          this.allowSubmit = false
        }
      })
    },

    showChangeSalerModel (params) {
      const channelquery = {
        // publisherId: this.query.publisherId,
        pageNumber: 1,
        pageSize: 100,
        type: JSON.stringify([2])
      }
      getCompanyPage(channelquery).then((res) => {
        this.channellist = res.list
      })
      this.currentCompanyName = params.currentCompanyName
      this.choiceComponyId = params.currentCompanyId
      this.currentUserName = params.currentUserName
      this.choiceSalerId = params.currentUserId
      this.changeSaler.advertiserId = params.id
      this.ChangeCustomerSalermodel = true
    },
    // 确认变更客户所属
    confirmChangeCustomerUser () {
      this.changeSaler.companyId = this.choiceComponyId
      this.changeSaler.userId = this.choiceSalerId
      addAdvertiserForUser(this.changeSaler).then(res => {
        if (res) {
          this.$Notice.success({ desc: '成功变更客户所属信息！' })
          this.ChangeCustomerSalermodel = false
          this.initPageData()
        } else {
          this.ChangeCustomerSalermodel = true
        }
      })
    },

    handleDownloadCustomer () {
      const filename = '客户清单(' + ParseDate(new Date()) + ').xlsx'

      let customerStatus = ''
      if (this.advertiserStatus !== '') {
        const status = []
        status.push(this.advertiserStatus)
        customerStatus = JSON.stringify(status)
      }

      const params = {
        companyStatus: customerStatus,
        type: this.query.type,
        companyId: this.query.companyId,
        userId: this.query.userId,
        keyword: this.query.keyword
      }

      downloadFileRequest(
        process.env.VUE_APP_API_URL_V2 + '/ooh-crm/v1/advertiser/exportexcel',
        params,
        filename
      )
    }
  },
  watch: {
    choiceComponyId (val) {
      if (val) {
        const data = {
          companyId: val,
          pageNumber: 1,
          pageSize: 1000
        }
        getSellerPage(data).then((response) => {
          this.choicechannelUsers = response.list
        })
      }
    },
    'customerData.provinceCode' (val) {
      if (val) {
        this.getCityList()
      } else {
        this.customerData.cityCode = ''
        this.customerData.areaCode = ''

        this.cityList = []
        this.regionList = []
      }
    },
    'customerData.cityCode' (val) {
      if (val) {
        this.getRegionList()
      } else {
        this.customerData.areaCode = ''
        this.regionList = []
      }
    }
  },
  computed: {
    allowEdit: function () {
      return this.isAuth('Advertiser_Edit')
    },
    adjustAscription: function () {
      // 调整销售
      return this.isAuth('Advertiser_Adjust_Ascription')
    },
    invalidAdvertiser: function () {
      // 调整销售
      return this.isAuth('Advertiser_Invalid')
    }
  }
}
</script>
