<style>
.serviceRemark{
    max-width: 240px;
    /* height: 50px; */
    padding: 5px;
    border: 1px solid #5cadff;
    border-radius: 3px;
    color: #808695;
    font-weight: 400;

    position: fixed;
    top: 220px;
    background-color: papayawhip;
    z-index: 999;
}
</style>
<template>
  <div>
    <Row :gutter="8">
        <i-col span="24">
          <RadioGroup v-model="query.queryType"  size="small">
            <Radio
              v-for="item in groupTypeArray"
              :key="item.id"
              :label="item.id"
              >{{ item.name }}</Radio
            >
          </RadioGroup>
        </i-col>
      </Row>

    <hr style="margin: 10px 0px" />
    <Row :gutter="8" v-if="query.queryType===1">

      <i-col span="6">

      <p class="title p-t-5">计账周期</p>
      <Row :gutter="8">
        <i-col span="12" class="p-b-5">
          <DatePicker
            placeholder="开始月份"
            v-model="chkStartDate"
            type="month"
            format="yyyy-MM"
            size="small"
              :clearable="false"
          ></DatePicker>
        </i-col>
        <i-col span="12" class="p-b-5">
          <DatePicker
            placeholder="结束月份"
            format="yyyy-MM"
            size="small"
            type="month"
            v-model="chkEndDate"
            placement="bottom-end"
              :clearable="false"
          ></DatePicker>
        </i-col>

      </Row>
      </i-col>
      <i-col span="4">
        <p class="title p-t-5">渠道公司</p>
        <Select v-model="query.companyId" size="small">
          <Option value="0" v-if="companyArray.length > 1">全部</Option>
          <Option
            v-for="(company, index) in companyArray"
            :key="index"
            :value="company.id"
            >{{ company.name }}</Option
          >
        </Select>

      </i-col>
      <i-col span="14">
        <p class="title p-t-5">关键字</p>
    <Row :gutter="8">
      <i-col span="16">
        <i-input
          v-model="query.keyword"
          type="text"
          size="small"
          clearable
          placeholder="如：客户、品牌、编码"
        ></i-input>
      </i-col>
      <i-col span="4">
        <Button
          type="primary"
          icon="ios-search"
          long
          size="small"
          :loading="loadSearch"
          @click="search"
        >
          <span v-if="!loadSearch">搜索</span>
          <span v-else>加载中...</span>
        </Button>
      </i-col>
      <i-col span="4">
        <Button type="success" size="small" class="m-l-5" @click="choiceDownLoadProgramme">下载列表</Button>
        <!-- <Button type="success" size="small" class="m-l-5" @click="showDownLoadProgrammeModal = true">下载对账清单</Button> -->
      </i-col>
    </Row>

      </i-col>
    </Row>
    <Row :gutter="8"  v-if="query.queryType===2">
      <i-col span="4">
        <p class="title p-t-5">费用类型</p>
        <Select v-model="query.feeType" size="small">
          <Option
            v-for="(item, index) in feeTypeArray"
            :key="index"
            :value="item.id"
            >{{ item.name }}</Option
          >
        </Select>

      </i-col>
      <i-col span="4">

      <p class="title p-t-5">计账周期</p>
      <Row :gutter="8">
        <i-col span="12" class="p-b-5">
          <DatePicker
            placeholder="开始月份"
            v-model="chkStartDate"
            type="month"
            format="yyyy-MM"
            size="small"
              :clearable="false"
          ></DatePicker>
        </i-col>
        <i-col span="12" class="p-b-5">
          <DatePicker
            placeholder="结束月份"
            format="yyyy-MM"
            size="small"
            type="month"
            v-model="chkEndDate"
              :clearable="false"
            placement="bottom-end"
          ></DatePicker>
        </i-col>

      </Row>
      </i-col>
      <i-col span="4">
        <p class="title p-t-5">渠道公司</p>
        <Select v-model="query.companyId" size="small">
          <Option value="0" v-if="companyArray.length > 1">全部</Option>
          <Option
            v-for="(company, index) in companyArray"
            :key="index"
            :value="company.id"
            >{{ company.name }}</Option
          >
        </Select>

      </i-col>
      <i-col span="4">
        <p class="title p-t-5">账单状态</p>
        <Select v-model="query.billStatus" size="small">
          <Option
            v-for="(item, index) in statusArray"
            :key="index"
            :value="item.id"
            >{{ item.name }}</Option
          >
        </Select>

      </i-col>
      <i-col span="8">
        <p class="title p-t-5">关键字</p>
    <Row :gutter="8">
      <i-col span="16">
        <i-input
          v-model="query.keyword"
          type="text"
          size="small"
          clearable
          placeholder="如：客户、品牌、编码"
        ></i-input>
      </i-col>
      <i-col span="4">
        <Button
          type="primary"
          icon="ios-search"
          long
          size="small"
          :loading="loadSearch"
          @click="search"
        >
          <span v-if="!loadSearch">搜索</span>
          <span v-else>加载中...</span>
        </Button>
      </i-col>
      <i-col span="4">
        <Button type="success" size="small" class="m-l-5" @click="choiceDownLoadProgramme">下载列表</Button>
        <!-- <Button type="success" size="small" class="m-l-5" @click="showDownLoadProgrammeModal = true">下载对账清单</Button> -->
      </i-col>
    </Row>

      </i-col>
    </Row>
    <Table :loading="loading"
         stripe :data="list" :columns="query.queryType===1?contractColumns:tableColumns"
        :height="clientHeight"></Table>
    <Row>
      <i-col span='12'>

      </i-col>
      <i-col  span='12'>
        <div class="paging_style">
          <Page
            size="small"
            :total="total"
            :page-size="query.pageSize"
            show-total
            show-sizer
            :current="query.pageNumber"
            @on-change="handlePageChanged"
            :page-size-opts="[15,50,100,200,500]"
            @on-page-size-change="handlePageSizeChanged"></Page>
        </div>
      </i-col>
    </Row>

    <Modal v-model="modalContract" :width="1200" footer-hide>
      <contract-detail v-if="modalContract&&contractId" :id="contractId"  :versionId="0"></contract-detail>
    </Modal>
    <billDetailModal ref="billDetail"></billDetailModal>
  </div>
</template>

<script>
import ContractDetail from '@/components/contract/contractDetail/Contract'
import billDetailModal from './billDetails'

import { toMoney } from '@/utils/wnumb_own'
import { ParseDate, ParseDateForMonth, lastMonthDate } from '@/utils/dateFormat'
import { formatReconcilliationStatus } from '@/utils/tagStatus'
import { downloadFileRequest } from '@/utils/download'

// import { confirmfeeitems } from '@/api/statement/bill'
// import { countContractAmount } from '@/api/dw/verify'

import { getCompanyChild } from '@/api/os/company'
import { listContractBillDetail } from '@/api/statement/contractBillDetail'

export default {
  components: {
    ContractDetail, billDetailModal
  },
  data () {
    return {
      loadSearch: false,
      groupTypeArray: [
        { id: 1, name: '按合同汇总' },
        { id: 2, name: '按明细查看' }
      ],
      chkStartDate: new Date().getFullYear() + '-01-01',
      chkEndDate: new Date(),

      feeTypeArray: [
        { id: -1, name: '全部' },
        { id: 1, name: '发布费' },
        { id: 2, name: '服务费' }
      ],
      statusArray: [
        { id: -1, name: '全部' },
        { id: 1, name: '已结算' },
        { id: 2, name: '未结算' }
      ],

      companyArray: [],
      keyword: '',

      clientHeight: 0,
      total: 0,
      loading: false,

      query: {
        queryType: 1,
        pageNumber: 1,
        pageSize: 15,
        companyId: '0',
        startDate: '',
        feeType: -1,
        endDate: '',
        billStatus: -1,
        keyword: ''
      },
      list: [],

      contractColumns: [
        {
          title: '渠道公司',
          key: 'companyName'
        },
        {
          title: '销售',
          key: 'userName'
        },
        {
          title: '合同编号',
          key: 'contractCode',
          render: (h, params) => {
            return h('a',
              {
                on: {
                  click: () => {
                    this.contractId = params.row.contractId
                    this.modalContract = true
                  }
                }
              }, params.row.contractCode)
          }
        },
        {
          title: '合同名称',
          key: 'contractName'
        },
        {
          title: '客户',
          key: 'advertiserName'
        },
        {
          title: '品牌',
          key: 'brandName'
        },
        {
          title: '合同起止日',
          key: 'contractSchedule'
        },

        // {
        //   title: '对账月份',
        //   key: 'executeDate',
        //   render: (h, params) => {
        //     return h('div', [h('span', getMonth(params.row.executeDate))])
        //   }
        // },
        // {
        //   title: '合同总额',
        //   key: 'amount',
        //   render: (h, params) => {
        //     return h('div', [
        //       h(
        //         'span',
        //         {
        //
        //         },
        //         toMoney(params.row.amount)
        //       )
        //     ])
        //   }
        // },
        {
          title: '签约价',
          align: 'center',
          children: [
            {
              title: '总额',
              align: 'center',
              key: 'signContractAmount',
              render: (h, param) => {
                return h('span', {

                }, toMoney(param.row.signContractAmount))
              }
            },
            {
              title: '发布费',
              align: 'center',
              key: 'signUsePrice',
              render: (h, param) => {
                return h('span', {

                }, toMoney(param.row.signUsePrice))
              }
            },
            {
              title: '服务费',
              align: 'center',
              key: 'signServicePrice',
              render: (h, param) => {
                return h('span', {

                }, toMoney(param.row.signServicePrice))
              }
            }
          ]
        },
        {
          title: '执行额',
          align: 'center',
          children: [
            {
              title: '总额',
              align: 'center',
              key: 'executeAmount',
              render: (h, param) => {
                return h('span', {

                }, toMoney(param.row.executeAmount))
              }
            },
            {
              title: '发布费',
              align: 'center',
              key: 'executeUsePrice',
              render: (h, param) => {
                return h('span', {
                }, toMoney(param.row.executeUsePrice))
              }
            },
            {
              title: '服务费',
              align: 'center',
              key: 'executeServicePrice',
              render: (h, param) => {
                return h('span', {

                }, toMoney(param.row.executeServicePrice))
              }
            }
          ]
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('a', {
              on: {
                click: () => {
                  this.$store.commit('set_executionAmount_detailBean', params.row)
                  this.$store.commit('set_executionAmount_pageType', 'Detail')
                }
              }
            }, '详情')
          }
        }
      ],

      tableColumns: [
        {
          title: '费用类型',
          key: 'feeTypeName'
        },
        {
          title: '公司名称',
          key: 'companyName'
        },
        {
          title: '合同编号',
          key: 'contractCode',
          render: (h, params) => {
            return h('a',
              {
                on: {
                  click: () => {
                    this.contractId = params.row.contractId
                    this.modalContract = true
                  }
                }
              }, params.row.contractCode)
          }
        },
        {
          title: '合同名称',
          key: 'contractName'
        },
        {
          title: '客户',
          key: 'advertiserName'
        },
        {
          title: '品牌',
          key: 'brandName'
        },
        {
          title: '合同起止日',
          key: 'contractSchedule'
        },

        {

          title: '执行月',
          key: 'executeMonth'
        },
        {
          width: 100,
          renderHeader: (h, params) => {
            return h('div', [
              h('span', '计账时间'),
              h('Icon', {
                props: { type: 'md-help-circle', size: 18, color: '#2d8cf0', id: 'serviceIon' },
                style: { 'margin-left': '4px', cursor: 'pointer' },
                on: {
                  click: (event) => {
                    if (document.getElementsByClassName('serviceRemark').length < 1) {
                      var serviceDiv = document.createElement('div')
                      serviceDiv.className = 'serviceRemark'

                      const title = document.createElement('p')
                      title.innerHTML = '账单生成时间'
                      serviceDiv.appendChild(title)

                      const content = document.createElement('p')
                      content.innerHTML = '发布费账单每月底最后1秒生成当月账单。'
                      serviceDiv.appendChild(content)

                      const content1 = document.createElement('p')
                      content1.innerHTML = '服务费账单根据用户需求可能是“作业完成时间”、“质检通过时间”、“对账完成时间”亦或是每月底最后1秒等。'
                      serviceDiv.appendChild(content1)

                      event.target.parentElement.appendChild(serviceDiv)
                      // 5秒后自动关闭
                      setTimeout(() => {
                        if (serviceDiv.parentNode) {
                          serviceDiv.parentNode.removeChild(serviceDiv)
                        }
                      }, 5000)
                    } else {
                      const serviceDivRemark = document.getElementsByClassName('serviceRemark')
                      serviceDivRemark[0].parentNode.removeChild(serviceDivRemark[0])
                    }
                  }
                }
              })
            ])
          },
          key: 'chargeTime'
        },
        {
          width: 100,
          renderHeader: (h, params) => {
            return h('div', [
              h('span', '执行时间'),
              h('Icon', {
                props: { type: 'md-help-circle', size: 18, color: '#2d8cf0', id: 'serviceIon' },
                style: { 'margin-left': '4px', cursor: 'pointer' },
                on: {
                  click: (event) => {
                    if (document.getElementsByClassName('serviceRemark').length < 1) {
                      var serviceDiv = document.createElement('div')
                      serviceDiv.className = 'serviceRemark'

                      const title = document.createElement('p')
                      title.innerHTML = '账单真实发生的时间'
                      serviceDiv.appendChild(title)

                      const content = document.createElement('p')
                      content.innerHTML = '发布费账单发生时间的周期为合同档期。'
                      serviceDiv.appendChild(content)
                      const content1 = document.createElement('p')
                      content1.innerHTML = '服务费账单发生时间根据用户需求可能是:1.合同档期开始首月最后一秒。2.与工单任务挂钩，工单任务完成时间等。'
                      serviceDiv.appendChild(content1)

                      event.target.parentElement.appendChild(serviceDiv)
                      // 5秒后自动关闭
                      setTimeout(() => {
                        if (serviceDiv.parentNode) {
                          serviceDiv.parentNode.removeChild(serviceDiv)
                        }
                      }, 5000)
                    } else {
                      const serviceDivRemark = document.getElementsByClassName('serviceRemark')
                      serviceDivRemark[0].parentNode.removeChild(serviceDivRemark[0])
                    }
                  }
                }
              })
            ])
          },
          key: 'executeSchedule'
        },
        {
          width: 100,
          renderHeader: (h, params) => {
            return h('div', [
              h('span', '计费名称'),
              h('Icon', {
                props: { type: 'md-help-circle', size: 18, color: '#2d8cf0', id: 'serviceIon' },
                style: { 'margin-left': '4px', cursor: 'pointer' },
                on: {
                  click: (event) => {
                    if (document.getElementsByClassName('serviceRemark').length < 1) {
                      var serviceDiv = document.createElement('div')
                      serviceDiv.className = 'serviceRemark'

                      const title = document.createElement('p')
                      title.innerHTML = '账单费用名称'
                      serviceDiv.appendChild(title)

                      const content = document.createElement('p')
                      content.innerHTML = '通常为费用的备注，可能是冲抵时的备注说明，也可能是费用的资源名称等。'
                      serviceDiv.appendChild(content)

                      event.target.parentElement.appendChild(serviceDiv)
                      // 5秒后自动关闭
                      setTimeout(() => {
                        if (serviceDiv.parentNode) {
                          serviceDiv.parentNode.removeChild(serviceDiv)
                        }
                      }, 5000)
                    } else {
                      const serviceDivRemark = document.getElementsByClassName('serviceRemark')
                      serviceDivRemark[0].parentNode.removeChild(serviceDivRemark[0])
                    }
                  }
                }
              })
            ])
          },
          key: 'feeItemName'
        },
        {
          title: '执行额',
          key: 'executeAmount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                toMoney(params.row.executeAmount)
              )
            ])
          }
        },

        {
          title: '状态',
          key: 'billStatus',
          width: 120,
          render: (h, data) => {
            return formatReconcilliationStatus(
              h,
              data.row.billStatus,
              data.row.billStatusName)
          }
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('a', {
              on: {
                click: () => {
                  this.$refs.billDetail.showModal(params.row.feeItemId)
                }
              }
            }, '费用组成')
          }
        }
      ],

      summaryData: null,
      modalContract: false,
      contractId: 0
    }
  },
  created () {
    this.clientHeight = document.documentElement.clientHeight - 220
    // this.query.startDate = '2020-01-01'
    // this.query.endDate = new Date().getFullYear() + '-12-31'
    this.initCompanyArray()
    this.search()
  },
  methods: {
    initCompanyArray () {
      // 获取交易方公司列表
      getCompanyChild({
        companyId: this.companyId,
        type:
          this.$store.getters.token.userInfo.publisherId === this.companyId
            ? 1
            : 2,
        childType: 2,
        companyType: 1
      }).then((res) => {
        let array = [
          {
            id: res.companyId,
            name: res.companyName
          }
        ]
        if (res.children.length) {
          array = array.concat(
            this.formatCompanyArray(res.children).filter(
              (x) => x.id !== res.companyId
            )
          )
        }
        this.companyArray = array
        // if (res.length > 1) {
        //   this.query.companyId = '0'
        // } else {
        //   this.query.companyId = this.companyId
        // }

        this.search()
      })
    },
    // 重组公司列表结构
    formatCompanyArray (companyTree) {
      let resultArray = []
      companyTree.forEach((item) => {
        resultArray.push({ id: item.companyId, name: item.companyName })
        if (item.children.length) {
          resultArray = resultArray.concat(
            this.formatCompanyArray(item.children)
          )
        }
      })
      return resultArray
    },
    search () {
      this.query.pageNumber = 1
      this.query.startDate = this.chkStartDate
        ? ParseDate(this.chkStartDate)
        : null
      this.query.endDate = this.chkEndDate
        ? lastMonthDate(
          this.chkEndDate.getFullYear(),
          this.chkEndDate.getMonth() + 1
        )
        : null
      this.$store.commit(
        'set_executionAmount_transactionReconciliationQuery',
        this.query
      )
      this.$store.commit('set_executionAmount_update', new Date())
      this.loadPage()
    },
    loadPage () {
      this.loading = true
      const that = this
      that.query.startDate = this.chkStartDate
        ? ParseDate(this.chkStartDate)
        : null
      that.query.endDate = this.chkEndDate
        ? lastMonthDate(
          this.chkEndDate.getFullYear(),
          this.chkEndDate.getMonth() + 1
        )
        : null

      listContractBillDetail(that.query).then((res) => {
        if (res.list.length) {
          res.list.map((item) => {
            item.visible = item.visibleScope === 2
            return item
          })
        }

        that.list = res.list
        that.total = res.totalRow
        that.loading = false

        // 如果当前是最后一页，同时没有数据，那么重新加载上一页
        if (res.totalRow > 0 && res.list.length === 0) {
          that.query.pageNumber -= 1
          that.loadPage()
        }
      })
    },
    formatMoney (number) {
      return toMoney(number)
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.loadPage()
    },
    handlePageSizeChanged (pagesize) {
      this.query.pageSize = pagesize
      this.query.pageNumber = 1
      this.loadPage()
    },

    choiceDownLoadProgramme () {
      const that = this
      const params = that.query

      that.downLoadExecl(params)
    },
    downLoadExecl (params) {
      let dateStr = ParseDate(new Date())

      if (params.startDate && params.endDate) {
        dateStr = ParseDateForMonth(params.startDate) + '至' + ParseDateForMonth(params.endDate)
      }
      const filename = '交易对账明细(' + dateStr + ').xlsx'
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-statement/v1/contractBillDetail/exportContractBillDetail', params, filename)
    }
  },
  computed: {
    companyId () {
      return this.$store.getters.token.userInfo.companyId
    },
    reloadList () {
      return this.$store.state.executionAmount.reloadList
    }
  },
  watch: {
    'query.queryType' () {
      this.search()
    },
    reloadList () {
      this.loadPage()
    }
  }
}
</script>
