import request from '@/utils/requestV2'
const qs = require('qs')

// 确认任务完成
export function confirmFinish (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/confirmfinish',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 工单任务照片重拍
export function remakePhoto (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/remakephoto',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取task资源位置
export function getTaskPosition (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/gettaskposition',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取takitem素材和回传照片附件信息
export function getAttachInfo (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/getattachinfo',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 分页获取任务类型
export function getTaskItemPage (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/gettaskitempage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查询当前在刊资源和画面信息
export function getResourceWithFile (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/getresourcewithfile',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 设置画面来源(上刊中画面不支持批量设置)
export function setPicResource (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/setpicresource',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 设置作业供应商
export function setLabor (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/setlabor',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 设置作业时间
export function setActionDate (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/setactiondate',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 派单任务项
export function assignTaskItems (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/assigntaskitems',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 取消派单任务
export function revokeAssignTaskitem (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/revokeassigntaskitem',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 供应商获取任务列表
export function getSupplyTaskitemPage (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/getsupplytaskitempage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 分配小组
export function setLaborWorkgroup (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/setlaborworkgroup',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 巡检打卡核查与计费
export function calculateActionCost (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/calculateactioncost',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 标记图片是否用于创建报告
export function reportPicMark (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/reportpicmark',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 设置画面是否复用存在旧画
export function setReuseOldFile (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/setresuseoldfile',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 终止任务（资源同时存在上刊或下刊任务时，选择终止一种任务）
export function terminatedTaskitem (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/terminatedtaskitem',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 批量取消派单任务
export function revokeAssignTaskitems (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/revokeassigntaskitems',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取资源指定维修项的质保情况
export function getRepairInfo (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/getrepairinfo',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 分页获取正在发布中的任务项
export function getPublishingTaskitemPage (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/getpublishingtaskitempage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 根据资源和日期获取任务集合
export function getTaskItemList (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/gettaskitemlist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 根据资源和日期获取任务集合
export function gettaskitemhistorylist (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/gettaskitemhistorylist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取指定任务类型的任务状态枚举列表
export function getTaskitemStatus (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/gettaskitemstatus',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取运维任务审批列表
export function getTaskApproveList (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/gettaskapprovelist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 一键提交运维任务审批
export function startApprove (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/startapprove',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查询指定任务类型是否配置审批流程
export function tasktypeApproveProgress (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/tasktypeapproveprogress',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 选取运维任务审批列表中的审批任务进行终止
export function terminalTaskApprove (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/terminaltaskapprove',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 取消分配小组
export function cancelWorkgroup (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/cancelworkgroup',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取下刊任务对于订单档期详情
export function getOrderUninstallGather (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/getorderuninstallgather',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取下刊任务对于订单档期点位详情
export function getOrderUninstallDetailPage (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/getorderuninstalldetailpage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// // 分页获取点位下一档期任务信息
// export function getNextTaskitemPage (data) {
//   return request({
//     url: '/ooh-msp/v2/taskitem/getnexttaskitempage',
//     method: 'post',
//     data: qs.stringify(data)
//   })
// }

// 根据任务id获取任务详情
export function getTaskitemInfo (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/gettaskiteminfo',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 质检-任务质量检测
export function confirmTaskFinish (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/confirmtaskfinish',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 质检-任务对账
export function confirmAmount (data) {
  return request({
    url: '/ooh-msp/v2/taskitem/confirmamount',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 质检-任务对账
 * @param {Object} data 参数
 * @returns
 */
export function startAccount (data) {
  return request.post('/ooh-msp/v2/taskitem/startAccount', qs.stringify(data))
}
