<!-- 结算单创建 -->
<template>
  <Modal v-model="showCreateSettlementModal" width="80%">
    <Row :gutter="8">
      <i-col span="6">
        <h3 class="p-t-10">结算单名称</h3>
        <i-input
          v-model="name"
          placeholder="可填写结算单名称"
        ></i-input>
      </i-col>
      <i-col span="6">
        <h3 class="p-t-10">结算单备注</h3>
        <i-input
          v-model="mark"
          placeholder="可填写结算单备注信息..."
        ></i-input>
      </i-col>
    </Row>
    <Row>
      <i-col span="24"> </i-col>
    </Row>
    <div>
      <Row :gutter="8">
        <i-col span="6">
          <h3 class="p-t-10">结算公司</h3>
          <Select v-model="query.purchaserCompanyId" >
            <Option
              v-for="(company, index) in settlementcompanyArray"
              :key="index"
              :value="company.id"
              >{{ company.name }}</Option
            >
          </Select>
        </i-col>
        <i-col span="6">
          <h3 class="p-t-10">供应商</h3>
          <Select v-model="query.supplierCompanyId">
            <Option
              v-for="(company, index) in suppliercompanyArray"
              :key="index"
              :value="company.id"
              >{{ company.name }}</Option
            >
          </Select>
        </i-col>

        <i-col span="6">
          <h3 class="p-t-10">资产</h3>
          <Select v-model="assetIds"  clearable multiple>
            <Option
              v-for="(asset, index) in assetList"
              :key="index"
              :value="asset.id"
              >{{ asset.name }}</Option
            >
          </Select>
        </i-col>
        <i-col span="6">
          <h3 class="p-t-10">作业类型</h3>
          <Select
            v-model="taskitemTypes"
             clearable
              multiple
          >
            <Option
              v-for="(type, index) in taskitemTypeArray"
              :key="index"
              :value="type.id"
              >{{ type.name }}</Option
            >
          </Select>
        </i-col>

        <i-col span="6">
          <h3 class="p-t-10">作业完成周期</h3>
          <DatePicker
                placeholder="作业完成周期"
                type="daterange"
                 clearable
                v-model="actionDate"
                style="width: 100%"
                format="yyyy-MM-dd"
              ></DatePicker>
        </i-col>
        <i-col span="6">
          <h3 class="p-t-10">质检周期</h3>
          <DatePicker
                placeholder="质检周期"
                type="daterange"
                 clearable
                v-model="qualityCheckDate"
                style="width: 100%"
                format="yyyy-MM-dd"
              ></DatePicker>
        </i-col>
        <i-col span="6">
          <h3 class="p-t-10">记账周期</h3>
          <DatePicker
                placeholder="记账周期"
                type="daterange"
                 clearable
                v-model="transactionDate"
                style="width: 100%"
                format="yyyy-MM-dd"
              ></DatePicker>
        </i-col>
        <i-col span="6">
          <h3 class="p-t-10">&nbsp;</h3>
          <Button
                type="primary"
                icon="ios-search"
                @click="reloadList"
              >
                搜索
              </Button>
        </i-col>
      </Row>
      <Row class="p-t-10">
        <i-col span="24"><h3>可结算计费项</h3></i-col>
      </Row>

      <Table
        stripe
        border
        :data="list"
        :columns="tableColumns"
        :height="500"
        @on-selection-change="selectionChange"
      ></Table>
      <Row>
        <i-col span="12">
          <span style="height: 28px; line-height: 28px"
            >已选中{{ chkfeeitemIds.length }}条</span
          >
        </i-col>
        <i-col span="12">
          <div class="paging_style">
            <Page
              size="small"
              :total="total"
              :page-size="query.pageSize"
              show-total
              show-sizer
              :page-size-opts="[15, 50, 100, 200, 500]"
              :current="query.pageNumber"
              @on-change="handlePageChanged"
              @on-page-size-change="handlePageSizeChanged"
            >
            </Page>
          </div>
        </i-col>
      </Row>
    </div>

    <div slot="footer">
      <Button type="text" @click="showCreateSettlementModal = false"
        >取消</Button
      >
      <Button type="primary" @click="confirmCreate">确认</Button>
    </div>
  </Modal>
</template>
<script>
import { toMoney } from '@/utils/wnumb_own'
import { getmspfeeitempage, createbill } from '@/api/statement/bill'
import { getPublisherAssetList } from '@/api/rim/asset'
import { ParseDate } from '@/utils/dateFormat'

import {
  listMspTaskType
} from '@/api/statement/mspBillDetail'
export default {
  data () {
    return {
      showCreateSettlementModal: false,
      settlementcompanyArray: [],
      suppliercompanyArray: [],
      taskitemTypeArray: [],
      transactionDate: [],
      actionDate: [],
      qualityCheckDate: [],
      total: 0,
      lastPage: true,
      query: {
        feeitemType: 2,
        billStatus: -1,
        pageSize: 15,
        pageNumber: 1
      },
      assetIds: [],
      taskitemTypes: [],
      list: [],
      tableColumns: [
        {
          type: 'selection',
          key: 'feeitemId',
          width: 60,
          align: 'center'
        },
        {
          title: '供应商',
          key: 'companyName',
          render: (h, params) => {
            return h('div', [h('p', params.row.supplierCompanyName)])
          }
        },
        {
          title: '代理商',
          key: 'createCompanyName',
          render: (h, params) => {
            return h('div', [h('p', params.row.createCompanyName)])
          }
        },
        {
          title: '资源编号',
          key: 'resourceCode',
          render: (h, params) => {
            return h('div', [h('p', params.row.resourceCode)])
          }
        },
        {
          title: '资产',
          key: 'code',
          render: (h, params) => {
            return h('div', [h('p', params.row.assetName)])
          }
        },
        {
          title: '位置',
          key: 'position',
          render: (h, params) => {
            return h('div', [h('p', params.row.position)])
          }
        },
        {
          title: '资源类型',
          key: 'startDate',
          render: (h, params) => {
            return h('div', [h('p', params.row.resourceTypeName)])
          }
        },
        {
          title: '用途',
          key: 'adTypeName',
          render: (h, params) => {
            return h('div', [h('p', params.row.adTypeName || '-')])
          }
        },
        {
          title: '作业类型',
          key: 'taskTypeName',
          render: (h, params) => {
            return h('div', [h('p', params.row.taskTypeName)])
          }
        },
        {
          title: '记账金额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.amount)
              )
            ])
          }
        },
        {
          title: '派单时间',
          key: 'amount',
          render: (h, params) => {
            return h('div', [h('p', params.row.supplierDispatchTime)])
          }
        },
        {
          title: '作业完成时间',
          key: 'amount',
          render: (h, params) => {
            return h('div', [h('p', params.row.taskFinishTime)])
          }
        },
        {
          title: '质检时间',
          key: 'qualityCheckTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.qualityCheckTime)])
          }
        },
        {
          title: '记账时间',
          key: 'confirmTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.confirmTime)])
          }
        }
      ],

      currentPageAllItemIds: [],
      chkfeeitemIds: [],
      mark: '',
      name: '',
      assetList: [],
      assetNames: []
    }
  },
  methods: {
    showModal (
      settlementcompanyArray,
      settlementcompanyId,
      suppliercompanyArray,
      supplierCompanyId
    ) {
      this.initAssetArray()
      this.inittaskitemTypeArray()
      this.settlementcompanyArray = settlementcompanyArray
      this.suppliercompanyArray = suppliercompanyArray
      this.list = []
      this.chkfeeitemIds = []
      this.query.purchaserCompanyId =
        settlementcompanyId || settlementcompanyArray[0].id
      this.query.supplierCompanyId =
        supplierCompanyId || suppliercompanyArray[0].id

      this.initItemArray()
      this.showCreateSettlementModal = true
    },

    // 加载作业类型
    inittaskitemTypeArray () {
      const that = this
      that.taskitemTypeArray = []

      listMspTaskType({
      }).then((res) => {
        that.taskitemTypeArray = res
      })
    },
    // 加载所有可选线路
    initAssetArray () {
      getPublisherAssetList().then((res) => {
        this.assetList = res
      })
    },
    reloadList () {
      this.query.pageNumber = 1
      this.chkfeeitemIds = []
      this.initItemArray()
    },
    // 加载所有可结算的交易项
    initItemArray () {
      const that = this
      if (this.transactionDate.length) {
        this.query.confirmStartDate = this.transactionDate[0] ? ParseDate(this.transactionDate[0]) : null
        this.query.confirmEndDate = this.transactionDate[1] ? ParseDate(this.transactionDate[1]) : null
      } else {
        this.query.confirmStartDate = null
        this.query.confirmEndDate = null
      }

      if (this.actionDate.length) {
        this.query.deliveryFinishStartDate = this.actionDate[0] ? ParseDate(this.actionDate[0]) : null
        this.query.deliveryFinishEndDate = this.actionDate[1] ? ParseDate(this.actionDate[1]) : null
      } else {
        this.query.deliveryFinishStartDate = null
        this.query.deliveryFinishEndDate = null
      }

      if (this.qualityCheckDate.length) {
        this.query.qualityCheckStartDate = this.qualityCheckDate[0] ? ParseDate(this.qualityCheckDate[0]) : null
        this.query.qualityCheckEndDate = this.qualityCheckDate[1] ? ParseDate(this.qualityCheckDate[1]) : null
      } else {
        this.query.qualityCheckStartDate = null
        this.query.qualityCheckEndDate = null
      }

      this.query.assetIdList = JSON.stringify(this.assetIds)
      this.query.mspTaskTypeList = JSON.stringify(this.taskitemTypes)
      getmspfeeitempage(this.query).then((res) => {
        res.list.map((item) => {
          item._checked = that.chkfeeitemIds.includes(item.feeitemId)
          return item
        })
        that.currentPageAllItemIds = res.list.map((item) => {
          return item.feeitemId
        })
        that.list = res.list
        that.total = res.totalRow
        that.lastPage = res.lastPage
      })
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.initItemArray()
    },
    handlePageSizeChanged (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.initItemArray()
    },
    // 选中的结算项变更
    selectionChange (selection) {
      const that = this
      const selectionIds = selection.map((item) => {
        return item.feeitemId
      })
      // 循环当前页面所有列
      that.currentPageAllItemIds.forEach((item) => {
        // 列是否是选中状态
        if (selectionIds.includes(item)) {
          // 判断是否已在列表，不在则加入
          if (!that.chkfeeitemIds.includes(item)) {
            that.chkfeeitemIds.push(item)
          }
        } else {
          // 列未被选中
          // 判断是否已在列表，在则移除
          if (that.chkfeeitemIds.includes(item)) {
            that.chkfeeitemIds.splice(that.chkfeeitemIds.indexOf(item), 1)
          }
        }
      })
    },
    // 确认创建结算单
    confirmCreate () {
      const that = this
      const data = {
        feeitemListJsonStr: JSON.stringify(that.chkfeeitemIds),
        feeitemType: 2,
        mark: that.mark,
        name: that.name,
        zones: that.assetNames.toString(),
        purchaserCompanyId: that.query.purchaserCompanyId,
        supplierCompanyId: that.query.supplierCompanyId,
        publisherId: that.publisherId
      }
      createbill(data).then((res) => {
        if (res && !res.errcode) {
          that.showCreateSettlementModal = false
          that.$store.commit('set_settlementSupplier_update', new Date())
        }
      })
    }
  },
  computed: {
    publisherId () {
      return this.$store.getters.token.userInfo.publisherId
    }
  }
}
</script>
