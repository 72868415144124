import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取当前用户所属项目
 * @param {Object} data
 */
export function getByUser (data) {
  return request({
    url: '/ooh-os/v1/project/getByUser',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 变更当前项目
export function changeProject (data) {
  return request({
    url: '/ooh-os/v1/project/changeProject',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 获取项目列表
 * @param {object} data
 * @returns
 */
export function getList (data) {
  return request.post('/ooh-os/v1/project/getList', qs.stringify(data))
}

/**
 * 保存项目
 * @param {object} data
 * @returns
 */
export function saveProject (data) {
  return request.post('/ooh-os/v1/project/saveProject', qs.stringify(data))
}

/**
 * 更新项目
 * @param {object} data
 * @returns
 */
export function updateProject (data) {
  return request.post('/ooh-os/v1/project/updateProject', qs.stringify(data))
}

/**
 * 获取项目下的公司
 * @param {object} data
 * @returns
 */
export function getProjectCompany (data) {
  return request.post('/ooh-os/v1/project/getProjectCompany', qs.stringify(data))
}

/**
 * 新增项目下的代理商
 * @param {object} data
 * @returns
 */
export function addAgency (data) {
  return request.post('/ooh-os/v1/project/addAgency', qs.stringify(data))
}

/**
 * 移除项目下的代理商
 * @param {object} data
 * @returns
 */
export function removeAgency (data) {
  return request.post('/ooh-os/v1/project/removeAgency', qs.stringify(data))
}

/**
 * 新增项目下的供应商
 * @param {object} data
 * @returns
 */
export function addSupplier (data) {
  return request.post('/ooh-os/v1/project/addSupplier', qs.stringify(data))
}

/**
 * 移除项目下的供应商
 * @param {object} data
 * @returns
 */
export function removeSupplier (data) {
  return request.post('/ooh-os/v1/project/removeSupplier', qs.stringify(data))
}
