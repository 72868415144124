<!-- 结算单创建 -->
<template>
  <Modal v-model="showFeeitemsModal" width="1000" :closable="false">
    <Row class="p-b-5" :gutter="8" >
      <i-col span="4">
        <Select v-model="query.feeType" placeholder="费用类型">
          <Option
            v-for="(item, index) in feeTypeArray"
            :key="index"
            :value="item.id"
            >{{ item.name }}</Option
          >
        </Select>
      </i-col>
      <i-col span="8">
        <Row :gutter="8">
          <i-col span="12" class="p-b-5">
            <DatePicker
              placeholder="开始月份"
              v-model="chkStartDate"
              type="month"
              format="yyyy-MM"
              :clearable="false"
            ></DatePicker>
          </i-col>
          <i-col span="12" class="p-b-5">
            <DatePicker
              placeholder="结束月份"
              format="yyyy-MM"
              type="month"
              v-model="chkEndDate"
              placement="bottom-end"
              :clearable="false"
            ></DatePicker>
          </i-col>
        </Row>
      </i-col>
      <i-col span="8">
        <i-input
          v-model="keyword"
          clearable
          placeholder="关键字：支持客户名称、品牌名称、合同编号、产品名称等"
        ></i-input>
      </i-col>

      <i-col span="4">
        <Button
          type="primary"
          size="small"
          icon="ios-search"
          @click="reloadList"
        >
          搜索
        </Button>
      </i-col>
    </Row>
    <Table
      stripe
      border
      :data="list"
      :height="500"
      :columns="feeitemColumn"
      @on-selection-change="selectionChange"
    ></Table>

    <Row>
      <i-col span="12">
        <span style="height: 28px; line-height: 28px"
          >已选中{{ chkfeeitemIds.length }}条</span
        >
      </i-col>
      <i-col span="12">
        <div class="paging_style">
          <Page
            size="small"
            :total="total"
            :page-size="query.pageSize"
            show-total
            :current="query.pageNumber"
            @on-change="handlePageChanged"
            show-sizer
            :page-size-opts="[15, 50, 100, 200, 500]"
            @on-page-size-change="handlePageSizeChanged"
          ></Page>
        </div>
      </i-col>
    </Row>

    <div slot="footer">
      <Button type="text" @click="showFeeitemsModal = false">取消</Button>
      <Button type="primary" @click="confirm">确认</Button>
    </div>
  </Modal>
</template>
<script>
import { toMoney } from '@/utils/wnumb_own'
import {
  listContractStatementBillDetail,
  batchaddorremovefeeitemlist
} from '@/api/statement/bill'
import {
  ParseDate,
  lastMonthDate
} from '@/utils/dateFormat'
import { formatReconcilliationStatus } from '@/utils/tagStatus'

export default {
  data () {
    return {
      showFeeitemsModal: false,
      total: 0,
      lastPage: true,
      billId: 0,
      query: {
        companyId: 0,
        billStatus: -1,
        pageSize: 15,
        pageNumber: 1
      },
      chkStartDate: new Date().getFullYear() + '-01-01',
      chkEndDate: new Date(),
      feeTypeArray: [
        { id: -1, name: '全部' },
        { id: 1, name: '发布费' },
        { id: 2, name: '服务费' }
      ],

      keyword: '',
      list: [],

      feeitemColumn: [
        {
          type: 'selection',
          key: 'feeItemId',
          width: 60,
          align: 'center'
        },
        {
          title: '费用类型',
          key: 'feeTypeName'
        },
        {
          title: '公司名称',
          key: 'companyName'
        },
        {
          title: '合同编号',
          key: 'contractCode',
          render: (h, params) => {
            return h('a',
              {
                on: {
                  click: () => {
                    this.contractId = params.row.contractId
                    this.modalContract = true
                  }
                }
              }, params.row.contractCode)
          }
        },
        {
          title: '合同名称',
          key: 'contractName'
        },
        {
          title: '客户',
          key: 'advertiserName'
        },
        {
          title: '品牌',
          key: 'brandName'
        },
        {
          title: '合同起止日',
          key: 'contractSchedule'
        },

        {
          title: '执行月',
          key: 'executeMonth'
        },
        {
          title: '计账时间',
          key: 'chargeTime'
        },
        {
          title: '执行时间',
          key: 'executeSchedule'
        },
        {
          title: '计费名称',
          key: 'feeItemName'
        },
        {
          title: '执行额',
          key: 'executeAmount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                toMoney(params.row.executeAmount)
              )
            ])
          }
        },

        {
          title: '状态',
          key: 'billStatus',
          width: 120,
          render: (h, data) => {
            return formatReconcilliationStatus(
              h,
              data.row.billStatus,
              data.row.billStatusName)
          }
        }
      ],

      currentPageAllItemIds: [],
      chkfeeitemIds: [],
      mark: '',
      CreateCompanyArray: [],
      createCompanyId: []
    }
  },
  methods: {
    showModal (billDetail, billId) {
      this.list = []
      this.chkfeeitemIds = []
      this.billId = billId
      this.query.pageNumber = 1

      this.query.companyId = billDetail.agentCompanyId

      this.query.startDate = null
      this.query.endDate = null

      this.showFeeitemsModal = true
      if (billDetail) {
        this.initItemArray()
      }
    },

    // 加载所有可结算的交易项
    initItemArray () {
      const that = this
      that.query.startDate = this.chkStartDate
        ? ParseDate(this.chkStartDate)
        : null
      that.query.endDate = this.chkEndDate
        ? lastMonthDate(
          this.chkEndDate.getFullYear(),
          this.chkEndDate.getMonth() + 1
        )
        : null
      that.query.keyword = that.keyword
      listContractStatementBillDetail(this.query).then((res) => {
        res.list.map((item) => {
          item._checked = that.chkfeeitemIds.includes(item.feeItemId)
          return item
        })
        that.currentPageAllItemIds = res.list.map((item) => {
          return item.feeItemId
        })
        that.list = res.list
        that.total = res.totalRow
        that.lastPage = res.lastPage
      })
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.initItemArray()
    },

    handlePageSizeChanged (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.initItemArray()
    },
    reloadList () {
      this.query.pageNumber = 1
      this.initItemArray()
    },
    // 选中的结算项变更
    selectionChange (selection) {
      const that = this
      const selectionIds = selection.map((item) => {
        return item.feeItemId
      })
      // 循环当前页面所有列
      that.currentPageAllItemIds.forEach((item) => {
        // 列是否是选中状态
        if (selectionIds.includes(item)) {
          // 判断是否已在列表，不在则加入
          if (!that.chkfeeitemIds.includes(item)) {
            that.chkfeeitemIds.push(item)
          }
        } else {
          // 列未被选中
          // 判断是否已在列表，在则移除
          if (that.chkfeeitemIds.includes(item)) {
            that.chkfeeitemIds.splice(that.chkfeeitemIds.indexOf(item), 1)
          }
        }
      })
    },
    // 确定添加结算项
    confirm () {
      const that = this
      if (that.chkfeeitemIds.length) {
        const data = {
          billId: that.billId,
          feeitemType: 1,
          feeitemListJsonStr: JSON.stringify(that.chkfeeitemIds),
          action: 1
        }
        batchaddorremovefeeitemlist(data).then((res) => {
          if (res && !res.errcode) {
            that.showFeeitemsModal = false
            that.$store.commit('set_settlementChannel_update', new Date())
          }
        })
      } else {
        that.$Notice.info({ desc: '请至少选择一个结算项！' })
      }
    }
  },
  computed: {
    publisherId () {
      return this.$store.getters.token.userInfo.publisherId
    }
  }
}
</script>
