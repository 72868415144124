<template>
  <div class="p-t-5 p-l-5">
        <Divider dashed style="margin: 10px 0 5px 0;"><span class="divider-text">数据汇总</span></Divider>
        <div >
          <div class="m-t-10">
              <p class="title">结算公司</p>
            {{totalSumaryBean.companyName}}
          </div>

        </div>
        <Row class=" m-t-10 p-t-5 p-b-5 ">
            <i-col span="24" class="text-16 p-b-10">
               总金额<p class="text-orange">{{formatMoney(totalSumaryBean.totalAmount)}}</p>
            </i-col>
            <i-col span="12" class=" p-b-5 ">
                <p class="title">已结算</p>
                {{formatMoney(totalSumaryBean.committedAmount)}}
            </i-col>
            <i-col span="12" class=" p-b-5 ">
                <p class="title">未结算</p>
                {{formatMoney(totalSumaryBean.unCommittedAmount)}}
            </i-col>
        </Row>
        <Divider dashed style="margin: 10px 0 5px 0;"></Divider>
        <Row class=" m-t-10 p-t-5 p-b-5 ">
            <i-col span="24" class="text-16 p-b-10">
              发布费总金额<p class="text-orange">{{formatMoney(totalSumaryBean.totalUseFeeAmount)}}</p>
            </i-col>
            <i-col span="12" class=" p-b-5 ">
                <p class="title">已结算</p>
                {{formatMoney(totalSumaryBean.committedUseFeeAmount)}}
            </i-col>
            <i-col span="12" class=" p-b-5 ">
                <p class="title">未结算</p>
                {{formatMoney(totalSumaryBean.unCommittedUseFeeAmount)}}
            </i-col>
            <i-col span="12" class=" p-b-5 ">
                <p class="title">已结算调整项</p>
                {{formatMoney(totalSumaryBean.committedUseFeeAdjustAmount)}}
            </i-col>
            <i-col span="12" class=" p-b-5 ">
                <p class="title">未结算调整项</p>
                {{formatMoney(totalSumaryBean.unCommittedUseFeeAdjustAmount)}}
            </i-col>
        </Row>
        <Divider dashed style="margin: 10px 0 5px 0;"></Divider>
        <Row class=" m-t-10 p-t-5 p-b-5 ">
            <i-col span="24" class="text-16 p-b-10">
              服务费总金额<p class="text-orange">{{formatMoney(totalSumaryBean.totalServiceFeeAmount)}}</p>
            </i-col>
            <i-col span="12" class=" p-b-5 ">
                <p class="title">已结算</p>
                {{formatMoney(totalSumaryBean.committedServiceFeeAmount)}}
            </i-col>
            <i-col span="12" class=" p-b-5 ">
                <p class="title">未结算</p>
                {{formatMoney(totalSumaryBean.unCommittedServiceFeeAmount)}}
            </i-col>
            <i-col span="12"  class=" p-b-5 ">
                <p class="title">已结算调整项</p>
                {{formatMoney(totalSumaryBean.committedServiceFeeAdjustAmount)}}
            </i-col>
            <i-col span="12">
                <p class="title">未结算调整项</p>
                {{formatMoney(totalSumaryBean.unCommittedServiceFeeAdjustAmount)}}
            </i-col>
        </Row>
        <Divider dashed style="margin: 10px 0 5px 0;"></Divider>
        <Row class=" m-t-10 p-t-5 p-b-5 ">
          <i-col span="24" class="text-16 p-b-10">
            其他调整项
            </i-col>
            <i-col span="12" class=" p-b-5 ">
                <p class="title">已结算</p>
                {{formatMoney(totalSumaryBean.committedOtherFeeAdjustAmount)}}
            </i-col>
            <i-col span="12" class=" p-b-5 ">
                <p class="title">未结算</p>
                {{formatMoney(totalSumaryBean.unCommittedOtherFeeAdjustAmount)}}
            </i-col>

        </Row>

        <Divider dashed style="margin: 10px 0 5px 0;"></Divider>
        <Row class=" m-t-10 p-t-5 p-b-5 ">
          <i-col span="24" class="text-16 p-b-10">
            罚款项
            </i-col>
            <i-col span="12" class=" p-b-5 ">
                <p class="title">已结算</p>
                {{formatMoney(totalSumaryBean.committedPenaltyAmount)}}
            </i-col>
            <i-col span="12" class=" p-b-5 ">
                <p class="title">未结算</p>
                {{formatMoney(totalSumaryBean.unCommittedPenaltyAmount)}}
            </i-col>
        </Row>

    </div>
</template>

<script>
import { getAgentStatementBillSummary } from '@/api/statement/bill'
import { toMoney } from '@/utils/wnumb_own'

export default {
  data () {
    return {
      showMoreCondition: false,
      totalSumaryBean: {},
      repaymentssummary: {}
    }
  },
  methods: {
    handleChangeCondition () {
      this.showMoreCondition = !this.showMoreCondition
    },
    initPageData (query) {
      getAgentStatementBillSummary(query).then((res) => {
        this.totalSumaryBean = res
      })
    },
    formatMoney (number) {
      return toMoney(number)
    }
  },
  computed: {
    transactionSettlementQuery () {
      return this.$store.state.settlementChannel.transactionSettlementQuery
    },
    beginUpdate () {
      return this.$store.state.settlementChannel.beginUpdate
    }
  },
  watch: {
    beginUpdate (val) {
      const query = {
        publisherId: this.$store.getters.token.userInfo.publisherId
      }

      query.startDate = this.transactionSettlementQuery.startDate
      query.endDate = this.transactionSettlementQuery.endDate
      query.keyword = this.transactionSettlementQuery.keyword
      query.billStatus =
          this.transactionSettlementQuery.billStatus === -1
            ? null
            : this.transactionSettlementQuery.billStatus
      query.agentCompanyId = this.transactionSettlementQuery.agentCompanyId
      this.initPageData(query)
    }
  }
}
</script>
