<template>
    <div>
      <i-table stripe  :data="list" :columns="columns"></i-table>
      <div class="paging_style">
        <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator :current="query.pageNumber" @on-change="handlePageNumberChange"></Page>
      </div>

    </div>
</template>

<script>
import { getOrderPage, getConflictList } from '@/api/order/order'

import { GetCurrentSchedule } from '@/utils/dateFormat'

export default {
  props: {
    params: Object,
    existedOrders: Array
  },
  data () {
    return {
      list: [],
      columns: [
        { title: '投放品牌', key: 'brandName' },
        { title: '备注', key: 'remark' },
        {
          title: '投放档期',
          render: (h, data) => {
            const spans = []
            data.row.purchasedScheduleList.forEach(item => {
              spans.push(h('span', GetCurrentSchedule(item.startDate, item.endDate)))
              spans.push(h('br'))
            })
            return h('div', spans)
          }
        },
        // {
        //   title: '档期天数',
        //   key: 'pubishDay',
        //   render: (h, params) => {
        //     return h('span',
        //       params.row.tradePublishday + '天'
        //     )
        //   }
        // },
        {
          title: '采购资源统计',
          width: 150,
          render: (h, params) => {
            const spans = []
            params.row.orderProductQuantityBeanList.forEach(resource => {
              spans.push(h(
                'span',
                resource.productName
              ))
              spans.push(h(
                'span',
                {
                  attrs: {
                    class: 'text-orange text-12'
                  }
                },
                resource.quantity
              ))
              spans.push(h(
                'span',
                resource.unitName
              ))
              spans.push(h('br'))
            })
            return h('div', spans)
          }
        },
        {
          title: '操作',
          render: (h, data) => {
            if (this.orders.some(item => item.id === data.row.id)) {
              return h('span', {
                // style: { color: '#ef4f4f' },
                on: {
                  // click: () => {
                  //   this.handleRemove(data.row)
                  // }
                }
              }, '-')
              // return h('a', {
              //   style: { color: '#ef4f4f' },
              //   on: {
              //     click: () => {
              //       this.handleRemove(data.row)
              //     }
              //   }
              // }, '移除')
            } else {
              return h('a', {
                props: { type: 'primary', size: 'small' },
                on: {
                  click: () => {
                    this.handleAdd(data.row)
                  }
                }
              }, '添加')
            }
          }
        }
      ],
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 10,
        keyword: '',
        status: JSON.stringify([1, 3]),
        userId: this.params.userId,
        advertiserId: this.params.advertiserResultBean.id,
        showProductQuantity: true
      }
    }
  },
  methods: {
    getOrders () {
      getOrderPage(this.query).then(res => {
        this.list = res.list
        this.total = res.totalRow
        res.list.forEach(x => {
          const idx = this.orders.findIndex(item => item.id === x.id)
          if (idx >= 0) {
            this.orders.splice(idx, 1)
            this.orders.push(x)
          }
        })
      })
    },
    handleAdd (order) {
      getConflictList({ orderId: order.id }).then(res => {
        if (res.length === 0) {
          // this.orders.push(order)
          this.$emit('on-chosen', order.id)
        } else {
          this.$Notice.error({ desc: '当前订单存在点位冲突，请先替换点位！' })
        }
        // if (this.orders.every(x => x.id !== order.id)) {
        //   this.orders.push(order)
        //   this.$emit('on-chosen', this.orders)
        // }
      })
    },
    handleRemove (order) {
      const index = this.orders.findIndex(item => item.id === order.id)
      this.orders.splice(index, 1)
      this.$emit('on-chosen', this.orders)
    },
    handlePageNumberChange (page) {
      this.query.pageNumber = page
      this.getOrders()
    }
  },
  created () {
    // this.orders = this.existedOrders || []
    this.getOrders()
  },
  computed: {
    orders: function () {
      return this.existedOrders
    }
  }
}
</script>
