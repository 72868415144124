<template>
    <div class="p-t-5 p-l-5">
        <Divider dashed style="margin: 10px 0 5px 0;"><span class="divider-text">汇总</span></Divider>

        <Row class="m-t-10 p-t-5 p-b-5">
            <i-col span="24" class="p-b-10">
              <p class="title">合同执行额</p> <p class="text-orange text-16 ">{{formatMoney(statisticInfo.totalExecuteAmount)}}</p>
            </i-col>
            <i-col span="12">
                <p class="title">已结算</p>
                {{formatMoney(statisticInfo.settleExecuteAmount)}}
            </i-col>
            <i-col span="12">
                <p class="title">未结算</p>
                {{formatMoney(statisticInfo.unsettledExecuteAmount)}}
            </i-col>
        </Row>

        <Divider dashed style="margin: 10px 0 5px 0;"><span class="divider-text">分类</span></Divider>
        <Row class="m-t-10 p-t-5 p-b-5">
            <i-col span="24" class="p-b-10">
              <p class="title">发布费执行额</p><p class="text-orange text-16 ">{{formatMoney(statisticInfo.totalUseExecuteAmount)}}</p>
            </i-col>
            <i-col span="12">
                <p class="title">已结算</p>
                {{formatMoney(statisticInfo.useSettleExecuteAmount)}}
            </i-col>
            <i-col span="12">
                <p class="title">未结算</p>
                {{formatMoney(statisticInfo.useUnsettledExecuteAmount)}}
            </i-col>
        </Row>

        <Row class="m-t-10 p-t-5 p-b-5">
            <i-col span="24" class="p-b-10">
              <p class="title">服务费执行额</p><p class="text-orange text-16 ">{{formatMoney(statisticInfo.totalServiceExecuteAmount)}}</p>
            </i-col>
            <i-col span="12">
                <p class="title">已结算</p>
                {{formatMoney(statisticInfo.serviceSettleExecuteAmount)}}
            </i-col>
            <i-col span="12">
                <p class="title">未结算</p>
                {{formatMoney(statisticInfo.serviceUnsettledExecuteAmount)}}
            </i-col>
        </Row>

    </div>
</template>

<script>

import { getContractBillSummaryStatistics } from '@/api/statement/contractBillDetail'
import { toMoney } from '@/utils/wnumb_own'

export default {
  data () {
    return {
      query: {
      },
      statisticInfo: {}
    }
  },
  created () {
  },
  methods: {
    initPageData () {
      getContractBillSummaryStatistics(this.query).then(res => {
        this.statisticInfo = res
      })
    },
    formatMoney (number) {
      return toMoney(number)
    }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.executionAmount.beginUpdate
    },
    transactionReconciliationQuery () {
      return this.$store.state.executionAmount.transactionReconciliationQuery
    }
  },
  watch: {
    beginUpdate () {
      this.query.companyId = this.transactionReconciliationQuery.companyId
      this.query.startDate = this.transactionReconciliationQuery.startDate
      this.query.endDate = this.transactionReconciliationQuery.endDate
      this.query.keyword = this.transactionReconciliationQuery.keyword

      this.query.billStatus = this.transactionReconciliationQuery.billStatus
      this.query.queryType = this.transactionReconciliationQuery.queryType
      this.query.feeType = this.transactionReconciliationQuery.feeType
      if (this.query.queryType) {
        this.initPageData()
      }
    }
  }
}
</script>
