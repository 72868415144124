<style scoped>
.box_content {
  height: 100%;
  /* padding: 8px 8px; */
  overflow-y: scroll;
  scrollbar-width: none; /* FireFox */
  -ms-overflow-style: none; /*IE10+*/
}
.box_content_padding {
  padding: 8px 8px;
}

.box_content::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.platform {
  display: block;
  padding: 10px 0;
  /* color: #fff; */
  border-radius: 3px;
}

.platform:hover {
  color: #5cadff;
  border-color: #5cadff;
}

.platform-actived {
  color: #2d8cf0;
  border-color: #2b85e4;
}
</style>

<template>
  <div>
    <slot name="left_box">
      <div
        v-show="showLeftBlock"
        class="left-block"
      >
        <div
          class="left-icon-show"
          @click="showLeftBlock=false"
        >
          <Icon
            type="md-arrow-dropleft"
            :size="20"
          />
        </div>
        <div class="box_content box_content_padding">
          <slot name="left_box_content"></slot>
        </div>

      </div>
      <div
        v-show="!showLeftBlock"
        class="left-icon-hide"
        @click="showLeftBlock=true"
      >
        <Icon
          type="md-arrow-dropright"
          :size="20"
        />
      </div>
    </slot>
    <slot name="right_box">
      <div
        v-show="showRightBlock"
        class="right-block"
      >
        <div
          class="right-icon-show"
          @click="showRightBlock=false"
        >
          <Icon
            type="md-arrow-dropright"
            :size="20"
          />
        </div>
        <div class="box_content box_content_padding">
          <slot name="right_box_content"></slot>
        </div>
      </div>
      <div
        v-show="!showRightBlock"
        class="right-icon-hide"
        @click="showRightBlock=true"
      >
        <Icon
          type="md-arrow-dropleft"
          :size="20"
        />
      </div>
    </slot>
    <slot name="bottom_box">
      <div
        v-show="showBottomBlock"
        class="bottom-block"
        :style="{'height': bottomHeight, 'left': showLeftBlock?'462px':'122px', 'right':showRightBlock?'302px':'2px' }"
      >
        <div
          v-show="isShowMap"
          style="position: absolute;right: 0px;z-index: 9999;"
        >
          <div
            v-if="bottomHeight!=='90%'"
            class="bottom-icon-up"
            @click="handleChangeBottomHeight(1)"
          >
            <Icon
              type="md-arrow-dropup"
              :size="18"
            />
          </div>
          <div
            v-if="bottomHeight!=='5px'"
            class="bottom-icon-down"
            @click="handleChangeBottomHeight(-1)"
          >
            <Icon
              type="md-arrow-dropdown"
              :size="18"
            />
          </div>
        </div>
        <div class="box_content">
          <div class="platform-buttom-list">
            <div
              v-for="item in linkTagArray"
              :key="'tag_'+item.key"
              :class="item.key===activedTagName? 'bottom-button-active':'bottom-button'"
              @click="handleChangeTag(item.key)"
            >
              {{item.value}}
            </div>
          </div>
          <div class="box_content_padding">
            <slot name="bottom_box_content"></slot>
          </div>
        </div>

      </div>
    </slot>
    <slot name="bg_title">
      <p class="text-center p-t-5" style="padding-right: 120px;">
        <span class="text-16"  @click="showChangeProjectModal" >{{currentProjectName}}
        </span><Icon v-if="projectArray.length>1" style="color:#4285f4;cursor:pointer;" size="16" type="md-swap" @click="showChangeProjectModal"/>
        <span class="m-r-10 m-l-10">用户：{{$store.state.user.token.userInfo.name}}</span>
        <!-- <span>{{currentTime}}</span> -->
      </p>
    </slot>

    <slot name="bg_map"></slot>
    <Modal
      v-model="switchProjectModal"
      :footer-hide="true"
      width="800"
    >
      <p
        slot="header"
        class="text-center"
      >切换项目</p>
      <Row :gutter="8">
        <i-col
          span="12"
          class="m-b-5"
          v-for="item in projectArray"
          :key="'p_' + item.id"
        >
          <a
            class="text-center platform"
            :class="currentProjectId === item.id ? 'platform-actived' : ''"
            href="javascript:void(0)"
            @click="handleSwitchProject(item)"
          >
            <h2>{{ item.name }}</h2>
          </a>
        </i-col>
      </Row>
    </Modal>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { ParseDateTime } from '@/utils/dateFormat'

import { getByUser } from '@/api/os/project'

export default {
  data () {
    return {
      // bottomHeight: '300px',
      currentTime: '',
      switchProjectModal: false,
      projectArray: []
    }
  },
  computed: {
    currentProjectId () {
      return this.$store.getters.token.userInfo.currentProjectId
    },
    currentProjectName () {
      return this.$store.getters.token.userInfo.currentProjectName
    },
    linkTagArray: {
      get () {
        return this.$store.state.sys.linkTagArray
      },
      set (val) {
        this.$store.commit('set_link_tag_array', val)
      }

    },
    linkRouterMaps () {
      return this.$store.state.sys.linkRouterMaps
    },
    activedTagName: {
      get () {
        return this.$store.state.sys.activedTagName
      },
      set (val) {
        this.$store.commit('set_actived_tag_name', val)
      }
    },
    showLeftBlock: {
      get () {
        return this.$store.state.sys.showLeftBlock
      },
      set (val) {
        this.setShowLeftBlock(val)
      }
    },
    showRightBlock: {
      get () {
        return this.$store.state.sys.showRightBlock
      },
      set (val) {
        this.setShowRightBlock(val)
      }
    },
    showBottomBlock: {
      get () {
        return this.$store.state.sys.showBottomBlock
      },
      set (val) {
        this.setShowBottomBlock(val)
      }
    },
    bottomHeight: {
      get () {
        return this.$store.state.sys.bottomHeight
      },
      set (val) {
        this.setBottomHeight(val)
      }
    },
    isShowMap () {
      return this.$store.state.sys.isShowMap
    }
  },
  created () {
    // this.loadTime()
    this.loadPorjects()
  },
  methods: {
    ...mapMutations({
      setShowLeftBlock: 'set_show_left_block',
      setShowRightBlock: 'set_show_right_block',
      setShowBottomBlock: 'set_show_bottom_block',
      setBottomHeight: 'set_bottom_height'
    }),
    loadTime () {
      this.currentTime = ParseDateTime(new Date())
      setTimeout(() => {
        this.loadTime()
      }, 1000)
    },
    showChangeProjectModal () {
      this.switchProjectModal = true
    },
    loadPorjects () {
      const that = this
      getByUser({}).then(res => {
        if (res && !res.errcode) {
          that.projectArray = res
          this.$store.commit('set_project_array', res)
        }
      })
    },
    // 切换项目
    handleSwitchProject (item) {
      if (item.id !== this.currentProjectId) {
        // 更新项目
        this.$store.dispatch('changeProject', { projectId: item.id, projectName: item.name }).then(() => {
          this.switchProjectModal = false
          window.location.href = '/'
        }).catch(() => {
          this.switchProjectModal = false
        })
      }
    },
    handleChangeBottomHeight (val) {
      if (this.bottomHeight === '5px') {
        this.bottomHeight = (val > 0) ? '300px' : '5px'
      } else if (this.bottomHeight === '300px') {
        this.bottomHeight = (val > 0) ? '90%' : '5px'
      } else if (this.bottomHeight === '90%') {
        this.bottomHeight = (val > 0) ? '90%' : '300px'
      }
    },
    handleChangeTag (key) {
      this.activedTagName = key
    }

  },
  watch: {
    activedTagName: {
      deep: true,
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal !== oldVal) {
          const curTagIndex = this.linkTagArray.findIndex(x => x.key === newVal)
          const oldTagIndex = this.linkTagArray.findIndex(x => x.key === oldVal)
          if (curTagIndex - oldTagIndex < 0) {
            this.linkTagArray.splice(curTagIndex + 1, this.linkTagArray.length - curTagIndex + 1)
          }
          if (newVal && this.linkRouterMaps.get(newVal)) {
            this.linkRouterMaps.get(newVal).call()
          }
        }
      }
    }
  }
}
</script>
