<template>
  <Card v-if="value&&value.length>0">
    <p slot="title">服务签约变化</p>
    <div>
      <Row
        type="flex"
        align="middle"
        justify="center"
        :gutter="18"
      >
        <i-col span="11">
          <i-table
           stripe
            :data="from"
            :columns="columns"
          ></i-table>
        </i-col>

        <i-col span="2" class="text-center">
          <Icon type="md-arrow-round-forward" size="40" color="green"></Icon>
        </i-col>

        <i-col span="11">
          <i-table
           stripe
            :data="to"
            :columns="columns"
          ></i-table>
        </i-col>
      </Row>
    </div>

  </Card>
</template>

<script>
// import orderExpand from './OrderExpand'
import { toMoney } from '@/utils/wnumb_own'
export default {
  // components: { orderExpand },
  props: {
    value: Array
  },
  data () {
    return {
      // columns: [
      //   {type: 'expand',
      //     width: 50,
      //     render: (h, data) => {
      //       return h(orderExpand, {
      //         props: {
      //           products: data.row.checkContractProductBeanList
      //         }
      //       })
      //     } },
      //     {title: '客户', key: 'advertiserName'},
      //     {title: '品牌', key: 'brandName'},
      //     {title: '开始档期', key: 'startDate'},
      //     {title: '结束档期', key: 'endDate'}
      //     // {title: '天数', key: ''},
      //     // {title: '总价', key: ''}
      //   // {title: '',
      //   //   render: (h, data) => {
      //   //     return h('a', {

      //   //     }, '查看详情')
      //   //   }}
      // ],
      columns: [
        { title: '名称', key: 'serviceItemName' },
        { title: '签约数量', key: 'signServiceQuantity' },
        {
          title: '签约价',
          key: 'signUnitPrice',
          render: (h, data) => {
            return h('div', [
              h('span', {}, this.formatMoney(data.row.signUnitPrice * data.row.signServiceQuantity))
            ])
          }
        }
      ],
      from: [],
      to: []
    }
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    }

  },
  created () {
    this.value.forEach(order => {
      this.to = this.to.concat(order.to)
      this.from = this.from.concat(order.from)
    })
  }

}
</script>
