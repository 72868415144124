<template>
  <div>
    <Divider
      dashed
      style="color:#fff"
    >上刊设置汇总</Divider>
    <div>
      <Table
        stripe
        size="small"
        :data="list"
        :columns="tabelColumns"
      ></Table>
    </div>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { GetCurrentSchedule } from '@/utils/dateFormat'
import { getOrderScheduleList } from '@/api/msp/demand'
import { createOrderTask } from '@/api/order/demand'
export default {
  mixins: [sysMixins],
  data () {
    return {
      list: [],
      tabelColumns: [
        {
          title: '档期',
          render: (h, params) => {
            return h('span', GetCurrentSchedule(params.row.startDate, params.row.endDate, false))
          }
        },
        {
          title: '操作',
          align: 'right',
          render: (h, params) => {
            return h('a', {
              on: {
                click: () => {
                  // this.handleSetting(params.row)
                  this.startDate = params.row.startDate
                  this.endDate = params.row.endDate
                  this.handleCreateTask()
                }
              }
            }, '创建换刊申请·')
          }
        }
      ],
      // createTaskModal: false,
      startDate: '',
      endDate: '',
      taskType: 1
      // taskTypeArray: [
      //   { value: 1, name: '上刊申请' },
      //   { value: 7, name: '下刊申请' }
      // ]
    }
  },
  computed: {
    orderId () {
      return this.$store.state.installSetting.demandOrderId
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      getOrderScheduleList({ orderId: this.orderId }).then(res => {
        if (res && !res.errcode) {
          this.list = res
        } else {
          this.list = []
        }
      })
    },
    handleCreateTask () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要创建换刊申请？',
        onOk: () => {
          const postData = {
            orderId: this.orderId,
            startDate: this.startDate,
            endDate: this.endDate
          }
          createOrderTask(postData).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.$store.commit('set_demand_type', this.taskType)
              this.$store.dispatch('getTaskPageData', { orderId: this.orderId, type: this.taskType })
            }
          })
        }
      })

      // this.createTaskModal = false
    }
  },
  watch: {
    orderId (val) {
      this.initData()
    }
  }
}
</script>
