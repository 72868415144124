<template>
  <div class="p-t-5 p-l-5">
        <Divider dashed style="margin: 10px 0 5px 0;"><span class="divider-text">数据汇总</span></Divider>
        <div >
          <div class="m-t-10">
              <p class="title">结算公司</p>
            {{totalSumaryBean.purchaserCompanyName}}
          </div>
          <div class="m-t-10">
              <p class="title">供应商</p>
            {{totalSumaryBean.supplierCompanyName}}
          </div>
        </div>
        <Row class=" m-t-10 p-t-5 p-b-5 ">
            <i-col span="24" class="text-16 p-b-10">
               总金额<p class="text-orange">{{formatMoney(totalSumaryBean.totalAmount)}}</p>
            </i-col>
            <i-col span="12" class=" p-b-5 ">
                <p class="title">已结算</p>
                {{formatMoney(totalSumaryBean.committedAmount)}}
            </i-col>
            <i-col span="12" class=" p-b-5 ">
                <p class="title">未结算</p>
                {{formatMoney(totalSumaryBean.unCommittedAmount)}}
            </i-col>
        </Row>

        <Divider dashed style="margin: 10px 0 5px 0;"></Divider>
        <Row class=" m-t-10 p-t-5 p-b-5 ">
          <i-col span="24" class="text-16 p-b-10">
            调整项
            </i-col>
            <i-col span="12" class=" p-b-5 ">
                <p class="title">已结算</p>
                {{formatMoney(totalSumaryBean.committedAdjustAmount)}}
            </i-col>
            <i-col span="12" class=" p-b-5 ">
                <p class="title">未结算</p>
                {{formatMoney(totalSumaryBean.unCommittedAdjustAmount)}}
            </i-col>

        </Row>

        <Divider dashed style="margin: 10px 0 5px 0;"></Divider>
        <Row class=" m-t-10 p-t-5 p-b-5 ">
          <i-col span="24" class="text-16 p-b-10">
            罚款项
            </i-col>
            <i-col span="12" class=" p-b-5 ">
                <p class="title">已结算</p>
                {{formatMoney(totalSumaryBean.committedPenaltyAmount)}}
            </i-col>
            <i-col span="12" class=" p-b-5 ">
                <p class="title">未结算</p>
                {{formatMoney(totalSumaryBean.unCommittedPenaltyAmount)}}
            </i-col>
        </Row>

    </div>
</template>

<script>
import { getSupplierStatementBillSummary } from '@/api/statement/bill'
import { toMoney } from '@/utils/wnumb_own'

export default {
  data () {
    return {
      showMoreCondition: false,
      totalSumaryBean: {},
      repaymentssummary: {}
    }
  },
  methods: {
    handleChangeCondition () {
      this.showMoreCondition = !this.showMoreCondition
    },
    initPageData (query) {
      getSupplierStatementBillSummary(query).then((res) => {
        this.totalSumaryBean = res
      })
    },
    formatMoney (number) {
      return toMoney(number)
    }
  },
  computed: {
    maintenanceSettlementQuery () {
      return this.$store.state.settlementSupplier.maintenanceSettlementQuery
    },
    beginUpdate () {
      return this.$store.state.settlementSupplier.beginUpdate
    }
  },
  watch: {
    beginUpdate (val) {
      const query = {
        publisherId: this.$store.getters.token.userInfo.publisherId
      }

      query.startDate = this.maintenanceSettlementQuery.startDate
      query.endDate = this.maintenanceSettlementQuery.endDate
      query.keyword = this.maintenanceSettlementQuery.keyword
      query.billStatus =
        this.maintenanceSettlementQuery.billStatus === -1
          ? null
          : this.maintenanceSettlementQuery.billStatus
      query.purchaserCompanyId =
        this.maintenanceSettlementQuery.purchaserCompanyId
      query.supplierCompanyId =
        this.maintenanceSettlementQuery.supplierCompanyId
      this.initPageData(query)
    }
  }
}
</script>
