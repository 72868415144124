<template>
  <div>
    <div class="platform-buttom-list">
      <div class="bottom-button m-r-5" @click="goToIndex">
        项目管理
      </div>
      <div class="bottom-button-active">
        SKU管理
      </div>
    </div>
    <div>
      <ProductManage :projectId="id" />
    </div>
  </div>
</template>

<script>
import { projectCommonMxins } from '../../mixins/common'

// 产品管理组件
import ProductManage from '@/components/productManage/bottom/Index.vue'

export default {
  mixins: [projectCommonMxins],
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  components: {
    ProductManage
  },
  data () {
    return {

    }
  }
}
</script>

<style></style>
