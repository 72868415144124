
<template>

    <transactionHistory v-if="contractId" :contractId="contractId"  :versionId="contractId"></transactionHistory>
</template>

<script>
import transactionHistory from '../../settlement/common/transactionHistory'

export default {
  components: { transactionHistory },
  data () {
    return {

    }
  },
  created () {
  },
  methods: {
  },
  computed: {
    detailBean () {
      return this.$store.state.executionAmount.detailBean
    },
    contractId () {
      return this.detailBean.contractId
    }
  },
  watch: {
  }
}
</script>
