import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取上刊需求列表
 * @param {Object} data
 */
export function getDemandList (data) {
  return request(
    {
      url: '/v2/demand/getdemandlist',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 获取订单档期列表
 * @param {Object} data
 */
export function getOrderScheduleList (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/getorderschedulelist',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 订单产品资源数量统计
 * @param {Object} data
 */
export function getOrderProductStatistic (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/getorderproductstatistic',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 订单发布中资源 产品数量统计
 * @param {Object} data
 */
export function getOrderPublishProductStatistic (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/getorderpublishproductstatistic',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 获取订单指定档期资源详情
 * @param {Object} data
 */
export function getOrderScheduleDetailPage (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/getorderscheduledetailpage',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 设置连刊
 * @param {Object} data
 */
export function setDemandLx (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/setdemandlx',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 设置站点资源上刊需求
 * @param {Object} data
 */
export function setStationTask (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/setstationtask',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 获取梯牌资源序列号，及对应的画面信息
 * @param {Object} data
 */
export function getStairSerialsInfo (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/getstairserialsinfo',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 设置梯牌资源上刊需求
 * @param {Object} data
 */
export function setStationStairTask (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/setstationstairtask',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 提交上刊需求
 * @param {Object} data
 */
export function submitDemand (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/submitdemand',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 撤回上刊需求
 * @param {Object} data
 */
export function revocation (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/revocation',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 订单发布中资源信息
 * @param {Object} data
 */
export function getOrderPublishResourcePage (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/getorderpublishresourcepage',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 上刊需求 任务进度统计
 * @param {Object} data
 */
export function demandProgress (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/demandprogress',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 监播申请
 * @param {Object} data
 */
export function createInspect (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/createinspect',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 批量上刊设置申请监播
 * @param {Object} data
 */
export function createInspects (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/createinspects',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}
/**
 * 分页获取上刊需求资源详情
 * @param {Object} data
 */
export function getDemandDetailPage (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/getdemanddetailpage',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 获取上刊需求资源类型的资产站点统计
 * @param {Object} data
 */
export function getResourceParameter (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/getresourceparameter',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 删除上刊设置
 * @param {Object} data
 */
export function deleteStationTask (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/deletestationtask',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 获取上刊需求 产品类型列表
 * @param {Object} data
 */
export function getDemandProductList (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/getdemandproductlist',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 更新资源上刊需求设置
 * @param {Object} data
 */
export function updateStationTask (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/updatestationtask',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 上刊需求编辑更新后 确认提交
 * @param {Object} data
 */
export function updateDemand (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/updatedemand',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 删除上刊需求
 * @param {Object} data
 */
export function deleteDemand (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/deletedemand',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 更新上刊需求对应task item状态
 * @param {Object} data
 */
export function updateDemandTaskitem (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/updatedemandtaskitem',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 获取资源序列号，及对应的画面信息
 * @param {Object} data
 */
export function getmspSerialsInfo (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/getserialsinfo',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 获取任务费用类型
 * @param {Object} data
 */
export function getTaskCostType (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/gettaskcosttype',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}
/**
 * 获取配置费用
 * @param {Object} data
 */
export function getTaskitemCostList (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/gettaskitemcostlist',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 获取任务默认名称
 * @param {Object} data
 */
export function getTaskDefaultName (data) {
  return request(
    {
      url: '/ooh-msp/v2/demand/gettaskdefaultname',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 获取供应商资产
 * @param {Object} data
 * @returns
 */
export function getPublisherAssetList (data) {
  return request.post('/ooh-msp/v2/demand/getsupplier-assetList', qs.stringify(data))
}

/**
 * 根据上刊任务获取下刊任务默认名称
 * @param {Object} data
 * @returns
 */
export function getUninstallDefaultName (data) {
  return request.post('/ooh-msp/v2/demand/getUninstallDefaultName', qs.stringify(data))
}
