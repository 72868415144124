<template>
  <div>
    <i-table stripe :columns="columns" :data="products"></i-table>
    <Modal v-model="showModal" :width="400" :mask-closable="false">
      <p slot="header" class="workplatform-title">
        <span>调整结算价</span>
      </p>
      <InputNumber
        v-model="params.normalSettlementPrice"
        :min="0"
        style="width: 100%"
      ></InputNumber>

      <div slot="footer">
        <i-button class="btn-black" @click="showModal = false">关闭</i-button>
        <i-button type="primary" @click="confirmChange">确认</i-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { GetCurrentSchedule } from '@/utils/dateFormat'

import { updateOrderSettlementPrice } from '@/api/scp/statementorder'
import { saveGoodServiceFee, getOrderGoodServiceFee } from '@/api/scp/contractorder'
export default {
  props: {
    versionId: {
      type: Number,
      required: true
    },
    products: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      columns: [{ title: '资源名称', key: 'productName' },
        {
          title: '投放档期',
          render: (h, data) => {
            return h(
              'span',
              GetCurrentSchedule(data.row.startDate, data.row.endDate)
            )
          }
        },
        {
          title: '采购数量',
          align: 'center',
          render: (h, data) => {
            return h('span', data.row.signNormalQuantity)
          }
        },

        {
          title: '订单价',
          render: (h, data) => {
            return h('div', [
              h('span', { class: 'money' }, toMoney(data.row.normalUsePrice)),
              h('span', '(' + data.row.usePriceDiscount + ')')
            ])
          }
        },

        {
          title: '结算价',
          render: (h, data) => {
            return h('div', [
              h(
                'span',
                { attrs: { class: 'money' } },
                toMoney(data.row.normalSettlementPrice)
              ),
              h(
                'span',
                { attrs: { class: 'discount' } },
                this.computedSale(data.row.normalUsePrice, data.row.normalSettlementPrice)
              )
            ])
          }
        },

        {
          title: '操作',
          render: (h, data) => {
            const detailButton = h(
              'a',
              {
                style: { 'margin-left': '5px' },
                on: {
                  click: () => {
                    this.showDetail(data.row)
                  }
                }
              },
              '调整结算价'
            )
            const returnBtn = [
              this.allowEdit ? detailButton : ''
            ]
            return h('div', returnBtn)
          }
        }],
      showModal: false,
      params: {
        statementDetailId: 0,
        normalSettlementPrice: 0,
        totalProduceSettlePrice: 0,
        totalInstallSettlePrice: 0
      },
      showServiceSettingModal: false,
      services: []
    }
  },
  created () {
  },
  computed: {
    allowEdit: function () {
      // 允许输入 执行价低于最低折扣
      return this.$store.getters.token.publisherSetting.statementPriceEditable
    }
  },
  watch: {
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },
    computedSale (usePrice, sellPrice) {
      if (usePrice > 0) {
        const discount = (sellPrice / usePrice) * 10
        const showDisCount = discount.toFixed(2)
        return '(' + showDisCount + '折)'
      } else {
        return ''
      }
      // return Number((sellPrice * 10 / usePrice).toFixed(2))
    },
    showDetail (record) {
      this.params.statementDetailId = record.statementDetailId
      this.params.normalSettlementPrice = record.normalSettlementPrice
      // this.params.totalInstallSettlePrice = record.totalInstallSettlePrice
      // this.params.totalProduceSettlePrice = record.totalProduceSettlePrice
      this.showModal = true
    },
    confirmChange () {
      const that = this
      updateOrderSettlementPrice({
        statementDetailId: that.params.statementDetailId,
        normalSettlementPrice: that.params.normalSettlementPrice
      }).then((res) => {
        that.showModal = false
        that.$emit('reloadProducts')
      })
    },
    showServiceSetting (product) {
      this.orderId = product.orderId
      getOrderGoodServiceFee({
        orderId: product.orderId,
        goodUseFeeId: product.goodUseFeeId
      }).then((res) => {
        this.services = res
        this.showServiceSettingModal = true
      })
    },
    confirmServiceSetting () {
      saveGoodServiceFee({
        orderId: this.orderId,
        versionId: this.versionId,

        serviceFees: JSON.stringify(this.services.map(item => {
          return {
            goodServiceFeeId: item.goodServiceFeeId, signServiceNum: item.signServiceQuantity, signServiceAmount: item.signServiceAmount
          }
        }))
      }).then((res) => {
        this.showServiceSettingModal = false
        this.$emit('reloadProducts')
      })
    }

  }
}
</script>
