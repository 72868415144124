<template>
  <Modal v-model="show" width="60%">
    <p slot="header" class="text-center">工单列表</p>
    <Table stripe size="small" :data="list" :columns="tableColumns"></Table>
  </Modal>
</template>

<script>
export default {
  props: {
    list: [Array]
  },
  data () {
    return {
      show: false,
      tableColumns: [
        { title: '类型', align: 'center', key: 'typeName' },
        { title: '状态', align: 'center', key: 'statusName' },
        { title: '完成时间', align: 'center', key: 'finishTime' },
        { title: '质检时间', align: 'center', key: 'qualitytestTime' }
      ]
    }
  },
  methods: {
    init () {
      this.show = true
    }
  }
}
</script>
