<template>
  <div>
    <Row>
      <i-col span="24" class="text-right">
        <i-button type="primary" class="m-r-5" size="small" @click="showHistory=true">审批记录</i-button>
        <i-button type="primary" class="m-r-5" size="small"  @click="handleDownload">下载确认单</i-button>
        <i-button type="primary" class="m-r-5" v-if="showAppovalButton" size="small"  @click="handleDownloadApproval">下载确认单审批表</i-button>
        <i-button type="error" class="m-r-5" size="small" @click="handleInvalid" v-if="allowInvalid">作废</i-button>
        <i-button type="error" class="m-r-5" size="small"  @click="cancelContractApproval" v-if="contract.status===4">撤回审核申请</i-button>
        <i-button type="error" class="m-r-5" size="small"  @click="handleChangeCode" v-if="nullifiable">更改编号</i-button>
        <i-button type="error" class="m-r-5" size="small" @click="createContract" v-if="contract.toBid<=0">升级为合同</i-button>
      </i-col>
    </Row>

    <contract-detail ref="contractDetail" :id="id" :versionId="otherParams"  :theme="'black'" @on-update="handleContractUpdate"></contract-detail>
    <invalid-component ref="invalidComponent" :id="id"  @on-success="handleInvalidSuccess"></invalid-component>
    <Modal v-model="showHistory" :width="800" footer-hide>
      <history :relateId="otherParams" :type="approvalType" v-if="showHistory"></history>
    </Modal>

  </div>
</template>

<script>
import { downloadFileRequest } from '@/utils/download'

import History from '@/components/approval/History'
import ContractDetail from '@/components/contract/contractDetail/Contract'
import InvalidComponent from '@/components/contract/contractDetail/detail/InvalidComponent'

import { changeContractCode, createdraftfromconfirmation, withdrawContractApproval } from '@/api/scp/contract'

export default {
  props: {
    baseId: {
      type: Number,
      required: true
    },
    otherParams: {
      type: Number,
      required: true
    }
  },
  components: {
    History, ContractDetail, InvalidComponent
  },
  data () {
    return {
      id: this.baseId,
      showHistory: false,
      approvalType: 12,
      contract: {}
    }
  },
  methods: {
    handleInvalid () {
      this.$refs.invalidComponent.initPage()
    },
    handleInvalidSuccess () {
      this.$refs.contractDetail.getDetail()
    },
    handleDownload () {
      const filename = '广告发布确认单(' + this.contract.code + ')' + '.doc'

      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/contract/download', { contractId: this.id }, filename)
    },
    handleDownloadApproval () {
      const filename = '确认单审批表(' + this.contract.code + ')' + '.doc'

      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/contract/downloadapprovalform', { contractId: this.id }, filename)
    },
    handleContractUpdate (contract) {
      this.contract = contract
    },
    handleChangeCode () {
      let newCode = this.contract.code
      this.$Modal.confirm({
        title: '更改合同的编号',
        render: h => {
          return h('Input', {
            props: {
              value: newCode
            },
            on: {
              'on-change': event => {
                newCode = event.target.value
              }
            }

          })
        },
        onOk: () => {
          changeContractCode({ contractId: this.id, contractCode: newCode }).then(res => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '更改编号成功' })
              // window.location.reload()
              this.$refs.contractDetail.getDetail()
            }
          })
        }
      })
    },
    createContract () {
      createdraftfromconfirmation({ confirmationBid: this.baseId, destSubjectType: this.$store.getters.token.publisherSetting.contractType === 1 ? 1 : 3 }).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({ desc: '合同创建成功，即将进入合同编辑页面！' })
          this.$emit('on-showContract', res)
        }
      })
    },
    reloadcountDown () {
      if (this.countDown > 0) {
        this.countDown -= 1
      }
    },
    cancelContractApproval () {
      let newCode = ''
      this.$Modal.confirm({
        title: '撤销审批确认',

        render: h => {
          return [h('p', '撤销审批，确认单将变为草稿状态，订单方案将恢复到确认单提交前的状态，确认撤销？', {

          }), h('Input', {
            props: {
              value: newCode,
              placeholder: '撤销备注'
            },
            on: {
              'on-change': event => {
                newCode = event.target.value
              }
            }

          })]
        },
        onOk: () => {
          withdrawContractApproval({ versionId: this.contract.versionId, remark: newCode }).then(res => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '撤回审批申请成功！' })
              this.$refs.contractDetail.getDetail()
            }
          })
        }
      })
    }

  },
  computed: {
    allowInvalid: function () {
      // let startDate = new Date(this.contract.startDate)
      return this.contract.status !== -1 && this.isAuth('Agreement_Invalid')
    },
    nullifiable: function () {
      // let startDate = new Date(this.contract.startDate)
      return this.contract.status !== -1 && this.isAuth('Agreement_ChangeCode')
    },
    showAppovalButton () {
      // 只有北京显示此按钮
      return this.$store.getters.token.userInfo.publisherId === 18
    }
  },
  watch: {
    baseId (val) {
      this.id = this.baseId
    }
  }
}
</script>
