<template>
  <div>
    <Divider dashed style="color:#fff">设置下刊内容</Divider>
    <Form
      ref="newTaskData"
      :model="newTaskData"
      :rules="ruleValidate"
      label-position="top"
    >
      <FormItem
        label="作业日期"
        prop="actionDate"
      >
        <DatePicker
          placeholder="选择作业日期"
          size="small"
          v-model="newTaskData.actionDate"
          type="date"
          style="width:100%"
          :editable="false"
          :clearable="false"
          @on-change="changeActionDate"
        ></DatePicker>
      </FormItem>
      <FormItem label="备注">
        <Input
          type="textarea"
          size="small"
          :rows="3"
          v-model.trim="newTaskData.remarks"
          placeholder="备注"
        />
      </FormItem>
      <FormItem>
        <div class="text-right">
          <Button
            type="success"
            size="small"
            :disabled="selectedResourceIds.length===0"
            @click="handleSubmit"
          >确认配置</Button>
        </div>

      </FormItem>
    </Form>
  </div>
</template>

<script>
import { setTaskitemUninstall } from '@/api/msp/demandV3'
import { updateTaskitem } from '@/api/order/demand'
export default {
  data () {
    return {
      newTaskData: {
        taskitemType: 7,
        actionDate: '',
        taskId: null,
        extras: '',
        // productId: null,
        remarks: ''
      },
      ruleValidate: {
        actionDate: [{ required: true, message: ' ', trigger: 'change' }]
        // name: [{ required: true, type: 'string', message: '请填写任务名称', trigger: 'blur' }]
      }
    }
  },
  computed: {
    curTaskId () {
      return this.$store.state.installSetting.currentTaskId
    },
    setOperatingType () {
      return this.$store.state.installSetting.setOperatingType
    },
    selectedResourceIds: {
      get () {
        return this.$store.state.installSetting.selectedResourceIds
      },
      set (val) {
        this.$store.commit('set_selected_resourceIds', val)
      }
    },
    selectedTaskitemIds: {
      get () {
        return this.$store.state.installSetting.selectedTaskIds
      },
      set (val) {
        this.$store.commit('set_selected_taskIds', val)
      }
    },
    selectedProductId: {
      get () {
        return this.$store.state.installSetting.selectedProductId
      },
      set (val) {
        this.$store.commit('set_selected_productId', val)
      }
    }
  },
  methods: {
    changeActionDate (date) {
      this.newTaskData.actionDate = date
    },
    handleSubmit () {
      this.$refs.newTaskData.validate(valid => {
        if (valid) {
          if (this.setOperatingType === 1) {
            // this.newTaskData.productId = this.selectedProductId
            this.newTaskData.taskId = this.curTaskId
            this.newTaskData.resourceIds = JSON.stringify(this.selectedResourceIds)
            setTaskitemUninstall(this.newTaskData).then(res => {
              if (res && res.errcode === 0) {
                this.$Notice.success({ desc: '操作成功' })
                this.selectedResourceIds = []
                this.selectedTaskitemIds = []
                this.$store.commit('set_temp_selected_item', null)
                this.$store.dispatch('getPublishingTaskitemData')
              }
            })
          } else {
            this.newTaskData.taskitemIds = JSON.stringify(this.selectedTaskitemIds)
            updateTaskitem(this.newTaskData).then(res => {
              if (res && res.errcode === 0) {
                this.$Notice.success({ desc: '操作成功' })
                this.selectedResourceIds = []
                this.selectedTaskitemIds = []
                this.$store.commit('set_temp_selected_item', null)
                this.$store.dispatch('getTaskitemData')
              }
            })
          }
        }
      })
    }
  }
}
</script>
