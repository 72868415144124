<template>
  <div>
    <Modal v-model="resModal" :mask-closable="false" footer-hide width="60">
      <p slot="header" class="text-center">选择资源</p>
      <Tabs v-model="resBoxShow" size="small">
        <TabPane label="选择资源" name="tab_choice">
          <div>
            <Row :gutter="8" class="m-b-5">
              <i-col span="3">
                <Select v-model="resQuery.assetId" :cleable="false" size="small" placeholder="选择资产"
                  @on-change="handleChangeAsset">
                  <Option v-for="item in assetArray" :key="'aid_' + item.id" :value="item.id">{{ item.name }}</Option>
                </Select>
              </i-col>
              <i-col span="3">
                <i-select size="small" placeholder="站点" clearable v-model="resQuery.stationId">
                  <i-option :value="item.id" v-for="item in stationArray" :key="item.id">{{ item.name }}</i-option>
                </i-select>
              </i-col>
              <i-col span="3">
                <i-select size="small" placeholder="位置" clearable v-model="resQuery.position">
                  <i-option v-for="item in positionArray" :value="item.value" :key="'pos_' + item.value">{{ item.name
                    }}</i-option>
                </i-select>
              </i-col>
              <i-col span="3">
                <i-select size="small" placeholder="资源类型(可搜索)" clearable filterable v-model="resQuery.resourcetypeId">
                  <OptionGroup v-for="item in resourceTypeArray" :key="'re_parent_' + item.id" :label="item.name">
                    <Option v-for="child in item.children" :value="child.id" :key="'re_child' + child.id">{{ child.name
                      }}
                    </Option>
                  </OptionGroup>
                </i-select>
              </i-col>
              <i-col span="2">
                <i-select size="small" placeholder="等级" clearable v-model="resQuery.level">
                  <i-option v-for="item in levelArray" :key="'level_' + item.id" :value="item.id">{{ item.name
                    }}</i-option>
                </i-select>
              </i-col>
              <i-col span="2">
                <i-select size="small" placeholder="状态" clearable v-model="resQuery.enabled">
                  <i-option v-for="item in statusArray" :key="'status_' + item.value" :value="item.value">{{ item.name
                    }}</i-option>
                </i-select>
              </i-col>

              <i-col span="4">
                <i-input size="small" placeholder="关键字：支持名称查询" v-model="resQuery.keyword"></i-input>
              </i-col>
              <i-col span="4">
                <i-button size="small" class="m-r-5" type="primary" icon="ios-search"
                  @click="handleSearch">搜索</i-button>
                <Poptip confirm transfer title="一键键入会将搜索结果全部加入，确定要进行一键加入？" @on-ok="onAutoAddRes">
                  <Button type="success" size="small">一键加入</Button>
                </Poptip>
              </i-col>
            </Row>
            <div class="m-b-5">
              <Button type="success" size="small" :disabled="selectedResArray.length === 0"
                @click="handleConfirmRes">确认加入</Button>
            </div>
            <Table size="small" stripe :max-height="700" :data="resList" :columns="resColumns" :loading="resLoading"
              @on-selection-change="handleSelectedRes">
            </Table>
            <div class="paging_style">
              <Page size="small" :total="resTotal" :page-size="resQuery.pageSize" show-total show-elevator show-sizer
                :current="resQuery.pageNumber" :page-size-opts="[15, 50, 100, 200]" @on-change="handlePageChange"
                @on-page-size-change="handelChangePageSize"></Page>
            </div>
          </div>
        </TabPane>
        <TabPane label="已有资源" name="tab_exist">
          <div class="m-b-10">
            <Button type="primary" size="small" class="m-r-5" :disabled="selectedExistResIds.length === 0"
              @click="onEditRes">编辑规格</Button>
            <Button type="error" size="small" class="m-r-5" :disabled="selectedExistResIds.length === 0"
              @click="handleDeleteResItem">删除资源</Button>
          </div>
          <Table size="small" stripe :max-height="700" :data="existResArray" :columns="existResColumns"
            @on-selection-change="onSelectedExistRes">
          </Table>
          <div class="paging_style">
            <Page size="small" :total="existResTotal" :page-size="existResQuery.pageSize" show-total show-elevator
              show-sizer :current="existResQuery.pageNumber" :page-size-opts="[15, 50, 100, 200]"
              @on-change="onChangeExistPage" @on-page-size-change="onChangeExistPageSize"></Page>
          </div>
        </TabPane>
      </Tabs>

    </Modal>
    <Modal v-model="editResModal" width="400">
      <p slot="header" class="text-center">编辑规格信息</p>
      <p class="m-b-10 text-orange">提示：常规媒体可以不用编辑，电子媒体根据实际情况进行编辑。</p>
      <!-- <Row :gutter="8" class="table-title">
        <i-col span="8" class="p-l-5">资源编号</i-col>
        <i-col span="8" class="p-l-5">售卖规格（秒或天）</i-col>
        <i-col span="8" class="p-l-5">售卖周期数（次）</i-col>
      </Row>
      <Row :gutter="8" class="m-b-5" v-for="item in selectedResArray" :key="'res_item_' + item.id">
        <i-col span="8">
          <Tag color="default">{{ item.code }}</Tag>
        </i-col>
        <i-col span="8">
          <InputNumber v-model="item.duration" :min="1" size="small" style="width:100%" placeholder="最小售卖规格">
          </InputNumber>
        </i-col>
        <i-col span="8">
          <InputNumber v-model="item.period" :min="1" size="small" style="width:100%" placeholder="每天周期数">
          </InputNumber>
        </i-col>
      </Row> -->
      <Row :gutter="8" class="m-b-10">
        <i-col span="10">售卖规格（秒或天）</i-col>
        <i-col span="14">
          <InputNumber v-model="editModel.duration" :min="1" size="small" style="width:100%" placeholder="最小售卖规格">
          </InputNumber>
        </i-col>
      </Row>
      <Row :gutter="8" class="m-b-10">
        <i-col span="10">售卖周期数（次）</i-col>
        <i-col span="14">
          <InputNumber v-model="editModel.period" :min="1" size="small" style="width:100%" placeholder="每天周期数">
          </InputNumber>
        </i-col>
      </Row>
      <div slot="footer">
        <Button type="text" class="m-r-5" @click="editResModal = false">取消</Button>
        <Button type="primary" @click="onConfirmEdit">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getPositions, getResourcePage } from '@/api/product/resource'
import { getResourceTypeList } from '@/api/product/asset'
import { getStationByAsset } from '@/api/product/station'
import { getSkuResourcePage } from '@/api/product/productskuedit'
import { addProjectResource, deleteProjectResource, autoAddProjectResource, updateProjectResource } from '@/api/product/projectResource'
export default {
  props: {
    assetIds: {
      type: Array,
      required: true
    },
    skuId: {
      type: Number,
      required: true
    },
    projectId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      resModal: false,
      resBoxShow: 'tab_exist',
      resLoading: false,
      resQuery: {
        pageSize: 15,
        pageNumber: 1,
        assetId: null,
        stationId: null,
        enabled: null,
        position: null,
        level: null,
        resourcetypeId: null,
        keyword: ''
      },
      stationArray: [],
      positionArray: [],
      resourceTypeArray: [],
      levelArray: [
        { id: 1, name: 'A' },
        { id: 2, name: 'A+' },
        { id: 3, name: 'A++' },
        { id: 4, name: 'S' },
        { id: 5, name: 'S+' },
        { id: 6, name: 'S++' }
      ],
      statusArray: [
        { value: 1, name: '可用' },
        { value: 0, name: '不可用' }
      ],
      resList: [],
      resTotal: 0,
      resColumns: [
        { type: 'selection', width: 60, align: 'center' },
        { title: '编号', align: 'center', key: 'code' },
        { title: '所属站点', align: 'center', key: 'stationName' },
        { title: '资源类型', align: 'center', key: 'resourcetypeName' },
        { title: '等级', align: 'center', key: 'levelName' },
        {
          title: '状态',
          align: 'center',
          key: 'enabled',
          render: (h, params) => {
            return h('span', params.row.enabled ? '可用' : '不可用')
          }
        }
      ],
      existResQuery: {
        pageSize: 15,
        pageNumber: 1,
        skuId: null
      },
      selectedResArray: [],
      selectedExistResIds: [],
      existResArray: [],
      existResTotal: 0,
      existResColumns: [
        { type: 'selection', width: 60, align: 'center' },
        {
          title: '编号',
          align: 'center',
          key: 'resourceCode',
          render: (h, { row }) => {
            return h('span', row.resource.code || '/')
          }
        },
        {
          title: '站点',
          align: 'center',
          render: (h, { row }) => {
            return h('span', row.resource.assetName ? `${row.resource.assetName} - ${row.resource.stationName}` : '/')
          }
        },
        {
          title: '资源类型',
          align: 'center',
          render: (h, { row }) => {
            return h('span', row.resource.resourcetypeName || '/')
          }
        },
        {
          title: '等级',
          align: 'center',
          render: (h, { row }) => {
            return h('span', row.resource.levelName || '/')
          }
        },
        { title: '售卖规格（秒或天）', align: 'center', key: 'duration' },
        { title: '售卖周期数（次）', align: 'center', key: 'period' }
        // {
        //   title: '操作',
        //   align: 'center',
        //   render: (h, { row }) => {
        //     const html = [
        //       h('a', {
        //         class: ['m-r-5'],
        //         on: {
        //           click: () => {
        //             this.onEditRes(row)
        //           }
        //         }
        //       }, '编辑规格'),
        //       h('a', {
        //         style: { color: '#f56c6c' },
        //         on: {
        //           click: () => {
        //             this.handleDeleteResItem(row)
        //           }
        //         }
        //       }, '删除')
        //     ]
        //     return h('div', html)
        //   }
        // }
      ],
      editResModal: false,
      editModel: {
        duration: 1,
        period: 1
      }
    }
  },
  computed: {
    assetArray () {
      return this.$store.state.productManage.assetArray
    },
    opType () {
      return this.$store.state.productManage.opType
    }
  },
  methods: {
    showModal () {
      this.resBoxShow = 'tab_exist'
      this.resQuery.assetId = this.assetIds[0]
      this.existResQuery.skuId = this.skuId
      this.selectedResArray = []
      this.selectedExistResIds = []
      this.getExistResData()
      this.initStationArray()
      this.getPositionData()
      this.getResourceTypeData()
      this.getResData()
      this.resModal = true
    },
    handleChangeAsset () {
      this.initStationArray()
      this.getPositionData()
      this.handleSearch()
    },
    // 初始化站点选项
    initStationArray () {
      this.resQuery.stationId = null
      getStationByAsset({ assetId: this.resQuery.assetId }).then((res) => {
        if (res && !res.errcode) {
          this.stationArray = res
        } else {
          this.stationArray = []
        }
      })
    },
    getPositionData () {
      getPositions({ assetIds: JSON.stringify([this.resQuery.assetId]) }).then(res => {
        if (res && !res.errcode) {
          this.positionArray = res
        } else {
          this.positionArray = []
        }
      })
    },
    /**
    * 获取资源类型数据
    */
    getResourceTypeData () {
      getResourceTypeList({ assetId: this.resQuery.assetId }).then(res => {
        if (res && !res.errcode) {
          this.resourceTypeArray = res
        } else {
          this.resourceTypeArray = []
        }
      })
    },
    getResData () {
      this.resLoading = true
      getResourcePage(this.resQuery).then(res => {
        if (res && !res.errcode) {
          this.resList = res.list
          this.resTotal = res.totalRow
          this.resQuery.pageNumber = res.pageNumber
          // this.resList.forEach(item => {
          //   item._checked = false
          //   if (this.selectedResArray.some(x => x.id === item.id)) {
          //     item._checked = true
          //   }
          // })
        } else {
          this.resList = []
          this.resTotal = 0
          this.resQuery.pageNumber = 1
        }
      }).finally(() => { this.resLoading = false })
    },
    handlePageChange (curPage) {
      this.resQuery.pageNumber = curPage
      this.getResData()
    },
    handelChangePageSize (pageSize) {
      this.resQuery.pageSize = pageSize
      this.getResData()
    },
    handleSearch () {
      this.resQuery.pageNumber = 1
      this.getResData()
    },
    handleSelectedRes (selection) {
      const curSelections = selection.map(x => {
        return {
          id: x.id,
          code: x.code,
          duration: 1,
          period: 1
        }
      })
      this.selectedResArray = curSelections

      // // 获取当前删除的项
      // const delItems = this.resList.filter(x => !curSelections.some(s => s.id === x.id))
      // if (delItems.length) {
      //   // 排除已删除项数据
      //   this.selectedResArray = this.selectedResArray.filter(x => !delItems.some(s => s.id === x.id))
      // }

      // curSelections.forEach(item => {
      //   if (!this.selectedResArray.some(x => x.id === item.id)) {
      //     this.selectedResArray.push(item)
      //   }
      // })
    },
    onSelectedExistRes (selection) {
      this.selectedExistResIds = selection.map(x => x.id)
    },
    /**
     * 获取已有资源数据
     */
    getExistResData () {
      getSkuResourcePage(this.existResQuery).then(res => {
        if (res && !res.errcode) {
          this.existResArray = res.list
          this.existResTotal = res.totalRow
        } else {
          this.existResArray = []
          this.existResTotal = 0
        }
      })
    },
    onChangeExistPage (curPage) {
      this.existResQuery.pageNumber = curPage
      this.getExistResData()
    },
    onChangeExistPageSize (pageSize) {
      this.existResQuery.pageSize = pageSize
      this.getExistResData()
    },
    onEditRes (params) {
      // const item = {
      //   code: params.resource.code,
      //   id: params.resource.id,
      //   duration: params.duration,
      //   period: params.period
      // }
      // this.selectedResArray = [item]
      this.editResModal = true
    },
    /**
     * 确认加入
     */
    handleConfirmRes () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认信息无误并提交？',
        onOk: async () => {
          const resInfos = this.selectedResArray.map(x => {
            return {
              resourceId: x.id,
              duration: x.duration,
              period: x.period
            }
          })
          const postData = {
            projectId: this.projectId,
            skuId: this.skuId,
            resourceInfos: JSON.stringify(resInfos)
          }
          const result = await addProjectResource(postData).finally(() => { this.editResModal = false })
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.selectedResArray = []
            this.getExistResData()
            this.resBoxShow = 'tab_exist'
          }
        }
      })
    },
    /**
     * 批量删除资源
     */
    handleDeleteResItem () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要删除所选项？',
        onOk: async () => {
          const postData = {
            ids: JSON.stringify(this.selectedExistResIds)
          }
          const result = await deleteProjectResource(postData)
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.selectedExistResIds = []
            this.getExistResData()
          }
          // this.getResData()
        }
      })
    },
    /**
     * 编辑规格
     */
    onConfirmEdit () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要信息无误并提交？',
        onOk: async () => {
          const postData = {
            ids: JSON.stringify(this.selectedExistResIds),
            duration: this.editModel.duration,
            period: this.editModel.period
          }
          const result = await updateProjectResource(postData).finally(() => { this.editResModal = false })
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.selectedExistResIds = []
            this.getExistResData()
          }
        }
      })
    },
    /**
     * 一键加入
     */
    onAutoAddRes () {
      const postData = {
        duration: 1,
        period: 1,
        skuId: this.skuId,
        assetId: this.resQuery.assetId,
        keyword: this.resQuery.keyword,
        level: this.resQuery.level,
        position: this.resQuery.position,
        resourcetypeId: this.resQuery.resourcetypeId,
        stationId: this.resQuery.stationId,
        enabled: this.resQuery.enabled
      }
      autoAddProjectResource(postData).then(res => {
        if (res && res.success) {
          this.$Notice.success({ desc: '操作成功' })
          this.getExistResData()
          this.resBoxShow = 'tab_exist'
        }
      })
    }
  }
}
</script>
