<template>
  <Modal v-model="showBillDetailModal" title="账单组成明细" width="800" footer-hide>
    <div>
       <Table
         stripe border :data="list" :columns="column"></Table>
    </div>
  </Modal>
</template>
<script>
import { toMoney } from '@/utils/wnumb_own'
import { listFeeItemDetailByFeeItemId } from '@/api/statement/contractBillDetail'
export default {
  data () {
    return {
      showBillDetailModal: false,
      list: [],
      column: [
        {
          title: '费用名称',
          key: 'feeName',
          render: (h, params) => {
            return h('div', [h('p', params.row.feeName)])
          }
        },

        {
          title: '数量',
          key: 'quantity',
          render: (h, params) => {
            return h('div', [h('p', params.row.quantity)])
          }
        },
        {
          title: '记账时间',
          key: 'chargeTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.chargeTime)])
          }
        },
        {
          title: '执行时间',
          key: 'executeTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.executeTime || '-')])
          }
        },
        {
          title: '执行金额',
          key: 'executeAmount',
          render: (h, params) => {
            return h('div', [h('p', toMoney(params.row.executeAmount))])
          }
        }
      ]
    }
  },
  methods: {
    showModal (feeItemId) {
      this.initlist(feeItemId)

      this.showBillDetailModal = true
    },
    initlist (feeItemId) {
      listFeeItemDetailByFeeItemId({ feeItemId: feeItemId }).then(res => {
        this.list = res
      })
    }
  }
}
</script>
