<template>
  <Modal v-model="modifyModal" width="600">
    <p slot="header" class="text-center">创建项目</p>
    <Form ref="formValidate" :model="formData" :rules="ruleValidate">
      <FormItem label="项目名称" prop="name">
        <Input size="small" v-model="formData.name" placeholder="填写项目名称"></Input>
      </FormItem>
      <FormItem label="选择平台产品" prop="productId">
        <Select v-model="formData.productId" size="small" placeholder="请选择平台产品">
          <Option v-for="item in platProductArray" :key="'product_' + item.dictKey" :value="Number(item.dictKey)">
            {{ item.dictValue }}</Option>
        </Select>
      </FormItem>
      <FormItem label="选择项目经营方" prop="projectOwnerId">
        <Select v-model="formData.productId" size="small" placeholder="选择项目经营方">
          <Option v-for="item in childCompanyArray" :key="'com_' + item.dictKey" :value="Number(item.dictKey)">
            {{ item.dictValue }}</Option>
        </Select>
      </FormItem>
      <FormItem label="备注" prop="desc">
        <Input v-model="formData.desc" type="textarea" :autosize="{ minRows: 2, maxRows: 5 }"
          placeholder="填写备注"></Input>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button type="text" class="m-r-5" @click="modifyModal = false">取消</Button>
      <Button type="primary" @click="onSave">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { projectMixins } from '../mixins/index'
import { saveProject, updateProject } from '@/api/os/project'
export default {
  mixins: [projectMixins],
  props: {
    opreate: {
      type: Number,
      default: 1 // 1:新增，2：编辑
    },
    onSuccess: {
      type: Function,
      default: () => { }
    }
  },
  data () {
    return {
      modifyModal: false,
      formData: {
        name: '',
        productId: null,
        publisherId: null,
        desc: '',
        projectOwnerId: null
      },
      ruleValidate: {
        name: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        productId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        projectOwnerId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    showModal (data) {
      if (data && this.opreate === 2) { // 编辑
        Object.keys(this.formData).forEach(key => {
          this.formData[key] = data[key]
        })
        this.formData.projectOwnerId = data.productId
      } else {
        // Object.assign(this.$data.formData, this.$options.data().formData)
        this.formData.name = ''
        this.formData.productId = null
        this.formData.projectOwnerId = null
        this.formData.desc = ''
        this.formData.publisherId = this.publisherId
      }

      this.modifyModal = true
      this.getPlatProductData()
      this.getChildCompanyData()
    },
    onSave () {
      this.$refs.formValidate.validate(async (valid) => {
        if (valid) {
          let result = null
          if (this.opreate === 1) {
            result = await saveProject(this.formData)
          } else {
            result = await updateProject(this.formData)
          }

          if (result && result.success) {
            this.modifyModal = false
            this.onSuccess()
            this.$Notice.success({ desc: '操作成功' })
          }
        }
      })
    }
  }
}
</script>
