<!-- 结算单创建 -->
<template>
  <Modal v-model="showCreateSettlementModal" width="80%">
    <Row :gutter="8">
      <i-col span="6">
        <h3 class="p-t-10">结算单名称</h3>
        <i-input v-model="name" placeholder="可填写结算单名称"></i-input>
      </i-col>
      <i-col span="6">
        <h3 class="p-b-10">结算单备注</h3>
        <i-input v-model="mark" placeholder="可填写结算单备注"></i-input>
      </i-col>
    </Row>
    <hr style="margin: 10px 0px" />
    <Row :gutter="8">
      <i-col span="6">
        <h3 class="p-t-10">费用类型</h3>
        <Select v-model="query.feeType">
          <Option
            v-for="(item, index) in feeTypeArray"
            :key="index"
            :value="item.id"
            >{{ item.name }}</Option
          >
        </Select>
      </i-col>
      <i-col span="6">

        <h3 class="p-t-10">查询周期</h3>
        <Row :gutter="8">
          <i-col span="12" class="p-b-5">
            <DatePicker
              placeholder="开始月份"
              v-model="chkStartDate"
              type="month"
              format="yyyy-MM"
              :clearable="false"
            ></DatePicker>
          </i-col>
          <i-col span="12" class="p-b-5">
            <DatePicker
              placeholder="结束月份"
              format="yyyy-MM"
              type="month"
              v-model="chkEndDate"
              placement="bottom-end"
              :clearable="false"
            ></DatePicker>
          </i-col>
        </Row>
      </i-col>
      <i-col span="4">
        <h3 class="p-t-10">结算公司</h3>
        <Select v-model="query.companyId" @on-change="reloadList">
          <Option
            v-for="(company, index) in companyArray"
            :key="index"
            :value="company.id"
            >{{ company.name }}</Option
          >
        </Select>
      </i-col>
      <i-col span="8">
        <Row :gutter="8" class="m-b-5">
          <i-col span="18">
            <h3 class="p-t-10">关键字</h3>
            <i-input
              v-model="keyword"
              clearable
              placeholder="关键字：支持客户名称、品牌名称、合同编号、产品名称等"
            ></i-input>
          </i-col>
          <i-col span="6">
            <h3 class="p-t-10">&nbsp;</h3>
            <Button type="primary" icon="ios-search" @click="reloadList">
              <span>搜索</span>
            </Button>
          </i-col>
        </Row>
      </i-col>
    </Row>
    <Table
      stripe
      border
      :data="list"
      :columns="tableColumns"
      :height="500"
      @on-selection-change="selectionChange"
    ></Table>
    <Row>
      <i-col span="12">
        <span style="height: 28px; line-height: 28px"
          >已选中{{ chkfeeitemIds.length }}条</span
        >
      </i-col>
      <i-col span="12">
        <div class="paging_style">
          <Page
            size="small"
            :total="total"
            :page-size="query.pageSize"
            show-total
            show-sizer
            :page-size-opts="[15, 50, 100, 200, 500]"
            :current="query.pageNumber"
            @on-change="handlePageChanged"
            @on-page-size-change="handlePageSizeChanged"
          >
          </Page>
        </div>
      </i-col>
    </Row>

    <div slot="footer">
      <Button type="text" @click="showCreateSettlementModal = false"
        >取消</Button
      >
      <Button type="primary" @click="confirmCreate">确认</Button>
    </div>
  </Modal>
</template>
<script>
import { toMoney } from '@/utils/wnumb_own'
import { listContractStatementBillDetail, createbill } from '@/api/statement/bill'
import { ParseDate, lastMonthDate } from '@/utils/dateFormat'
import { formatReconcilliationStatus } from '@/utils/tagStatus'

export default {
  data () {
    return {
      showCreateSettlementModal: false,
      companyArray: [],
      total: 0,
      lastPage: true,
      query: {
        feeType: -1,
        queryType: 2,
        feeitemType: 1,
        companyId: 0,
        billStatus: -1,
        pageSize: 15,
        pageNumber: 1
      },
      currentYear: new Date().getFullYear(),
      chkStartDate: new Date().getFullYear() + '-01-01',
      chkEndDate: new Date(),
      feeTypeArray: [
        { id: -1, name: '全部' },
        { id: 1, name: '发布费' },
        { id: 2, name: '服务费' }
      ],
      list: [],
      tableColumns:
      [{
        type: 'selection',
        key: 'feeItemId',
        width: 60,
        align: 'center'
      },
      {
        title: '费用类型',
        key: 'feeTypeName'
      },
      {
        title: '公司名称',
        key: 'companyName'
      },
      {
        title: '合同编号',
        key: 'contractCode',
        render: (h, params) => {
          return h('a',
            {
              on: {
                click: () => {
                  this.contractId = params.row.contractId
                  this.modalContract = true
                }
              }
            }, params.row.contractCode)
        }
      },
      {
        title: '合同名称',
        key: 'contractName'
      },
      {
        title: '客户',
        key: 'advertiserName'
      },
      {
        title: '品牌',
        key: 'brandName'
      },
      {
        title: '合同起止日',
        key: 'contractSchedule'
      },

      {
        title: '执行月',
        key: 'executeMonth'
      },
      {
        title: '计账时间',
        key: 'chargeTime'
      },
      {
        title: '执行时间',
        key: 'executeSchedule'
      },
      {
        title: '计费名称',
        key: 'feeItemName'
      },
      {
        title: '执行额',
        key: 'executeAmount',
        render: (h, params) => {
          return h('div', [
            h(
              'span',
              toMoney(params.row.executeAmount)
            )
          ])
        }
      },

      {
        title: '状态',
        key: 'billStatus',
        width: 120,
        render: (h, data) => {
          return formatReconcilliationStatus(
            h,
            data.row.billStatus,
            data.row.billStatusName)
        }
      }
      ],
      currentPageAllItemIds: [],
      chkfeeitemIds: [],
      mark: '',
      keyword: '',
      name: ''
    }
  },
  methods: {
    showModal (companyArray, defaultId) {
      this.companyArray = companyArray
      this.list = []
      this.chkfeeitemIds = []
      this.query.companyId = defaultId || companyArray[0].id
      this.initItemArray()
      this.showCreateSettlementModal = true
    },
    reloadList () {
      this.query.pageNumber = 1
      this.initItemArray()
    },
    // 加载所有可结算的交易项
    initItemArray () {
      const that = this
      that.query.keyword = that.keyword
      that.query.startDate = this.chkStartDate
        ? ParseDate(this.chkStartDate)
        : (this.currentYear + '-01-01')
      that.query.endDate = this.chkEndDate
        ? lastMonthDate(
          this.chkEndDate.getFullYear(),
          this.chkEndDate.getMonth() + 1
        )
        : (this.currentYear + '-12-31')
      listContractStatementBillDetail(this.query).then((res) => {
        res.list.map((item) => {
          item._checked = that.chkfeeitemIds.includes(item.feeItemId)
          return item
        })
        that.currentPageAllItemIds = res.list.map((item) => {
          return item.feeItemId
        })
        that.list = res.list
        that.total = res.totalRow
        that.lastPage = res.lastPage
      })
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.initItemArray()
    },

    handlePageSizeChanged (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.initItemArray()
    },
    // 选中的结算项变更
    selectionChange (selection) {
      const that = this
      const selectionIds = selection.map((item) => {
        return item.feeItemId
      })
      // 循环当前页面所有列
      that.currentPageAllItemIds.forEach((item) => {
        // 列是否是选中状态
        if (selectionIds.includes(item)) {
          // 判断是否已在列表，不在则加入
          if (!that.chkfeeitemIds.includes(item)) {
            that.chkfeeitemIds.push(item)
          }
        } else {
          // 列未被选中
          // 判断是否已在列表，在则移除
          if (that.chkfeeitemIds.includes(item)) {
            that.chkfeeitemIds.splice(that.chkfeeitemIds.indexOf(item), 1)
          }
        }
      })
    },
    // 确认创建结算单
    confirmCreate () {
      const that = this
      const data = {
        feeitemListJsonStr: JSON.stringify(that.chkfeeitemIds),
        feeitemType: 1,
        mark: that.mark,
        name: that.name,
        companyId: that.query.companyId,
        publisherId: that.publisherId
      }
      createbill(data).then((res) => {
        if (res && !res.errcode) {
          that.showCreateSettlementModal = false
          that.$store.commit('set_settlementChannel_update', new Date())
        }
      })
    }
  },
  computed: {
    publisherId () {
      return this.$store.getters.token.userInfo.publisherId
    }
  }
}
</script>
