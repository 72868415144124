import { render, staticRenderFns } from "./servicePriceSetting.vue?vue&type=template&id=dac6c2ce"
import script from "./servicePriceSetting.vue?vue&type=script&lang=js"
export * from "./servicePriceSetting.vue?vue&type=script&lang=js"
import style0 from "./servicePriceSetting.vue?vue&type=style&index=0&id=dac6c2ce&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports