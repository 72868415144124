<template>
  <div>
    <div class="platform-buttom-list">
      <div class="bottom-button m-r-5" @click="goToIndex">
        项目管理
      </div>
      <div class="bottom-button-active">
        代理商管理
      </div>
    </div>
    <div class="m-b-10 text-right">
      <div><Button type="primary" size="small" @click="onAdd">添加代理商</Button></div>
    </div>
    <div>
      <Table stripe max-height="620" size="small" :data="list" :columns="tableColumns" :loading="tableLoading"></Table>
    </div>
    <add-agent ref="addAgent" :projectId="id" :onSuccess="getTableData" />
    <product-list ref="productList" :projectId="id" :companyId="choicedId" />
    <asset-package-list ref="assetPackageList" :companyId="choicedId" :projectId="id" />
  </div>
</template>

<script>
import { projectCommonMxins } from '../../mixins/common'
import { getProjectCompany, removeAgency } from '@/api/os/project'

import AddAgent from './Add.vue'
import AssetPackageList from './AssetPackageList.vue'
import ProductList from './ProductList.vue'
export default {
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  mixins: [projectCommonMxins],
  components: {
    AddAgent,
    ProductList,
    AssetPackageList
  },
  data () {
    return {
      query: {
        projectId: null,
        type: 3 // 3：代理商；5：供应商
      },
      tableLoading: false,
      list: [],
      tableColumns: [
        { title: '公司名称', align: 'center', key: 'name' },
        { title: '联系电话', align: 'center', key: 'tel' },
        {
          title: '操作',
          align: 'center',
          render: (h, { row }) => {
            const html = []
            html.push(
              h('a', {
                class: ['m-r-5'],
                on: {
                  click: () => {
                    this.onViewSku(row)
                  }
                }
              }, 'SKU管理'),
              h('a', {
                class: ['m-r-5'],
                on: {
                  click: () => {
                    this.onViewPackage(row)
                  }
                }
              }, '资产包管理'),
              h('a', {
                style: {
                  color: '#ef4f4f'
                },
                on: {
                  click: () => {
                    this.onRemoveCom(row)
                  }
                }
              }, '移除')
            )
            return h('div', html)
          }
        }
      ],
      choicedId: -1
    }
  },
  mounted () {
    this.query.projectId = this.id
    this.getTableData()
  },
  methods: {
    async getTableData () {
      this.tableLoading = true
      const result = await getProjectCompany(this.query).finally(() => { this.tableLoading = false })
      this.list = (result && !result.errcode) ? result : []
    },
    onAdd () {
      this.$nextTick(() => {
        this.$refs.addAgent.showModal()
      })
    },
    onRemoveCom (params) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认要移除当前代理商？',
        onOk: async () => {
          const postData = {
            agencyIds: JSON.stringify([params.id]),
            projectId: this.id
          }
          const result = await removeAgency(postData)
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.getTableData()
          }
        }
      })
    },
    onViewSku (params) {
      this.choicedId = params.id
      this.$nextTick(() => {
        this.$refs.productList.showModal()
      })
    },
    onViewPackage (params) {
      this.choicedId = params.id
      this.$nextTick(() => {
        this.$refs.assetPackageList.showModal()
      })
    }
  }
}
</script>
