<template>
  <div>
    <container>
      <div slot="right_box_content">
        <component ref="right" :is="curRightComponent" :id="projectId" />
      </div>
      <div slot="bottom_box_content">
        <component :is="curBottomComponent" :id="projectId"></component>
      </div>
      <div slot="bg_map"></div>

    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'

import productStoreModule from '@/store/modules/productManage'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    Bottom: () => import('./components/product/Index.vue')
  },
  data () {
    return {
      projectId: null
    }
  },
  mounted () {
    if (!this.$route.params.id || isNaN(Number(this.$route.params.id))) {
      this.$Notice.error({ desc: '非法访问，缺少必要参数' })
      this.$router.push({ name: 'project-index' })
      return
    }

    if (!this.$store.hasModule('productManage')) {
      this.$store.registerModule('productManage', productStoreModule)
    }

    this.projectId = Number(this.$route.params.id)
    this.setShowLeftBlock(false)
    this.setShowRightBlock(false)
    this.setShowMap(false)

    this.setBottomHeight('90%')
    this.setBottomComponent('Bottom')
    // this.setRightComponent('Right')
  },
  methods: {
    // /**
    //  * 重新加载右侧数据
    //  */
    // reloadRightData () {
    //   this.$refs.right.initData()
    // }
  },
  destroyed () {
    // 重置state，并注销moudle
    this.$store.commit('reset_state')
    this.$store.unregisterModule('productManage')
  }
}
</script>
