import request from '@/utils/requestV2'
const qs = require('qs')

// 延期订单锁定时间
export function extendTime (data) {
  return request({
    url: '/ooh-order/v1/order/extendtime',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 发起订单
export function addOrder (data) {
  return request({
    url: '/ooh-order/v1/order/addorder',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 复制订单
export function copyOrder (data) {
  return request({
    url: '/ooh-order/v1/order/copyorder',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 订单预提交
export function beforeSubmit (data) {
  return request({
    url: '/ooh-order/v1/order/beforesubmit',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 删除订单
export function deleteOrder (data) {
  return request({
    url: '/ooh-order/v1/order/deleteorder',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 根据订单状态和用户获取客户信息
export function getOrderAdvertiser (data) {
  return request({
    url: '/ooh-order/v1/order/getadvertiser',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取订单价格汇总清单
export function getChargeList (data) {
  return request({
    url: '/ooh-order/v1/order/getchargelist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取订单冲突
export function getConflictList (data) {
  return request({
    url: '/ooh-order/v1/order/getconflictlist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取资源冲突的订单数量，默认查公司及下属公司所有，如查个人传递userId。
export function getConflictOrderQuantity (data) {
  return request({
    url: '/ooh-order/v1/order/getconflictorderquantity',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 订单详情
export function getOrder (data) {
  return request({
    url: '/ooh-order/v1/order/getorder',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取订单相关指标
export function getOrderIndex (data) {
  return request({
    url: '/ooh-order/v1/order/getorderindex',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取订单单品信息
export function getOrderInfo (data) {
  return request({
    url: '/ooh-order/v1/order/getorderinfo',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取订单基础信息(多订单)
export function getOrderList (data) {
  return request({
    url: '/ooh-order/v1/order/getorderlist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取订单列表
export function getOrderPage (data) {
  return request({
    url: '/ooh-order/v1/order/getorderpage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// // 获取上刊列表
// export function getInstallOrderPage (data) {
//   return request({
//     url: '/ooh-order/v1/order/getinstallorderpage',
//     method: 'post',
//     data: qs.stringify(data)
//   })
// }
// 获取订单列表
export function updateSchedule (data) {
  return request({
    url: '/ooh-order/v1/order/updateschedule',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 提交订单
export function submitOrder (data) {
  return request({
    url: '/ooh-order/v1/order/submitorder',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 更新订单备注信息
export function updateInfo (data) {
  return request({
    url: '/ooh-order/v1/order/updateinfo',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取订单包含的产品KIND
 * @param {Object} data
 */
export function getProductKindList (data) {
  return request({
    url: '/ooh-order/v1/order/getproductkindlist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// // 临近上刊订单数量、资源数量
// export function getNearDemandQuantity (data) {
//   return request({
//     url: '/ooh-order/v1/order/getneardemandquantity',
//     method: 'post',
//     data: qs.stringify(data)
//   })
// }
// 设置订单的联合投放商家数
export function setBusinessCount (data) {
  return request({
    url: '/ooh-order/v1/order/setbusinesscount',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 撤销订单
export function revoke (data) {
  return request({
    url: '/ooh-order/v1/order/revoke',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取订单内包含的站点集合
export function getOrderStationList (data) {
  return request({
    url: '/ooh-order/v1/order/getstationlist',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取订单导出配置字典
 */
export function getExcelConfigs () {
  return request({
    url: '/ooh-order/v1/order/getexcelconfigs',
    method: 'post'
  })
}
// 锁位不确定订单
export function lockOrder (data) {
  return request({
    url: '/ooh-order/v1/order/lockorder',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取订单方案列表
export function getOrderExcelList (data) {
  return request({
    url: '/ooh-order/v1/order/getorderexcellist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 开始生成订单方案excel
export function getOrderExcel (data) {
  return request({
    url: '/ooh-order/v1/order/getorderexcel',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 下载订单方案
export function downloadExcel (data) {
  return request({
    url: '/ooh-order/v1/order/downloadexcel',
    method: 'get',
    params: data
  })
}
// 获取订单冲突详情
export function getConflictInfo (data) {
  return request({
    url: '/ooh-order/v1/order/getconflictinfo',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 订单锁位且标识为已完成，可用于履约进程
export function finishOrder (data) {
  return request({
    url: '/ooh-order/v1/order/finishorder',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取平台交易信息
export function getdealinfo (data) {
  return request({
    url: '/ooh-order/v1/platform/getdealinfo',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取订单发布类型
export function getOrderTypeList () {
  return request({
    url: '/ooh-order/v1/order/gettypelist',
    method: 'get'
  })
}
// 获取订单状态
export function getOrderStatus () {
  return request({
    url: '/ooh-order/v1/order/getorderstatus',
    method: 'get'
  })
}
// 获取订单冲突预测详情
export function getConflictPredictList (data) {
  return request({
    url: '/ooh-order/v1/order/getConflictPredictList',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 更新订单备注信息
export function updateOrderRemark (data) {
  return request({
    url: '/ooh-order/v1/order/updateinfo',
    method: 'post',
    data: qs.stringify(data)
  })
}

// ---------------业主订单------------------
// 获取业主订单列表
export function getOwnerOrderPage (data) {
  return request({
    url: '/ooh-order/v1/order/getOwnerOrderPage',
    method: 'post',
    data: qs.stringify(data)
  })
}
